import { StyledEditDetailsContainer } from '@/modules/site-details/styled'
import { dataTestId } from '@/tests/testid'
import EditDetailsForm from '@/modules/site-details/components/contract-details/edit-details/edit-details-form'

const EditDetails = () => {
  return (
    <StyledEditDetailsContainer data-testid={dataTestId.siteDetailsEditDetails}>
      <EditDetailsForm />
    </StyledEditDetailsContainer>
  )
}

export default EditDetails
