import { Flexbox, Text, Divider } from '@skf-internal/ui-components-react'
import { FC } from 'react'
import { CustomerNotesMessage } from '@/models/reportStatus/types'
import { dataTestId } from '@/tests/testid'
import {
  StyledCustomerNotesHistoryContainer,
  StyledCustomerNotesHistoryNoteContainer,
} from '@/modules/report-status/styled'
import SKfCustomerNoteHistory from '@/modules/report-status/components/history/customerNotes/SKFCustomerNoteHistory'
import HalCustomerNoteHistory from '@/modules/report-status/components/history/customerNotes/HalCustomerNoteHistory'
import { DateFormats, formatDate } from '@/shared/dateUtils'

interface CustomerNotesHistoryProps {
  customerNote: CustomerNotesMessage | undefined
  userName: string
}

const CustomerNotesHistory: FC<CustomerNotesHistoryProps> = ({ customerNote, userName }) => {
  if (customerNote === undefined) {
    return null
  }

  const createSKFNote = (
    customerNoteRecommendedActions: {
      code: string
      additionalNotes: string
    }[]
  ): string => {
    let note: string = ''

    customerNoteRecommendedActions.forEach((action) => {
      note += `${action.code} ${action.additionalNotes},`
    })

    return note
  }

  return (
    <>
      <StyledCustomerNotesHistoryContainer
        feGap="xxl"
        feFlexDirection="column"
        data-testid={dataTestId.customerNotesHistory}
      >
        <Flexbox feFlexDirection="column" feGap="lg">
          <StyledCustomerNotesHistoryNoteContainer>
            <span>
              <b>{customerNote.isSkf ? 'SKF' : 'HAL'}</b>
            </span>
            <span>{formatDate(customerNote.noteDate, DateFormats.AmericanDateTimeFormat)}</span>
            <span>{customerNote.isSkf ? userName : undefined}</span>
          </StyledCustomerNotesHistoryNoteContainer>
          <div>
            <Text feFontWeight="bold">Note:</Text>
            <Text>{customerNote.isSkf ? createSKFNote(customerNote.recommendedActions) : customerNote.notes}</Text>
          </div>
        </Flexbox>
        {customerNote.isSkf ? (
          <SKfCustomerNoteHistory customerNote={customerNote} />
        ) : (
          <HalCustomerNoteHistory customerNote={customerNote} />
        )}
      </StyledCustomerNotesHistoryContainer>
      <Divider />
    </>
  )
}
export default CustomerNotesHistory
