import { AxiosError, AxiosRequestConfig } from 'axios'
import { AxiosInterceptors, BaseClient } from './BaseClient'
import { getBaseClientUrl, getAuthToken } from '@/api/baseClientUtils'

export class NetworkClient extends BaseClient {
  async injectHeaders(config: AxiosRequestConfig): Promise<AxiosRequestConfig> {
    if (config.url && /.*\{.*\}.*/.test(config.url) && config.params) {
      config.url = Object.values(config.params).reduce((acc: string, value) => {
        return acc.replace(/\${(.*?)}/, value as string)
      }, config.url)
      config.params = undefined
    }

    config.baseURL = getBaseClientUrl(config.url)

    if (config.baseURL) {
      //In case of s3 bucket the base utl will be undefined and we do not need to add auth token
      config.headers = { Authorization: await getAuthToken() }
    } else {
      config.headers = {
        'Cache-Control': 'no-cache, no-store, must-revalidate',
        Pragma: 'no-cache',
        Expires: '0',
      }
    }

    return config
  }

  addInterceptors(axiosInterceptors: AxiosInterceptors): void {
    super.addInterceptors(axiosInterceptors)
    axiosInterceptors.request.use(this.injectHeaders, (error: AxiosError) => Promise.reject(error))
  }
}

const networkClient = new NetworkClient()

export default networkClient
