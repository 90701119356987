import { useAutomaticsDiagnosticsContext } from '@/contexts/automaticDiagnostics'
import { generateAutomaticDiagnosticsCSVData } from '@/modules/automatic-diagnostics/utils/index'
import ModuleHeader from '@/shared/components/ModuleHeader'
import useDeepCompareEffect from '@/shared/hooks/useDeepCompareEffect'
import {
  setModuleHeaderActionPanelData,
  setModuleHeaderCSVData,
  setModuleHeaderConfigData,
} from '@/store/moduleHeader/actions'
import { useAppDispatch, useTypedSelector } from '@/store/store'

const AutomaticDiagnosticsHeader = () => {
  const automaticDiagnosticsHeaderDispatch = useAppDispatch()
  const { selectedCustomer } = useTypedSelector((state) => ({ ...state }))
  const { automaticsDiagnosticsData } = useAutomaticsDiagnosticsContext()

  useDeepCompareEffect(() => {
    automaticDiagnosticsHeaderDispatch(
      setModuleHeaderConfigData({
        showHeader: true,
        title: 'Automatic Diagnostics',
        showInfoPanel: false,
        showActionPanel: true,
        subtitle: '',
        showSubtitle: false,
      })
    )

    automaticDiagnosticsHeaderDispatch(
      setModuleHeaderActionPanelData({
        withLoader: true,
        withCSVDownloadButton: true,
        csvDownLoadButtonSize: 'lg',
        withClearFiltersButton: false,
        withRefreshButton: false,
        withTableStateChangeSwitch: false,
      })
    )
  }, [automaticsDiagnosticsData])

  useDeepCompareEffect(() => {
    if (automaticsDiagnosticsData) {
      automaticDiagnosticsHeaderDispatch(
        setModuleHeaderCSVData({
          csvData: generateAutomaticDiagnosticsCSVData(automaticsDiagnosticsData, selectedCustomer.name),
          moduleName: 'Automatic Diagnostics',
        })
      )
    }
  }, [automaticsDiagnosticsData, selectedCustomer])

  return <ModuleHeader />
}

export default AutomaticDiagnosticsHeader
