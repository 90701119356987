import { useRef, useCallback } from 'react'
import { Row } from '@tanstack/react-table'
import { AssetRow } from '@/models/analysisBacklog/types'
import { RecommendedAction } from '@/models/unresolvedRecommendations/types'
import { OrphanNoteAcknowledged, OrphanNoteUnacknowledged } from '@/models/orphanNotes/types'
import { DevicesSensorsTableDeviceRowData } from '@/models/devicesAndSensors/types'

/**
 * A custom React hook that facilitates keyboard navigation within a table.
 *
 * This hook allows users to navigate through table rows using the arrow keys.
 * When the up or down arrow key is pressed, the focus moves to the previous or next
 * table row, respectively, enabling keyboard accessibility for table navigation.
 *
 * @returns {{
 *   tbodyRef: React.RefObject<HTMLTableSectionElement>, // A ref object that should be attached to the <tbody> element of the table.
 *   handleKeyDown: (event: React.KeyboardEvent<HTMLTableRowElement>, row: Row<AssetRow> | Row<RecommendedAction> | Row<OrphanNoteUnacknowledged | OrphanNoteAcknowledged> | Row<DevicesSensorsTableDeviceRowData>) => void // A callback function to handle key down events on table rows.
 * }} An object containing a reference to the table body and the keyboard event handler.
 */

const useTableKeyboardNavigation = () => {
  const tbodyRef = useRef<HTMLTableSectionElement>(null)

  const handleKeyDown = useCallback(
    (
      event: React.KeyboardEvent<HTMLTableRowElement>,
      row:
        | Row<AssetRow>
        | Row<RecommendedAction>
        | Row<OrphanNoteUnacknowledged | OrphanNoteAcknowledged>
        | Row<DevicesSensorsTableDeviceRowData>
    ) => {
      event.preventDefault()
      const currentRow = tbodyRef.current?.children.namedItem(row.id) as HTMLTableRowElement | null

      if (event.key === 'ArrowUp') {
        const previousRow = currentRow?.previousElementSibling as HTMLTableRowElement | null
        previousRow?.focus()
      }
      if (event.key === 'ArrowDown') {
        const nextRow = currentRow?.nextElementSibling as HTMLTableRowElement | null
        nextRow?.focus()
      }
    },
    []
  )

  return { tbodyRef, handleKeyDown }
}

export default useTableKeyboardNavigation
