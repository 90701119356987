import { useFaultsFormStateContext } from '@/contexts/moduleContexts/faultsForm'
import { UpdateFaultPath } from '@/models/reportStatus/faultsFormStateTypes'
import { StyledRadioButton } from '@/modules/report-status/styled'
import { onComponentChange } from '@/modules/report-status/utils/formUtils'
import { dataTestId } from '@/tests/testid'
import { RadioGroupItem } from '@skf-internal/ui-components-react'
import { FC } from 'react'

interface FormRadioGroupProps {
  value: string
  items: RadioGroupItem[]
  legend: string
  fieldPath: UpdateFaultPath
}

const FormRadioGroup: FC<FormRadioGroupProps> = ({ value, items, legend, fieldPath: { fieldId, fieldName } }) => {
  const { faultsFormDispatch } = useFaultsFormStateContext()

  return (
    <StyledRadioButton
      feHorizontal
      value={value}
      feItems={items}
      feLegend={legend}
      onChange={(_, value) => onComponentChange(value === 'yes' ? true : false, faultsFormDispatch, fieldId, fieldName)}
      data-testid={dataTestId.formRadioGroup}
    />
  )
}

export default FormRadioGroup
