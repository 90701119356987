import { useRef } from 'react'
import EditDetails from '@/modules/site-details/components/contract-details/edit-details'
import { StyledContractDetailsTabs } from '@/modules/site-details/styled'
import { dataTestId } from '@/tests/testid'

const ContractDetailsTabs = () => {
  const tabsItemsRef = useRef([
    {
      label: 'Edit Details',
      id: 'edit-details',
      children: <EditDetails />,
    },
  ])
  return (
    <StyledContractDetailsTabs
      feType="expanded"
      feItems={tabsItemsRef.current}
      data-testid={dataTestId.siteDetailsContractDetailsTabs}
    />
  )
}

export default ContractDetailsTabs
