import { WidgetType } from '@/models/dashboard/types'
import { LoadingType } from './useApi'
import { addWidgetType, startGlobalLoading, startHeaderLoading, stopLoading } from '@/store/loading/action'
import { useAppDispatch } from '@/store/store'

/**
 * A custom hook for dispatching loading actions.
 *
 * This hook provides a function to handle loading states based on the provided parameters.
 * It can dispatch actions to start or stop loading globally, for headers, or for specific widgets.
 *
 * @returns {(params: { loaderType?: number; widgetType?: WidgetType }[]) => void} A function
 *          that takes an array of parameters. Each parameter can specify a loading type and
 *          an optional widget type to manage loading states accordingly.
 */

const useLoadingDispatch = () => {
  const loadingDispatch = useAppDispatch()

  /**
   * handleLoading dispatches loading actions based on the provided loaderType.
   *
   * @param {Object[]} params - Array of objects containing loaderType.
   * @param {number} params[].loaderType - Type of loader (GLOBAL, HEADER, NONE).
   */

  const handleLoading = (params: { loaderType?: number; widgetType?: WidgetType }[]) => {
    params.forEach((item) => {
      if (item.loaderType === LoadingType.GLOBAL) {
        loadingDispatch(startGlobalLoading())
      } else if (item.loaderType === LoadingType.HEADER) {
        loadingDispatch(startHeaderLoading())
      } else if (item.loaderType === LoadingType.WIDGET) {
        if (item.widgetType) {
          loadingDispatch(addWidgetType(item.widgetType))
        }
      } else {
        loadingDispatch(stopLoading())
      }
    })
  }

  return handleLoading
}

export default useLoadingDispatch
