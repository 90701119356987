import { Period, Slice } from '@/models/widgets/asset-data-collection/types'
import { useCallback, useMemo, useState } from 'react'

export type UseSlicesRangeReturn<T extends object, P extends Period> = {
  slices: Slice<T, P>[]
  navigateLeft: () => void
  canNavigateLeft: boolean
  navigateRight: () => void
  canNavigateRight: boolean
}

export function useSlicesRange<T extends object, P extends Period>(
  slices: Slice<T, P>[],
  numberOfSlicesToShow: number
): UseSlicesRangeReturn<T, P> {
  const safeNumberOfSlicesToShow = Math.min(numberOfSlicesToShow, slices.length)

  const [showRightOffset, setShowRightOffset] = useState(0)
  const canNavigateLeft = showRightOffset + safeNumberOfSlicesToShow < slices.length
  const canNavigateRight = showRightOffset > 0

  const navigateLeft = useCallback(() => {
    if (canNavigateLeft) {
      setShowRightOffset((prev) => prev + 1)
    }
  }, [setShowRightOffset, canNavigateLeft])

  const navigateRight = useCallback(() => {
    if (canNavigateRight) {
      setShowRightOffset((prev) => prev - 1)
    }
  }, [setShowRightOffset, canNavigateRight])

  const shownSlices = useMemo(() => {
    return slices.slice(
      -(safeNumberOfSlicesToShow + showRightOffset),
      showRightOffset !== 0 ? -showRightOffset : undefined
    )
  }, [slices, safeNumberOfSlicesToShow, showRightOffset])

  return useMemo(
    () => ({ slices: shownSlices, navigateLeft, canNavigateLeft, navigateRight, canNavigateRight }),
    [shownSlices, navigateLeft, canNavigateLeft, navigateRight, canNavigateRight]
  )
}
