// =================================================================================================
// IMPORTS
// =================================================================================================

import { ROUTES } from '@/shared/constants'
import { getRouteFromPathname } from '@/shared/utils'
import { css } from '@emotion/react'
import { FC, useMemo } from 'react'
import { useLocation } from 'react-router-dom'

// =================================================================================================
// COMPONENT
// =================================================================================================

export const ColumnDef: FC<{ columnWidth?: number }> = ({ columnWidth }) => {
  const { pathname } = useLocation()

  const isDeviceSensorPath = useMemo(() => {
    const currentRoute = getRouteFromPathname(pathname)
    return currentRoute === ROUTES.DEVICES_AND_SENSORS
  }, [pathname])

  const width = columnWidth ? (isDeviceSensorPath ? `${columnWidth}%` : `${columnWidth}px`) : '100%'

  return (
    <col
      span={1}
      css={css`
        width: ${width};
      `}
    ></col>
  )
}
