import { getAnalyticsBacklogAssets } from '@/api/paths/analysis'
import { GetAnalysisBacklogAssetsResponse } from '@/api/paths/analysis/types'
import { useApi } from '@/shared/hooks/useApi'
import { STORAGE_REPORT_STATUS_ORIGIN } from '@/shared/localStorageUtils'
import { getSidFromPathname } from '@/shared/utils'
import { AxiosError } from 'axios'
import { useParams } from 'react-router-dom'

const useGetAnalyticsBacklogData = (
  onSuccess: ((result: GetAnalysisBacklogAssetsResponse) => Promise<void>) | undefined,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onError: ((error: AxiosError<unknown, any>) => Promise<void>) | undefined
) => {
  let { sid: customerId } = useParams()

  if (!customerId) {
    customerId = getSidFromPathname(sessionStorage.getItem(STORAGE_REPORT_STATUS_ORIGIN))
  }

  return useApi(async () =>
    getAnalyticsBacklogAssets(
      {
        onSuccess,
        onError,
      },
      {
        customerId,
      }
    )
  )
}

export default useGetAnalyticsBacklogData
