import { isEqual } from '@/shared/utils'
import { DependencyList, EffectCallback, useEffect, useRef } from 'react'

/**
 * A custom hook that works like `useEffect`, but performs a deep comparison of dependencies
 * to avoid unnecessary effect executions. The effect will only re-run if the deep comparison
 * determines that the dependencies have changed.
 *
 * @param {EffectCallback} callback - The function to be executed when the dependencies change.
 * @param {DependencyList} dependencies - The list of dependencies for the effect,
 * which will be deeply compared to decide whether the effect should be re-executed.
 */

const useDeepCompareEffect = (callback: EffectCallback, dependencies: DependencyList) => {
  const currentDependenciesRef = useRef<DependencyList>()
  if (!isEqual(currentDependenciesRef.current, dependencies)) {
    currentDependenciesRef.current = dependencies
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(callback, [currentDependenciesRef.current])
}

export default useDeepCompareEffect
