import { FC, useCallback, useState } from 'react'
import { addToDate, epochDate, parseDate } from '@/shared/dateUtils'
import { DatePicker } from '@skf-internal/ui-components-react'
import { ReportDate } from '@/models/reportGenerator/types'
import { StyledReportDateSelectWrap } from '@/modules/report-generator/styled'
import { dataTestId } from '@/tests/testid'

interface ReportDateSelectProps {
  value: ReportDate
  onChange: (value: ReportDate) => void
}

const ReportDateSelect: FC<ReportDateSelectProps> = ({ value, onChange }) => {
  const [startDate, setStartDate] = useState<Date | undefined>(value?.startDate)
  const [endDate, setEndDate] = useState(value?.endDate)

  const handleDateRangeChange = useCallback(
    (dates: [Date | undefined, Date | undefined]) => {
      const [start, end] = dates
      setStartDate(start)
      setEndDate(end)

      if (start && end) {
        onChange({ startDate: start, endDate: end })
      }
    },
    [onChange]
  )

  return (
    <StyledReportDateSelectWrap data-testid={dataTestId.generateReportFiltersReportDateSelect}>
      <DatePicker
        feSize={'sm'}
        feLabel="Report date"
        onChange={(range) => handleDateRangeChange(range as [Date, Date])}
        value={[startDate, endDate]}
        feRange
        feMinDate={epochDate().toDate()}
        feMaxDate={parseDate(addToDate(new Date(), 100, 'years'))}
        allowClear
      />
    </StyledReportDateSelectWrap>
  )
}
export default ReportDateSelect
