import { css } from '@emotion/react'
import { FC } from 'react'
import { RAwidgetDataType } from '@/models/widgets/recommended-actions/types'
import RATableRow from './table-row/RATableRow'
import { StyledRAWidgetTable, StyledRANoDataContainer } from '../styled'
import { useDashboardContext } from '@/contexts/dashboard'
import { dataTestId } from '@/tests/testid'

interface RecommendedActionsTableProps {
  slices: RAwidgetDataType
  showPercentages: boolean
  noDataInAllMonth: boolean
}

const RecommendedActionsTrendViewTable: FC<RecommendedActionsTableProps> = ({
  slices,
  showPercentages,
  noDataInAllMonth,
}) => {
  const { dashboardFilters } = useDashboardContext()

  return (
    (noDataInAllMonth && <StyledRANoDataContainer>No Data</StyledRANoDataContainer>) || (
      <StyledRAWidgetTable data-testid={dataTestId.recommendedActionsTrendViewTable}>
        <colgroup>
          <col span={12} />
          <col
            span={1}
            css={css`
              background-color: #bfdbfe99;
            `}
          />
        </colgroup>
        <tbody>
          {dashboardFilters.raStatus.includes('created') && (
            <RATableRow status={'created'} slices={slices} showPercentages={showPercentages} />
          )}
          {dashboardFilters.raStatus.includes('pending') && (
            <RATableRow status={'pending'} slices={slices} showPercentages={showPercentages} />
          )}
          {dashboardFilters.raStatus.includes('closed') && (
            <RATableRow status={'closed'} slices={slices} showPercentages={showPercentages} />
          )}
        </tbody>
      </StyledRAWidgetTable>
    )
  )
}

export default RecommendedActionsTrendViewTable
