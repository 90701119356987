import { SkfCustomerNoteData } from '@/models/reportStatus/types'
import { StyledInfoCard } from '@/modules/report-status/styled'
import { FC } from 'react'
import NoteInfo from './NoteInfo'
import { DateFormats, formatDate } from '@/shared/dateUtils'
import { dataTestId } from '@/tests/testid'

interface SkfInfoCardProps {
  noteData: SkfCustomerNoteData
}

const SkfInfoCard: FC<SkfInfoCardProps> = ({ noteData }) => {
  return (
    <StyledInfoCard data-testid={dataTestId.skfInfoCard}>
      <NoteInfo label="Fault" notes={noteData.fault} />
      <NoteInfo label="Collection Date" notes={formatDate(noteData.collectionDate, DateFormats.AmericanAMPMFormat)} />
      <NoteInfo label="Status" notes={noteData.status} />
      <NoteInfo label="Observation" notes={noteData.comments} />
      <NoteInfo label="Analyst Name" notes={noteData.userName} />
    </StyledInfoCard>
  )
}

export default SkfInfoCard
