import { fetchFaultEvidenceImage } from '@/api/paths/documents/files'
import { EvidenceImageData } from '@/models/reportStatus/evidenceTypes'
import { useApi } from '@/shared/hooks/useApi'
import { openToast } from '@/store/genericToast/actions'
import { useAppDispatch } from '@/store/store'

/**
 * Fetches an evidence image from a specified URL.

 * @param {EvidenceImageData} imageRequestData - The image data, containing the image URL.
 * @returns {() => Promise<void>} A function that triggers the image fetching process and returns a Promise.

 * @description
 * This hook initiates the process of fetching an evidence image from a given URL. It dispatches a Redux action to handle success or error scenarios, displaying appropriate toast messages.
 */
const useFetchFaultEvidenceImage = (imageRequestData: EvidenceImageData) => {
  const fetchFaultEvidenceImageDispatch = useAppDispatch()

  return useApi(() => {
    return fetchFaultEvidenceImage(
      { imageUrl: imageRequestData.imageInfo.fileURL },
      {
        onError: async () => {
          fetchFaultEvidenceImageDispatch(
            openToast({
              children: 'Image download failed, please try again later',
              feSeverity: 'error',
            })
          )
        },
      }
    )
  })
}

export default useFetchFaultEvidenceImage
