import { StyledNewNoteNotificationContainer } from '@/modules/report-status/styled'
import Badge from '@/shared/components/Badge'
import { DateFormats, formatDate } from '@/shared/dateUtils'
import { dataTestId } from '@/tests/testid'
import { Icon, Text } from '@skf-internal/ui-components-react'
import { FC } from 'react'

interface NewNoteNotificationProps {
  newestNoteCreationDate: Date | undefined
}

const NewNoteNotification: FC<NewNoteNotificationProps> = ({ newestNoteCreationDate }) => {
  return (
    <StyledNewNoteNotificationContainer feGap="md" data-testid={dataTestId.newNoteNotification}>
      <Badge badgeContent="">
        <Icon feIcon="email" feSize="sm" />
      </Badge>
      <Text>New Note @ {formatDate(newestNoteCreationDate, DateFormats.AmericanDateTimeFormat)}</Text>
    </StyledNewNoteNotificationContainer>
  )
}

export default NewNoteNotification
