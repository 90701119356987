import { Card, COLORS, Flexbox, FONT_SIZES, Icon, SPACINGS, styled } from '@skf-internal/ui-components-react'

export const StyledTableContainer = styled.div<{ bottomMargin: string }>`
  overflow: auto;
  position: relative;
  max-height: ${({ bottomMargin }) => `calc(100vh - ${bottomMargin})`};
`

export const StyledTable = styled.table`
  border-spacing: 0;
  width: 100%;
  table-layout: fixed;

  thead {
    background-color: ${COLORS.gray100};
    margin: 0;
    position: sticky;
    top: 0;
  }

  tbody tr {
    width: 100%;
    height: 2.875rem;
  }

  th {
    text-align: left;
    user-select: none;
    white-space: nowrap;
  }

  th > div {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  th,
  td {
    padding: ${SPACINGS.xs} ${SPACINGS.md};
  }
`
export const StyledTablePaddingData = styled.td<{ height: number }>`
  height: ${({ height }) => `${height}px`};
`

export const StyledTableHeaderColumn = styled.th<{ cursor: string; width: string }>`
  cursor: ${({ cursor }) => cursor};
  width: ${({ width }) => width};
`

export const StyledTableHeaderColumnContainer = styled.div<{ flexContentJustification: string }>`
  display: flex;
  justify-content: ${({ flexContentJustification }) => flexContentJustification};
  align-items: center;
`

export const StyledTabledHeaderColumnIconContainer = styled.div`
  display: flex;
  gap: inherit;
  align-items: inherit;
`

export const StyledTabledHeaderColumnIcon = styled(Icon)`
  color: ${COLORS.gray500};
`
export const StyledGenericFilterPopoverCard = styled(Card)`
  top: ${SPACINGS.sm};
  gap: ${SPACINGS.md};
  display: flex;
  column-gap: ${SPACINGS.xxl};
  flex-direction: column;
  border: none !important;
  box-shadow: none !important;

  * {
    font-size: 1rem !important;
  }

  & button span {
    font-size: 0.875rem !important;
  }
`

export const StyledDateFilterContentContainer = styled(Flexbox)`
  width: 31rem;
`

export const StyledListFilterSummaryContainer = styled.ul`
  list-style-type: disc;
  list-style-position: inside;
  margin: 0;
  padding: 0;

  & > li {
    font-size: ${FONT_SIZES.md};
  }
`

export const StyledDeviceStatusFilterRoot = styled(Flexbox)`
  width: 12.5rem;
`

export const StyledDeviceStatusFilterSummaryContainer = styled.ul`
  list-style-type: disc;
  list-style-position: inside;
  margin: 0;
  padding: 0;

  & > li {
    font-size: ${FONT_SIZES.md};
  }
`
