import { FC } from 'react'
import AssetHealthConditionStackedBar from './AssetHealthConditionStackedBar'
import AssetHealthConditionTable from './AssetHealthConditionTable'
import { YearMonth } from '@/models/widgets/asset-data-collection/types'
import { ConditionStatusWidgetDataType } from '@/models/widgets/asset-health-condition/types'
import { useSlicesRange } from '@/modules/dashboard/hooks/useSlicesRange'
import { dataTestId } from '@/tests/testid'
import { useNoDataInAllMonths } from '@/modules/dashboard/utils'
import { StyledAHCTrendViewContainer, StyledAHCTrendViewContent } from '../styled'

interface AssetHealthConditionTrendViewProps {
  slices: ConditionStatusWidgetDataType
  showPercentages: boolean
  selectedPeriod: YearMonth
}

const AssetHealthConditionTrendView: FC<AssetHealthConditionTrendViewProps> = ({
  slices,
  selectedPeriod,
  showPercentages,
}) => {
  const { slices: slicesRange } = useSlicesRange(slices, 12)
  const noDataInAllMonth = useNoDataInAllMonths(slices)

  return (
    <StyledAHCTrendViewContainer data-testid={dataTestId.assetHealthConditionTrendView}>
      <StyledAHCTrendViewContent>
        <AssetHealthConditionTable
          slices={slicesRange}
          selectedPeriod={selectedPeriod}
          showPercentages={showPercentages}
          noDataInAllMonth={noDataInAllMonth}
          headerWidth={chartPadding.left}
        />
        <AssetHealthConditionStackedBar
          slices={slicesRange}
          chartPadding={chartPadding}
          noDataInAllMonth={noDataInAllMonth}
          showPercentages={showPercentages}
        />
      </StyledAHCTrendViewContent>
    </StyledAHCTrendViewContainer>
  )
}

export default AssetHealthConditionTrendView

const chartPadding = {
  left: '10%',
  top: -1,
  right: 0,
  bottom: '23%',
}
