import { AnalysisReasonStringType } from '@/models/analysisBacklog/types'
import { analysisReasonsString } from '@/shared/components/TableComponents/GenericTableFilter/constants'
import { FilterSummaryProps } from '@/shared/components/TableComponents/GenericTableFilter/types'
import { StyledListFilterSummaryContainer } from '@/shared/components/TableComponents/styled'
import { dataTestId } from '@/tests/testid'
import { FC } from 'react'

const AnalysisReasonFilterSummary: FC<FilterSummaryProps<AnalysisReasonStringType[]>> = ({ filterValue }) => (
  <StyledListFilterSummaryContainer data-testid={dataTestId.analysisReasonFilterSummary}>
    {filterValue.map((v, index) => (
      <li key={index}>{analysisReasonsString[v]}</li>
    ))}
  </StyledListFilterSummaryContainer>
)

export default AnalysisReasonFilterSummary
