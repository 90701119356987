import { Select } from '@skf-internal/ui-components-react'
import { FC, useMemo } from 'react'
import { css } from '@emotion/react'
import { GlobalFilters } from '@/models/dashboard/globalFiltering/types'
import { useDashboardContext } from '@/contexts/dashboard'
import { dataTestId } from '@/tests/testid'

interface IFaultTypeSelect {
  className?: string
  value: GlobalFilters['faultType']
  onChange: (value: GlobalFilters['faultType']) => void
}
const FaultTypeSelect: FC<IFaultTypeSelect> = ({ className, value, onChange }) => {
  const { faultTypeSelectOptions } = useDashboardContext()

  const options = useMemo(
    () => [
      { label: 'All', value: 'all' },
      ...faultTypeSelectOptions.map(({ title, faultType, count }) => ({
        label: title,
        value: faultType,
        feShortLabel: count && count > 0 ? `(${count})` : '',
      })),
    ],

    [faultTypeSelectOptions]
  )

  const allValues = useMemo(() => faultTypeSelectOptions.map((option) => option.faultType), [faultTypeSelectOptions])

  const handleOnChange = (currentValue: GlobalFilters['faultType']) => {
    if (value.length === allValues.length && currentValue.length === allValues.length - 1) {
      const unselectedValue = allValues.find((val) => !currentValue.includes(val))
      onChange([unselectedValue] as GlobalFilters['faultType'])
    } else {
      onChange(currentValue)
    }
  }

  return (
    <Select
      data-testid={dataTestId.globalFilteringFaultTypeSelect}
      css={css`
        * {
          flex-wrap: wrap;
        }

        label {
          font-weight: 700;
        }

        span {
          font-weight: 400;
        }

        width: 205px;
      `}
      feSize="sm"
      className={className}
      value={value}
      onChange={handleOnChange}
      feItems={options}
      feLabel="Fault type"
      feMultiSelectionText={value.length === allValues.length ? 'All' : `${value.length} Selected`}
      feHideSelectionTags
      multiple
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      feRows={10}
    />
  )
}
export default FaultTypeSelect
