import { WidgetType } from '@/models/dashboard/types'
import {
  START_HEADER_LOADING,
  START_GLOBAL_LOADING,
  STOP_LOADING,
  ADD_WIDGET_TYPE,
  REMOVE_WIDGET_LOADER,
} from './action.types'

export const startHeaderLoading = () => ({
  type: START_HEADER_LOADING,
})

export const startGlobalLoading = () => ({
  type: START_GLOBAL_LOADING,
})

export const stopLoading = () => ({
  type: STOP_LOADING,
})

export const addWidgetType = (widgetType: WidgetType) => ({
  type: ADD_WIDGET_TYPE,
  payload: { widgetType },
})

export const removeWidgetLoader = (widgetType: WidgetType) => ({
  type: REMOVE_WIDGET_LOADER,
  payload: { widgetType },
})
