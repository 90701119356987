import { DevicesSensorsSideMenu } from '@/modules/devices-sensors/components/side-menu'
import { SideMenuCard } from '@/modules/devices-sensors/components/styled'
import { useTypedSelector } from '@/store/store'

const DevicesSensorsSideMenuCard = () => {
  const {
    globalLoading: { isGlobalLoading },
  } = useTypedSelector((state) => ({ ...state }))

  return (
    !isGlobalLoading && (
      <SideMenuCard>
        <DevicesSensorsSideMenu />
      </SideMenuCard>
    )
  )
}

export default DevicesSensorsSideMenuCard
