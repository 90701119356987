import { FetchDataContextProvider } from '@/contexts/common/fetchDataContext'
import { ReportStatusContextProvider } from '@/contexts/reportStatus'
import { dataTestId } from '@/tests/testid'
import { Outlet } from 'react-router-dom'

const ReportStatus = () => {
  return (
    <ReportStatusContextProvider>
      <FetchDataContextProvider>
        <Outlet />
        <div data-testid={dataTestId.reportStatus} />
      </FetchDataContextProvider>
    </ReportStatusContextProvider>
  )
}

export default ReportStatus
