import { StyledSubHeader, StyledSubheaderContainer, StyledSubHeaderText } from '@/modules/overview/styled'
import { dataTestId } from '@/tests/testid'
import { FC, ReactNode } from 'react'

export interface SubHeaderProps {
  titles: string[]
  gridProp?: string
  tertiarySubHeader?: ReactNode
}

const SubHeader: FC<SubHeaderProps> = ({ titles, gridProp, tertiarySubHeader = undefined }) => {
  return (
    <StyledSubheaderContainer feFlexDirection="column">
      <StyledSubHeader gridProp={gridProp}>
        {titles &&
          titles.map((title) => {
            return (
              <StyledSubHeaderText data-testid={dataTestId.subHeaderText} key={title}>
                {title}
              </StyledSubHeaderText>
            )
          })}
      </StyledSubHeader>
      {tertiarySubHeader || <></>}
    </StyledSubheaderContainer>
  )
}

export default SubHeader
