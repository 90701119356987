import { AssetStatusString } from '@/shared/models/types'

export type GlobalFilters = {
  raStatus: RecommendedActionStatusType[]
  assetStatus: StatusType[]
  faultType: string[]
}

export type FaultTypeSelectOption = {
  faultType: string
  title: string
  count?: number
}

export enum Action {
  FaultTypeSelectOptions = 'FAULT_TYPE_SELECT_OPTIONS',
}

export type RecommendedActionStatusType = 'created' | 'pending' | 'closed'
export type RaStatusSelectType = 'all' | RecommendedActionStatusType
export type StatusType = Exclude<AssetStatusString, 'never-reported'>
export type StatusSelectType = 'all' | StatusType
export type SelectFilterType = {
  raStatus: RaStatusSelectType[]
  assetStatus: StatusSelectType[]
  faultType: string[]
}
