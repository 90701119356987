import { Device } from '@/models/devicesAndSensors/types'
import DeviceDataFault from '@/modules/devices-sensors/components/side-menu/deviceSection/deviceDataFault'
import { Flexbox } from '@skf-internal/ui-components-react'
import { FC } from 'react'

const DeviceStatusIndicator: FC<{ faults: Device['faults'] }> = ({ faults }) => {
  return (
    <Flexbox feFlexDirection="column" feGap="xxs">
      {faults.length > 0 ? (
        faults.map((f) => <DeviceDataFault status={'notNormal'} fault={f} key={f} />)
      ) : (
        <DeviceDataFault status="normal" />
      )}
    </Flexbox>
  )
}

export default DeviceStatusIndicator
