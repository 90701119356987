import { DeviceStatus } from '@/models/devicesAndSensors/types'
import DeviceStatusCheckbox from '@/shared/components/TableComponents/GenericTableFilter/filterHelpComponents/DeviceStatusCheckbox'
import { FilterContentProps } from '@/shared/components/TableComponents/GenericTableFilter/types'
import { StyledDeviceStatusFilterRoot } from '@/shared/components/TableComponents/styled'
import { dataTestId } from '@/tests/testid'
import { FC, useCallback, useState } from 'react'

/**
 * A component for filtering devices based on their status.

 * @param {FilterContentProps<DeviceStatus[]>} props - The component props.
 * @property {DeviceStatus[]} props.initialValue - The initial selected device statuses.
 * @property {(newFilterValue: DeviceStatus[] | undefined) => void} props.onFilterValueChange - Callback function to handle filter value changes.
 * @returns {JSX.Element} The rendered DeviceStatusFilterContent component.
 */
const DeviceStatusFilterContent: FC<FilterContentProps<DeviceStatus[]>> = ({ initialValue, onFilterValueChange }) => {
  const [filteredStatus, setFilteredStatus] = useState<DeviceStatus[]>(initialValue || [])

  const findStatusValue = useCallback(
    (value: DeviceStatus): boolean => filteredStatus.includes(value),
    [filteredStatus]
  )

  const handleOnChange = useCallback(
    (status: DeviceStatus, checked: boolean) => {
      let newFilterValue: DeviceStatus[] | undefined = undefined

      if (checked) {
        newFilterValue = [...filteredStatus, status]
        setFilteredStatus(newFilterValue)
      } else {
        newFilterValue = filteredStatus.filter((v) => v !== status)
        setFilteredStatus(newFilterValue)
      }

      if (newFilterValue.length === 0) {
        newFilterValue = undefined
      }

      onFilterValueChange(newFilterValue)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filteredStatus]
  )

  return (
    <StyledDeviceStatusFilterRoot
      data-testid={dataTestId.deviceStatusFilterContent}
      feJustifyContent="center"
      feFlexDirection="column"
      feGap="md"
    >
      <DeviceStatusCheckbox status="notNormal" checked={findStatusValue('notNormal')} onChange={handleOnChange} />
      <DeviceStatusCheckbox status="normal" checked={findStatusValue('normal')} onChange={handleOnChange} />
    </StyledDeviceStatusFilterRoot>
  )
}

export default DeviceStatusFilterContent
