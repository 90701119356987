export type Customer = {
  id: string
  name: string
  customerNotesEnabled: boolean
}

export const siteIdsWithNotes = [
  '7f57483380b8c78f180076c4', // 'Halliburton - Blender Trucks'
  '7f57483380b8c78f180076c4', //'QA Pump Halilburton'
  'ca8dcb81ea944323b09fb0f1', //'QA Blender Halilburton'
  'b32f35d30f20b453328d9907', // 'Test Customer'
  'b32f35d30f20b453328d9907', // 'Test Customer'
]
