import { useDevicesAndSensorsContext } from '@/contexts/devicesAndSensors'
import DataRow from '@/modules/devices-sensors/components/side-menu/common/dataRow'
import { SensorImage } from '@/modules/devices-sensors/components/side-menu/common/sensorImage'
import DeviceStatusIndicator from '@/modules/devices-sensors/components/side-menu/deviceSection/deviceStatusIndicator'
import { StyledDataGrid, StyledDeviceModel } from '@/modules/devices-sensors/components/styled'
import { deviceNumberFormatter } from '@/modules/devices-sensors/utils/constants'
import { Flexbox, GridItem, Text } from '@skf-internal/ui-components-react'
import { FC } from 'react'
import { formatInternalTemp, formatProp, formatUsedBuffer } from '@/modules/devices-sensors/utils'
import {
  StyledThresholdWrapper,
  StyledTitle,
  StyledValuesWrapper,
  StyledThresholdValuesWrapper,
  StyledThresholdsDataWrapper,
  StyledData,
  StyledTitleThreshold,
} from './styled'
import { ThresholdValues } from './ThresholdValues'
import { ColoredValue } from './ColoredValue'
import { DateFormats, formatDate } from '@/shared/dateUtils'

export const DeviceDataSection: FC = () => {
  const {
    devicesAndSensorsSelectedItemsData: { device: deviceData },
  } = useDevicesAndSensorsContext()

  if (!deviceData) return <></>

  return (
    <Flexbox data-testid="device-data-section" feFlexDirection="column" feGap="md">
      <Flexbox feFlexDirection="row" feJustifyContent="space-between"></Flexbox>
      <StyledDataGrid>
        <>
          <GridItem data-testid="data-row">
            <Flexbox feFlexDirection="column" feGap="md">
              <Text feFontSize="xxl" feFontWeight="bold">
                {deviceNumberFormatter.format(deviceData.number)}. {deviceData.name}
              </Text>

              <DeviceStatusIndicator faults={deviceData.faults} />
            </Flexbox>
          </GridItem>
          <GridItem>
            <Flexbox feAlignItems="end">
              <StyledDeviceModel feFontSize="lg" feFontWeight="bold">
                {deviceData.model}
              </StyledDeviceModel>
              <SensorImage model={deviceData.model} />
            </Flexbox>
          </GridItem>
        </>

        <DataRow
          name="Last Communication"
          value={formatProp(deviceData.lastCommunicationDate, (prop) =>
            formatDate(prop, DateFormats.AmericanDateTimeFormat)
          )}
        />
      </StyledDataGrid>
      <StyledData>
        <StyledTitle>
          <div>Firmware Version</div>
          <div>Internal Temperature</div>
          <div>Used Buffer</div>
        </StyledTitle>
        <StyledThresholdWrapper>
          <StyledValuesWrapper>
            <Text feFontWeight="bold">{formatProp(deviceData.firmwareVersion, (prop) => prop)}</Text>
            <ColoredValue
              value={deviceData.internalTemp.value}
              warningHighLevel={deviceData.internalTemp.warningHighLevel}
              alarmHighLevel={deviceData.internalTemp.alarmHighLevel}
              executeFn={formatInternalTemp}
              unit={deviceData.internalTemp.unit}
            />
            <ColoredValue
              value={deviceData.usedBuffer.value}
              warningHighLevel={deviceData.usedBuffer.warningHighLevel}
              alarmHighLevel={deviceData.usedBuffer.alarmHighLevel}
              executeFn={formatUsedBuffer}
              unit={deviceData.usedBuffer.unit}
            />
          </StyledValuesWrapper>
          <StyledThresholdValuesWrapper>
            <StyledTitleThreshold>Thresholds</StyledTitleThreshold>
            <StyledThresholdsDataWrapper>
              <ThresholdValues
                title="Warning:"
                executeFnTemp={formatInternalTemp}
                executeFnBuffer={formatUsedBuffer}
                warningHighLevelTemp={deviceData.internalTemp.warningHighLevel}
                warningHighLevelBuffer={deviceData.usedBuffer.warningHighLevel}
                unitTemp={deviceData.internalTemp.unit}
                unitBuffer={deviceData.usedBuffer.unit}
              />
              <ThresholdValues
                title="Alarm:"
                executeFnTemp={formatInternalTemp}
                executeFnBuffer={formatUsedBuffer}
                warningHighLevelTemp={deviceData.internalTemp.alarmHighLevel}
                warningHighLevelBuffer={deviceData.usedBuffer.alarmHighLevel}
                unitTemp={deviceData.internalTemp.unit}
                unitBuffer={deviceData.usedBuffer.unit}
              />
            </StyledThresholdsDataWrapper>
          </StyledThresholdValuesWrapper>
        </StyledThresholdWrapper>
      </StyledData>
    </Flexbox>
  )
}
