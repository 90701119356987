import { OrphanNoteRow } from '@/models/orphanNotes/types'
import { DateFormats, formatDate } from '@/shared/dateUtils'
import { css } from '@emotion/react'
import { CellContext } from '@tanstack/react-table'

const NoteCreationDateCell = ({ row }: CellContext<OrphanNoteRow, string>) => {
  return (
    <div
      css={css`
        width: 100%;
        text-align: center;
      `}
    >
      {formatDate(row.original.composedAt, DateFormats.AmericanDateTimeFormat)}
    </div>
  )
}

export default NoteCreationDateCell
