import { ROUTES } from '@/shared/constants'
import { STORAGE_DARWIN_ERROR, STORAGE_ERROR_COUNT, STORAGE_ERROR_PATH_NAME } from '@/shared/localStorageUtils'
import { circularStringify } from '@/shared/utils'
import { setError } from '@/store/errorHandler/actions'
import { datadogLogs } from '@datadog/browser-logs'
import { Dispatch } from '@reduxjs/toolkit'
import { Component, ReactNode } from 'react'
import { connect } from 'react-redux'
import { DarwinDetachedModuleError, DarwinError, DarwinNotFoundError } from './DarwinError'

interface ErrorBoundaryProps {
  children: ReactNode
  fallbackComponent: ReactNode
  setError?: (error: Error) => void
}

interface ErrorBoundaryState {
  hasError: boolean
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props)

    this.state = {
      hasError: false,
    }
  }

  static getDerivedStateFromError(): ErrorBoundaryState {
    return { hasError: true }
  }

  componentDidCatch(error: Error) {
    this.props.setError && this.props.setError(error)
    const url = new URL(window.location.href)
    if (url.pathname !== ROUTES.ERROR && url.pathname !== ROUTES.TAB_ERROR) {
      sessionStorage.setItem(STORAGE_ERROR_PATH_NAME, url.pathname)
    }
    datadogLogs.logger.error('darwin-web-datadog-logs', {}, error)
    const errorCount = sessionStorage.getItem(STORAGE_ERROR_COUNT)
    let errorCountNumber = errorCount ? parseInt(errorCount, 10) : 0

    if (error.message.includes('401')) {
      errorCountNumber += 1
      sessionStorage.setItem(STORAGE_ERROR_COUNT, errorCountNumber.toString())
    }
  }

  public render() {
    const { children } = this.props
    return children
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setError: (errorData: Error) => {
    const darwinError = errorData as DarwinError | DarwinDetachedModuleError | DarwinNotFoundError
    localStorage.setItem(STORAGE_DARWIN_ERROR, circularStringify(darwinError))
    dispatch(setError({ error: darwinError }))
  },
})

export default connect(null, mapDispatchToProps)(ErrorBoundary)
