import React, { FC, useEffect, useRef, useState } from 'react'
import CustomerLogoCard from '@/modules/site-details/components/contract-details/customer-logo-card'
import {
  StyledEditDetailsSearch,
  StyledEditDetailsLogoCardContainer,
  StyledEditDetailsFlexboxWrapper,
  StyledEditDetailsText,
  StyledLabel,
  SuggestionsList,
  SuggestionItem,
  StyledEditDetailsSearchContainer,
  StyledSiteAddressLoader,
  StyledErrorParagraph,
  StyledEditDetailsReadOnlyText,
} from '@/modules/site-details/styled'
import useOutsideClick from '@/shared/hooks/useOutsideClick'
import { dataTestId } from '@/tests/testid'
import { Text } from '@skf-internal/ui-components-react'
import useAWSLocationService from '@/shared/hooks/useAWSLocationService'
import { useSiteDetailsContext } from '@/contexts/siteDetails'
import { useEffectOnce } from '@/shared/hooks/useEffectOnce'
import { SiteDetailsData } from '@/models/siteDetails/types'
import DragAndDropLogo from '@/modules/site-details/components/drag-and-drop'
import useTimeout from '@/shared/hooks/useTimeout'
import { ERROR_TIMEOUT } from '@/modules/site-details/utils/constants'

const EditDetailsForm: FC = () => {
  const {
    siteDetailsData,
    setIsSiteDetailsEdited,
    setSiteDetailsEditedFormData,
    siteDetailsEditedFormData,
    isSiteDetailsEdited,
    logoURL,
  } = useSiteDetailsContext()

  const [isSiteAddressEditable, setSiteAddressIsEditable] = useState(false)
  const siteAddressOutsideClickRef = useRef<HTMLInputElement>(null)

  const { searchPlaceIndex, suggestions, isLoading, resetSuggestions, error } = useAWSLocationService({})
  const { startTimeout: resetError, clearTimeout: clearResetErrorTimeOut } = useTimeout(() => {
    resetSuggestions()
  }, ERROR_TIMEOUT)

  useOutsideClick(
    () => {
      if (
        (isSiteDetailsEdited && siteDetailsEditedFormData.siteAddress === '') ||
        (!isSiteDetailsEdited && siteDetailsData.siteAddress === '')
      ) {
        setSiteAddressIsEditable(true)
      } else {
        setSiteAddressIsEditable(false)
      }
    },
    {
      refs: [siteAddressOutsideClickRef],
    }
  )

  const handleInputChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value
    setIsSiteDetailsEdited(true)
    setSiteDetailsEditedFormData({ ...siteDetailsData, siteAddress: input } as SiteDetailsData)
    await searchPlaceIndex(input)
  }

  const handleSuggestionClick = (suggestion: string) => {
    setIsSiteDetailsEdited(true)
    setSiteDetailsEditedFormData({ ...siteDetailsData, siteAddress: suggestion } as SiteDetailsData)
    setSiteAddressIsEditable(false)
  }

  useEffect(() => {
    if (!isSiteDetailsEdited) {
      resetSuggestions()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSiteDetailsEdited])

  useEffectOnce(() => {
    setSiteDetailsEditedFormData({ ...siteDetailsData } as SiteDetailsData)
  })

  useEffect(() => {
    resetError()
    return clearResetErrorTimeOut
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  useEffect(() => {
    if (siteDetailsEditedFormData.siteAddress === '' || siteDetailsData.siteAddress === '') {
      setSiteAddressIsEditable(true)
    } else if (!isSiteDetailsEdited) {
      setSiteAddressIsEditable(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteDetailsEditedFormData.siteAddress, siteDetailsData.siteAddress])

  return (
    <>
      <StyledEditDetailsFlexboxWrapper data-testid={dataTestId.siteDetailsEditDetailsForm}>
        <StyledEditDetailsReadOnlyText>
          <StyledLabel>Company name</StyledLabel>
          <Text>{siteDetailsData.companyName || '-'}</Text>
        </StyledEditDetailsReadOnlyText>
        <StyledEditDetailsReadOnlyText>
          <StyledLabel>Site name</StyledLabel>
          <Text>{siteDetailsData.siteName || '-'}</Text>
        </StyledEditDetailsReadOnlyText>
      </StyledEditDetailsFlexboxWrapper>
      <StyledEditDetailsFlexboxWrapper>
        <StyledEditDetailsLogoCardContainer>
          {logoURL ? <CustomerLogoCard showAction={true} /> : <DragAndDropLogo />}
        </StyledEditDetailsLogoCardContainer>

        <StyledEditDetailsSearchContainer feFlexDirection="column">
          {isSiteAddressEditable ? (
            <div ref={siteAddressOutsideClickRef}>
              {isLoading && <StyledSiteAddressLoader feSize="sm" />}
              <StyledEditDetailsSearch
                feLabel="Site address"
                value={isSiteDetailsEdited ? siteDetailsEditedFormData?.siteAddress : siteDetailsData.siteAddress}
                onChange={handleInputChange}
              />
              {suggestions.length > 0 && (
                <SuggestionsList>
                  {suggestions.map((suggestion, index) => (
                    <SuggestionItem key={index} onClick={() => handleSuggestionClick(suggestion)}>
                      {suggestion}
                    </SuggestionItem>
                  ))}
                </SuggestionsList>
              )}
            </div>
          ) : (
            <StyledEditDetailsText
              onClick={() => {
                setSiteAddressIsEditable(true)
              }}
            >
              <StyledLabel>Site address</StyledLabel>
              <Text>
                {isSiteDetailsEdited ? siteDetailsEditedFormData?.siteAddress : siteDetailsData.siteAddress || '-'}
              </Text>
            </StyledEditDetailsText>
          )}
          {error && <StyledErrorParagraph>{error}</StyledErrorParagraph>}
        </StyledEditDetailsSearchContainer>
      </StyledEditDetailsFlexboxWrapper>
    </>
  )
}

export default EditDetailsForm
