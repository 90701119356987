import { HalCustomerNoteData } from '@/models/reportStatus/types'
import InfoCard from '@/modules/report-status/components/customerNote/InfoCard'
import NoteCard from '@/modules/report-status/components/customerNote/NoteCard'
import { StyledCustomerIcon, StyledNoteMessageContainer } from '@/modules/report-status/styled'
import { dataTestId } from '@/tests/testid'
import { Flexbox } from '@skf-internal/ui-components-react'
import { FC } from 'react'

interface NoteMessageProps {
  customerNoteData: HalCustomerNoteData
}

const NoteMessage: FC<NoteMessageProps> = ({ customerNoteData }) => {
  return (
    <StyledNoteMessageContainer
      feGap="md"
      feAlignItems="center"
      className="noteMessage"
      feFlexDirection={customerNoteData.isSkf ? 'row-reverse' : 'row'}
      data-testid={dataTestId.customerNoteMessage}
    >
      <StyledCustomerIcon isSkf={customerNoteData.isSkf}>HAL</StyledCustomerIcon>
      <Flexbox feFlexDirection="row">
        <NoteCard customerNoteData={customerNoteData} />
        <InfoCard noteInfo={customerNoteData} />
      </Flexbox>
    </StyledNoteMessageContainer>
  )
}
export default NoteMessage
