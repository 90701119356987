import { WidgetType } from '@/models/dashboard/types'
import { ADD_WIDGET_TYPE, REMOVE_WIDGET_LOADER } from './action.types'
import { LoadingStateAction } from './types'

const initialState = {
  widgetTypes: [],
}

type initialStateType = {
  widgetTypes: WidgetType[]
}

type WidgetReducer = (state: initialStateType | undefined, action: LoadingStateAction) => initialStateType

const reducerWidgetLoading: WidgetReducer = (state = initialState, action) => {
  if (action.type === ADD_WIDGET_TYPE && !state.widgetTypes.includes(action.payload.widgetType)) {
    return {
      ...state,
      widgetTypes: [...state.widgetTypes, action.payload.widgetType],
    }
  }
  if (action.type === REMOVE_WIDGET_LOADER) {
    return {
      ...state,
      widgetTypes: state.widgetTypes.filter((type) => type !== action.payload.widgetType),
    }
  }
  return state
}

export default reducerWidgetLoading
