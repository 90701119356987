import { DarwinDetachedModuleError } from '@/shared/components/ErrorBoundary/DarwinError'
import GenericError from '@/shared/components/GenericError'
import { ROUTES } from '@/shared/constants'
import useDeepCompareEffect from '@/shared/hooks/useDeepCompareEffect'
import { STORAGE_ERROR_COUNT, STORAGE_ERROR_PATH_NAME } from '@/shared/localStorageUtils'
import { clearError, setError } from '@/store/errorHandler/actions'
import { useAppDispatch } from '@/store/store'
import { dataTestId } from '@/tests/testid'
import { AuthenticationResult, EventMessage, EventType, InteractionStatus } from '@azure/msal-browser'
import { useIsAuthenticated, useMsal } from '@azure/msal-react'
import { PropsWithChildren, useState } from 'react'
import { ROLE } from '@/../playwright/constants'

/**
 * AuthConsumer component manages authentication state and handles the login process
 * using Azure MSAL (Microsoft Authentication Library). It ensures that only authenticated users
 * with the appropriate role can access the children components.
 *
 * @component
 * @param {PropsWithChildren} props - The props for the component.
 * @param {ReactNode} props.children - The child components to be rendered if the user is authenticated and authorized.
 *
 * @returns {JSX.Element} The rendered component which either displays the children if the user is authenticated,
 * or a GenericError component if there is an authentication error.
 *
 * @throws {DarwinDetachedModuleError} Throws an error if the user is unauthorized during the login process.
 *
 * @example
 * <AuthConsumer>
 *   <YourProtectedComponent />
 * </AuthConsumer>
 */

const AuthConsumer = ({ children }: PropsWithChildren) => {
  const authConsumerDispatch = useAppDispatch()

  const isAuthenticated = useIsAuthenticated()
  const { instance: msalInstance, inProgress } = useMsal()
  const [isAuthError, setIsAuthError] = useState(false)

  const scopes = import.meta.env.VITE_AZURE_SILENCE_REQUEST_SCOPES.split(',')

  const signIn = async () => {
    const storedUrl = sessionStorage.getItem(STORAGE_ERROR_PATH_NAME)
    authConsumerDispatch(clearError())
    await msalInstance.loginRedirect({
      scopes,
      redirectStartPage: storedUrl || ROUTES.HOME,
    })
  }

  msalInstance.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
      const payload = event.payload as AuthenticationResult
      const account = payload.account
      const hasRole = account?.idTokenClaims?.roles?.includes(ROLE)
      sessionStorage.removeItem(STORAGE_ERROR_COUNT)
      if (!hasRole) {
        authConsumerDispatch(
          setError({
            error: new DarwinDetachedModuleError({
              status: 901,
              message: 'Unauthorized',
              name: '901',
              isAxiosError: false,
              /* v8 ignore next 1 */
              toJSON: () => ({}),
            }),
          })
        )
        setIsAuthError(true)
      } else {
        msalInstance.setActiveAccount(account)
        setIsAuthError(false)
      }
    } else if (
      (event.eventType === EventType.LOGIN_FAILURE && event.error?.name !== 'BrowserAuthError') ||
      (event.eventType === EventType.SSO_SILENT_FAILURE && event.error?.message.includes('monitor_window_timeout'))
    ) {
      authConsumerDispatch(
        setError({
          error: new DarwinDetachedModuleError({
            status: 401,
            message: 'Unauthorized',
            name: '401',
            isAxiosError: false,
            /* v8 ignore next 1 */
            toJSON: () => ({}),
          }),
        })
      )
      setIsAuthError(true)
    }
  })

  useDeepCompareEffect(() => {
    if (!isAuthenticated && inProgress === InteractionStatus.None) {
      signIn()
    }
  }, [isAuthenticated, inProgress])

  return (
    <>
      {isAuthenticated && msalInstance.getActiveAccount() && !isAuthError && (
        <div data-testid={dataTestId.authConsumer}>{children}</div>
      )}
      {isAuthError && <GenericError />}
    </>
  )
}
export default AuthConsumer
