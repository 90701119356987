import { useFaultsFormStateContext } from '@/contexts/moduleContexts/faultsForm'
import { useReportStatusContext } from '@/contexts/reportStatus'
import { Fault, FaultState, RecommendedActionState } from '@/models/reportStatus/faultsFormStateTypes'
import Menu from '@/modules/report-status/components/formMenu'
import { MenuItem } from '@/modules/report-status/components/formMenu/types'
import { UPDATE_FAULT } from '@/modules/report-status/reducer/actions.types'
import { StyledFaultMenu } from '@/modules/report-status/styled'
import {
  getEntityId,
  transformRecommendedActionToClosedRecommendedAction,
} from '@/modules/report-status/utils/formUtils'
import { DateFormats, formatDate } from '@/shared/dateUtils'
import useDeepCompareCallback from '@/shared/hooks/useDeepCompareCallback'
import useDeepCompareMemo from '@/shared/hooks/useDeepCompareMemo'
import { generateUUID } from '@/shared/utils'
import { dataTestId } from '@/tests/testid'
import { FC, useCallback } from 'react'

interface FaultMenuProps {
  fault: Fault
  isInEditMode: boolean
}

const FaultMenu: FC<FaultMenuProps> = ({ fault, isInEditMode }) => {
  const { state, recommendedActions, fault: faultDescription, collectionDate, createdDate, observation } = fault

  const { faultsFormDispatch } = useFaultsFormStateContext()
  const { setAnalyticsCancelButtonBehavior } = useReportStatusContext()

  const closeFault = useCallback(async () => {
    faultsFormDispatch({
      type: UPDATE_FAULT,
      payload: {
        id: getEntityId(fault),
        state: FaultState.PENDING_CLOSE,
        fault: faultDescription,
        correctDiagnostic: true,
        explanation: '',
        recommendedActions: await Promise.all(
          recommendedActions.map(transformRecommendedActionToClosedRecommendedAction)
        ),
        collectionDate: collectionDate,
        createdDate: createdDate,
        observation: observation,
      },
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state])

  const editFault = useDeepCompareCallback(() => {
    faultsFormDispatch({
      type: UPDATE_FAULT,
      payload: {
        id: getEntityId(fault),
        state: FaultState.EDIT,
      },
    })
  }, [fault])

  const addNewRecommendedAction = useDeepCompareCallback(() => {
    faultsFormDispatch({
      type: UPDATE_FAULT,
      payload: {
        id: getEntityId(fault),
        recommendedActions: [
          ...recommendedActions,
          {
            additionalNotes: '',
            workOrder: '',
            dueDate: formatDate(new Date(), DateFormats.ISO8601Format),
            createdDate: formatDate(new Date(), DateFormats.AmericanDateFormat),
            state: RecommendedActionState.NEW,
            id: generateUUID(),
          },
        ],
      },
    })
  }, [fault, recommendedActions])

  const menuItems = useDeepCompareMemo<MenuItem[]>(() => {
    const items: MenuItem[] = []

    if (state === FaultState.NEW || (state === FaultState.OPEN && !isInEditMode)) {
      items.push({
        label: 'Create Recommended Action',
        icon: 'recAction',
        onClick: () => {
          addNewRecommendedAction()
          setAnalyticsCancelButtonBehavior({ isDisabled: false })
        },
        disabled: recommendedActions.length >= 5,
      })
    }

    if (state === FaultState.OPEN && !isInEditMode) {
      items.push({
        label: 'Edit Fault',
        icon: 'edit',
        onClick: () => {
          setAnalyticsCancelButtonBehavior({ isDisabled: false })
          editFault()
        },
      })

      items.push({
        label: 'Close Fault',
        icon: 'closeAll',
        onClick: () => {
          setAnalyticsCancelButtonBehavior({ isDisabled: false })
          closeFault()
        },
        disabled: recommendedActions.some(({ state }) => state.toString() === RecommendedActionState.NEW),
      })
    }

    return items
  }, [isInEditMode, state, recommendedActions])

  return (
    <StyledFaultMenu feAlignItems="center" feJustifyContent="center">
      {[FaultState.NEW, FaultState.OPEN].includes(state) && menuItems.length > 0 && (
        <Menu items={menuItems} data-testid={dataTestId.faultFormMenu} />
      )}
    </StyledFaultMenu>
  )
}

export default FaultMenu
