import { OrphanNoteRow } from '@/models/orphanNotes/types'
import { Flexbox, Icon } from '@skf-internal/ui-components-react'
import { CellContext } from '@tanstack/react-table'

const AcknowledgedRowCell = ({ row }: CellContext<OrphanNoteRow, boolean>) => {
  if (row.original.acknowledged) {
    return (
      <Flexbox feJustifyContent="center">
        <Icon feIcon="checkCircle" feColor="green" feSize="md" />
      </Flexbox>
    )
  }
}

export default AcknowledgedRowCell
