import { Tabs, styled } from '@skf-internal/ui-components-react'

export const StyledAppTabs = styled(Tabs)`
  border: none;
  box-shadow: none;
  height: 100vh;

  & > [tabindex] {
    padding: 0;
  }

  & [role='tablist'] {
    justify-content: space-evenly;
    height: fit-content;

    & [role='tab'] {
      padding: 1rem 2rem;
      max-width: fit-content;
      border: none;
      font-weight: bold;

      span {
        overflow-x: unset;
        word-wrap: break-word;
        white-space: unset;
        text-align: center;
      }
    }
  }
`
