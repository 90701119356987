import { Icon, IconButton } from '@skf-internal/ui-components-react'
import { FC, useEffect, useRef, useState } from 'react'

const addTrailingSlash = (value: string) => {
  if (!value.endsWith('/')) {
    return value + '/'
  }
  return value
}

export const CopyHierarchyButton: FC<{ assetName: string; hierarchy: string }> = ({ assetName, hierarchy }) => {
  const [isCopied, setIsCopied] = useState<boolean>(false)
  const timerIdRef = useRef<NodeJS.Timeout | null>(null)

  const handleCopyButtonClick = async (hierarchy: string) => {
    await navigator.clipboard.writeText(addTrailingSlash(hierarchy) + assetName)
    setIsCopied(true)

    if (timerIdRef.current) {
      clearTimeout(timerIdRef.current)
    }

    timerIdRef.current = setTimeout(() => setIsCopied(false), 1 * 1000)
  }

  useEffect(() => {
    return () => {
      if (timerIdRef.current) {
        clearTimeout(timerIdRef.current)
      }
    }
  }, [])

  return isCopied ? (
    <Icon feIcon="checkCircle" feColor="brand" feSize="sm" />
  ) : (
    <IconButton
      as="button"
      feIcon="copy"
      feSize="sm"
      aria-label="Copy name"
      feColor="brand"
      onClick={() => handleCopyButtonClick(hierarchy)}
    />
  )
}
