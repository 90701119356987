// =================================================================================================
// IMPORTS
// =================================================================================================

import { Header, HeaderGroup } from '@tanstack/react-table'
import { FC } from 'react'
import { DevicesSensorsTableNumberHeaderColumn } from './columns/number-column'
import { DevicesSensorsTableNameHeaderColumn } from './columns/name-column'
import { DevicesSensorsTableStatusHeaderColumn } from './columns/status-column'
import { DevicesSensorsTableLastCommunicationDateHeaderColumn } from './columns/last-communication-date'
import { DevicesSensorsTableExpandRowHeaderColumn } from './columns/expand-row-column'
import { ColumnId, DevicesSensorsTableDeviceRowData } from '@/models/devicesAndSensors/types'
import { DevicesSensorsTableConnectedAssetHeaderColumn } from './columns/connected-assets-column'

// =================================================================================================
// UTILS
// =================================================================================================

const headerColumnRecord: Record<ColumnId, (header: Header<DevicesSensorsTableDeviceRowData, unknown>) => JSX.Element> =
  {
    number: (header) => <DevicesSensorsTableNumberHeaderColumn header={header} />,
    name: (header) => <DevicesSensorsTableNameHeaderColumn header={header} />,
    status: (header) => <DevicesSensorsTableStatusHeaderColumn header={header} />,
    lastCommunicationDate: (header) => <DevicesSensorsTableLastCommunicationDateHeaderColumn header={header} />,
    uniqueChannelsAssets: (header) => <DevicesSensorsTableConnectedAssetHeaderColumn header={header} />,
    expandRow: (header) => <DevicesSensorsTableExpandRowHeaderColumn header={header} />,
  }

// =================================================================================================
// COMPONENT
// =================================================================================================

export const TableHeader: FC<{ tableHeaderGroups: HeaderGroup<DevicesSensorsTableDeviceRowData>[] }> = ({
  tableHeaderGroups,
}) => {
  return (
    <thead>
      {tableHeaderGroups.map((headerGroup) => (
        <tr key={headerGroup.id}>
          {headerGroup.headers.map((header) => {
            const columnId: ColumnId = header.column.id! as ColumnId
            const ColDefComponent = () => headerColumnRecord[columnId](header)

            return <ColDefComponent key={header.id} />
          })}
        </tr>
      ))}
    </thead>
  )
}
