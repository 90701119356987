import { Tooltip, useTooltip } from '@skf-internal/ui-components-react'
import { FC, ReactNode } from 'react'

export const ToolTipOverviewDashboard: FC<{
  message: string
  children: ReactNode
}> = ({ message, children }) => {
  const [hoverRef, isHovered] = useTooltip()

  return (
    <>
      <div ref={hoverRef}>{children}</div>
      <Tooltip isHovered={isHovered} triggerElement={hoverRef.current}>
        {message}
      </Tooltip>
    </>
  )
}
