import useTimeout from '@/shared/hooks/useTimeout'
import { useAppDispatch, useTypedSelector } from '@/store/store'
import { useToast } from '@skf-internal/ui-components-react'
import { useCallback, useEffect, useRef } from 'react'
import { closeToast as closeToastAction } from '@/store/genericToast/actions'

/**
 * The GenericToast component displays toast notifications based on application state changes managed by Redux.
 *
 * This component does not accept any props directly but uses global state to determine its behavior.
 *
 * To trigger the toast display, dispatch the `openDispatch` action with the following parameters:
 * - `children` (**required**): The content to be displayed inside the toast.
 * - `feSeverity` (**required**): The severity level of the toast (e.g., 'success', 'error', 'info').
 * - `timeout` (optional): The duration (in seconds) before the toast automatically closes. Defaults to 7 seconds.
 * - `onClose` (optional): A callback function that is executed when the toast is closed.
 *
 * Note: You do not need to manage the `isOpen` parameter in the action; the reducer will handle it automatically.
 *
 * @returns {null} This component does not render any UI elements directly.
 */

const GenericToast = () => {
  const { genericToastData } = useTypedSelector((state) => ({ ...state }))

  const genericToastDispatch = useAppDispatch()

  const { isOpen, timeout = 7, children, feSeverity, onClose } = genericToastData

  const { addToast, removeToast } = useToast()
  const openedToastId = useRef<string>()

  const closeToast = useCallback(() => {
    openedToastId.current && removeToast(openedToastId.current)
    openedToastId.current = undefined
  }, [removeToast])

  const { startTimeout, clearTimeout } = useTimeout(() => {
    if (isOpen) {
      onClose && onClose()
      closeToast()
      genericToastDispatch(closeToastAction())
    }
  }, timeout * 1000)

  useEffect(() => {
    if (isOpen) {
      closeToast()
      clearTimeout()
      openedToastId.current = addToast({ ...genericToastData, children, feSeverity })
      startTimeout()
    } else {
      closeToast()
    }
  }, [addToast, children, clearTimeout, closeToast, feSeverity, genericToastData, isOpen, startTimeout])

  return <></>
}

export default GenericToast
