import { generateUUID } from '@/shared/utils'
import { closeGenericConfirmDialog } from '@/store/genericConfirmationDialog/actions'
import { useAppDispatch, useTypedSelector } from '@/store/store'
import { dataTestId } from '@/tests/testid'
import { Dialog, Flexbox, Spacer } from '@skf-internal/ui-components-react'

/**
 * The GenericConfirmationDialog component is a reusable component designed to display a confirmation dialog with customizable title, message, and buttons. 
 * It provides a generic interface for displaying confirmation dialogs across the application.
 * The component is managed by a centralized Redux store, which supplies the following state properties:
  - `isOpen`: A boolean indicating if the confirmation dialog is presently displayed.
  - `title`: A string denoting the title within the confirmation dialog.
  - `buttons`: An array containing React elements that depict the buttons to showcase in the dialog.
  - `message`: A string describing the message content of the confirmation dialog.
 *
 * The button type of buttons array is GenericConfirmationDialogButton
 * and each button shThe buttons within the buttons array are of type GenericConfirmationDialogButton, 
 * each containing the following properties:
  - `label`: A string representing the text displayed on the button.
  - `type`: Specifies the type of button.
  - `onClick`: A callback function managed by the calling component, triggered when the button is clicked.
 *
 * To open the component, utilize the `showGenericConfirmDialog` action and pass `isOpen` with a value of `true`, 
 * along with additional props to adjust the component's behavior as needed.
 * 
 * To close the component utilize the `closeGenericConfirmDialog` action
 * 
 * 
 * @return {*} 
 */
const GenericConfirmationDialog = () => {
  const {
    genericConfirmDialogData: { isOpen, title, buttons, message, element },
  } = useTypedSelector((state) => ({ ...state }))

  const genericConfirmationDialogDispatch = useAppDispatch()

  const closeDialog = () => {
    genericConfirmationDialogDispatch(closeGenericConfirmDialog())
  }

  return (
    (element && (
      <Dialog data-testid={dataTestId.genericDialogWithElement} feTitle="" open={isOpen} feOnClose={closeDialog}>
        {element}
      </Dialog>
    )) || (
      <Dialog
        data-testid={dataTestId.genericDialogWithText}
        open={isOpen}
        feTitle={title || ''}
        feOnClose={closeDialog}
      >
        <>
          <p>{message}</p>
          <Spacer />
          <Flexbox feGap="md" feJustifyContent="flex-end">
            {buttons && buttons.map((button) => <div key={generateUUID()}>{button}</div>)}
          </Flexbox>
        </>
      </Dialog>
    )
  )
}

export default GenericConfirmationDialog
