import { OverviewDataRow, OverviewSummary } from '@/models/overview/types'
const noDataString = 'N/A'
const mapOverviewData = (overviewAPIObject: OverviewSummary['companies'][number]): OverviewDataRow => {
  const { smallestLevel, mediumLevel, highestLevel, timeBetween } = overviewAPIObject.unhandledAssets || {}
  const { openRA, overdueRA } = overviewAPIObject.unresolvedRA || {}
  const { alarmedDevices, alarmedSensors } = overviewAPIObject.devicesSensors || {}
  const { automaticReports, companyID } = overviewAPIObject

  return {
    companyID,
    analysisBacklog: {
      level: {
        '1-3-days': smallestLevel ?? noDataString,
        '3-7-days': mediumLevel ?? noDataString,
        '>7-days': highestLevel ?? noDataString,
      },
      orphanNotes: overviewAPIObject.orphanNotes ?? noDataString,
      timeBetween: timeBetween ?? noDataString,
    },
    unresolvedRecommendations: {
      open: openRA ?? noDataString,
      overdue: overdueRA ?? noDataString,
    },
    deviceAndSensors: {
      devices: alarmedDevices ?? noDataString,
      sensors: alarmedSensors ?? noDataString,
    },
    autoDiagnostics: (Number.isInteger(automaticReports) && `${automaticReports}%`) || noDataString,
    // compliance: {
    //   online: overviewAPIObject.compliance,
    // },
    company: overviewAPIObject.companyName,
  }
}

export { mapOverviewData }
