import { Channel } from '@/models/devicesAndSensors/types'
import ChannelDataFault from '@/modules/devices-sensors/components/side-menu/channelSection/channelDataFault'
import { Flexbox } from '@skf-internal/ui-components-react'
import { FC } from 'react'

const ChannelStatusIndicator: FC<{ faults: Channel['faults']; status: Channel['status'] }> = ({ faults, status }) => {
  return (
    <Flexbox feFlexDirection="column" feGap="xxs">
      {status === 'channelNotConnected' && <ChannelDataFault status="channelNotConnected" />}
      {faults.length > 0 &&
        status !== 'channelNotConnected' &&
        faults.map((f) => (
          <ChannelDataFault status={f === 'no_measurement_ever' ? 'noSensorData' : 'notNormal'} fault={f} key={f} />
        ))}
      {status === 'normal' && <ChannelDataFault status="normal" />}
    </Flexbox>
  )
}

export default ChannelStatusIndicator
