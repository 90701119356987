import { useRef, useEffect, useCallback } from 'react'

type IntervalCallback = () => void

/**
 * A custom hook that manages an interval timer.
 *
 * This hook allows you to start and clear an interval, and ensures that the provided
 * callback function is always up-to-date by using a ref to store the latest version.
 * The interval will invoke the callback function at the specified delay in milliseconds.
 *
 * @param {IntervalCallback} callback - The function to be called on each interval tick.
 * @param {number} delay - The interval delay in milliseconds. The callback will be called
 *                         every `delay` milliseconds.
 * @returns {{ startInterval: () => void, clearInterval: () => void }} An object containing
 *          two functions: `startInterval` to start the interval and `clearInterval` to clear it.
 */

const useInterval = (callback: IntervalCallback, delay: number) => {
  const intervalRef = useRef<number | null>(null)
  const savedCallback = useRef<IntervalCallback>(callback)

  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  const startInterval = useCallback(() => {
    if (typeof delay === 'number') {
      intervalRef.current = window.setInterval(() => {
        savedCallback.current()
      }, delay)
    }
  }, [delay])

  const clearInterval = useCallback(() => {
    if (intervalRef.current !== null) {
      window.clearInterval(intervalRef.current)
      intervalRef.current = null
    }
  }, [])

  useEffect(() => {
    return () => clearInterval()
  }, [clearInterval])

  return { startInterval, clearInterval }
}

export default useInterval
