import { useFaultsFormStateContext } from '@/contexts/moduleContexts/faultsForm'
import {
  ClosedRecommendedAction,
  Fault,
  FaultState,
  RecommendedAction,
  RecommendedActionState,
} from '@/models/reportStatus/faultsFormStateTypes'
import FormDatePicker from '@/modules/report-status/components/formDatePicker'
import FormSelect from '@/modules/report-status/components/formSelect'
import FromTextArea from '@/modules/report-status/components/formTextArea'
import useScrollIntoViewOnce from '@/modules/report-status/hooks/useScrollIntoViewOnce'
import { StyledRecommendedActionRowContent } from '@/modules/report-status/styled'
import { getEntityId } from '@/modules/report-status/utils/formUtils'
import { incrementDate, decrementDate } from '@/shared/dateUtils'
import useDeepCompareMemo from '@/shared/hooks/useDeepCompareMemo'
import { useEffectOnce } from '@/shared/hooks/useEffectOnce'
import { capitalizeFirstLetter } from '@/shared/utils'
import { dataTestId } from '@/tests/testid'
import { FC, useRef } from 'react'

interface RecommendedActionRowProps {
  fault: Fault
  recommendedAction: RecommendedAction
}

const RecommendedActionRow: FC<RecommendedActionRowProps> = ({ fault, recommendedAction }) => {
  const faultId = useRef<string>(getEntityId(fault))
  const recommendedActionId = useRef<string>(getEntityId(recommendedAction))

  const {
    faultsFormState: { errors },
  } = useFaultsFormStateContext()

  const isInEditMode = useDeepCompareMemo(() => {
    return [RecommendedActionState.EDIT, RecommendedActionState.PENDING_CLOSE, RecommendedActionState.NEW].includes(
      recommendedAction.state
    )
  }, [recommendedAction])

  const errorData = useDeepCompareMemo(() => {
    return errors
      ?.find((faultError) => faultError.id === faultId.current)
      ?.recommendedActions.find((raError) => raError.id === recommendedActionId.current)
  }, [errors])

  const rowRef = useRef<HTMLDivElement>(null)
  const scrollToNewRA = useScrollIntoViewOnce(rowRef)

  useEffectOnce(() => {
    if (recommendedAction.state === RecommendedActionState.NEW) {
      scrollToNewRA()
    }
  })

  return (
    <StyledRecommendedActionRowContent
      data-testid={dataTestId.recommendedActionRow}
      feAlignItems="flex-start"
      state={recommendedAction.state}
      ref={rowRef}
    >
      {recommendedAction.createdDate &&
        ![RecommendedActionState.NEW, RecommendedActionState.EDIT].includes(recommendedAction.state) && (
          <FormDatePicker
            label="Created date"
            date={recommendedAction.createdDate}
            isInEditMode={false}
            state={fault.state}
            minDate={new Date()}
            maxDate={new Date()}
            data-testid={dataTestId.faultFormDatePicker}
          />
        )}

      {(fault.state.toString() === FaultState.PENDING_CLOSE ||
        recommendedAction.state.toString() === RecommendedActionState.PENDING_CLOSE) && (
        <FormSelect
          label="Outcome"
          isInEditMode={true}
          value={(recommendedAction as ClosedRecommendedAction).outcome}
          text={capitalizeFirstLetter((recommendedAction as ClosedRecommendedAction).outcome)}
          options={[
            { value: 'completed', label: 'Complete Recommended Action' },
            { value: 'rejected', label: 'Reject Recommended Action' },
          ]}
          state={FaultState.NEW}
          isRequired={true}
          fieldPath={{
            fieldName: 'outcome',
            fieldId: recommendedActionId.current,
            parentFieldId: faultId.current,
          }}
          errorData={errorData?.outcome}
          data-testid={dataTestId.faultFormSelect}
        />
      )}

      <FromTextArea
        label="Recommended Action"
        isInEditMode={![RecommendedActionState.OPEN, RecommendedActionState.EDIT].includes(recommendedAction.state)}
        value={recommendedAction.recommendedAction}
        maxHeight="9.375rem"
        minHeight="3.125rem"
        hint="Please provide a detailed recommended actions or any other information which may provide context."
        maxLength={1000}
        isRequired={true}
        fieldPath={{
          fieldName: 'recommendedAction',
          fieldId: recommendedActionId.current,
          parentFieldId: faultId.current,
        }}
        errorData={errorData?.recommendedAction}
        state={recommendedAction.state}
        data-testid={dataTestId.faultFormArea}
      />

      <FromTextArea
        label="Additional comments"
        isInEditMode={isInEditMode}
        value={recommendedAction.additionalNotes}
        maxHeight="9.375rem"
        minHeight="3.125rem"
        hint="Please provide a detailed description or any other information which may provide context."
        maxLength={1000}
        isRequired={(recommendedAction as ClosedRecommendedAction).outcome === 'rejected'}
        fieldPath={{
          fieldName: 'additionalNotes',
          fieldId: recommendedActionId.current,
          parentFieldId: faultId.current,
        }}
        errorData={errorData?.additionalNotes}
        state={recommendedAction.state}
        data-testid={dataTestId.faultFormArea}
      />
      <FromTextArea
        label="Work order"
        isInEditMode={isInEditMode}
        value={recommendedAction.workOrder}
        maxHeight="9.375rem"
        minHeight="3.125rem"
        hint="Please provide the customer's work order identification."
        maxLength={1000}
        fieldPath={{
          fieldName: 'workOrder',
          fieldId: recommendedActionId.current,
          parentFieldId: faultId.current,
        }}
        state={recommendedAction.state}
        data-testid={dataTestId.faultFormArea}
      />
      <FormDatePicker
        label="Due date"
        date={recommendedAction.dueDate}
        isInEditMode={isInEditMode}
        state={recommendedAction.state}
        isRequired={true}
        fieldPath={{
          fieldName: 'dueDate',
          fieldId: recommendedActionId.current,
          parentFieldId: faultId.current,
        }}
        errorData={errorData?.dueDate}
        maxDate={incrementDate(new Date(), 100, 'y')}
        minDate={new Date()}
        data-testid={dataTestId.faultFormDatePicker}
      />

      {(fault.state.toString() === FaultState.PENDING_CLOSE ||
        recommendedAction.state.toString() === RecommendedActionState.PENDING_CLOSE) && (
        <FormDatePicker
          label="Closed date"
          date={(recommendedAction as ClosedRecommendedAction).closeDate}
          isInEditMode={isInEditMode}
          state={recommendedAction.state}
          isRequired={true}
          fieldPath={{
            fieldName: 'closeDate',
            fieldId: recommendedActionId.current,
            parentFieldId: faultId.current,
          }}
          errorData={errorData?.closeDate}
          maxDate={new Date()}
          minDate={decrementDate(new Date(), 100, 'y')}
          data-testid={dataTestId.faultFormDatePicker}
        />
      )}
    </StyledRecommendedActionRowContent>
  )
}

export default RecommendedActionRow
