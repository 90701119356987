// =================================================================================================
// IMPORTS
// =================================================================================================

import StatusValueTooltip from '@/modules/devices-sensors/components/tooltip'
import { Flexbox } from '@skf-internal/ui-components-react'
import { FC, useMemo } from 'react'
import { deviceNumberFormatter } from '@/modules/devices-sensors/utils/constants'
import { DevicesSensorsTableChannelRowData } from '@/models/devicesAndSensors/types'
import { dataTestId } from '@/tests/testid'
import { StyledDevicesSensorsTableSensorRow } from '../styled'
import { StyledTd } from './styled'
import { DateFormats, formatDate } from '@/shared/dateUtils'
import { formatProp } from '@/modules/devices-sensors/utils'
import TextEllipsis, { TextEllipsisType } from '@/shared/components/TextEllipsis'
import useWindowDimensions from '@/shared/hooks/useWindowDimensions'

// =================================================================================================
// COMPONENT
// =================================================================================================

export const DevicesSensorsTableSensorRow: FC<
  DevicesSensorsTableChannelRowData & { odd: boolean; selected: boolean; onSensorRowClick: () => void }
> = ({ number, name, status, lastCommunicationDate, odd, selected, onSensorRowClick, asset }) => {
  const { width } = useWindowDimensions()

  const characterLengths = useMemo(() => {
    if (width <= 1200) {
      return {
        number: 3,
        name: 7,
        lastCommunicationDate: 14,
        assetName: 10,
      }
    } else if (width <= 1440) {
      return {
        number: 3,
        name: 14,
        lastCommunicationDate: 24,
        assetName: 15,
      }
    } else if (width <= 1900) {
      return {
        number: 3,
        name: 14,
        lastCommunicationDate: 24,
        assetName: 18,
      }
    } else {
      return {
        number: 12,
        name: 18,
        lastCommunicationDate: 24,
        assetName: 26,
      }
    }
  }, [width])

  return (
    <StyledDevicesSensorsTableSensorRow
      odd={odd}
      selected={selected}
      onClick={onSensorRowClick}
      data-testid={dataTestId.deviceSensorsTableRow}
    >
      <td colSpan={1}></td>
      <td colSpan={1}>
        <Flexbox feGap="sm">
          <TextEllipsis
            type={TextEllipsisType.SingleLine}
            characterLength={characterLengths.number}
            value={deviceNumberFormatter.format(number)}
          />
          <TextEllipsis type={TextEllipsisType.SingleLine} characterLength={characterLengths.name} value={name} />
        </Flexbox>
      </td>
      <td colSpan={1}>
        <Flexbox feJustifyContent="center">
          <StatusValueTooltip value={status} />
        </Flexbox>
      </td>
      <td colSpan={1}>
        <TextEllipsis
          type={TextEllipsisType.SingleLine}
          characterLength={characterLengths.lastCommunicationDate}
          value={formatProp(lastCommunicationDate, (prop) => formatDate(prop, DateFormats.AmericanDateTimeFormat))}
        />
      </td>
      <StyledTd colSpan={1}>
        {asset
          ? asset.map((item) => (
              <TextEllipsis
                type={TextEllipsisType.SingleLine}
                characterLength={characterLengths.assetName}
                value={item.asset.name}
                key={item.asset.name}
              />
            ))
          : '-'}
      </StyledTd>
      <td colSpan={1}></td>
    </StyledDevicesSensorsTableSensorRow>
  )
}
