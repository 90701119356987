import { ReportStatusLoaderData } from '@/app/routes/types'
import { useFaultsFormStateContext } from '@/contexts/moduleContexts/faultsForm'
import { Fault, FaultState, NonNormalStatus } from '@/models/reportStatus/faultsFormStateTypes'
import FormDatePicker from '@/modules/report-status/components/formDatePicker'
import FromSelect from '@/modules/report-status/components/formSelect'
import FromTextArea from '@/modules/report-status/components/formTextArea'
import DebugMockCustomerNote from '@/modules/report-status/components/mockCustomerNote'
import { StyledReportStatusFaultRowContent } from '@/modules/report-status/styled'
import { getEntityId } from '@/modules/report-status/utils/formUtils'
import { epochDate } from '@/shared/dateUtils'
import useDeepCompareMemo from '@/shared/hooks/useDeepCompareMemo'
import { AssetStatus } from '@/shared/models/types'
import { capitalizeFirstLetter, convertToBoolean } from '@/shared/utils'
import { useTypedSelector } from '@/store/store'
import { dataTestId } from '@/tests/testid'
import { FC, useRef } from 'react'
import { useLoaderData, useSearchParams } from 'react-router-dom'

interface FaultRowProps {
  fault: Fault
  isInEditMode: boolean
}

const FaultRow: FC<FaultRowProps> = ({ fault, isInEditMode }) => {
  const { reportFaultTypes } = useLoaderData() as ReportStatusLoaderData

  const faultId = useRef<string>(getEntityId(fault))

  const [searchParams] = useSearchParams()

  const {
    selectedCustomer: { customerNotesEnabled },
  } = useTypedSelector((state) => state)

  const debugMode = convertToBoolean(searchParams.get('debug')) && customerNotesEnabled

  const {
    faultsFormState: { errors },
  } = useFaultsFormStateContext()

  const errorData = useDeepCompareMemo(() => {
    return errors?.find((faultError) => faultError.id === faultId.current)
  }, [errors])

  const getFaultValueAndText = () => {
    let faultText = ''
    const faultType = reportFaultTypes.find((type) => type.code === fault.fault)
    if (faultType) {
      faultText = faultType.text
    }

    return {
      value: fault.fault,
      text: `${faultId.current} ${faultText}`,
    }
  }

  return (
    <>
      <StyledReportStatusFaultRowContent
        state={fault.state}
        feJustifyContent="flex-start"
        data-testid={dataTestId.faultFormRow}
      >
        <FromSelect
          label="Fault"
          isInEditMode={fault.state === FaultState.NEW}
          {...getFaultValueAndText()}
          options={reportFaultTypes
            .filter((type) => !type.deprecated)
            .map(({ code, text }) => ({
              value: code,
              label: `${text}`,
            }))}
          state={fault.state}
          isRequired={fault.state === FaultState.NEW}
          fieldPath={{
            fieldName: 'fault',
            fieldId: faultId.current,
          }}
          errorData={errorData?.fault}
          data-testid={dataTestId.faultFormSelect}
        />
        {fault.createdDate && ![FaultState.NEW, FaultState.EDIT].includes(fault.state) && (
          <FormDatePicker
            label="Created date"
            date={fault.createdDate}
            isInEditMode={false}
            state={fault.state}
            minDate={new Date()}
            maxDate={new Date()}
            data-testid={dataTestId.faultFormDatePicker}
          />
        )}
        <FormDatePicker
          label="Collection date"
          date={fault.collectionDate}
          isInEditMode={isInEditMode}
          state={fault.state}
          isRequired={true}
          fieldPath={{
            fieldName: 'collectionDate',
            fieldId: faultId.current,
          }}
          errorData={errorData?.collectionDate}
          maxDate={new Date()}
          minDate={epochDate().toDate()}
          data-testid={dataTestId.faultFormDatePicker}
        />
        <FromSelect
          label="Status"
          isInEditMode={![FaultState.PENDING_CLOSE, FaultState.OPEN].includes(fault.state)}
          value={fault.status}
          text={capitalizeFirstLetter(fault.status)}
          options={Object.keys(AssetStatus)
            .filter((status) => !['normal', 'never-reported'].includes(status)) //Excludes normal and never-reported
            .map((text) => ({
              value: text as NonNormalStatus,
              label: capitalizeFirstLetter(text),
            }))}
          state={fault.state}
          fieldPath={{
            fieldName: 'status',
            fieldId: faultId.current,
          }}
          isRequired={isInEditMode}
          errorData={errorData?.status}
          data-testid={dataTestId.faultFormSelect}
        />
        <FromTextArea
          label="Observation"
          isInEditMode={isInEditMode}
          value={fault.observation}
          maxHeight="150px"
          minHeight="50px"
          hint="Please provide a detailed description or any other information which may provide context."
          maxLength={1000}
          isRequired={true}
          fieldPath={{
            fieldName: 'observation',
            fieldId: faultId.current,
          }}
          errorData={errorData?.observation}
          state={fault.state}
          data-testid={dataTestId.faultFormArea}
        />
      </StyledReportStatusFaultRowContent>
      {debugMode && <DebugMockCustomerNote faultId={faultId.current} data-testid={dataTestId.mockCustomerNote} />}
    </>
  )
}

export default FaultRow
