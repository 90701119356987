import { useEffect, useState, useRef, useCallback } from 'react'

/**
 * A custom hook to track the presence of a DOM element based on a selector. It optionally executes
 * a callback when the element first appears.
 *
 * This hook also observes DOM changes using a `MutationObserver` to detect when the specified element
 * is added or removed from the DOM.
 *
 * @param {string} selector - The CSS selector to find the element in the DOM.
 * @param {() => void} [callback] - Optional callback function to be called once when the element is
 * first detected in the DOM.
 *
 * @returns {[boolean, () => void]} - A tuple containing a boolean indicating if the element is
 * present in the DOM and a reset function to allow re-triggering the callback.
 */

export default function useElementInDOM(selector: string, callback?: () => void) {
  const [isPresent, setIsPresent] = useState(false)
  const callbackCalled = useRef(false)

  useEffect(() => {
    const checkElement = () => {
      const element = document.querySelector(selector)
      const elementExists = !!element

      if (elementExists !== isPresent) {
        setIsPresent(elementExists)
      }

      if (elementExists && !callbackCalled.current) {
        callback && callback()
        callbackCalled.current = true
      }
    }

    // Initial check
    checkElement()

    // Set up a MutationObserver to watch for changes in the DOM
    const observer = new MutationObserver(checkElement)
    observer.observe(document.body, { childList: true, subtree: true })

    return () => {
      observer.disconnect()
    }
  }, [selector, callback, isPresent])

  const reset = useCallback(() => {
    callbackCalled.current = false
  }, [])

  return [isPresent, reset]
}
