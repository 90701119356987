import { StyledCustomerSelector } from '@/app/main-header/styled'
import { ROUTES } from '@/shared/constants'
import { getRouteFromPathname } from '@/shared/utils'
import { clearError } from '@/store/errorHandler/actions'
import { setModuleHeaderConfigData } from '@/store/moduleHeader/actions'
import { setSelectedCustomer } from '@/store/selectedCustomer/actions'
import { useAppDispatch, useTypedSelector } from '@/store/store'
import { dataTestId } from '@/tests/testid'
import { css } from '@emotion/react'
import { Global } from '@skf-internal/ui-components-react'
import { FC } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

const CustomerSelector: FC = () => {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const isNotOverviewPage = pathname !== ROUTES.OVERVIEW
  const isErrorPage = [ROUTES.ERROR, ROUTES.TAB_ERROR].includes(pathname as ROUTES)

  const customerSelectorDispatch = useAppDispatch()
  const { customersList, selectedCustomer } = useTypedSelector((state) => ({ ...state }))

  const handleOnCustomerSelectorChange = (value: string) => {
    const customer = customersList.find((c) => c.id === value)
    if (!customer) {
      throw new Error("Couldn't find any available customers with the id : " + value)
    } else if (value === 'overview') {
      customerSelectorDispatch(setModuleHeaderConfigData({ infoPanelElement: undefined }))
      customerSelectorDispatch(clearError())
      navigate(ROUTES.OVERVIEW)
    } else {
      customerSelectorDispatch(setSelectedCustomer(customer))
      customerSelectorDispatch(clearError())
      if (isErrorPage) {
        navigate(`${ROUTES.ANALYSIS_BACKLOG}/${customer.id}`)
      } else {
        navigate(`${getRouteFromPathname(pathname)}/${customer.id}`)
      }
    }
  }

  return (
    (isNotOverviewPage && (
      <>
        <Global
          styles={css`
            li:first-child:has(div[title='All sites overview']) {
              border-bottom: 1px solid #dee0e4 !important;
            }

            ul:has(div[title='All sites overview']) {
              width: 360px !important;
            }
          `}
        />
        <>
          {/* <ul id="downshift-:rnt:-menu" role="listbox" aria-labelledby="downshift-:rnt:-label" class="css-14ogb37"></ul> */}
          <StyledCustomerSelector
            data-testid={dataTestId.customersSelect}
            feItems={customersList.map((c) => {
              return {
                value: c?.id,
                label: c?.name,
              }
            })}
            multiple={false}
            feHideLabel={true}
            feLabel="Customers"
            fePlaceholder="Select a customer"
            value={selectedCustomer?.id}
            onChange={(value) => handleOnCustomerSelectorChange(value.toString())}
          />
        </>
      </>
    )) || <></>
  )
}
export default CustomerSelector
