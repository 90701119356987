// =================================================================================================
// IMPORTS
// =================================================================================================

import { ColumnDef } from '@/common/table/base-components/column-def'
import { OverviewDataRow } from '@/models/overview/types'
import SubHeader from '@/modules/overview/components/overview-table/subHeader'
import { ToolTipOverviewDashboard } from '@/modules/overview/components/overview-table/tooltip'
import useRedirectToModuleWithSelectedCustomer from '@/shared/hooks/useRedirectToModuleWithSelectedCustomer'
import {
  StyledOverviewAnalysisBacklogColumnContentContainer,
  StyledOverviewAnalysisBacklogColumnContentContainerText,
  StyledOverviewAnalysisBacklogColumnHeaderRow,
  StyledOverviewAnalysisBacklogColumnSubHeaderRow,
  StyledOverviewAnalysisBacklogColumnTableRow,
  StyledOverviewTimeBetweenSpan,
  StyledTertiarySubHeaderContainer,
} from '@/modules/overview/styled'
import TableHeaderColumn from '@/shared/components/TableComponents/TableHeaderColumn'
import { ROUTES } from '@/shared/constants'
import { dataTestId } from '@/tests/testid'
import { COLORS } from '@skf-internal/ui-components-react'
import { ColumnHelper, Header } from '@tanstack/react-table'
import { FC } from 'react'

// =================================================================================================
// UTILS
// ===============

export const analysisBacklogTitles: Record<
  keyof OverviewDataRow['analysisBacklog']['level'],
  Record<string, string>
> = {
  '1-3-days': {
    tooltipText: 'Assets pending analysis for 1-3 days',
    backgroundColor: '#e6f3ec',
    textBackgroundColor: 'greenBase',
    textColor: 'white',
  },
  '3-7-days': {
    tooltipText: 'Assets pending analysis for 3-7 days',
    backgroundColor: '#fffae8',
    textBackgroundColor: 'yellowDarker',
    textColor: 'black',
  },
  '>7-days': {
    tooltipText: 'Assets pending analysis for 7+ days',
    backgroundColor: '#fae6e6',
    textBackgroundColor: 'redBase',
    textColor: 'white',
  },
}

const tooltipText: Record<string, string> = {
  'time-between': 'Avg. days an asset is pending analysis',
  'orphan-notes': 'Orphan notes pending acknowledgment',
}

// =================================================================================================
// COLUMN DEF
// =================================================================================================

export const generateAnalysisBacklogColumnDefinition = (columnHelper: ColumnHelper<OverviewDataRow>) =>
  columnHelper.accessor('analysisBacklog', {
    id: 'analysisBacklog',
    header: 'Analysis Backlog',
  })

// =================================================================================================
// COLUMN DEF COMPONENT
// =================================================================================================

export const OverAllTableAnalysisBacklogColumnDef: FC = () => <ColumnDef />

// =================================================================================================
// HEADER COMPONENT
// =================================================================================================

export const OverAllTableAnalysisBacklogHeaderColumn: FC<{
  header: Header<OverviewDataRow, unknown>
}> = ({ header }) => {
  const tertiarySubHeader = (
    <StyledTertiarySubHeaderContainer>
      {Object.entries(analysisBacklogTitles).map(([key, props]) => (
        <StyledOverviewAnalysisBacklogColumnContentContainerText
          className="analysis-backlog-inner-titles"
          textBackgroundColor={props.textBackgroundColor as keyof typeof COLORS}
          textColor={props.textColor as keyof typeof COLORS}
        >
          {key}
        </StyledOverviewAnalysisBacklogColumnContentContainerText>
      ))}
      {Object.keys(analysisBacklogTitles)
        .slice(0, 2)
        .map(() => (
          <StyledOverviewAnalysisBacklogColumnContentContainerText
            className="analysis-backlog-inner-titles"
            textBackgroundColor="white"
            textColor="white"
          />
        ))}
    </StyledTertiarySubHeaderContainer>
  )
  return (
    <TableHeaderColumn<OverviewDataRow> spanCol={2} header={header} centerValue={true} withSorting={false}>
      <SubHeader
        titles={['Pending Analysis', 'Time Between', 'Orphan Notes']}
        gridProp="3fr 1fr 1fr"
        tertiarySubHeader={tertiarySubHeader}
      />
    </TableHeaderColumn>
  )
}

// =================================================================================================
// CELL COMPONENT
// =================================================================================================

interface OverAllTableAnalysisBacklogColumnCellProps {
  value: OverviewDataRow['analysisBacklog']
  companyId: string
}

const OverAllTableAnalysisBacklogColumnCell: FC<OverAllTableAnalysisBacklogColumnCellProps> = ({
  value,
  companyId,
}) => {
  const redirectToModuleWithSelectedCustomer = useRedirectToModuleWithSelectedCustomer()
  return (
    <td colSpan={2}>
      <StyledOverviewAnalysisBacklogColumnHeaderRow>
        <StyledOverviewAnalysisBacklogColumnSubHeaderRow>
          <StyledOverviewAnalysisBacklogColumnTableRow>
            {Object.entries(analysisBacklogTitles).map(([key, props]) => (
              <StyledOverviewAnalysisBacklogColumnContentContainer backgroundColor={props.backgroundColor} key={key}>
                <ToolTipOverviewDashboard message={props.tooltipText}>
                  <a
                    className="cell-value"
                    data-testid={dataTestId.overviewAnalyticsBacklogColumn}
                    onClick={() => redirectToModuleWithSelectedCustomer(ROUTES.ANALYSIS_BACKLOG, companyId)}
                  >
                    {value.level[key as keyof OverviewDataRow['analysisBacklog']['level']]}
                  </a>
                </ToolTipOverviewDashboard>
              </StyledOverviewAnalysisBacklogColumnContentContainer>
            ))}
          </StyledOverviewAnalysisBacklogColumnTableRow>
        </StyledOverviewAnalysisBacklogColumnSubHeaderRow>
        <div className="content-container">
          <ToolTipOverviewDashboard message={tooltipText['time-between']}>
            <a
              className="cell-value"
              data-testid={dataTestId.overviewAnalyticsBacklogTimeBetweenColumn}
              onClick={() => redirectToModuleWithSelectedCustomer(ROUTES.ANALYSIS_BACKLOG, companyId)}
            >
              <StyledOverviewTimeBetweenSpan threshold={parseFloat(value.timeBetween.toString())} />
              {value.timeBetween}
            </a>
          </ToolTipOverviewDashboard>
        </div>
        <div className="content-container">
          <ToolTipOverviewDashboard message={tooltipText['orphan-notes']}>
            <a
              className="cell-value"
              data-testid={dataTestId.overviewOrphanNotesColumn}
              onClick={() => redirectToModuleWithSelectedCustomer(ROUTES.ORPHAN_NOTES, companyId)}
            >
              {value.orphanNotes}
            </a>
          </ToolTipOverviewDashboard>
        </div>
      </StyledOverviewAnalysisBacklogColumnHeaderRow>
    </td>
  )
}

export default OverAllTableAnalysisBacklogColumnCell
