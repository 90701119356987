import { Loader, styled } from '@skf-internal/ui-components-react'

export const StyledGlobalLoaderWrapper = styled.div`
  position: fixed;
  height: 100%;
  width: 100vw;
  background: linear-gradient(180deg, #00000080 0%, #00000080 100%);
  z-index: 1001;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const StyledHeaderLoaderWrapper = styled.div<{ isVisible: boolean | undefined }>`
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
  position: static;
`
export const StyledGlobalLoader = styled(Loader)`
  display: flex;
  height: 100vh;
  margin: 0 auto;
`
