// =================================================================================================
// IMPORTS
// =================================================================================================

import { FC } from 'react'
import { Cell, Row, flexRender } from '@tanstack/react-table'
import { DevicesSensorsTableNumberColumnCell } from './columns/number-column'
import { DevicesSensorsTableNameColumnCell } from './columns/name-column'
import { DevicesSensorsTableStatusColumnCell } from './columns/status-column'
import { DevicesSensorsTableLastCommunicationDateColumnCell } from './columns/last-communication-date'
import { DevicesSensorsTableExpandRowColumnCell } from './columns/expand-row-column'
import { DataRow } from './row/data-row'
import { ColumnId, DevicesSensorsTableDeviceRowData, UniqueChannelsAssets } from '@/models/devicesAndSensors/types'
import { DevicesSensorsTableConnectedAssetColumnCell } from './columns/connected-assets-column'

// =================================================================================================
// UTILS
// =================================================================================================

export const dataColumnRecord: Record<
  ColumnId,
  (cell: Cell<DevicesSensorsTableDeviceRowData, unknown>) => JSX.Element
> = {
  number: (cell: Cell<DevicesSensorsTableDeviceRowData, unknown>) => (
    <DevicesSensorsTableNumberColumnCell value={cell.getValue<number>()} />
  ),
  name: (cell: Cell<DevicesSensorsTableDeviceRowData, unknown>) => (
    <DevicesSensorsTableNameColumnCell value={cell.getValue<string>()} />
  ),
  status: (cell: Cell<DevicesSensorsTableDeviceRowData, unknown>) => (
    <DevicesSensorsTableStatusColumnCell>
      {flexRender(cell.column.columnDef.cell, cell.getContext())}
    </DevicesSensorsTableStatusColumnCell>
  ),
  lastCommunicationDate: (cell: Cell<DevicesSensorsTableDeviceRowData, unknown>) => (
    <DevicesSensorsTableLastCommunicationDateColumnCell value={cell.getValue<string>()} />
  ),
  uniqueChannelsAssets: (cell: Cell<DevicesSensorsTableDeviceRowData, unknown>) => (
    <DevicesSensorsTableConnectedAssetColumnCell value={cell.getValue<Array<UniqueChannelsAssets>>()} />
  ),
  expandRow: (cell: Cell<DevicesSensorsTableDeviceRowData, unknown>) => (
    <DevicesSensorsTableExpandRowColumnCell key={cell.id}>
      {flexRender(cell.column.columnDef.cell, cell.getContext())}
    </DevicesSensorsTableExpandRowColumnCell>
  ),
}

// =================================================================================================
// COMPONENT
// =================================================================================================

export const TableData: FC<{
  items: Row<DevicesSensorsTableDeviceRowData>[]
}> = ({ items }) => {
  return items.map((row) => {
    const odd = !!(row.index % 2)

    return <DataRow odd={odd} row={row} key={`${row.index}-device-row`} />
  })
}
