import { useState, useEffect } from 'react'

/**
 * A custom React hook that detects scroll events on a specified element or the window.
 *
 * This hook tracks the current scroll position, previous scroll position, scrolling state,
 * scroll direction, and scroll speed. It can be used to implement scroll-based UI behaviors
 * such as animations, lazy loading, or analytics.
 *
 * @param {HTMLElement | undefined | Window} [element=window] - The DOM element to observe for scroll events.
 * If not provided, the hook will observe the `window` object for scroll events.
 *
 * @returns {{
 *   isScrolling: boolean,                 // Indicates whether the element is currently being scrolled.
 *   direction: 'stopped' | 'up' | 'down', // The direction of the scroll: 'up', 'down', or 'stopped'.
 *   scrollSpeed: number,                  // The speed of the scroll, measured in pixels per interval.
 *   position: number                      // The current scroll position of the element.
 * }} An object containing scroll detection data.
 */

const useScrollDetector = (element: HTMLElement | undefined | Window = window) => {
  const [position, setPosition] = useState(0)
  const [prevPosition, setPrevPosition] = useState(0)
  const [isScrolling, setIsScrolling] = useState(false)
  const [scrollingElement, setScrollingElement] = useState<HTMLElement | Window | undefined>(undefined)
  const [direction, setDirection] = useState<'stopped' | 'up' | 'down'>('stopped')
  const [scrollSpeed, setScrollSpeed] = useState(0)

  const calculateAndSetDirection = (oldPosition: number, newPosition: number) => {
    if (newPosition > oldPosition) {
      setDirection('down')
    } else if (newPosition < oldPosition) {
      setDirection('up')
    }
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setScrollingElement(element)
    }
  }, [element])

  useEffect(() => {
    let scrollTimeout: NodeJS.Timeout | null = null
    const checkScrollSpeed = (function (settings?: { delay?: number }) {
      settings = settings || {}

      let lastPos: number | null, newPos: number, timer: NodeJS.Timeout, delta: number
      const delay = settings.delay || 100 // in "ms" (higher means lower fidelity)

      const clear = () => {
        lastPos = null
        delta = 0
      }

      clear()

      return () => {
        if (scrollingElement) {
          newPos = scrollingElement === window ? scrollingElement.scrollY : (scrollingElement as HTMLElement).scrollTop
          if (lastPos != null) {
            delta = newPos - lastPos
          }
          lastPos = newPos
          clearTimeout(timer)
          timer = setTimeout(clear, delay)
        }
        return delta
      }
    })()
    if (scrollingElement) {
      scrollingElement.addEventListener(
        'scroll',
        () => {
          clearTimeout(scrollTimeout!)
          setIsScrolling(true)

          setPrevPosition(
            scrollingElement === window ? scrollingElement.scrollY : (scrollingElement as HTMLElement).scrollTop
          )

          setScrollSpeed(Math.abs(checkScrollSpeed()))
          scrollTimeout = setTimeout(function () {
            setIsScrolling(false)
            setDirection('stopped')
            setScrollSpeed(0)
          }, 66)
        },
        true
      )
    }

    return () => {
      if (scrollingElement) {
        scrollingElement.removeEventListener('scroll', () => {
          setIsScrolling(false)
          setPosition(0)
          setPrevPosition(0)
          setDirection('stopped')
        })
      }
    }
  }, [scrollingElement])

  useEffect(() => {
    let scrollingElementPosition

    if (scrollingElement) {
      scrollingElementPosition =
        scrollingElement === window ? scrollingElement.scrollY : (scrollingElement as HTMLElement).scrollTop

      if (position !== scrollingElementPosition) {
        calculateAndSetDirection(position, scrollingElementPosition)
        setPosition(scrollingElementPosition)
      }
    }
  }, [isScrolling, prevPosition, position, scrollingElement])

  return { isScrolling, direction, scrollSpeed, position }
}

export default useScrollDetector
