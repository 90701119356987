import { SubHeaderProps } from '@/modules/overview/components/overview-table/subHeader'
import { StyledTable } from '@/shared/components/TableComponents/styled'
import {
  COLORS,
  Flexbox,
  FONT_SIZES,
  FONT_WEIGHTS,
  Icon,
  Popup,
  SPACINGS,
  styled,
  Text,
} from '@skf-internal/ui-components-react'
import { Link } from '@skf-internal/ui-components-react'

export const StyledOverviewContainer = styled(Flexbox)`
  flex-direction: column;
  margin: ${SPACINGS.lg};
`

export const StyledViewFlexbox = styled(Flexbox)`
  margin: 0 ${SPACINGS.lg};
  flex-grow: 1;
  flex-direction: column;
`

export const StyledViewCard = styled.div`
  display: flex;
  flex-direction: column;
`

export const StyledActionMenu = styled.div`
  display: flex;
  align-items: end;
`
export const StyledActionColumnPopup = styled(Popup)`
  padding: 0 !important;
`
export const StyledTableCard = styled(StyledViewCard)`
  height: 75.5vh;
  gap: 1rem;
`
export const StyledTableHeaderRow = styled.tr`
  th {
    font-size: 0.85rem;
    text-align: center;
    text-wrap: balance;
  }

  th:not(:last-child) {
    border-right: 1px solid ${COLORS.gray200};
  }

  th div {
    height: 2.5rem;
  }
`

export const StyledOverviewTable = styled(StyledTable)`
  border: 1px solid ${COLORS.gray200};

  th {
    padding: 0.5rem 0 0;
    border-bottom: none;
  }

  .sub-header {
    background-color: #f6f6f6;
  }

  th div {
    padding-bottom: 0.2rem;
    gap: 0;
  }
`
export const StyledSubheaderContainer = styled(Flexbox)`
  background-color: #f6f6f6;
`
export const StyledActionMenuContainer = styled(Flexbox)`
  height: 100%;
  margin-top: 1rem;
`
export const StyledSubHeader = styled.div<Pick<SubHeaderProps, 'gridProp'>>`
  display: flex;
  justify-content: space-evenly;
  grid-template-columns: ${(props) => props.gridProp};
  text-align: center;
  padding: 0.5rem;
  width: 100%;

  > div:nth-child(2) {
    background-color: #f6f6f6;
    width: 100%;
  }

  :has(p:nth-child(2)) > p {
    width: 50%;
  }

  :has(p:nth-child(3)) > p {
    width: 20%;
  }

  :has(p:nth-child(3)) > p:first-of-type {
    width: 60%;
  }
`

export const StyledTertiarySubHeaderContainer = styled(Flexbox)`
  width: 100%;
  align-self: flex-start;

  & .analysis-backlog-inner-titles {
    font-weight: ${FONT_WEIGHTS.bold};
    font-size: 0.6rem;
    text-align: center;
    padding: ${SPACINGS.xxs};
    width: 100%;
    height: 1.5rem;
    vertical-align: middle;

    :nth-last-of-type(-n + 2) {
      background-color: ${COLORS.gray050};
    }
  }
`

export const StyledSubHeaderText = styled(Text)`
  font-weight: ${FONT_WEIGHTS.bold};
  text-wrap: balance;
`
export const StyledEmptySpaceHeader = styled.div`
  width: 100%;
  padding: 1.6rem;
`
export const StyledTableRow = styled.tr`
  font-size: 0.85rem;

  td {
    padding: 0;
    border-color: ${COLORS.gray100};
    border-width: 0 1px 1px 0;
    border-style: solid;
  }

  .cell-value {
    display: flex;
    align-items: end;
    justify-content: center;
    text-decoration: underline;
    cursor: pointer;
  }

  .content-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`

export const StyledOverviewAnalysisBacklogColumnHeaderRow = styled.div`
  display: grid;
  height: 4rem;
  grid-template-columns: 3fr 1fr 1fr;

  & .analysis-backlog-subtitles {
    font-weight: ${FONT_WEIGHTS.bold};
  }

  :nth-last-of-type(-n + 2) .content-container .cell-value {
    margin-top: 1rem;
  }
`
export const StyledOverviewAnalysisBacklogColumnSubHeaderRow = styled.div`
  display: flex;
  width: 100%;
`
export const StyledOverviewAnalysisBacklogColumnTableRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
`

export const StyledOverviewAnalysisBacklogColumnContentContainer = styled.div<{ backgroundColor: string }>`
  background-color: ${(props) => props.backgroundColor};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  :nth-last-of-type(-n + 3) .cell-value {
    margin-top: 1rem;
  }
`

export const StyledOverviewAnalysisBacklogColumnContentContainerText = styled.div<{
  textBackgroundColor: keyof typeof COLORS
  textColor: keyof typeof COLORS
}>`
  background-color: ${(props) => COLORS[props.textBackgroundColor]};
  color: ${(props) => COLORS[props.textColor]};
  display: flex;
  align-items: center;
  justify-content: center;
`

export const StyledOverviewTimeBetweenSpan = styled.span<{ threshold: number }>`
  height: 1rem;
  width: 1rem;
  background-color: ${(props) =>
    isNaN(props.threshold) || props.threshold > 7
      ? COLORS.redBase
      : props.threshold <= 3
        ? COLORS.greenBase
        : COLORS.yellowBase};
  border-radius: 50%;
  display: inline-block;
  margin-right: 0.5rem;
`
export const StyledOverviewAutomaticDiagnosticsColumn = styled.div`
  display: grid;
  height: 100%;
  align-items: center;
  grid-template-columns: 1fr;
  margin-top: 1rem;
`
export const StyledOverviewAutomaticDiagnosticsTooltip = styled.span<{ threshold: number }>`
  height: 1rem;
  width: 1rem;
  background-color: ${(props) =>
    isNaN(props.threshold) || props.threshold < 50
      ? COLORS.redBase
      : props.threshold > 70
        ? COLORS.greenBase
        : COLORS.yellowBase};
  border-radius: 50%;
  display: inline-block;
  margin-right: 0.5rem;
`
export const StyledOverviewSitesColumn = styled.div`
  display: grid;
  font-size: 1rem;
  text-align: start;
  grid-template-columns: 1fr;
  margin-top: 1rem;
  padding: 0 0.5rem;
`

export const StyledSitesLink = styled.a`
  color: ${COLORS.primary600};
  text-align: center;
  font-size: ${FONT_SIZES.sm};
  font-weight: ${FONT_WEIGHTS.normal};
  line-height: normal;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-skip-ink: none;
  text-decoration-thickness: auto;
  text-underline-offset: auto;
  text-underline-position: from-font;
`
export const StyledOverviewDevicesSensorsColumn = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100%;
  margin-top: 1rem;
`
export const StyledOverviewDevicesSensorsColumnIcon = styled(Icon)`
  background-color: #781e93;
  border-radius: 50%;
  height: 1rem !important;
  width: 1rem !important;
  margin-right: 0.5rem;
`

export const StyledOverviewUnresolvedRecommendationsColumn = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100%;
  margin-top: 1rem;
`
export const StyledLink = styled(Link)`
  padding: 10px;

  &:hover {
    background-color: rgb(231 238 250);
  }
`

export const StyledPopover = styled.div`
  width: max-content;
  background-color: rgb(255 255 255);
  border-radius: 0.125rem;
  font-size: 16px;
  padding: 0;
`
