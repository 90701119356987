import useDeepCompareEffect from '@/shared/hooks/useDeepCompareEffect'
import useDeepCompareMemo from '@/shared/hooks/useDeepCompareMemo'
import { DependencyList, useState } from 'react'

/**
 * A hook that provides a memoized value calculated after a debounce delay.
 * The calculation function (`callback`) is only invoked if dependencies remain stable
 * for the specified delay duration, preventing unnecessary recomputations.
 *
 * @template T The type of the memoized value.
 * @param {() => T} callback - The function to compute the memoized value.
 * @param {any[]} dependencies - An array of dependencies that, when changed, reset the debounce delay.
 * @param {number} delay - The debounce delay in milliseconds.
 * @returns {T} The memoized value, recalculated only after the debounce delay.
 *
 * @example
 * const expensiveValue = useDebouncedMemo(() => computeExpensiveValue(input), [input], 500);
 * // Only recalculates `computeExpensiveValue` if `input` remains unchanged for 500ms.
 */

const useDeepDebouncedMemo = <T>(callback: () => T, dependencies: DependencyList, delay: number): T => {
  const [debouncedValue, setDebouncedValue] = useState<T>(callback)
  // Schedule the callback to run after the delay
  useDeepCompareEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(callback)
    }, delay)

    // Clear the timeout if dependencies change within the delay period
    return () => {
      clearTimeout(handler)
    }
  }, [delay, dependencies])
  // Memoize the debounced value to prevent unnecessary re-renders
  return useDeepCompareMemo(() => debouncedValue, [debouncedValue])
}

export default useDeepDebouncedMemo
