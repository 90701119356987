import networkClient from '@/api/NetworkClient'
import { CommonPostWidgetDataRequest, CommonPostWidgetDataResult } from '@/api/paths/analytics/common/types'
import { PATHS } from '@/api/types'
import { AxiosError } from 'axios'
import { GetRecommendedActionsDueDatesStatusResponse } from './types'

const postRecommendedActionsDueDatesStatus = async (
  request: CommonPostWidgetDataRequest,
  handlers: CommonPostWidgetDataResult,
  params: Record<string, string | undefined>
): Promise<GetRecommendedActionsDueDatesStatusResponse | void> => {
  try {
    const response = await networkClient.post<CommonPostWidgetDataRequest, GetRecommendedActionsDueDatesStatusResponse>(
      PATHS.POST_RA_DUE_DATES_STATUS,
      request,
      {
        params,
      }
    )
    handlers.onSuccess && (await handlers.onSuccess(response.data))
  } catch (error) {
    handlers.onError && (await handlers.onError(error as AxiosError))
  }
}

export { postRecommendedActionsDueDatesStatus }
