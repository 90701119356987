import { FC } from 'react'
import RecommendedActionsGraph from './RecommendedActionsGraph'
import RecommendedActionsTrendViewTable from './RecommendedActionsTable'
import { useSlicesRange } from '@/modules/dashboard/hooks/useSlicesRange'
import { RAwidgetDataType } from '@/models/widgets/recommended-actions/types'
import { useNoDataInAllMonths } from '@/modules/dashboard/utils'
import { StyledRATendViewContainer, StyledRATendViewContent } from '../styled'
import { dataTestId } from '@/tests/testid'

interface RecommendedActionsTrendViewProps {
  slices: RAwidgetDataType
  showPercentages: boolean
}

const RecommendedActionsTrendView: FC<RecommendedActionsTrendViewProps> = ({ slices, showPercentages }) => {
  const { slices: slicesRange } = useSlicesRange(slices, 12)
  const noDataInAllMonth = useNoDataInAllMonths(slices)

  return (
    <StyledRATendViewContainer data-testid={dataTestId.recommendedActionsTrendView}>
      <StyledRATendViewContent>
        <RecommendedActionsTrendViewTable
          slices={slicesRange}
          showPercentages={showPercentages}
          noDataInAllMonth={noDataInAllMonth}
        />
        <RecommendedActionsGraph
          slices={slicesRange}
          showPercentages={showPercentages}
          noDataInAllMonth={noDataInAllMonth}
        />
      </StyledRATendViewContent>
    </StyledRATendViewContainer>
  )
}
export default RecommendedActionsTrendView
