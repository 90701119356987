// =================================================================================================
// IMPORTS
// =================================================================================================

import { ColumnDef } from '@/common/table/base-components/column-def'
import { Customer } from '@/models/customer/types'
import { OverviewDataRow } from '@/models/overview/types'
import { StyledEmptySpaceHeader, StyledOverviewSitesColumn, StyledSitesLink } from '@/modules/overview/styled'
import TableHeaderColumn from '@/shared/components/TableComponents/TableHeaderColumn'
import { ROUTES } from '@/shared/constants'
import useRedirectToModuleWithSelectedCustomer from '@/shared/hooks/useRedirectToModuleWithSelectedCustomer'
import { useTypedSelector } from '@/store/store'
import { dataTestId } from '@/tests/testid'
import { ColumnHelper, Header } from '@tanstack/react-table'
import { FC } from 'react'

// =================================================================================================
// COLUMN DEF
// =================================================================================================

export const generateCompanyColumnDefinition = (columnHelper: ColumnHelper<OverviewDataRow>) =>
  columnHelper.accessor('company', {
    id: 'company',
    header: 'Site',
    /* v8 ignore next 1 */
    cell: ({ getValue }) => <div>{getValue()}</div>,
  })

// =================================================================================================
// COLUMN DEF COMPONENT
// =================================================================================================

export const OverAllTableCompanyColumnDef: FC = () => <ColumnDef />

// =================================================================================================
// HEADER COMPONENT
// =================================================================================================

export const OverAllTableCompanyHeaderColumn: FC<{ header: Header<OverviewDataRow, unknown> }> = ({ header }) => (
  <TableHeaderColumn<OverviewDataRow> header={header} centerValue={true} withSorting={false}>
    <StyledEmptySpaceHeader className="sub-header" />
  </TableHeaderColumn>
)

// =================================================================================================
// CELL COMPONENT
// =================================================================================================

const OverAllTableCompanyColumnCell: FC<{ companyId: string }> = ({ companyId }) => {
  const { customersList } = useTypedSelector((state) => ({ ...state }))
  const company = customersList.find((customer) => customer.id === companyId) as Customer
  const redirectToModuleWithSelectedCustomer = useRedirectToModuleWithSelectedCustomer()
  return (
    <td>
      <StyledOverviewSitesColumn>
        <StyledSitesLink
          data-testid={dataTestId.overviewSitesColumn}
          onClick={() => redirectToModuleWithSelectedCustomer(ROUTES.DASHBOARD, companyId)}
          className="cell-value"
        >
          {company.name}
        </StyledSitesLink>
      </StyledOverviewSitesColumn>
    </td>
  )
}
export default OverAllTableCompanyColumnCell
