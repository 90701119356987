import { StatusCode } from '@/api/constants'
import { publishReport } from '@/api/paths/reportStatus'
import { ReportStatusLoaderData } from '@/app/routes/types'
import { useReportStatusContext } from '@/contexts/reportStatus'
import { Fault } from '@/models/reportStatus/faultsFormStateTypes'
import { ResponseReport } from '@/models/reportStatus/types'
import useMarkAsAcknowledge from '@/modules/orphan-notes/hooks/useMarkAsAcknowledge'
import { buildPublishedReport, getFaults, mapPublishedReportResponse } from '@/modules/report-status/utils'
import { useApi } from '@/shared/hooks/useApi'
import { startAsyncPoller } from '@/store/asyncPoller/actions'
import { openToast } from '@/store/genericToast/actions'
import { useAppDispatch } from '@/store/store'
import { isAxiosError } from 'axios'
import { useLoaderData } from 'react-router-dom'

/**
 * Publishes a report with acknowledged faults and customer notes.

 * @returns {() => Promise<{ faults: Fault[] } | { faults: [] }>} A function that triggers the report publishing process and returns a Promise. The Promise resolves with an object containing the published faults (`faults`) upon success, or an empty object upon failure.

 * @description
 * This hook initiates the process of publishing a report. It retrieves data from `useLoaderData`, dispatches Redux actions, interacts with other hooks, and handles various scenarios.

 *  - **Success Scenario:**
 *     1. Gathers faults and asset ID from provided parameters.
 *     2. Finds the last report timestamp from history data.
 *     3. Builds the report data to be published.
 *     4. Publishes the report using the `publishReport` function.
 *     5. Acknowledges any open faults with customer notes using `useMarkAsAcknowledge`.
 *     6. Starts an asynchronous poller to track report status.
 *     7. Returns the published faults upon successful publication.
 *  - **Error Scenario:**
 *     1. If error occurs during report publishing:
 *       - Handles conflicts and pre-condition failures by opening a conflict dialog.
 *       - Displays a generic error toast message for other errors.
 *     2. Returns an empty faults object upon failure.

 */
const usePublishReport = () => {
  const { assetFaultsAndHistory, customerNotes } = useLoaderData() as ReportStatusLoaderData
  const { setAnalyticsCancelButtonBehavior, setIsConflictDialogOpen } = useReportStatusContext()

  const { execute: acknowledgeAndSave } = useMarkAsAcknowledge()

  const publishReportDispatch = useAppDispatch()
  return useApi(async (params) => {
    if (params && params.postData && params.assetId) {
      const faults = params.postData as Fault[]
      const assetId = params.assetId
      let lastReportDateTime: string | undefined = undefined
      if (assetFaultsAndHistory.history.length > 0) {
        const lastReport = assetFaultsAndHistory.history.reduce((a, b) => (a.timestamp > b.timestamp ? a : b)) // GET LAST REPORT
        lastReportDateTime = lastReport.timestamp
      }

      const reportDataToPublish = buildPublishedReport(faults, lastReportDateTime)

      try {
        const publishReportResponse = (await publishReport({ ...reportDataToPublish }, { assetId })) as ResponseReport

        const openFaultsWithCustomerNotes = getFaults(assetFaultsAndHistory, customerNotes, assetId)

        const notesToAcknowledge = openFaultsWithCustomerNotes.reduce((acc, openFault) => {
          if (openFault.metadata.customerNotes.length > 0) {
            acc.push(...openFault.metadata.customerNotes.map((cn) => cn.idNote))
          }
          return acc
        }, [] as string[])

        await acknowledgeAndSave({ postData: Array.from(new Set(notesToAcknowledge)) })

        setAnalyticsCancelButtonBehavior({ isDisabled: true })

        publishReportDispatch(
          startAsyncPoller({
            pollerInterval: 2000,
            pollerTimeout: 10000,
            toastMessages: {
              startPollingMessage: 'Processing the status report',
              timeoutPollingMessage:
                'Publishing the status report failed due to time out. Please refresh the page and if this repeats, contact support.',
              failedPollingMessage: 'Publishing the status report failed. Please contact support.',
            },
          })
        )
        return { faults: mapPublishedReportResponse(publishReportResponse).openFaults.map((v) => v.fault) }
      } catch (error) {
        if (
          isAxiosError(error) &&
          error.response &&
          [StatusCode.Conflict, StatusCode.PreconditionRequired].includes(error.response.status)
        ) {
          setIsConflictDialogOpen(true)
        } else {
          publishReportDispatch(
            openToast({
              feSeverity: 'error',
              children: 'Update report data failed',
            })
          )
        }
        return { faults: [] }
      }
    }
    return Promise.reject('No data was send to publish report')
  })
}

export default usePublishReport
