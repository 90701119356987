import { getReportFaultTypes } from '@/api/paths/reportStatus'
import { GetReportFaultTypesResponse } from '@/api/paths/reportStatus/types'
import { DarwinError } from '@/shared/components/ErrorBoundary/DarwinError'
import { FaultTypes } from '@/models/reportStatus/types'
import { mapFaultTypes } from '@/modules/analysis-backlog/utils/index'
import { useApi } from '@/shared/hooks/useApi'
import { useAsyncThrow } from '@/shared/hooks/useAsyncThrow'

/**
 * Custom hook for fetching report fault types from the API.
 *
 * This hook utilizes the `useApi` hook to manage the API request and error handling.
 * It retrieves fault types using the `getReportFaultTypes` function and maps the response
 * to the appropriate format using `mapFaultTypes`. Any errors encountered during the
 * fetching process are thrown using the `useAsyncThrow` hook.
 *
 * @returns {Promise<FaultTypes>} A promise that resolves to the mapped fault types.
 *                                If an error occurs, it will throw a DarwinError with the
 *                                relevant error information.
 */

const useGetReportFaultTypes = () => {
  const throwError = useAsyncThrow()

  return useApi(async () => {
    const faultTypes = (await getReportFaultTypes({
      onError: async (error) => {
        throwError(new DarwinError(error))
      },
    })) as GetReportFaultTypesResponse
    if (faultTypes) {
      return new Promise<FaultTypes>((resolve) => resolve(mapFaultTypes(faultTypes)))
    }
  })
}

export default useGetReportFaultTypes
