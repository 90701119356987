import { Fragment, useState, useRef, useMemo, useCallback, useEffect } from 'react'
import { Column } from '@tanstack/react-table'
import { Button, COLORS, Flexbox, IconButton, Popup, useTooltip } from '@skf-internal/ui-components-react'
import useOutsideClick from '@/shared/hooks/useOutsideClick'
import { isEqual } from '@/shared/utils'
import Badge from '@/shared/components/Badge'
import { AppliedFilterDataType, FilterType } from '@/shared/components/TableComponents/GenericTableFilter/types'
import { filterRegistry } from '@/shared/components/TableComponents/GenericTableFilter/constants'
import AppliedFilterPopover from '@/shared/components/TableComponents/GenericTableFilter/filterHelpComponents/AppliedFilterPopover'
import { dataTestId } from '@/tests/testid'
import useEscapeKey from '@/shared/hooks/useEscapeKey'
import { StyledGenericFilterPopoverCard } from '@/shared/components/TableComponents/styled'

// Component props interface
interface GenericFilterPopoverProps<RowType> {
  filterType: FilterType
  column: Column<RowType, unknown>
}

// Merged DynamicFilterPopover
const GenericTableFilter = <RowType,>({ filterType, column }: GenericFilterPopoverProps<RowType>) => {
  const { FilterComponent } = filterRegistry[filterType]
  const isFilterApplied = column.getIsFiltered()

  const appliedFilterValue = column.getFilterValue() as AppliedFilterDataType

  const [showPopover, setShowPopover] = useState(false)
  const outsideClickRef = useRef<HTMLDivElement>(null)
  const clickedRef = useRef<HTMLElement>(null)

  // Local state for the filter value
  const [filterValue, setFilterValue] = useState<AppliedFilterDataType | undefined>()

  useEffect(() => {
    setFilterValue(() => (isFilterApplied ? appliedFilterValue : undefined))
  }, [appliedFilterValue, isFilterApplied])

  // Check if applying the filter is disabled
  const isFilterApplyDisabled = useMemo(() => {
    return (
      filterValue === undefined ||
      (isFilterApplied === true && appliedFilterValue !== undefined && isEqual(appliedFilterValue, filterValue))
    )
  }, [appliedFilterValue, filterValue, isFilterApplied])

  const handleFilterValueChange = useCallback((value?: AppliedFilterDataType) => {
    setFilterValue(value)
  }, [])

  const handleFilterSet = useCallback(() => {
    column.setFilterValue(filterValue)
    setShowPopover(false)
  }, [column, filterValue])

  const handleFilterClear = useCallback(() => {
    setFilterValue(undefined)
    column.setFilterValue(undefined)
    setShowPopover(false)
  }, [column])

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (event.key === 'Enter' && !isFilterApplyDisabled) {
        handleFilterSet()
      }
    },
    [handleFilterSet, isFilterApplyDisabled]
  )

  useOutsideClick(() => setShowPopover(false), {
    refs: [outsideClickRef],
    classNames: ['ant-picker-panel-container', 'ant-picker-date-panel-container'],
  })

  useEscapeKey(() => {
    setShowPopover(false)
    !isFilterApplied && setFilterValue(undefined)
  }, [])

  const [hoveredRef, isHovered] = useTooltip()

  return (
    <Fragment>
      <div ref={hoveredRef}>
        <Badge badgeContent="" bgColor={isFilterApplied ? COLORS.brand : 'transparent'}>
          <IconButton
            feSize="sm"
            feIcon="filter"
            as="button"
            aria-label="Open filter popover"
            onClick={() => setShowPopover(!showPopover)}
            ref={clickedRef}
            data-testid={dataTestId.genericTableFilterIcon}
          />
        </Badge>
      </div>
      <AppliedFilterPopover
        FilterSummaryComponent={filterRegistry[filterType].FilterSummaryComponent}
        appliedFilterValue={appliedFilterValue}
        isPopupShown={isHovered && isFilterApplied}
        hoveredElement={hoveredRef.current}
      />
      <Popup feNoPadding isHovered={showPopover} triggerElement={clickedRef.current}>
        <StyledGenericFilterPopoverCard
          ref={outsideClickRef}
          onKeyDown={handleKeyDown}
          data-testid={dataTestId.genericTableFilterPopup}
        >
          <FilterComponent onFilterValueChange={handleFilterValueChange} initialValue={filterValue} />

          <Flexbox feGap="sm" feJustifyContent="right">
            <Button
              feType="secondary"
              feSize="sm"
              onClick={handleFilterClear}
              data-testid={dataTestId.genericTableFilterPopupClearButton}
            >
              Clear
            </Button>
            <Button
              feSize="sm"
              onClick={handleFilterSet}
              disabled={isFilterApplyDisabled}
              data-testid={dataTestId.genericTableFilterPopupApplyButton}
            >
              Apply
            </Button>
          </Flexbox>
        </StyledGenericFilterPopoverCard>
      </Popup>
    </Fragment>
  )
}
export default GenericTableFilter
