// =================================================================================================
// UTILS
// =================================================================================================

import { FC } from 'react'
import { ColumnId } from './table'
import { Header, HeaderGroup } from '@tanstack/react-table'
// import { OverAllTableComplianceHeaderColumn } from './columns/compliance-column'
import { OverviewDataRow } from '@/models/overview/types'
import { StyledTableHeaderRow } from '@/modules/overview/styled'
import { OverAllTableAnalysisBacklogHeaderColumn } from '@/modules/overview/components/overview-table/columns/analysis-backlog-column'
import { OverAllTableAutoDiagnosticsHeaderColumn } from '@/modules/overview/components/overview-table/columns/auto-diagnostics-column'
import { OverAllTableCompanyHeaderColumn } from '@/modules/overview/components/overview-table/columns/sites-column'
import { OverAllTableDevicesAndSensorsColumn } from '@/modules/overview/components/overview-table/columns/devices-sensors-management-column'
import { OverAllTableUnresolvedRecommendationsColumn } from '@/modules/overview/components/overview-table/columns/unresolved-recommendations-column'
import { OverAllTableActionsHeaderColumn } from '@/modules/overview/components/overview-table/columns/actions-column'

const headerColumnRecord: Record<ColumnId, (header: Header<OverviewDataRow, unknown>) => JSX.Element> = {
  analysisBacklog: (header) => <OverAllTableAnalysisBacklogHeaderColumn header={header} />,
  autoDiagnostics: (header) => <OverAllTableAutoDiagnosticsHeaderColumn header={header} />,
  company: (header) => <OverAllTableCompanyHeaderColumn header={header} />,
  // compliance: (header) => <OverAllTableComplianceHeaderColumn header={header} />,
  deviceAndSensors: (header) => <OverAllTableDevicesAndSensorsColumn header={header} />,
  unresolvedRecommendations: (header) => <OverAllTableUnresolvedRecommendationsColumn header={header} />,
  actions: (header) => <OverAllTableActionsHeaderColumn header={header} />,
}

// =================================================================================================
// COMPONENT
// =================================================================================================

export const TableHeader: FC<{ tableHeaderGroups: HeaderGroup<OverviewDataRow>[] }> = ({ tableHeaderGroups }) => {
  return (
    <thead>
      {tableHeaderGroups.map((headerGroup) => (
        <StyledTableHeaderRow key={headerGroup.id}>
          {headerGroup.headers.map((header) => {
            const columnId: ColumnId = header.column.id! as ColumnId
            const ColDefComponent = () => headerColumnRecord[columnId](header)
            return <ColDefComponent key={header.id} />
          })}
        </StyledTableHeaderRow>
      ))}
    </thead>
  )
}
