import { updateEvidenceImageData } from '@/api/paths/documents/files'
import { UpdateFaultImageDataRequest } from '@/api/paths/documents/files/types'
import { useFaultsFormStateContext } from '@/contexts/moduleContexts/faultsForm'
import { EvidenceImageData } from '@/models/reportStatus/evidenceTypes'
import { UPDATE_EVIDENCE_IMAGE_DATA } from '@/modules/report-status/reducer/actions.types'
import { updateEntity } from '@/modules/report-status/utils/formUtils'
import { useApi } from '@/shared/hooks/useApi'
import { AxiosError } from 'axios'

/**
 * Updates the image data for a fault evidence.

 * @param {EvidenceImageData} imageRequestData - The original image data.
 * @param {string} newFileName - The new file name for the image.
 * @returns {() => Promise<void>} A function that triggers the update process and returns a Promise.

 * @description
 * This hook initiates the process of updating the image data for a specific fault evidence. It constructs an update request with the new file name and dispatches Redux actions to handle success and error scenarios.

 * **Success Scenario:**
 *   - Updates the `imageInfo.fileName` field in the `EvidenceImageData` object.
 *   - Dispatches a Redux action to update the fault evidence data with the new information.

 * **Error Scenario:**
 *   - Updates the `appInfo.error` field in the `EvidenceImageData` object with the error message.
 *   - Dispatches a Redux action to update the fault evidence data with the error information.
 */
const useUpdateFaultEvidenceImageData = (imageRequestData: EvidenceImageData, newFileName: string) => {
  const { faultsFormDispatch } = useFaultsFormStateContext()

  return useApi(() => {
    const updateRequest = {
      fileName: newFileName,
      tags: imageRequestData.bucketInfo.tags,
    } as UpdateFaultImageDataRequest

    return updateEvidenceImageData(
      updateRequest,
      {
        onSuccess: async () => {
          faultsFormDispatch({
            type: UPDATE_EVIDENCE_IMAGE_DATA,
            payload: updateEntity(imageRequestData, {
              imageInfo: { fileName: newFileName },
            } as unknown as Partial<EvidenceImageData>).updated,
          })
        },
        onError: async (error: AxiosError) => {
          imageRequestData.appInfo.error = error.message
          faultsFormDispatch({
            type: UPDATE_EVIDENCE_IMAGE_DATA,
            payload: imageRequestData,
          })
        },
      },
      { fileID: encodeURIComponent(imageRequestData.imageInfo.fileID) }
    )
  })
}

export default useUpdateFaultEvidenceImageData
