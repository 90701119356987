import { useFetchDataContext } from '@/contexts/common/fetchDataContext'
import { STORAGE_ERROR_COUNT } from '../localStorageUtils'
import useDeepCompareEffect from './useDeepCompareEffect'

/**
 * A custom hook that cleans up session storage based on the data fetch status.
 * Specifically, it removes an error count from session storage when the `fetchDataStatus` is successful
 * and the error count stored in `STORAGE_ERROR_COUNT` equals 1.
 *
 * This hook relies on the `fetchDataStatus` from the `useFetchDataContext` to determine the cleanup logic.
 * The effect is compared deeply using `useDeepCompareEffect`.
 *
 * @returns {void}
 */

const useCleanupStorage = () => {
  const { fetchDataStatus } = useFetchDataContext()
  useDeepCompareEffect(() => {
    const errorCount = sessionStorage.getItem(STORAGE_ERROR_COUNT)
    if (errorCount && +errorCount === 1 && fetchDataStatus === 'success') {
      sessionStorage.removeItem(STORAGE_ERROR_COUNT)
    }
  }, [fetchDataStatus])
}

export default useCleanupStorage
