import { getOverviewData } from '@/api/paths/overview'
import { DarwinDetachedModuleError } from '@/shared/components/ErrorBoundary/DarwinError'
import { useOverviewContext } from '@/contexts/overview'
import { OverviewSummary } from '@/models/overview/types'
import { mapOverviewData } from '@/modules/overview/utils'
import { useApi } from '@/shared/hooks/useApi'
import { useAsyncThrow } from '@/shared/hooks/useAsyncThrow'
import { AxiosError } from 'axios'

const useGetOverviewData = () => {
  const throwError = useAsyncThrow()
  const { setOverviewData } = useOverviewContext()

  return useApi(() => {
    return getOverviewData({
      onSuccess: async (result: OverviewSummary) => {
        const sortedData = result.companies
          .map((company) => mapOverviewData(company))
          .sort((a, b) => a.company.localeCompare(b.company))

        setOverviewData(sortedData)
      },
      onError: async (error: AxiosError) => {
        throwError(new DarwinDetachedModuleError(error))
      },
    })
  })
}

export default useGetOverviewData
