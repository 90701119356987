import { CLEAR_ERROR, SET_ERROR } from '@/store/errorHandler/actions.types'
import { ErrorState, ErrorStateAction } from '@/store/errorHandler/types'

export const initialState: ErrorState | null = null

type ErrorReducer = (state: ErrorState | undefined, action: ErrorStateAction) => ErrorState

const errorDataReducer: ErrorReducer = (state = initialState, action) => {
  if (action.type === SET_ERROR) {
    return action.payload
  }
  if (action.type === CLEAR_ERROR) {
    return { error: null }
  }
  return state
}

export default errorDataReducer
