import { useFaultEvidenceContext } from '@/contexts/moduleContexts/evidence'
import { useFaultsFormStateContext } from '@/contexts/moduleContexts/faultsForm'
import { REMOVE_EVIDENCE_UPLOADED_IMAGE } from '@/modules/report-status/reducer/actions.types'
import { useApi } from '@/shared/hooks/useApi'

/**
 * Deletes an evidence image associated with a fault.

 * @param {string} fileId - The ID of the file to be deleted.
 * @returns {() => Promise<void>} A function that triggers the deletion process and returns a Promise.

 * @description
 * This hook initiates the deletion of an evidence image for a specific fault. It dispatches a Redux action to remove the image from the fault's evidence list.
 */
const useDeleteFaultImage = (fileId: string) => {
  const { faultsFormDispatch } = useFaultsFormStateContext()
  const { faultId } = useFaultEvidenceContext()

  const deleteEvidenceImage = async () => {
    faultsFormDispatch({ type: REMOVE_EVIDENCE_UPLOADED_IMAGE, payload: { faultId, fileId } })
  }

  return useApi(() => {
    return deleteEvidenceImage()
  })
}

export default useDeleteFaultImage
