import { COLORS, GridItem, styled, Text } from '@skf-internal/ui-components-react'

export const Title = styled(Text)`
  width: 50%;
`

export const DataText = styled(Text)`
  font-weight: bold;
`

export const ChannelDataWrapper = styled(GridItem)<{ isLastItem: boolean }>`
  border-bottom: ${({ isLastItem }) => !isLastItem && `1px solid ${COLORS.gray400}`};
  gap: 1rem;
  padding-bottom: 1rem;
  grid-template-columns: 50% 50% !important;
`

export const StyledChannelTitle = styled(Text)`
  min-width: 10.6rem;
  width: 50%;
`
