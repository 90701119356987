// =================================================================================================
// IMPORTS
// =================================================================================================

import imx8Image from '../images/imx8.webp'
import imx16Image from '../images/imx16.webp'
import { FC } from 'react'
import { Device } from '@/models/devicesAndSensors/types'
import { StyledImage } from '@/modules/devices-sensors/components/styled'

// =================================================================================================
// COMPONENT
// =================================================================================================

export const SensorImage: FC<{ model: Device['model'] }> = ({ model }) => {
  return <StyledImage src={model === 'IMX-16' ? imx16Image : imx8Image} alt={model} />
}
