import styled from '@emotion/styled'
import { Link } from '@skf-internal/ui-components-react'

export const StyledLink = styled(Link)`
  padding: 10px;

  &:hover {
    background-color: rgb(231 238 250);
  }
`

export const StyledPopover = styled.div`
  width: max-content;
  background-color: rgb(255 255 255);
  border-radius: 0.125rem;
  font-size: 16px;
  padding: 0;
`
