import networkClient from '@/api/NetworkClient'
import { GetOverviewDataRequest, GetOverviewDataResponse, GetOverviewDataResult } from '@/api/paths/overview/types'
import { PATHS } from '@/api/types'

import { OverviewSummary } from '@/models/overview/types'
import { AxiosError } from 'axios'

const getOverviewData = async (handlers: GetOverviewDataResult): Promise<OverviewSummary | void> => {
  try {
    const response = await networkClient.get<GetOverviewDataRequest, GetOverviewDataResponse>(PATHS.GET_OVERVIEW_DATA)
    handlers.onSuccess && (await handlers.onSuccess(response.data))
  } catch (error) {
    handlers.onError && (await handlers.onError(error as AxiosError))
  }
}

export { getOverviewData }
