import {
  ClosedRecommendedAction as ClosedRecommendedActionType,
  RecommendedAction,
} from '@/models/reportStatus/faultsFormStateTypes'
import {
  StyledClosedRecommendedActionRow,
  StyledClosedRecommendedActionLabel,
  StyledClosedRecommendedActionRowData,
  StyledClosedRecommendedActionRowOutcome,
  StyledClosedRecommendedActionDescription,
  StyledClosedRecommendedActionCloseDetails,
} from '@/modules/report-status/styled'
import { closedRecommendedActionOutcomeLabel } from '@/modules/report-status/utils/constants'
import { DateFormats, formatDate } from '@/shared/dateUtils'
import { dataTestId } from '@/tests/testid'
import { Heading, Icon } from '@skf-internal/ui-components-react'
import { FC } from 'react'

interface ClosedRecommendedActionProps {
  recommendedActionIndex: number
  recommendedAction: RecommendedAction
}

const ClosedRecommendedAction: FC<ClosedRecommendedActionProps> = ({ recommendedActionIndex, recommendedAction }) => {
  const {
    outcome,
    recommendedAction: description,
    username,
    closeDate,
  } = recommendedAction as ClosedRecommendedActionType

  return (
    <StyledClosedRecommendedActionRow data-testid={dataTestId.closedRecommendedAction}>
      <StyledClosedRecommendedActionRowData feAlignItems="center">
        <div>
          <Icon feSize="md" feIcon="arrowRight" />
        </div>
        <Heading as="h2">{recommendedActionIndex}</Heading>
        <StyledClosedRecommendedActionRowOutcome as="h3">
          {closedRecommendedActionOutcomeLabel[outcome]}
        </StyledClosedRecommendedActionRowOutcome>
        <StyledClosedRecommendedActionLabel>Recommended Action</StyledClosedRecommendedActionLabel>
        <StyledClosedRecommendedActionDescription>{description}</StyledClosedRecommendedActionDescription>
      </StyledClosedRecommendedActionRowData>
      <StyledClosedRecommendedActionCloseDetails feJustifyContent="space-evenly">
        <div> {username}</div>
        <div> {closeDate !== null ? formatDate(closeDate, DateFormats.AmericanDateFormat) : '-'}</div>
      </StyledClosedRecommendedActionCloseDetails>
    </StyledClosedRecommendedActionRow>
  )
}

export default ClosedRecommendedAction
