import { useDevicesAndSensorsContext } from '@/contexts/devicesAndSensors'
import ChannelStatusIndicator from '@/modules/devices-sensors/components/side-menu/channelSection/channelStatusIndicator'
import DataRow from '@/modules/devices-sensors/components/side-menu/common/dataRow'
import { StyledDataGrid, StyledDivider } from '@/modules/devices-sensors/components/styled'
import { ChannelTypeText, deviceNumberFormatter } from '@/modules/devices-sensors/utils/constants'
import { roundVoltageValue } from '@/modules/devices-sensors/utils/tableUtils'
import { Flexbox, Text } from '@skf-internal/ui-components-react'
import { FC, useMemo } from 'react'
import { DataText, ChannelDataWrapper, StyledChannelTitle } from './styled'
import { formatProp } from '@/modules/devices-sensors/utils'
import TextEllipsis, { TextEllipsisType } from '@/shared/components/TextEllipsis'
import useWindowDimensions from '@/shared/hooks/useWindowDimensions'

export const ChannelDataSection: FC = () => {
  const {
    devicesAndSensorsSelectedItemsData: { channel: channelData },
  } = useDevicesAndSensorsContext()

  const { width } = useWindowDimensions()

  const characterLength = useMemo(() => {
    let result = 6
    if (width <= 1200) {
      result = 20
    } else if (width <= 1440) {
      result = 20
    } else if (width <= 1700) {
      result = 22
    } else {
      result = 28
    }
    return result
  }, [width])

  if (!channelData) return <></>

  return (
    <>
      <StyledDivider />

      <Flexbox data-testid="channel-data-section" feFlexDirection="column" feGap="md">
        <Flexbox feFlexDirection="column" feGap="xs">
          <Text feFontSize="xl">Channel details</Text>
          <Text feFontSize="lg" feFontWeight="bold">
            {deviceNumberFormatter.format(channelData.number)}. {channelData.name}
          </Text>
          <Text>{ChannelTypeText[channelData.type]}</Text>
        </Flexbox>

        <ChannelStatusIndicator faults={channelData.faults} status={channelData.status} />

        {channelData.status !== 'channelNotConnected' && (
          <>
            <StyledDataGrid>
              <>
                {channelData.type === 'analog' && (
                  <DataRow
                    name="Voltage"
                    value={formatProp(channelData.voltage, (prop) => `${roundVoltageValue(prop)} V`)}
                  />
                )}
              </>
            </StyledDataGrid>
          </>
        )}
        {channelData.asset.map((item, index, array) => (
          <ChannelDataWrapper isLastItem={index === array.length - 1}>
            <Flexbox>
              <StyledChannelTitle>Connected Asset:</StyledChannelTitle>
              <DataText>
                <TextEllipsis
                  value={formatProp(item.asset, (prop) => prop.name)}
                  type={TextEllipsisType.SingleLine}
                  characterLength={characterLength}
                />
              </DataText>
            </Flexbox>

            <Flexbox>
              <StyledChannelTitle>Belongs to:</StyledChannelTitle>
              <DataText>
                <TextEllipsis
                  value={formatProp(item, (prop) => prop.functionalLocationName)}
                  type={TextEllipsisType.SingleLine}
                  characterLength={characterLength}
                />
              </DataText>
            </Flexbox>
          </ChannelDataWrapper>
        ))}
      </Flexbox>
    </>
  )
}
