import {
  ClosedRecommendedActionState,
  RecommendationState,
  RecommendedAction,
} from '@/models/unresolvedRecommendations/types'
import { RedirectButton } from '@/modules/unresolved-recommendation/components/redirectButton/redirectButton'
import { AssetStatus, MetaTableState } from '@/shared/models/types'
import TextEllipsis, { TextEllipsisType } from '@/shared/components/TextEllipsis'
import { createColumnHelper } from '@tanstack/react-table'
import { COLORS, css } from '@skf-internal/ui-components-react'
import AssetStatusIcon from '@/modules/unresolved-recommendation/components/status-icon'
import { filterRegistry } from '@/shared/components/TableComponents/GenericTableFilter/constants'
import { createTableSortingOrderMap, sortingRegistry } from '@/shared/components/TableComponents/sortingFunction'
import { openRecommendationAssetStatusSortingOrder } from '@/shared/components/TableComponents/sortingFunction/constants'

const columnHelper = createColumnHelper<RecommendedAction>()

function dueInDaysHelper(daysInDue: string) {
  if (
    [RecommendationState.DefaultDate, ClosedRecommendedActionState.dueInDays].includes(
      daysInDue as RecommendationState.DefaultDate | ClosedRecommendedActionState.dueInDays
    )
  ) {
    return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>{'-'}</div>
  }
  const daysDiff = parseInt(daysInDue)
  let backgroundColor = ''
  let color = ''
  if (daysDiff < 1 && daysDiff > -30) {
    backgroundColor = COLORS.orangeDarker
    color = 'white'
  } else if (daysDiff <= -30) {
    backgroundColor = COLORS.redBase
    color = 'white'
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <span
        css={css({
          color: color,
          backgroundColor: backgroundColor,
          padding: '3px 5px 3px 8px',
          borderRadius: '5px',
        })}
      >
        {daysDiff} Days
      </span>
    </div>
  )
}

interface TableCellContentProps {
  children: React.ReactNode
  textAlignCondition: string
}

const TableCellContent: React.FC<TableCellContentProps> = ({ children, textAlignCondition }) => {
  return (
    <div
      css={css`
        overflow-wrap: anywhere;
        text-align: ${textAlignCondition === '-' ? 'center' : 'left'};
      `}
    >
      {children}
    </div>
  )
}

export const unresolvedRecommendationTableDefinition = (tableState: boolean = false) => [
  columnHelper.accessor((row) => row.id, {
    id: 'ID',
    header: '#',
    cell: ({ getValue }) => {
      return <span>{getValue()}</span>
    },
    size: 50,
    enableSorting: true,
    sortingFn: sortingRegistry.number<RecommendedAction>,
    enableColumnFilter: false,
  }),

  columnHelper.accessor((row) => row.assetStatus, {
    id: 'assetStatus',
    header: 'Asset Status',
    cell: ({ getValue }) => {
      return (
        <AssetStatusIcon
          css={css`
            margin: auto;
          `}
          value={getValue<AssetStatus>()}
        />
      )
    },
    size: 200,
    enableSorting: true,
    sortingFn: (rowA, rowB, columnId) =>
      sortingRegistry.status<RecommendedAction>(
        rowA,
        rowB,
        columnId,
        createTableSortingOrderMap(openRecommendationAssetStatusSortingOrder)
      ),
    enableColumnFilter: true,
    filterFn: filterRegistry.status.filterFn,
    meta: {
      filterType: 'status',
    },
  }),

  columnHelper.accessor((row) => row.assetName, {
    id: 'assetName',
    header: 'Asset',
    cell: ({ getValue }) => {
      const assetName = getValue<string>()
      return <TableCellContent textAlignCondition={assetName}>{assetName}</TableCellContent>
    },
    size: 200,
    enableSorting: true,
    enableColumnFilter: true,
    filterFn: filterRegistry.text.filterFn,
    meta: {
      filterType: 'text',
    },
  }),

  columnHelper.accessor((row) => row.assetParent, {
    id: 'assetParent',
    header: 'Belongs To',
    cell: ({ getValue }) => {
      const assetParent = getValue<string>()
      return <TableCellContent textAlignCondition={assetParent}>{assetParent}</TableCellContent>
    },
    size: 160,
    enableSorting: true,
    enableColumnFilter: true,
    filterFn: filterRegistry.text.filterFn,
    meta: {
      filterType: 'text',
    },
  }),

  columnHelper.accessor((row) => row.faultType, {
    id: 'faultType',
    header: 'Fault',
    cell: ({ getValue }) => {
      const faultType = getValue<string>()
      return <TableCellContent textAlignCondition={faultType}>{faultType}</TableCellContent>
    },
    size: 200,
    enableSorting: true,
    enableColumnFilter: true,
    filterFn: filterRegistry.text.filterFn,
    meta: {
      filterType: 'text',
    },
  }),

  columnHelper.accessor((row) => row.recommendedAction, {
    id: 'recommendedAction',
    header: 'Recommended Action',
    cell: ({ getValue }) => {
      const recommendedAction = getValue<string>()
      return (
        <TableCellContent textAlignCondition={recommendedAction}>
          {<TextEllipsis type={TextEllipsisType.MultiLine} rowsUntilEllipsis={3} value={recommendedAction} />}
        </TableCellContent>
      )
    },
    size: 400,
    enableSorting: true,
    enableColumnFilter: true,
    filterFn: filterRegistry.text.filterFn,
    meta: {
      filterType: 'text',
    },
  }),

  columnHelper.accessor((row) => row.raOpenDate, {
    id: 'raOpenDate',
    header: 'Created Date',
    cell: ({ getValue }) => (
      <div
        css={css`
          display: flex;
          justify-content: center;
          align-items: center;
        `}
      >
        {getValue<string>()}
      </div>
    ),
    size: 170,
    sortingFn: sortingRegistry.date<RecommendedAction>,
    enableSorting: true,
    enableColumnFilter: true,
    meta: {
      filterType: 'date',
    },
    filterFn: filterRegistry.date.filterFn,
  }),

  columnHelper.accessor((row) => row.raDueDate, {
    id: 'raDueDate',
    header: 'Due Date',
    cell: ({ getValue }) => (
      <div
        css={css`
          display: flex;
          justify-content: center;
          align-items: center;
        `}
      >
        {getValue<string>()}
      </div>
    ),
    sortingFn: sortingRegistry.date<RecommendedAction>,
    enableSorting: true,
    enableColumnFilter: true,
    meta: {
      filterType: 'date',
    },
    filterFn: filterRegistry.date.filterFn,
    size: 145,
  }),

  columnHelper.accessor((row) => row.dueInDays.toString(), {
    id: 'dueInDays',
    header: 'Days To Due',
    cell: ({ getValue }) => {
      const value = getValue<string>()
      return dueInDaysHelper(value)
    },
    size: 145,
    sortingFn: sortingRegistry.number<RecommendedAction>,
    enableColumnFilter: true,
    filterFn: filterRegistry.text.filterFn,
    meta: {
      filterType: 'number',
    },
  }),

  columnHelper.accessor((row) => row.assetID, {
    id: 'action',
    header: '',
    enableSorting: false,
    enableColumnFilter: false,
    cell: ({ getValue, table, column }) => {
      const value = getValue()
      const { columnFilters, sorting } = table.getState()
      const {
        columnDef: { meta },
      } = column
      const tableState = (meta as MetaTableState).tableState

      return <RedirectButton value={value} columnFilters={columnFilters} sorting={sorting} tableState={tableState} />
    },
    size: 50,
    meta: {
      tableState,
    },
  }),
]
