import { AxiosError } from 'axios'
import networkClient from '../../NetworkClient'
import { PATHS } from '../../types'
import { GetAutomaticDiagnosticsRequest, GetAutomaticDiagnosticsResponse, GetAutomaticDiagnosticsResult } from './types'
import { AutomaticDiagnosticsData } from '@/models/automaticDiagnostics/types'

const getAutomaticDiagnostics = async (
  handlers: GetAutomaticDiagnosticsResult,
  params: Record<string, string | undefined>
): Promise<AutomaticDiagnosticsData | void> => {
  try {
    const response = await networkClient.get<GetAutomaticDiagnosticsRequest, GetAutomaticDiagnosticsResponse>(
      PATHS.GET_AUTOMATIC_DIAGNOSTICS_DATA,
      { params }
    )

    handlers.onSuccess && (await handlers.onSuccess(response.data))
  } catch (error) {
    handlers.onError && (await handlers.onError(error as AxiosError))
  }
}

export { getAutomaticDiagnostics }
