// =================================================================================================
// IMPORTS
// =================================================================================================

import { columnDefinitions } from './table'
import { DevicesSensorsTableNameColumnDef } from './columns/name-column'
import { DevicesSensorsTableLastCommunicationDateColumnDef } from './columns/last-communication-date'
import { DevicesSensorsTableStatusColumnDef } from './columns/status-column'
import { DevicesSensorsTableExpandRowColumnDef } from './columns/expand-row-column'
import { DevicesSensorsTableNumberColumnDef } from '@/modules/devices-sensors/components/table/columns/number-column'
import { ColumnId } from '@/models/devicesAndSensors/types'
import { DevicesSensorsTableConnectedAssetColumnDef } from './columns/connected-assets-column'

// =================================================================================================
// UTILS
// =================================================================================================

const colDefRecord: Record<ColumnId, JSX.Element> = {
  number: <DevicesSensorsTableNumberColumnDef />,
  name: <DevicesSensorsTableNameColumnDef />,
  status: <DevicesSensorsTableStatusColumnDef />,
  lastCommunicationDate: <DevicesSensorsTableLastCommunicationDateColumnDef />,
  uniqueChannelsAssets: <DevicesSensorsTableConnectedAssetColumnDef />,
  expandRow: <DevicesSensorsTableExpandRowColumnDef />,
}

// =================================================================================================
// COMPONENT
// =================================================================================================

export const ColGroup = () => {
  return (
    <colgroup>
      {columnDefinitions.map((column) => {
        const columnId: ColumnId = column.id! as ColumnId
        const ColDefComponent = () => colDefRecord[columnId]

        return <ColDefComponent key={column.id} />
      })}
    </colgroup>
  )
}
