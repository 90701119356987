import { ChannelDataSection } from '@/modules/devices-sensors/components/side-menu/channelSection'
import { DeviceDataSection } from '@/modules/devices-sensors/components/side-menu/deviceSection'
import { dataTestId } from '@/tests/testid'
import { Flexbox } from '@skf-internal/ui-components-react'
import { FC } from 'react'

// =================================================================================================
// COMPONENT
// =================================================================================================

export const DevicesSensorsSideMenu: FC = () => {
  return (
    <Flexbox data-testid={dataTestId.devicesSensorsSideMenu} feFlexDirection="column" feStretch={true}>
      <DeviceDataSection />
      <ChannelDataSection />
    </Flexbox>
  )
}
