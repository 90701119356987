import useContextWrapper from '@/shared/hooks/useContextWrapper'
import { OrphanNotes } from '@/models/orphanNotes/types'
import { createContext, useMemo, useState } from 'react'

interface IOrphanNotesContext {
  orphanNotes: OrphanNotes
  setOrphanNotes: React.Dispatch<React.SetStateAction<IOrphanNotesContext['orphanNotes']>>
  showAcknowledged: boolean
  setShowAcknowledged: React.Dispatch<React.SetStateAction<IOrphanNotesContext['showAcknowledged']>>
}

export const OrphanNotesContext = createContext<IOrphanNotesContext | null>(null)

export const useOrphanNotesContext = () =>
  useContextWrapper(OrphanNotesContext, {
    contextName: useOrphanNotesContext.name,
    providerName: OrphanNotesContextProvider.name,
  })

export const OrphanNotesContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [orphanNotes, setOrphanNotes] = useState<IOrphanNotesContext['orphanNotes']>({
    acknowledged: [],
    unacknowledged: [],
  })

  const [showAcknowledged, setShowAcknowledged] = useState<IOrphanNotesContext['showAcknowledged']>(false)

  const value = useMemo(
    () => ({
      orphanNotes,
      setOrphanNotes,
      showAcknowledged,
      setShowAcknowledged,
    }),
    [orphanNotes, showAcknowledged]
  )

  return <OrphanNotesContext.Provider value={value}>{children}</OrphanNotesContext.Provider>
}
