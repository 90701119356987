import { AxiosError } from 'axios'
import networkClient from '../../NetworkClient'
import { PATHS } from '../../types'
import { GetCustomersRequest, GetCustomersResponse, GetCustomersResult } from './types'
import { Customer } from '@/models/customer/types'
import { createCustomerList } from '@/app/routes/routesUtils'

const getCustomers = async (handlers: GetCustomersResult): Promise<Customer[] | void> => {
  try {
    const response = await networkClient.get<GetCustomersRequest, GetCustomersResponse>(PATHS.GET_SITES_DATA)

    return createCustomerList(response.data)
  } catch (error) {
    handlers.onError && (await handlers.onError(error as AxiosError))
  }
}

export { getCustomers }
