import { COLORS, Divider, SIZES, Select, styled } from '@skf-internal/ui-components-react'

export const StyledMainHeader = styled.div`
  height: 60px;
  background-color: ${COLORS.brand};
  margin-bottom: 2rem;
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  align-items: center;
`
export const StyledLogo = styled.a`
  display: flex;
  align-items: center;
  min-width: 14rem;
  gap: 1rem;
  text-decoration: none;
`
export const StyledLogoDivider = styled(Divider)`
  margin-block: 1px;
  margin-inline: 1px;
  opacity: 0.5;
`
export const StyledLogoutArea = styled.a`
  display: flex;
  align-items: center;
  min-width: 8rem;
  justify-content: space-between;
  padding: 1.25rem;

  :hover {
    cursor: pointer;
    color: ${COLORS.primary200};
    text-decoration: underline;
  }
`
export const StyledCustomerSelector = styled(Select)`
  height: 60px;
  display: flex;
  align-items: center;
  background-color: rgb(132 169 233 / 15%);
  width: 360px;

  > div {
    background: none;
    border: none;
    width: 100%;
  }

  button {
    gap: 0;
    padding: 0 1.125rem;
    justify-content: center;

    span {
      color: ${COLORS.white};
      font-size: ${SIZES.s18};
    }

    span:last-child {
      block-size: 1rem;
    }

    :hover {
      background-color: transparent !important;
    }
  }
`
