// =================================================================================================
// IMPORTS
// =================================================================================================

import { DevicesSensorsTableDeviceRowData } from '@/models/devicesAndSensors/types'
import { deviceNumberFormatter } from '@/modules/devices-sensors/utils/constants'
import TableHeaderColumn from '@/shared/components/TableComponents/TableHeaderColumn'
import { ColumnHelper, Header } from '@tanstack/react-table'
import { FC, useMemo } from 'react'
import { ColumnCell } from './commonCells/column-cell'
import { ColumnDef } from '@/common/table/base-components/column-def'
import useWindowDimensions from '@/shared/hooks/useWindowDimensions'
import { filterRegistry } from '@/shared/components/TableComponents/GenericTableFilter/constants'

// =================================================================================================
// CONSTANTS
// =================================================================================================

const COLUMN_WIDTH_IN_PERCENT = 9

// =================================================================================================
// COLUMN DEF
// =================================================================================================

export const generateNumberColumnDefinition = (columnHelper: ColumnHelper<DevicesSensorsTableDeviceRowData>) =>
  columnHelper.accessor('number', {
    id: 'number',
    header: '',
    cell: ({ getValue }) => getValue(),
    enableColumnFilter: true,
    filterFn: filterRegistry.number.filterFn<DevicesSensorsTableDeviceRowData>,
  })

// =================================================================================================
// COLUMN DEF COMPONENT
// =================================================================================================

export const DevicesSensorsTableNumberColumnDef: FC = () => <ColumnDef columnWidth={COLUMN_WIDTH_IN_PERCENT} />

// =================================================================================================
// HEADER COMPONENT
// =================================================================================================

export const DevicesSensorsTableNumberHeaderColumn: FC<{
  header: Header<DevicesSensorsTableDeviceRowData, unknown>
}> = ({ header }) => (
  <TableHeaderColumn<DevicesSensorsTableDeviceRowData>
    columnWidth={COLUMN_WIDTH_IN_PERCENT}
    header={header}
    centerValue={true}
  />
)

// =================================================================================================
// CELL COMPONENT
// =================================================================================================

export const DevicesSensorsTableNumberColumnCell: FC<{ value: number }> = ({ value }) => {
  const { width } = useWindowDimensions()

  const characterLength = useMemo(() => {
    let result = 3
    if (width <= 1200) {
      result = 2
    } else if (width <= 1440) {
      result = 4
    } else {
      result = 12
    }
    return result
  }, [width])

  return (
    <ColumnCell
      columnWidth={COLUMN_WIDTH_IN_PERCENT}
      value={deviceNumberFormatter.format(value)}
      centerValue={true}
      characterLength={characterLength}
    />
  )
}
