import { reconfirmReport } from '@/api/paths/reportStatus'
import { DarwinError } from '@/shared/components/ErrorBoundary/DarwinError'
import { useReportStatusContext } from '@/contexts/reportStatus'
import { useApi } from '@/shared/hooks/useApi'
import { useAsyncThrow } from '@/shared/hooks/useAsyncThrow'
import { startAsyncPoller } from '@/store/asyncPoller/actions'
import { useAppDispatch } from '@/store/store'

/**
 * Reconfirms a report for a given asset ID.

 * @param {string | undefined} assetId - The ID of the asset to reconfirm.
 * @returns {() => Promise<void>} A function that triggers the reconfirmation process and returns a Promise.

 * @description
 * This hook initiates the reconfirmation of a report for a specific asset. It dispatches a Redux action to trigger the reconfirmation process, handles success and error scenarios, and displays appropriate toast messages. 

 * On success, it starts an asynchronous poller to track the reconfirmation status.
 * On error, it displays an error message and handles specific error cases.
 */
const useReconfirmReport = (assetId: string | undefined) => {
  const throwError = useAsyncThrow()
  const { setAnalyticsCancelButtonBehavior } = useReportStatusContext()

  const reconfirmButtonDispatch = useAppDispatch()

  return useApi(async () => {
    return reconfirmReport(
      {
        onSuccess: async () => {
          setAnalyticsCancelButtonBehavior({ isDisabled: true })
          reconfirmButtonDispatch(
            startAsyncPoller({
              pollerInterval: 2000,
              pollerTimeout: 10000,
              toastMessages: {
                startPollingMessage: 'Processing Reconfirm status request',
                timeoutPollingMessage:
                  'Reconfirm status request timed out. Please refresh the page and if this repeats, contact support.',
                failedPollingMessage: 'Reconfirm status action failed. Please contact support.',
              },
            })
          )
        },
        onError: async (error) => {
          throwError(new DarwinError(error))
        },
      },
      { assetId }
    )
  })
}

export default useReconfirmReport
