import { ConditionStatusWidgetDataType, Severity } from '@/models/widgets/asset-health-condition/types'
import { tableCellFormatter, truncateValue } from '@/modules/dashboard/utils'
import {
  severityColor,
  severityToIconColor,
  severityToIconName,
} from '@/modules/dashboard/utils/asset-health-condition'
import { dataTestId } from '@/tests/testid'
import { css } from '@emotion/react'
import { Icon } from '@skf-internal/ui-components-react'
import { FC } from 'react'

interface AHCTableRowProps {
  severity: string
  slices: ConditionStatusWidgetDataType
  showPercentages: boolean
}

const AHCTableRow: FC<AHCTableRowProps> = ({ severity, slices, showPercentages }) => {
  return (
    <tr key={severity} data-testid={dataTestId.assetHealthConditionTrendViewTableRow}>
      <th>
        <div>
          <Icon
            feIcon={severityToIconName(severity as Severity)}
            feColor={severityToIconColor(severity as Severity)}
            feSize="sm"
          />
        </div>
      </th>
      {slices.map(({ period, data }) => (
        <td
          role="gridcell"
          key={`${period.year}-${period.month}-${severity}`}
          css={css`
            background-color: ${Object.keys(data).length === 0 ? '#f6f6f6' : ''};
            color: ${data[severity] >= 0 ? severityColor(severity as Severity) : '#aaafb8'};
          `}
        >
          {data[severity] >= 0 ? truncateValue(tableCellFormatter(data[severity], 1, showPercentages)) : 'NA'}
        </td>
      ))}
    </tr>
  )
}

export default AHCTableRow
