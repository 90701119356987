import { Flexbox, Icon, SPACINGS, Text } from '@skf-internal/ui-components-react'
import { FC, ReactNode } from 'react'
import TreeBranchIcon from '../assets/tree-branch.svg?react'
import TreeLastBranchIcon from '../assets/tree-last.svg?react'
import { AssetHealthFault, Faults, FaultStatusType, RecommendedAction } from '@/models/widgets/asset-health-fault/types'
import { machineHealthFaultColor, withOpacity } from '@/modules/dashboard/utils/asset-health-fault'
import { useNavigate, useParams } from 'react-router-dom'
import { ROUTES } from '@/shared/constants'
import {
  StyledCard,
  StyledCardHeader,
  StyledFaultsContainer,
  StyledFaultLink,
  StyledIndented,
  StyledSVGContainer,
  StyledStackBarDiv,
  StyledCardHeaderDiv,
  StyledHeadingWithItemDiv,
  StyledFaultsContentFlexbox,
  StyledObservationHeadingWithItemText,
  StyledRecommendedActionContentDiv,
  StyledRecommendedActionContentInnerDiv,
} from '../styled'
import { css } from '@emotion/react'
import { STORAGE_REPORT_STATUS_ORIGIN } from '@/shared/localStorageUtils'
import FlexibleCollapse from '@/shared/components/FlexibleCollapse'
import TextEllipsis, { TextEllipsisType } from '@/shared/components/TextEllipsis'
import { useDashboardContext } from '@/contexts/dashboard'

interface IStackedBar {
  asset: AssetHealthFault
  faultType: string
  selected: number
}
const StackedBar: FC<IStackedBar> = ({ asset, faultType, selected }) => {
  const { id, name: assetName, status: assetStatus, lastFaultCreatedDate, faults } = asset

  return (
    <StyledStackBarDiv>
      <StyledCard key={id} feNoPadding>
        <FlexibleCollapse
          header={
            <StyledCardHeader>
              <StyledCardHeaderDiv>
                {icon(assetStatus)}
                {assetName ? assetName : '-'}
              </StyledCardHeaderDiv>
              <Text style={{ fontWeight: '400' }}>{lastFaultCreatedDate ? lastFaultCreatedDate : '-'}</Text>
            </StyledCardHeader>
          }
        >
          <StyledFaultsContainer backgroundColor={withOpacity(machineHealthFaultColor(assetStatus), 0.2)}>
            {faults.map((fault) => (
              <FaultsContent key={fault.id} fault={fault} faultType={faultType} assetId={id} selected={selected} />
            ))}
          </StyledFaultsContainer>
        </FlexibleCollapse>
      </StyledCard>
    </StyledStackBarDiv>
  )
}

const Branch: FC<{ isLast?: boolean }> = ({ isLast = false }) => (
  <StyledSVGContainer>{isLast ? <TreeLastBranchIcon /> : <TreeBranchIcon />}</StyledSVGContainer>
)

const DateItem: FC<{ date: string; heading: string }> = ({ date, heading }) => (
  <Flexbox
    feFlexDirection="row"
    feJustifyContent="left"
    css={css`
      margin-top: ${SPACINGS.md};
    `}
  >
    <Text css={css({ fontWeight: '700' })}>{heading}</Text>
    <Text css={css({ fontWeight: '400', marginLeft: SPACINGS.sm + `!important` })}>{date ? date : '-'}</Text>
  </Flexbox>
)

const HeadingWithItem: FC<{ heading: string; children: ReactNode }> = ({ heading, children }) => (
  <StyledHeadingWithItemDiv>
    <div
      css={css`
        font-weight: 700;
      `}
    >
      {heading}
    </div>
    <div>{children}</div>
  </StyledHeadingWithItemDiv>
)

const FaultsContent: FC<{ fault: Faults; faultType: string; assetId: string; selected: number }> = ({
  fault,
  faultType,
  assetId,
  selected,
}) => {
  const { id, recommendedActions, observation } = fault
  const navigate = useNavigate()
  const { sid } = useParams()
  const { dashboardFilters, widgetAssetHealthFaultData } = useDashboardContext()

  return (
    <StyledFaultsContentFlexbox feJustifyContent="center" feAlignItems="center" feFlexDirection="column">
      <Flexbox
        feFlexDirection="row"
        feJustifyContent="left"
        css={css`
          width: 100%;
        `}
      >
        <Icon
          feIcon="recAction"
          css={css`
            align-self: flex-start;
            margin-bottom: ${SPACINGS.xs};
            margin-left: ${SPACINGS.xxs};
            margin-top: ${SPACINGS.xs};
          `}
        />
        <StyledFaultLink
          onClick={() => {
            sessionStorage.setItem(STORAGE_REPORT_STATUS_ORIGIN, `${ROUTES.DASHBOARD}/${sid}`)
            navigate(`${ROUTES.REPORT_STATUS}/${assetId}`, {
              state: { from: ROUTES.DASHBOARD, selected, dashboardFilters, widgetAssetHealthFaultData },
            })
          }}
        >
          {`#${id} ${faultType}`}
        </StyledFaultLink>
      </Flexbox>
      <Flexbox feFlexDirection="column">
        <StyledIndented>
          <Branch />
          <HeadingWithItem
            heading="Observation"
            children={
              <StyledObservationHeadingWithItemText>
                {observation ? (
                  <TextEllipsis type={TextEllipsisType.MultiLine} rowsUntilEllipsis={3} value={observation} />
                ) : (
                  '-'
                )}
              </StyledObservationHeadingWithItemText>
            }
          />
        </StyledIndented>
        {recommendedActions.map((recommendedAction, i: number) => (
          <RecommendedActionContent
            key={recommendedAction.id}
            recommendedAction={recommendedAction}
            isLastRecommendedAction={i === recommendedActions.length - 1}
          />
        ))}
      </Flexbox>
    </StyledFaultsContentFlexbox>
  )
}

const RecommendedActionContent: FC<{ recommendedAction: RecommendedAction; isLastRecommendedAction: boolean }> = ({
  recommendedAction,
  isLastRecommendedAction,
}) => {
  const { id, openDate, recommendation, additionalNotes, workOrder, dueDate } = recommendedAction
  return (
    <StyledRecommendedActionContentDiv>
      <StyledIndented>
        <Branch isLast={isLastRecommendedAction} />
        <StyledRecommendedActionContentInnerDiv>
          <Text
            css={css({
              textAlign: 'left',
              fontWeight: '700',
              width: '199px',
            })}
          >
            {`Recommended Action #${id ? id : '-'}`}
          </Text>
          <StyledIndented>
            <Branch />
            <DateItem heading="Created Date" date={openDate} />

            <Branch />
            <HeadingWithItem heading="Recommended action">
              {recommendation ? (
                <TextEllipsis type={TextEllipsisType.MultiLine} rowsUntilEllipsis={3} value={recommendation} />
              ) : (
                '-'
              )}
            </HeadingWithItem>

            <Branch />
            <HeadingWithItem heading="Additional Notes">
              {additionalNotes ? (
                <TextEllipsis type={TextEllipsisType.MultiLine} rowsUntilEllipsis={3} value={additionalNotes} />
              ) : (
                '-'
              )}
            </HeadingWithItem>

            <Branch />
            <HeadingWithItem heading="Work Order">
              {workOrder ? (
                <TextEllipsis type={TextEllipsisType.MultiLine} rowsUntilEllipsis={3} value={workOrder} />
              ) : (
                '-'
              )}
            </HeadingWithItem>

            <Branch isLast />
            <DateItem heading="Due Date" date={dueDate ? dueDate : '-'} />
          </StyledIndented>
        </StyledRecommendedActionContentInnerDiv>
      </StyledIndented>
    </StyledRecommendedActionContentDiv>
  )
}

const icon = (type: FaultStatusType) => {
  switch (type) {
    case 'severe':
      return <Icon feIcon="danger" feColor="red" feSize="sm" />
    case 'unacceptable':
      return <Icon feIcon="warning" feColor="orange" feSize="sm" />
    case 'unsatisfactory':
      return <Icon feIcon="error" feColor="yellow" feSize="sm" />
    case 'normal':
      return <Icon feIcon="checkCircle" feColor="green" feSize="sm" />
    case 'acceptable':
      return <Icon feIcon="refresh" feColor="green" feSize="sm" />
  }
}
export default StackedBar
