import { DevicesSensorsSideMenuChannelData } from '@/models/devicesAndSensors/types'
import {
  ChannelFaultText,
  ChannelStatusIcon,
  ChannelStatusString,
  ChannelTextColor,
} from '@/modules/devices-sensors/utils/constants'
import { Flexbox, Icon, Text } from '@skf-internal/ui-components-react'
import { FC } from 'react'

const ChannelDataFault: FC<{
  status: DevicesSensorsSideMenuChannelData['status']
  fault?: DevicesSensorsSideMenuChannelData['faults'][number]
}> = ({ status, fault }) => {
  const iconData = ChannelStatusIcon[status]
  const isFaulted = fault && status

  return (
    <Flexbox data-testid="channel-data-fault" feFlexDirection="row" feGap="xs" feAlignItems="center">
      <Icon feIcon={iconData.icon} feColor={iconData.color} feSize="sm" />
      <Text feColor={ChannelTextColor[status]} feFontWeight="bold">
        {isFaulted && ChannelFaultText[fault]}
        {!isFaulted && status !== 'channelNotConnected' && ChannelStatusString['normal']}
        {!isFaulted && status === 'channelNotConnected' && ChannelStatusString['channelNotConnected']}
      </Text>
    </Flexbox>
  )
}

export default ChannelDataFault
