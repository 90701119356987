import { FC } from 'react'
import DueDatesStatusBarChart from './DueDatesStatusBarChart'
import { StyledDueDatesStatusWrapper, StyledDueDatesStatusLabels, StyledDueDatesStatusTitle } from '../styled'

const DueDatesStatus: FC = () => {
  return (
    <StyledDueDatesStatusWrapper>
      <StyledDueDatesStatusTitle>Open Recommended Actions due dates status</StyledDueDatesStatusTitle>
      <StyledDueDatesStatusLabels>
        <span>Overdue</span>
        <span>Upcoming</span>
      </StyledDueDatesStatusLabels>
      <DueDatesStatusBarChart />
    </StyledDueDatesStatusWrapper>
  )
}

export default DueDatesStatus
