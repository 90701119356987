import { AutomaticsDiagnosticsContextProvider, useAutomaticsDiagnosticsContext } from '@/contexts/automaticDiagnostics'
import { FetchDataContextProvider, useFetchDataContext } from '@/contexts/common/fetchDataContext'
import { AutomaticDiagnosticsData } from '@/models/automaticDiagnostics/types'
import AutomaticDiagnosticsAverageBox from '@/modules/automatic-diagnostics/components/averageBox'
import AutomaticDiagnosticsFooter from '@/modules/automatic-diagnostics/components/footer'
import AutomaticDiagnosticsHeader from '@/modules/automatic-diagnostics/components/header'
import useGetAutomaticDiagnosticsData from '@/modules/automatic-diagnostics/hooks/useGetAutomaticDiagnosticsData'
import { StyledEChartContainer, StyledModuleContainer } from '@/modules/automatic-diagnostics/styled'
import { getAutomaticDiagnosticsChartOptions } from '@/modules/automatic-diagnostics/utils'
import { ReactEChartsRenderer } from '@/modules/dashboard/components/common/e-charts-renderer'
import DataRefresher from '@/shared/components/DataRefresher'
import { LoadingType } from '@/shared/hooks/useApi'
import useCleanupStorage from '@/shared/hooks/useCleanupStorage'
import useDeepCompareEffect from '@/shared/hooks/useDeepCompareEffect'
import { stopLoading } from '@/store/loading/action'
import { useAppDispatch } from '@/store/store'
import { dataTestId } from '@/tests/testid'
import { Flexbox } from '@skf-internal/ui-components-react'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

const AutomaticDiagnostics = () => {
  return (
    <AutomaticsDiagnosticsContextProvider>
      <FetchDataContextProvider>
        <OverlappingAutomaticDiagnostics />
      </FetchDataContextProvider>
    </AutomaticsDiagnosticsContextProvider>
  )
}

const OverlappingAutomaticDiagnostics = () => {
  const { execute, status } = useGetAutomaticDiagnosticsData()

  const { sid } = useParams()

  const { setFetchDataFn, setFetchDataStatus } = useFetchDataContext()

  const { automaticsDiagnosticsData } = useAutomaticsDiagnosticsContext()

  const automaticDiagnosticDispatch = useAppDispatch()

  useEffect(() => {
    setFetchDataFn(() => execute)
  }, [execute, setFetchDataFn])

  useDeepCompareEffect(() => {
    if (sid) {
      execute({ loaderType: LoadingType.GLOBAL })
    }
  }, [sid])

  useEffect(() => {
    setFetchDataStatus(status)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status])

  useDeepCompareEffect(() => {
    if (automaticsDiagnosticsData && Object.keys(automaticsDiagnosticsData).length > 0) {
      automaticDiagnosticDispatch(stopLoading())
    }
  }, [automaticsDiagnosticsData])

  useCleanupStorage()

  return (
    <>
      <StyledModuleContainer>
        <AutomaticDiagnosticsHeader />
        {automaticsDiagnosticsData && status !== 'loading' && (
          <Flexbox data-testid={dataTestId.automaticDiagnosticsComponent}>
            <StyledEChartContainer>
              <ReactEChartsRenderer option={getAutomaticDiagnosticsChartOptions(automaticsDiagnosticsData)} />
              <AutomaticDiagnosticsFooter />
            </StyledEChartContainer>
            <AutomaticDiagnosticsAverageBox />
          </Flexbox>
        )}
      </StyledModuleContainer>
      <DataRefresher<AutomaticDiagnosticsData> functionToExecute={execute} />
    </>
  )
}

export default AutomaticDiagnostics
