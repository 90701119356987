import { ReactEChartsRenderer } from '../../../common/e-charts-renderer'
import { FC } from 'react'
import { severities } from '@/modules/dashboard/utils/constants'
import type { EChartsOption } from 'echarts'
import { getDueDatesStatusBarChartOptions } from '@/modules/dashboard/utils/recommended-actions'
import useDeepCompareMemo from '@/shared/hooks/useDeepCompareMemo'
import { useDashboardContext } from '@/contexts/dashboard'
import { DueDatesStatusPeriod } from '@/models/widgets/recommended-actions/types'

const DueDatesStatusBarChart: FC = () => {
  const { recommendedActionsDueDatesStatus } = useDashboardContext()

  const totals = useDeepCompareMemo(
    () =>
      recommendedActionsDueDatesStatus?.map((item) =>
        severities.reduce((sum, severity) => sum + (item.statusCounts[severity] || 0), 0)
      ),
    [recommendedActionsDueDatesStatus, severities]
  )

  const xAxisLabels = useDeepCompareMemo(
    () =>
      recommendedActionsDueDatesStatus
        .flatMap((item) => [DueDatesStatusPeriod[item.period as keyof typeof DueDatesStatusPeriod] || item.period, ''])
        .slice(0, 7),
    [recommendedActionsDueDatesStatus]
  )

  const alteredData = useDeepCompareMemo(
    () =>
      recommendedActionsDueDatesStatus
        ?.map((item) => [
          item.statusCounts,
          { normal: null, acceptable: null, unsatisfactory: null, unacceptable: null, severe: null },
        ])
        .flat()
        .slice(0, 8),
    [recommendedActionsDueDatesStatus]
  )

  const option = useDeepCompareMemo<EChartsOption>(
    () => getDueDatesStatusBarChartOptions(xAxisLabels, totals, alteredData),
    [xAxisLabels, totals, alteredData]
  )

  return <ReactEChartsRenderer option={option} />
}

export default DueDatesStatusBarChart
