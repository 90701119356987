import { useFaultsFormStateContext } from '@/contexts/moduleContexts/faultsForm'
import { FaultState, FieldErrorState, UpdateFaultPath } from '@/models/reportStatus/faultsFormStateTypes'
import {
  StyledComponentErrorText,
  StyledComponentLabel,
  StyledReportStatusComponent,
} from '@/modules/report-status/styled'
import { onComponentChange } from '@/modules/report-status/utils/formUtils'
import TextEllipsis, { TextEllipsisType } from '@/shared/components/TextEllipsis'
import { dataTestId } from '@/tests/testid'
import { Select, SelectItem } from '@skf-internal/ui-components-react'
import { FC } from 'react'

interface FormSelectProps {
  label: string
  isInEditMode: boolean
  value: string
  text: string
  options: SelectItem[]
  state: FaultState
  fieldPath: UpdateFaultPath
  isRequired?: boolean
  errorData?: FieldErrorState
}

const FormSelect: FC<FormSelectProps> = ({
  label,
  isInEditMode,
  value,
  text,
  options,
  isRequired = false,
  fieldPath: { fieldId, fieldName, parentFieldId },
  errorData,
  state,
}) => {
  const { faultsFormDispatch } = useFaultsFormStateContext()

  return (
    <StyledReportStatusComponent
      feFlexDirection="column"
      isValid={errorData?.isValid}
      state={state}
      data-testid={dataTestId.formSelect}
    >
      <StyledComponentLabel
        isRequired={isRequired && isInEditMode}
        isValid={errorData?.isValid}
        state={state}
        data-testid={dataTestId.formSelectLabel}
      >
        {label}
      </StyledComponentLabel>
      {!isInEditMode && <TextEllipsis type={TextEllipsisType.MultiLine} rowsUntilEllipsis={3} value={text} />}
      {isInEditMode && (
        <>
          <Select<string>
            feSize="sm"
            feItems={options}
            feLabel=""
            multiple={false}
            value={value}
            feRows={6}
            onChange={(selectedValue) =>
              onComponentChange<string>(selectedValue, faultsFormDispatch, fieldId, fieldName, parentFieldId)
            }
          />
          <StyledComponentErrorText data-testid={dataTestId.formSelectErrorArea}>
            {state !== FaultState.NEW.toString() && errorData?.error}
          </StyledComponentErrorText>
        </>
      )}
    </StyledReportStatusComponent>
  )
}

export default FormSelect
