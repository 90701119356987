import { uploadFaultImages } from '@/api/paths/documents/files'
import { PostUploadFaultImagesRequest } from '@/api/paths/documents/files/types'
import { useFaultsFormStateContext } from '@/contexts/moduleContexts/faultsForm'
import { EvidenceImageData } from '@/models/reportStatus/evidenceTypes'
import { UPDATE_EVIDENCE_IMAGE_DATA } from '@/modules/report-status/reducer/actions.types'
import { updateEntity } from '@/modules/report-status/utils/formUtils'
import { useApi } from '@/shared/hooks/useApi'
import { AxiosError } from 'axios'

const useUploadFaultEvidenceImages = (imageRequestData: EvidenceImageData) => {
  const { faultsFormDispatch } = useFaultsFormStateContext()

  return useApi(() => {
    const uploadRequest = {
      category: imageRequestData.bucketInfo.category,
      content: imageRequestData.imageInfo.content,
      contentType: imageRequestData.imageInfo.contentType,
      fileName: imageRequestData.imageInfo.fileName,
      nodeID: imageRequestData.appInfo.nodeID,
      siteID: imageRequestData.appInfo.siteID,
      tags: imageRequestData.bucketInfo.tags,
    } as PostUploadFaultImagesRequest

    return uploadFaultImages(uploadRequest, {
      onSuccess: async (result) => {
        faultsFormDispatch({
          type: UPDATE_EVIDENCE_IMAGE_DATA,
          payload: updateEntity(imageRequestData, {
            imageInfo: result,
            appInfo: { isUploaded: true },
          } as unknown as Partial<EvidenceImageData>).updated,
        })
      },
      onError: async (error: AxiosError) => {
        imageRequestData.appInfo.error = error.message
        faultsFormDispatch({
          type: UPDATE_EVIDENCE_IMAGE_DATA,
          payload: imageRequestData,
        })
      },
    })
  })
}

export default useUploadFaultEvidenceImages
