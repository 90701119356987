export enum StatusCode {
  Ok = 200,
  Created = 201,
  NoContent = 204,
  Found = 302,
  BadRequest = 400,
  Unauthorized = 401,
  Forbidden = 403,
  NotFound = 404,
  Conflict = 409,
  Timeout = 408,
  PreconditionRequired = 428,
  BadToken = 499,
  InternalServerError = 500,
  BadGateway = 502,
  TimeoutGateway = 504,
}
