import { StatusCode } from '@/api/constants'
import {
  DarwinDetachedModuleError,
  DarwinError,
  DarwinErrorDialog,
  DarwinNotFoundError,
} from '@/shared/components/ErrorBoundary/DarwinError'
import { ROUTES } from '@/shared/constants'
import useDeepCompareEffect from '@/shared/hooks/useDeepCompareEffect'
import { useEffectOnce } from '@/shared/hooks/useEffectOnce'
import { STORAGE_DARWIN_ERROR, STORAGE_ERROR_SID } from '@/shared/localStorageUtils'
import { getSidFromPathname } from '@/shared/utils'
import { openToast } from '@/store/genericToast/actions'
import { stopLoading } from '@/store/loading/action'
import { useAppDispatch, useTypedSelector } from '@/store/store'
import { useIsAuthenticated } from '@azure/msal-react'
import { PropsWithChildren, useRef } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

/**
 * ErrorConsumer component handles the error state in the application,
 * navigating to error pages based on the error type and user authentication status.
 *
 * @param {PropsWithChildren} props - The props for the ErrorConsumer component.
 * @param {React.ReactNode} props.children - The child elements to be rendered within the component.
 *
 * @returns {JSX.Element} The rendered ErrorConsumer component with its children.
 */

const ErrorConsumer = ({ children }: PropsWithChildren) => {
  const { errorData } = useTypedSelector((state) => ({ ...state }))
  const navigate = useNavigate()
  const errorConsumerDispatch = useAppDispatch()
  const { pathname } = useLocation()
  const isAuthenticated = useIsAuthenticated()
  const storedError = useRef<DarwinError>(JSON.parse(localStorage.getItem(STORAGE_DARWIN_ERROR) || '{}') as DarwinError)

  useEffectOnce(() => {
    if (errorData && errorData.error) {
      sessionStorage.setItem(STORAGE_ERROR_SID, getSidFromPathname(pathname))
    }
  })
  useDeepCompareEffect(() => {
    if (errorData && errorData.error) {
      const darwinError = errorData.error
      errorConsumerDispatch(stopLoading())
      if (
        (darwinError as DarwinDetachedModuleError).isInDetachedMode ||
        !(darwinError as DarwinNotFoundError).isInTabMode ||
        darwinError.status === StatusCode.Unauthorized
      ) {
        navigate(ROUTES.ERROR)
      } else if (
        (darwinError as DarwinError).isInTabMode &&
        isAuthenticated &&
        !(darwinError as DarwinErrorDialog).isDialogError
      ) {
        navigate(ROUTES.TAB_ERROR)
      } else if ((darwinError as DarwinErrorDialog).isDialogError && isAuthenticated) {
        errorConsumerDispatch(
          openToast({
            children: errorData.error.message,
            feSeverity: 'error',
          })
        )
        navigate(ROUTES.TAB_ERROR)
      }
    } else if (
      Object.keys(storedError.current).length > 0 &&
      ![ROUTES.ERROR, ROUTES.TAB_ERROR].includes(pathname as ROUTES)
    ) {
      localStorage.removeItem(STORAGE_DARWIN_ERROR)
      localStorage.removeItem(STORAGE_ERROR_SID)
    }
  }, [errorData, pathname])

  return <>{children}</>
}

export default ErrorConsumer
