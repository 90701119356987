import { FC } from 'react'
import { ColGroup } from '../col-group'
import { DevicesSensorsTableSensorRow } from '../sensor-row'
import { DevicesSensorsTableChannelRowData, DevicesSensorsTableDeviceRowData } from '@/models/devicesAndSensors/types'
import { RowDivider, Table, TableData, TableRow } from '@/modules/devices-sensors/components/styled'
import { useDevicesAndSensorsContext } from '@/contexts/devicesAndSensors'

// =================================================================================================
// UTILS
// =================================================================================================

const sortChannelNumber = (a: DevicesSensorsTableChannelRowData, b: DevicesSensorsTableChannelRowData) =>
  a.number - b.number

// =================================================================================================
// SUB-COMPONENT
// =================================================================================================

export const ChannelsPane: FC<{
  device: DevicesSensorsTableDeviceRowData
  channels: DevicesSensorsTableChannelRowData[]
  isDeviceSelected: boolean
}> = ({ device, channels, isDeviceSelected }) => {
  const { devicesAndSensorsSelectedItemsData, setDevicesAndSensorsSelectedItemsData } = useDevicesAndSensorsContext()

  const { channel: selectedChannel, channelIndex: selectedChannelIndex } = devicesAndSensorsSelectedItemsData

  const analogSensors = channels.filter((v) => v.type === 'analog')
  const digitalSensors = channels.filter((v) => v.type === 'digital')
  const invalidSensors = channels.filter((v) => v.type === 'invalid')

  const handleSensorRowClick = (channel: DevicesSensorsTableChannelRowData, channelIndex: number) =>
    setDevicesAndSensorsSelectedItemsData({ ...devicesAndSensorsSelectedItemsData, device, channel, channelIndex })

  let rowIndex: number = 0
  return (
    channels.length > 0 && (
      <TableRow>
        <TableData colSpan={6}>
          <Table>
            <ColGroup />
            <tbody>
              {analogSensors.sort(sortChannelNumber).map((s, i) => {
                const odd = !!(rowIndex % 2)

                rowIndex++
                return (
                  <DevicesSensorsTableSensorRow
                    key={i}
                    {...s}
                    odd={odd}
                    onSensorRowClick={() => handleSensorRowClick(s, i)}
                    selected={isDeviceSelected && i === selectedChannelIndex && s.type === selectedChannel?.type}
                  />
                )
              })}

              {digitalSensors.sort(sortChannelNumber).length > 0 && (
                <>
                  <RowDivider />
                  {digitalSensors.map((s, i) => {
                    const odd = !!(rowIndex % 2)

                    rowIndex++
                    return (
                      <DevicesSensorsTableSensorRow
                        key={i}
                        {...s}
                        odd={odd}
                        onSensorRowClick={() => handleSensorRowClick(s, i)}
                        selected={isDeviceSelected && i === selectedChannelIndex && s.type === selectedChannel?.type}
                      />
                    )
                  })}
                </>
              )}

              {invalidSensors.sort(sortChannelNumber).length > 0 && (
                <>
                  <RowDivider />
                  {invalidSensors.map((s, i) => {
                    const odd = !!(rowIndex % 2)

                    rowIndex++
                    return (
                      <DevicesSensorsTableSensorRow
                        key={i}
                        {...s}
                        odd={odd}
                        onSensorRowClick={() => handleSensorRowClick(s, i)}
                        selected={isDeviceSelected && i === selectedChannelIndex && s.type === selectedChannel?.type}
                      />
                    )
                  })}
                </>
              )}
            </tbody>
          </Table>
        </TableData>
      </TableRow>
    )
  )
}
