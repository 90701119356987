import { START_ASYNC_POLLER, STOP_ASYNC_POLLER } from '@/store/asyncPoller/actions.types'
import { AsyncPollerState } from '@/store/asyncPoller/types'

export const startAsyncPoller = (payload: Partial<AsyncPollerState>) => ({
  type: START_ASYNC_POLLER,
  payload,
})

export const stopAsyncPoller = () => ({
  type: STOP_ASYNC_POLLER,
})
