// =================================================================================================
// IMPORTS
// =================================================================================================

import TextEllipsis, { TextEllipsisType } from '@/shared/components/TextEllipsis'
import { Flexbox } from '@skf-internal/ui-components-react'
import { FC, ReactNode } from 'react'
import { TableCell } from './styled'

// =================================================================================================
// INTERFACE
// =================================================================================================

interface ColumnCellProps<T> {
  value: T
  columnWidth: number
  centerValue: boolean
  characterLength: number
}

// =================================================================================================
// COMPONENTS
// =================================================================================================

export function ColumnCell<T extends string>({ value, columnWidth, centerValue, characterLength }: ColumnCellProps<T>) {
  return (
    <TableCell columnWidth={columnWidth}>
      <Flexbox feJustifyContent={centerValue ? 'center' : 'start'}>
        <TextEllipsis type={TextEllipsisType.SingleLine} characterLength={characterLength} value={value} />
      </Flexbox>
    </TableCell>
  )
}

export const ComponentColumnCell: FC<{ children: ReactNode; columnWidth: number; centerValue: boolean }> = ({
  children,
  columnWidth,
  centerValue,
}) => {
  return (
    <TableCell columnWidth={columnWidth}>
      <Flexbox feJustifyContent={centerValue ? 'center' : 'start'}>{children}</Flexbox>
    </TableCell>
  )
}
