import { useFetchDataContext } from '@/contexts/common/fetchDataContext'
import { useOrphanNotesContext } from '@/contexts/orphanNotes'
import { OrphanNoteRow } from '@/models/orphanNotes/types'
import { calculateOrphanNotesSubheaderInfoPanelData } from '@/modules/orphan-notes/utils'
import ModuleHeader from '@/shared/components/ModuleHeader'
import { HeaderInfoPanelIconData } from '@/shared/components/ModuleHeader/infoPanel/HeaderInfoPanelIcon'
import useDeepCompareEffect from '@/shared/hooks/useDeepCompareEffect'
import useDeepCompareMemo from '@/shared/hooks/useDeepCompareMemo'
import { setModuleHeaderActionPanelData, setModuleHeaderConfigData } from '@/store/moduleHeader/actions'
import { useAppDispatch } from '@/store/store'
import { Table } from '@tanstack/react-table'
import { FC } from 'react'

interface OrphanNotesProps {
  table: Table<OrphanNoteRow>
}
export const OrphanNotesHeader: FC<OrphanNotesProps> = ({ table }) => {
  const { showAcknowledged, setShowAcknowledged, orphanNotes } = useOrphanNotesContext()
  const { fetchDataStatus } = useFetchDataContext()

  const orphanNotesHeaderDispatch = useAppDispatch()

  const infoPanelData = useDeepCompareMemo<HeaderInfoPanelIconData[]>(() => {
    return calculateOrphanNotesSubheaderInfoPanelData(orphanNotes)
  }, [table.getFilteredRowModel().flatRows])

  useDeepCompareEffect(() => {
    if (infoPanelData.length > 0) {
      orphanNotesHeaderDispatch(
        setModuleHeaderConfigData({
          infoPanelElement: undefined,
          infoPanelData,
        })
      )
    }
  }, [infoPanelData])

  useDeepCompareEffect(() => {
    orphanNotesHeaderDispatch(
      setModuleHeaderConfigData({
        showHeader: true,
        title: 'Orphan Notes',
        showInfoPanel: true,
        showActionPanel: true,
      })
    )
    orphanNotesHeaderDispatch(
      setModuleHeaderActionPanelData({
        withLoader: true,
        withTableStateChangeSwitch: true,
        tableStateChangeSwitchCurrentState: showAcknowledged,
        tableStateChangeSwitchFn: setShowAcknowledged,
        tableStateChangeSwitchLabel: 'Show acknowledged',
        withRefreshButton: true,
      })
    )
  }, [table, fetchDataStatus, showAcknowledged])

  return <ModuleHeader />
}
