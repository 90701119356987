import { StyledTableContainer } from '@/shared/components/TableComponents/styled'
import { forwardRef, PropsWithChildren } from 'react'

interface ScrollableInsetProps extends PropsWithChildren {
  bottomMargin?: string
}

/**
 * A scrollable inset component, providing a scrollable container with a customizable bottom margin.
 * @param {string} bottomMargin - The bottom margin of the container, defaulting to '16rem'.
 * @returns {JSX.Element} The rendered ScrollableInset component.
 */
const ScrollableInset = forwardRef<HTMLDivElement, ScrollableInsetProps>(
  ({ children, bottomMargin = '16rem' }, ref) => (
    <StyledTableContainer bottomMargin={bottomMargin} ref={ref}>
      {children}
    </StyledTableContainer>
  )
)

export default ScrollableInset
