import { useFetchDataContext } from '@/contexts/common/fetchDataContext'
import { useDevicesAndSensorsContext } from '@/contexts/devicesAndSensors'
import { TableCard } from '@/modules/devices-sensors/components/styled'
import { DevicesSensorsTable } from '@/modules/devices-sensors/components/table/table'
import useGetDevicesAndSensorsData from '@/modules/devices-sensors/hooks/useGetDevicesAndSensorsData'
import { LoadingType } from '@/shared/hooks/useApi'
import useCleanupStorage from '@/shared/hooks/useCleanupStorage'
import useDeepCompareEffect from '@/shared/hooks/useDeepCompareEffect'
import { useEffectOnce } from '@/shared/hooks/useEffectOnce'
import { dataTestId } from '@/tests/testid'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

const DeviceSensorsTableCard = () => {
  const { sid } = useParams()

  const { execute, status, reset } = useGetDevicesAndSensorsData()

  const { setFetchDataFn, setFetchDataStatus, fetchDataStatus } = useFetchDataContext()

  const { setDevicesAndSensorsTableData, setDevicesAndSensorsSelectedItemsData } = useDevicesAndSensorsContext()

  useEffectOnce(() => {
    execute({ loaderType: LoadingType.GLOBAL })
  })

  useDeepCompareEffect(() => {
    if (fetchDataStatus === 'success' && sid) {
      reset()
      execute({ loaderType: LoadingType.GLOBAL })
    }
  }, [sid])

  useEffect(() => {
    setFetchDataFn(() => execute)
  }, [execute, setFetchDataFn])

  useEffect(() => {
    setFetchDataStatus(status)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status])

  useCleanupStorage()

  useDeepCompareEffect(() => {
    setDevicesAndSensorsTableData({ sorting: [], expanded: {}, columnFilters: [] })
    setDevicesAndSensorsSelectedItemsData({ device: undefined, channel: undefined })
  }, [sid])

  return (
    <TableCard data-testid={dataTestId.deviceSensorsTableCard}>
      <DevicesSensorsTable />
    </TableCard>
  )
}

export default DeviceSensorsTableCard
