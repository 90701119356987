import { Card, COLORS, Popup, SPACINGS, styled } from '@skf-internal/ui-components-react'

export const StyledReasonIndicator = styled(Card)<{ withRedirect: boolean }>`
  padding: ${SPACINGS.xs} ${SPACINGS.sm};
  gap: ${SPACINGS.xs};
  align-items: center;
  height: 2.375rem;
  font-size: 1rem;
  display: flex;
  white-space: nowrap;
  cursor: ${({ withRedirect }) => (withRedirect ? 'pointer' : 'auto')};

  & > [data-comp='divider'] {
    margin-inline: -${SPACINGS.xxs};
  }

  & > .container {
    align-self: baseline;
  }
`

export const StyledFaultedAlarmsPopup = styled(Popup)`
  background-color: ${COLORS.gray050};
  font-size: 1rem;
  width: fit-content;
`
