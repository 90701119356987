import { useDashboardContext } from '@/contexts/dashboard'
import { Slice, YearMonth } from '@/models/widgets/asset-data-collection/types'
import { RAstatusOptionalProps } from '@/models/widgets/recommended-actions/types'
import RecommendedActionsPiChart from '@/modules/dashboard/components/widgets/recommended-actions/ra-current-month-status/RecommendedActionsPiChart'
import { workOrderStatuses } from '@/modules/dashboard/utils/constants'
import { dataTestId } from '@/tests/testid'
import { FC, useMemo } from 'react'
import { StyledRACurrentMonthStatusContainer, StyledRACurrentMonthStatusContent } from '../styled'
import RAContentRow from './currentmoth-content-row/RAContentRow'

interface RACurrentMonthStatusProps {
  slice: Slice<RAstatusOptionalProps, YearMonth>
  showPercentages: boolean
}

const RecommendedActionsCurrentMonthStatus: FC<RACurrentMonthStatusProps> = ({ slice, showPercentages }) => {
  const { dashboardFilters } = useDashboardContext()

  const data = useMemo(() => {
    const dataMap = new Map(Object.entries(slice.data))
    return workOrderStatuses
      .filter((status) => dataMap.has(status))
      .map((status) => ({
        name: status,
        value: Number(dataMap.get(status)),
      }))
  }, [slice.data])

  return (
    <StyledRACurrentMonthStatusContainer data-testid={dataTestId.recommendedActionsCurrentMonthStatus}>
      <h4>Current Month Status</h4>

      <StyledRACurrentMonthStatusContent>
        <div>
          {data.map(
            (item) =>
              dashboardFilters.raStatus.includes(item.name) && (
                <RAContentRow key={item.name} name={item.name} value={item.value} showPercentages={showPercentages} />
              )
          )}
        </div>
        <RecommendedActionsPiChart
          data={data}
          specialLabelIndex={1}
          specialLabel="Special Category"
          showPercentages={showPercentages}
        />
      </StyledRACurrentMonthStatusContent>
    </StyledRACurrentMonthStatusContainer>
  )
}

export default RecommendedActionsCurrentMonthStatus
