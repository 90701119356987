import {
  Button,
  Flexbox,
  Heading,
  Icon,
  Image,
  Loader,
  Search,
  styled,
  Tabs,
  Text,
  TextField,
} from '@skf-internal/ui-components-react'

export const StyledSiteDetailsParentContainer = styled(Flexbox)`
  width: 100%;
  height: 100%;
`
export const StyledSiteDetailsContainer = styled(Flexbox)`
  gap: 2rem;
  width: 100%;
  overflow: scroll;
  height: 100vh;
  margin-top: -2rem;
  padding-bottom: 2rem;
`
export const StyledBackButton = styled(Button)<{ backgroundColor: string }>`
  width: 9rem;
  font-size: 0.65rem;
  height: 2rem;
  background-color: ${(props) => props.backgroundColor};

  span {
    margin-inline: 0;

    svg {
      width: 1rem;
    }
  }
`

export const StyledTechInfoGrid = styled(Flexbox)`
  width: 20%;
  min-width: 26.25rem;
  height: 53.3rem;
  padding: 0 2rem 2rem;
  background: #f6f7f8;
  box-shadow: 0 0.0625rem 0.1875rem 0 rgb(0 0 0 / 25%);
  align-items: center;
  flex-direction: column;
`

export const StyledTechInfoImageContainer = styled(Flexbox)`
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 0.4rem 0 0;
  flex-direction: column;
`

export const StyledTechInfoImage = styled.div`
  width: 17.0625rem;
  height: 10.625rem;
`
export const StyledCustomerLogo = styled(Image)`
  width: 100%;
  height: 100%;
`
export const StyledCustomerLogoComponentContainer = styled(Flexbox)<{ backgroundColor: string }>`
  width: 16rem;
  height: 8.625rem;
  background: ${(props) => props.backgroundColor};
  justify-content: center;
  align-items: center;
`
export const StyledCustomerLogoComponent = styled(StyledCustomerLogo)`
  width: 10.625rem;
  height: 6.25rem;
`
export const StyledTechInfoContainer = styled(Flexbox)`
  width: 25rem;
  height: 41.95rem;
  flex-shrink: 0;
  border-radius: 0.125rem;
  background: #fff;
  box-shadow: 0 0.0625rem 0.1875rem 0 rgb(0 0 0 / 25%);
  padding: 1.5rem;
  flex-direction: column;
`

export const StyledTechInfoTitleContainer = styled.div`
  height: 6%;

  p {
    color: rgb(39 51 66);
    font-size: 1rem;
    font-weight: 700;
  }
`

export const StyledTechInfoSectionContainer = styled.div`
  margin: 1rem 0;

  p {
    font-size: 1rem;
  }
`

export const StyledTechInfoSectionHeading = styled(Text)`
  color: #808895;
  font-weight: 400;
  padding-bottom: 0.5rem;
  font-size: 0.875rem;
`
export const StyledContractDetailsTabs = styled(Tabs)`
  border: none;
  box-shadow: none;
  height: 100%;
  width: calc(100% - 0.4rem);

  & > [tabindex] {
    padding: 0;
  }

  & [role='tablist'] {
    height: fit-content;
    background-color: #d5d8dc;

    & [role='tab'] {
      padding: 1rem 2rem;
      max-width: fit-content;
      border: none;
      font-weight: bold;

      span {
        overflow-x: unset;
        word-wrap: break-word;
        white-space: unset;
        text-align: center;
      }
    }
  }
`

export const StyledContractDetailsContainer = styled(Flexbox)`
  width: 100%;
  height: 54.625rem;
  justify-content: space-between;
  padding: 1rem;
`

export const StyledContractDetailsContent = styled(Flexbox)`
  width: 100%;
  padding-top: 1.85rem;
`

export const StyledSiteDetailsHeading = styled(Heading)`
  font-weight: 500;
  font-size: 1.7rem !important;
`

export const StyledSiteDetailsActionButtonContainer = styled(Flexbox)`
  position: relative;
  bottom: 0.4rem;
  width: calc(100% + 0.5rem);
  height: 6rem;
  padding: 0 3.375rem;
  align-items: center;
  gap: 1rem;
  background: #fff;
  margin-left: -0.9rem;
  box-shadow: 0 -0.125rem 0.1875rem 0 rgb(0 0 0 / 25%);
`
export const StyledEditDetailsContainer = styled(Flexbox)`
  width: 100%;
  min-height: 31.25rem;
  border-radius: 0 0 0.125rem 0.125rem;
  background: #fff;
  box-shadow: 0 0.0625rem 0.1875rem 0 rgb(0 0 0 / 25%);
  padding: 4rem 4rem 2rem;
  gap: 4rem;
  flex-direction: column;
`

export const StyledEditDetailsFlexboxWrapper = styled(Flexbox)`
  width: 100%;
  max-width: 43.75rem;
  gap: 2rem;
  flex-wrap: wrap;
  justify-content: space-between;
`

export const StyledEditDetailsTextField = styled(TextField)`
  width: calc(50% - 1rem);
  min-width: 17.5rem;
  flex-grow: 1;
`
export const StyledEditDetailsText = styled(Flexbox)`
  width: calc(50% - 1rem);
  min-width: 17.5rem;
  flex-grow: 1;
  flex-direction: column;
  gap: 0.5rem;

  cursor: pointer;

  label {
    font-weight: 700;
    font-size: 1rem !important;
  }
`
export const StyledEditDetailsReadOnlyText = styled(Flexbox)`
  width: calc(50% - 1rem);
  min-width: 17.5rem;
  flex-grow: 1;
  flex-direction: column;
  gap: 0.5rem;

  label {
    font-weight: 700;
    font-size: 1rem !important;
  }
`

export const StyledEditDetailsLogoCardContainer = styled.div`
  max-width: calc(50% - 1rem);
  min-width: 17.5rem;
  height: fit-content;
  flex-grow: 1;
`

export const StyledEditDetailsSearch = styled(Search)`
  width: 18.75rem;
  max-width: 100%;
  flex-grow: 1;
  font-weight: 500;

  label {
    font-weight: 700;
    font-size: 1rem !important;
  }
`
export const StyledEditDetailsSearchContainer = styled(Flexbox)`
  width: calc(50% - 1rem);
  position: relative;
`
export const StyledSiteAddressLoader = styled(Loader)`
  position: absolute;
  left: 6.5rem;
`
export const StyledCustomerLogoCardContainer = styled(Flexbox)`
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: start;
  flex-direction: column;

  label {
    font-size: 1rem !important;
    font-weight: 700;
  }
`

export const StyledLabel = styled.label`
  text-align: left;
  width: 100%;
  font-size: 1rem !important;
  font-weight: 700;
`

export const StyledCustomerLogoCardFooter = styled(Flexbox)`
  width: 16.4rem;
  justify-content: space-between;
`

export const StyledIsRequired = styled.span`
  color: red;
`
export const SuggestionsList = styled.ul`
  width: 18.75rem;
  max-height: 12.5rem;
  overflow-y: auto;
  background-color: white;
  border: 0.0625rem solid #ccc;
  border-top: none;
  list-style: none;
  margin: 0;
  padding: 0;
  z-index: 1000;
`

export const SuggestionItem = styled.li`
  padding: 0.625rem;
  cursor: pointer;

  &:hover {
    background-color: #f0f0f0;
  }
`
export const StyledDashedBorder = styled.div`
  height: 8.625rem;
  width: 16rem;
  border: 0.125rem dashed #c7cad0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 0.55rem 0;
`

export const StyledErrorParagraph = styled.p`
  color: red;
  font-size: 0.75rem;
  font-weight: 500;
`
export const StyledIcon = styled(Icon)`
  height: 3.125rem;
  width: 3.125rem;
`

export const StyledInput = styled.input`
  display: none;
`

export const StyledParagraph = styled.p`
  font-size: 0.75rem;
`

export const StyledUploadIconContainer = styled.div`
  height: 3.4375rem;
  width: 3.4375rem;
  margin-top: 1.25rem;
  border: 0.1875rem solid #0f58d6;
  border-radius: 0.25rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`
