import { useState, useEffect, useCallback } from 'react'
import { LocationClient, SearchPlaceIndexForTextCommand } from '@aws-sdk/client-location'
import { SDKAuthHelper, withAPIKey } from '@aws/amazon-location-utilities-auth-helper'
import useDeepCompareMemo from '@/shared/hooks/useDeepCompareMemo'
import useDeepCompareCallback from '@/shared/hooks/useDeepCompareCallback'

/**
 * Properties for the useAWSLocationService hook.
 *
 * @typedef {Object} useAWSLocationServiceProps
 * @property {number} [maxResults=5] - The maximum number of results to return from the AWS Location Service.
 * @property {string} [language='en-us'] - The language code in which to return results.
 * @property {number} [biasLatitude] - The latitude to bias search results towards.
 * @property {number} [biasLongitude] - The longitude to bias search results towards.
 */

/**
 * Custom hook to interact with AWS Location Service and retrieve place suggestions based on user input.
 *
 * This hook initializes the AWS Location Client using an API key, obtains the user's geolocation if available,
 * and provides a function to search the AWS Location Service's Place Index for place suggestions. The hook also
 * tracks loading, suggestions, and error states.
 *
 * @param {useAWSLocationServiceProps} props - Configuration properties for the AWS Location Service request.
 *
 * @returns {Object} - The hook's return values.
 * @returns {Function} searchPlaceIndex - Function to search for places in the AWS Place Index using a query string.
 * @returns {string[]} suggestions - Array of place suggestions returned from the AWS Location Service.
 * @returns {boolean} isLoading - Indicates whether the place search is currently loading.
 * @returns {string|null} error - Error message if an error occurs during the search, otherwise null.
 * @returns {Function} resetSuggestions - Function to reset the suggestions, error, and loading state.
 */

interface useAWSLocationServiceProps {
  maxResults?: number
  language?: string
  biasLatitude?: number
  biasLongitude?: number
}

const useAWSLocationService = ({
  maxResults = 5,
  language = 'en-us',
  biasLatitude,
  biasLongitude,
}: useAWSLocationServiceProps) => {
  const [suggestions, setSuggestions] = useState<string[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [authHelper, setAuthHelper] = useState<SDKAuthHelper | null>(null)
  const [userLocation, setUserLocation] = useState<[number, number] | null>(null)

  useEffect(() => {
    const initializeAuthHelper = async () => {
      const helper = await withAPIKey(import.meta.env.VITE_AWS_LOCATIONS_SERVICE_API_KEY)
      setAuthHelper(helper)
    }
    initializeAuthHelper()

    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setUserLocation([position.coords.longitude, position.coords.latitude])
        },
        (error) => {
          console.error('Error getting user location:', error.message)
        }
      )
    } else {
      console.warn('Geolocation is not supported by this browser.')
    }
  }, [])

  const locationClient = useDeepCompareMemo(() => {
    if (!authHelper) return null

    const client = new LocationClient({
      region: import.meta.env.VITE_AWS_REGION,
      ...authHelper.getLocationClientConfig(),
    })

    return client
  }, [authHelper])

  const searchPlaceIndex: (input: string) => void = useDeepCompareCallback(
    async (input: string) => {
      if (!locationClient) return
      if (input.length <= 2) {
        setSuggestions([])
        return
      }

      setIsLoading(true)
      setError(null)

      try {
        const biasPosition = biasLatitude && biasLongitude ? [biasLongitude, biasLatitude] : userLocation || undefined

        const command = new SearchPlaceIndexForTextCommand({
          IndexName: import.meta.env.VITE_AWS_LOCATIONS_SERVICE_PLACE_INDEX_NAME,
          Text: input,
          MaxResults: maxResults,
          Language: language,
          BiasPosition: biasPosition,
        })

        const response = await locationClient.send(command)

        if (response.Results && response.Results.length > 0) {
          const places = response.Results.map((result) => result.Place?.Label || '')
          setSuggestions(places)
        } else {
          setSuggestions([])
        }
      } catch (err) {
        setError('Failed to fetch suggestions')
        setSuggestions([])
      } finally {
        setIsLoading(false)
      }
    },
    [locationClient, maxResults, language, biasLatitude, biasLongitude, userLocation]
  )

  /**
   * Resets the suggestions, error, and loading state.
   * Use this function to clear the current state of the hook.
   */
  const resetSuggestions = useCallback(() => {
    setSuggestions([])
    setError(null)
    setIsLoading(false)
  }, [])

  return { searchPlaceIndex, suggestions, isLoading, error, resetSuggestions }
}

export default useAWSLocationService
