import { useAnalysisBacklogContext } from '@/contexts/analysisBacklog'
import { useFetchDataContext } from '@/contexts/common/fetchDataContext'
import { AssetRow } from '@/models/analysisBacklog/types'
import EmptyAssetTableState from '@/modules/analysis-backlog/EmptyAssetTableState'
import { StyledAssetsTable, StyledAssetsTableRow } from '@/modules/analysis-backlog/styled'
import ScrollableInset from '@/shared/components/TableComponents/ScrollableInset'
import TablePadding from '@/shared/components/TableComponents/TablePadding'
import TableHeaderColumn from '@/shared/components/TableComponents/TableHeaderColumn'
import { useDebounce } from '@/shared/hooks/useDebounce'
import useDeepCompareEffect from '@/shared/hooks/useDeepCompareEffect'
import useElementInDOM from '@/shared/hooks/useElementInDOM'
import useTableKeyboardNavigation from '@/shared/hooks/useTableKeyboardNavigation'
import { stopLoading } from '@/store/loading/action'
import { useAppDispatch, useTypedSelector } from '@/store/store'
import { dataTestId } from '@/tests/testid'
import { css } from '@emotion/react'
import { Table, flexRender } from '@tanstack/react-table'
import { useVirtualizer } from '@tanstack/react-virtual'
import { FC, Fragment, useEffect, useRef, useState } from 'react'

interface AssetTableProps {
  tableScrollOffset: number | undefined
  table: Table<AssetRow>
}

export const AssetTable: FC<AssetTableProps> = ({ table, tableScrollOffset }) => {
  return <InnerAssetTable tableScrollOffset={tableScrollOffset} table={table} />
}

interface InnerAssetTable {
  table: Table<AssetRow>
  tableScrollOffset: number | undefined
}

const InnerAssetTable: FC<InnerAssetTable> = ({ table, tableScrollOffset }) => {
  const tableRows = table.getRowModel().rows
  const tableColumns = table.getAllColumns()
  const tableHeaderGroups = table.getHeaderGroups()
  const tableContainerRef = useRef<HTMLDivElement>(null)

  const [selectedRowIndex, setSelectedRowIndex] = useState<number>(0)

  const { fetchDataStatus } = useFetchDataContext()

  const { getVirtualItems, getTotalSize, measureElement, scrollToIndex, scrollToOffset, scrollOffset } = useVirtualizer(
    {
      getScrollElement: () => tableContainerRef.current,
      count: tableRows.length,
      overscan: 10,
      estimateSize: () => 46,
    }
  )
  const { setTableScrollPosition } = useAnalysisBacklogContext()
  const innerAssetDispatch = useAppDispatch()

  const [isElementInDOM] = useElementInDOM(`[data-testid="${dataTestId.headerInfoPanelIcon}"]`)

  const {
    globalLoading: { isGlobalLoading },
  } = useTypedSelector((state) => ({ ...state }))

  useEffect(() => {
    setTableScrollPosition(scrollOffset)
  }, [scrollOffset, setTableScrollPosition])

  useDebounce(
    () => {
      if (!tableScrollOffset && tableRows.length > 0) {
        scrollToIndex(0)
      }
    },
    [tableRows, tableScrollOffset],
    100
  )

  useEffect(() => {
    if (tableScrollOffset) {
      scrollToOffset(tableScrollOffset, { behavior: 'smooth' })
      setTableScrollPosition(0)
    }
  }, [scrollToOffset, setTableScrollPosition, tableScrollOffset, tableRows])

  useDeepCompareEffect(() => {
    if (
      (tableRows.length > 0 && isElementInDOM && fetchDataStatus === 'success') ||
      (tableRows.length === 0 && fetchDataStatus !== 'loading')
    ) {
      innerAssetDispatch(stopLoading())
    }
  }, [tableRows, fetchDataStatus, isElementInDOM])

  const virtualRows = getVirtualItems()

  const paddingTop = virtualRows.length > 0 ? virtualRows[0].start || 0 : 0
  const paddingBottom = virtualRows.length > 0 ? getTotalSize() - (virtualRows[virtualRows.length - 1].end || 0) : 0

  const { tbodyRef, handleKeyDown } = useTableKeyboardNavigation()

  return (
    !isGlobalLoading && (
      <ScrollableInset ref={tableContainerRef}>
        <StyledAssetsTable aria-label="Asset table">
          <colgroup>
            {tableColumns.map((column) => (
              <col
                key={column.id}
                css={css`
                  width: ${column.columnDef.size === undefined ? 'auto' : `${column.getSize()}px`};
                  min-width: ${column.columnDef.minSize === undefined ? 'unset' : `${column.columnDef.minSize}px`};
                  max-width: ${column.columnDef.maxSize === undefined ? 'unset' : `${column.columnDef.maxSize}px`};
                `}
              ></col>
            ))}
          </colgroup>
          <thead
            css={css`
              z-index: 1000;
            `}
          >
            {tableHeaderGroups.map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <Fragment key={header.id}>
                    <TableHeaderColumn<AssetRow> header={header} />
                  </Fragment>
                ))}
              </tr>
            ))}
          </thead>
          {tableRows.length > 0 && (
            <tbody ref={tbodyRef}>
              <TablePadding height={paddingTop} />
              {getVirtualItems().map((virtualRow) => {
                const row = tableRows[virtualRow.index]
                const odd = !!(virtualRow.index % 2)

                return (
                  <StyledAssetsTableRow
                    isOdd={odd}
                    isSelected={row.index === selectedRowIndex}
                    id={row.id}
                    tabIndex={0}
                    onKeyDown={(e) => {
                      if (['ArrowUp', 'ArrowDown'].includes(e.key)) {
                        if (e.key === 'ArrowUp' && row.index === 0) return
                        if (e.key === 'ArrowDown' && row.index === tableRows.length - 1) return

                        handleKeyDown(e, row)
                        setSelectedRowIndex(row.index + (e.key === 'ArrowUp' ? -1 : 1))
                      }
                    }}
                    onClick={() => {
                      setSelectedRowIndex(row.index)
                    }}
                    key={virtualRow.key}
                    data-index={virtualRow.index}
                    ref={measureElement}
                  >
                    {row.getVisibleCells().map((cell) => {
                      return <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                    })}
                  </StyledAssetsTableRow>
                )
              })}
              <TablePadding height={paddingBottom} />
            </tbody>
          )}
        </StyledAssetsTable>
        <EmptyAssetTableState assetTable={table} />
      </ScrollableInset>
    )
  )
}
