import { DeviceStatus } from '@/models/devicesAndSensors/types'
import { AssetStatusString } from '@/shared/models/types'

const analysisBacklogAssetStatusSortingOrder: AssetStatusString[] = [
  'normal',
  'acceptable',
  'unsatisfactory',
  'unacceptable',
  'severe',
  'never-reported',
] as const

const openRecommendationAssetStatusSortingOrder: AssetStatusString[] = [
  'severe',
  'unacceptable',
  'unsatisfactory',
  'acceptable',
  'normal',
] as const

const devicesAndSensorsStatusSortingOrder: DeviceStatus[] = ['normal', 'notNormal'] as const

export {
  analysisBacklogAssetStatusSortingOrder,
  devicesAndSensorsStatusSortingOrder,
  openRecommendationAssetStatusSortingOrder,
}
