import { EffectCallback, useEffect, useRef } from 'react'

type RemoveReturnType<T extends (...args: unknown[]) => unknown> = (...args: Parameters<T>) => void

/**
 * A custom hook that runs the provided effect only once, similar to `useEffect` with an empty dependency array,
 * but ensures the effect is not re-executed even if there are component re-renders.
 *
 * @param {RemoveReturnType<EffectCallback>} effect - The effect function to run only once.
 */

export const useEffectOnce = (effect: RemoveReturnType<EffectCallback>) => {
  const done = useRef(false)

  return useEffect(() => {
    /* v8 ignore next 3 */
    if (done.current) {
      return
    }

    done.current = true
    return effect()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
