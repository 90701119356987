import { ChannelStatus } from '@/models/devicesAndSensors/types'
import { ChannelStatusIcon, ChannelStatusString } from '@/modules/devices-sensors/utils/constants'
import { Icon, Tooltip, useTooltip } from '@skf-internal/ui-components-react'
import { FC } from 'react'

interface StatusValueTooltipProps {
  value: ChannelStatus
}

const StatusValueTooltip: FC<StatusValueTooltipProps> = ({ value }) => {
  const { icon, color } = ChannelStatusIcon[value]
  const statusString = ChannelStatusString[value]

  const [hoverRef, isHovered] = useTooltip()

  return (
    <>
      <div ref={hoverRef}>
        <Icon feIcon={icon} feColor={color} feSize="xs" />
      </div>

      <Tooltip isHovered={isHovered} triggerElement={hoverRef.current}>
        {statusString}
      </Tooltip>
    </>
  )
}

export default StatusValueTooltip
