import { dataTestId } from '@/tests/testid'
import { Flexbox, Text } from '@skf-internal/ui-components-react'
import { FC } from 'react'

const OrphanNoteData: FC<{ name: string; value?: string }> = ({ name, value }) => {
  return (
    <Flexbox feGap="xs" feFlexDirection="column" data-testId={dataTestId.orphanNoteDataName}>
      <Text feFontWeight="bold" feFontSize="sm">
        {name}
      </Text>
      <Text>{value ?? '-'}</Text>
    </Flexbox>
  )
}

export default OrphanNoteData
