import { IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser'
import { msalConfig } from './msalConfig'

let msalInstance: IPublicClientApplication | null = null

export const initializeMsal = async (): Promise<IPublicClientApplication> => {
  if (!msalInstance) {
    msalInstance = new PublicClientApplication(msalConfig)
    await msalInstance.initialize()
  }
  return msalInstance
}
