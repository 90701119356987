import networkClient from '@/api/NetworkClient'
import {
  GetSiteDetailsRequest,
  GetSiteDetailsResponse,
  GetSiteDetailsResult,
  UpdateSiteDetailsRequest,
  UpdateSiteDetailsResponse,
  UpdateSiteDetailsResult,
} from '@/api/paths/sites/siteDetails/types'
import { PATHS } from '@/api/types'

import { SiteDetailsData } from '@/models/siteDetails/types'
import { AxiosError } from 'axios'

const getSiteDetails = async (
  handlers: GetSiteDetailsResult,
  params: Record<string, string | undefined>
): Promise<SiteDetailsData | void> => {
  try {
    const response = await networkClient.get<GetSiteDetailsRequest, GetSiteDetailsResponse>(PATHS.GET_SITE_DETAILS, {
      params,
    })
    handlers.onSuccess && (await handlers.onSuccess(response.data))
  } catch (error) {
    handlers.onError && (await handlers.onError(error as AxiosError))
  }
}

const updateSiteDetails = async (
  request: UpdateSiteDetailsRequest,
  handlers: UpdateSiteDetailsResult,
  params: Record<string, string | undefined>
): Promise<SiteDetailsData | void> => {
  try {
    const response = await networkClient.patch<UpdateSiteDetailsRequest, UpdateSiteDetailsResponse>(
      PATHS.GET_SITE_DETAILS,
      request,
      {
        params,
      }
    )
    handlers.onSuccess && (await handlers.onSuccess(response.data))
  } catch (error) {
    handlers.onError && (await handlers.onError(error as AxiosError))
  }
}

export { getSiteDetails, updateSiteDetails }
