import { getOrphanNotes } from '@/api/paths/orphanNotes'
import { DarwinDetachedModuleError } from '@/shared/components/ErrorBoundary/DarwinError'
import { useOrphanNotesContext } from '@/contexts/orphanNotes'
import { OrphanNotes } from '@/models/orphanNotes/types'
import { useApi } from '@/shared/hooks/useApi'
import { useAsyncThrow } from '@/shared/hooks/useAsyncThrow'
// import { mapAndFormatOrphanNotes } from '@/modules/orphan-notes/utils'
import { AxiosError } from 'axios'
import { useParams } from 'react-router-dom'

const useGetOrphansNotes = () => {
  // const { selectedCustomer } = useTypedSelector((state) => ({ ...state }))
  const { sid: companyId } = useParams()
  const { setOrphanNotes } = useOrphanNotesContext()

  const throwError = useAsyncThrow()

  return useApi(() => {
    return getOrphanNotes(
      {
        onSuccess: async (response: OrphanNotes) => {
          setOrphanNotes(response)
        },
        onError: async (error: AxiosError) => {
          throwError(new DarwinDetachedModuleError(error))
        },
      },
      {
        companyId,
      }
    )
  })
}

export default useGetOrphansNotes
