import { Select, SelectItem } from '@skf-internal/ui-components-react'
import { FC, useMemo } from 'react'
import { css } from '@emotion/react'
import { RaStatusSelectType, SelectFilterType } from '@/models/dashboard/globalFiltering/types'
import { workOrderStatuses as allRecommendedAction } from '@/modules/dashboard/utils/constants'
import { dataTestId } from '@/tests/testid'
import { statusText } from '@/modules/dashboard/utils/recommended-actions'
interface IRecommendedActionSelect {
  className?: string
  value: RaStatusSelectType[]
  onChange: (value: RaStatusSelectType[]) => void
}
const RecommendedActionSelect: FC<IRecommendedActionSelect> = ({ className, value, onChange }) => {
  const options = useMemo<SelectItem<RaStatusSelectType>[]>(
    () => [
      { label: 'All', value: 'all' as RaStatusSelectType },
      ...allRecommendedAction.map((status) => ({
        label: statusText(status),
        value: status as RaStatusSelectType,
      })),
    ],
    []
  )

  const handleOnChange = (currentValue: SelectFilterType['raStatus']) => {
    if (value.length === allRecommendedAction.length && currentValue.length === allRecommendedAction.length - 1) {
      const unselectedValue = allRecommendedAction.find((val) => !currentValue.includes(val as RaStatusSelectType))
      onChange([unselectedValue] as SelectFilterType['raStatus'])
    } else {
      onChange(currentValue)
    }
  }

  return (
    <Select<RaStatusSelectType>
      data-testid={dataTestId.globalFilteringRecommendedActionSelect}
      css={css`
        * {
          flex-wrap: wrap;
        }

        label {
          font-weight: 700;
        }

        span {
          font-weight: 400;
        }

        width: 205px;
      `}
      feSize="sm"
      className={className}
      value={value}
      onChange={handleOnChange}
      feItems={options}
      feLabel="Recommended action status"
      feMultiSelectionText={allRecommendedAction.length === value.length ? 'All' : `${value.length} Selected`}
      feHideSelectionTags
      multiple
    />
  )
}
export default RecommendedActionSelect
