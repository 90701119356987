/**
 * Triggers the download of a CSV file.
 *
 * This utility function creates a temporary link element, generates a Blob from
 * the provided CSV data, and programmatically clicks the link to initiate the
 * download. The downloaded file will have the specified name or default to 'data.csv'.
 *
 * @param {string} [csvData=''] - The CSV data to be downloaded. If not provided, an empty string will be used.
 * @param {string} [csvName='data.csv'] - The name of the downloaded CSV file. Defaults to 'data.csv' if not specified.
 */

const downloadCSV = (csvData: string = '', csvName: string = 'data.csv') => {
  const link = document.createElement('a')
  link.download = csvName
  link.href = window.URL.createObjectURL(new Blob([csvData], { type: 'text/csv' }))
  link.click()
}

/**
 * Generates a CSV filename based on the provided module name and the current timestamp.
 *
 * The generated filename will include the module name converted to lowercase and spaces replaced
 * with underscores, followed by a timestamp. The filename will end with a '.csv' extension.
 *
 * @param {string} [moduleName=''] - The name of the module used to create the filename. Defaults to an empty string if not provided.
 * @returns {string} - The generated CSV filename.
 */

const getCSVFilename = (moduleName: string = ''): string => {
  const timestamp = new Date().getTime()
  return `${moduleName.toLowerCase().split(' ').join('_')}_${timestamp}.csv`
}

export { downloadCSV, getCSVFilename }
