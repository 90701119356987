import { DarwinWidgetError } from '@/shared/components/ErrorBoundary/DarwinError'
import { useDashboardContext } from '@/contexts/dashboard'
import { generateLast12MonthsData } from '@/modules/dashboard/utils'
import useDeepCompareEffect from '@/shared/hooks/useDeepCompareEffect'
import { FC } from 'react'

interface WidgetErrorHandlerProps {
  widgetErrors: DarwinWidgetError[]
}
const WidgetErrorHandler: FC<WidgetErrorHandlerProps> = ({ widgetErrors }) => {
  const { setWidgetAssetHealthConditionData, setWidgetRecommendedActions } = useDashboardContext()

  useDeepCompareEffect(() => {
    if (widgetErrors.length > 0) {
      widgetErrors.forEach((error) => {
        switch (error.widgetType) {
          case 'asset-health-condition':
            setWidgetAssetHealthConditionData(generateLast12MonthsData())
            break
          case 'recommended-actions':
            setWidgetRecommendedActions(generateLast12MonthsData())
            break
        }
      })
    }
  }, [widgetErrors])

  return <></>
}

export default WidgetErrorHandler
