import { FC } from 'react'
import { HalCustomerNoteData, SkfCustomerNoteData } from '@/models/reportStatus/types'
import { StyledInfoCardContainer } from '@/modules/report-status/styled'
import CustomerInfoCard from '@/modules/report-status/components/customerNote/CustomerInfoCard'
import SkfInfoCard from '@/modules/report-status/components/customerNote/SkfInfoCard'
import { dataTestId } from '@/tests/testid'

const InfoCard: FC<{
  noteInfo: SkfCustomerNoteData | HalCustomerNoteData
}> = ({ noteInfo }) => {
  return (
    <StyledInfoCardContainer>
      {noteInfo.isSkf ? (
        <SkfInfoCard noteData={noteInfo} data-testid={dataTestId.skfInfoCard} />
      ) : (
        <CustomerInfoCard noteData={noteInfo} data-testid={dataTestId.customerInfoCard} />
      )}
    </StyledInfoCardContainer>
  )
}
export default InfoCard
