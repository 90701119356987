import { useNavigate, useParams } from 'react-router-dom'
import { IconButton, Tooltip, useTooltip } from '@skf-internal/ui-components-react'
import { FC } from 'react'
import { ColumnFiltersState, SortingState } from '@tanstack/react-table'
import { ROUTES } from '@/shared/constants'
import { RecommendedAction } from '@/models/unresolvedRecommendations/types'
import { useUnresolvedRecommendationsContext } from '@/contexts/unresolvedRecommendations'
import { dataTestId } from '@/tests/testid'
import { STORAGE_REPORT_STATUS_ORIGIN } from '@/shared/localStorageUtils'

type RedirectButtonProps = {
  value: RecommendedAction['assetID']
  columnFilters: ColumnFiltersState
  sorting: SortingState
  tableState: boolean
}

export const RedirectButton: FC<RedirectButtonProps> = ({ value, columnFilters, sorting, tableState }) => {
  const navigate = useNavigate()
  const { tableScrollPosition } = useUnresolvedRecommendationsContext()
  const { sid } = useParams()

  const [hoverRef, isHovered] = useTooltip()
  const handleClick = (assetID: RecommendedAction['assetID']) => {
    sessionStorage.setItem(STORAGE_REPORT_STATUS_ORIGIN, `${ROUTES.UNRESOLVED_RECOMMENDATION}/${sid}`)
    navigate(`${ROUTES.REPORT_STATUS}/${assetID}`, {
      state: { columnFilters, sorting, tableState, tableScrollPosition },
    })
  }
  return (
    <div onClick={() => handleClick(value)}>
      <Tooltip isHovered={isHovered} triggerElement={hoverRef.current}>
        Report status
      </Tooltip>
      <IconButton
        feIcon="openInNew"
        as="button"
        aria-label="Invalid Input"
        feSize="sm"
        ref={hoverRef}
        data-testid={dataTestId.unresolvedRecommendationsRedirectButton}
      />
    </div>
  )
}
