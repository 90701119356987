import { Heading, Link, styled } from '@skf-internal/ui-components-react'

export const StyledErrorWrapper = styled.div`
  text-align: center;
  height: 100vh;
  padding-top: 10%;
`
export const StyledErrorContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
  margin-top: 100px;
`
export const StyledDescriptionError = styled.p`
  width: 500px;
`
export const StyledTitleError = styled(Heading)`
  width: 500px;
`
export const StyledGoBackButton = styled(Link)`
  border: 1px solid #0f58d6;
  padding: 10px 20px;
  margin-top: 20px;
`
