import { StyledReconfirmFlex, StyledReconfirmUsernameSpan } from '@/modules/report-status/styled'
import { DateFormats, formatDate } from '@/shared/dateUtils'
import { FC } from 'react'

type HistoryRowProps = {
  timestamp: string
  username: string
}
const HistoryRow: FC<HistoryRowProps> = ({ timestamp, username }) => {
  return (
    <StyledReconfirmFlex feGap="sm">
      <span>{formatDate(timestamp, DateFormats.AmericanAMPMFormat)}</span>
      <StyledReconfirmUsernameSpan>{username}</StyledReconfirmUsernameSpan>
    </StyledReconfirmFlex>
  )
}

export default HistoryRow
