import { CLOSE_TOAST, OPEN_TOAST } from '@/store/genericToast/actions.types'
import { GenericToastAction, GenericToastState } from '@/store/genericToast/types'

export const initialState: GenericToastState = {
  feSeverity: 'info',
  children: '',
  isOpen: false,
}

type GenericToastDataReducer = (state: GenericToastState | undefined, action: GenericToastAction) => GenericToastState

const openGenericToastReducer: GenericToastDataReducer = (state = initialState, action) => {
  if (action.type === OPEN_TOAST) {
    return action.payload
  }
  if (action.type === CLOSE_TOAST) {
    return initialState
  }

  return state
}

export default openGenericToastReducer
