import { SiteDetailsData } from '@/models/siteDetails/types'
import {
  StyledTechInfoContainer,
  StyledTechInfoImageContainer,
  StyledTechInfoSectionContainer,
  StyledTechInfoSectionHeading,
  StyledTechInfoGrid,
  StyledTechInfoImage,
  StyledTechInfoTitleContainer,
} from '@/modules/site-details/styled'
import { dataTestId } from '@/tests/testid'
import { Text } from '@skf-internal/ui-components-react'
import React, { FC } from 'react'
import CustomerLogoCard from '@/modules/site-details/components/contract-details/customer-logo-card'
import { useSiteDetailsContext } from '@/contexts/siteDetails'

const TechnicalInformation: FC = () => {
  const { siteDetailsData } = useSiteDetailsContext()
  const { databases } = siteDetailsData as SiteDetailsData

  return (
    <StyledTechInfoGrid data-testid={dataTestId.siteDetailsTechnicalInfo}>
      <StyledTechInfoImageContainer>
        <StyledTechInfoImage>
          <CustomerLogoCard />
        </StyledTechInfoImage>
      </StyledTechInfoImageContainer>

      <StyledTechInfoContainer>
        <StyledTechInfoTitleContainer>
          <Text>Technical Information</Text>
        </StyledTechInfoTitleContainer>

        {(databases?.analyst?.length ?? 0) > 0 && (
          <StyledTechInfoSectionContainer>
            <StyledTechInfoSectionHeading>Analyst DB:</StyledTechInfoSectionHeading>
            {databases?.analyst?.map((analystDB) => {
              const { original_db_name, hierarchies } = analystDB
              return (
                <React.Fragment key={analystDB.id}>
                  {hierarchies?.map(({ hierarchy_name }, index) => (
                    <Text key={`${analystDB.id}-${index}`}>{`${original_db_name} # ${hierarchy_name}`}</Text>
                  ))}
                </React.Fragment>
              )
            })}
          </StyledTechInfoSectionContainer>
        )}

        {(databases?.observer?.length ?? 0) > 0 && (
          <StyledTechInfoSectionContainer>
            <StyledTechInfoSectionHeading>Observer DB:</StyledTechInfoSectionHeading>
            {databases?.observer?.map((observerDB, index) => <Text key={index}>{observerDB.original_db_name}</Text>)}
          </StyledTechInfoSectionContainer>
        )}

        {(databases?.axios?.length ?? 0) > 0 && (
          <StyledTechInfoSectionContainer>
            <StyledTechInfoSectionHeading>AXIOS DB:</StyledTechInfoSectionHeading>
            {databases?.axios?.map((axiosDB, index) => <Text key={index}>{axiosDB.original_db_name}</Text>)}
          </StyledTechInfoSectionContainer>
        )}
      </StyledTechInfoContainer>
    </StyledTechInfoGrid>
  )
}

export default TechnicalInformation
