import { CLEAR_ERROR, SET_ERROR } from './actions.types'
import { ErrorState } from './types'

export const setError = (payload: ErrorState) => ({
  type: SET_ERROR,
  payload,
})

export const clearError = () => ({
  type: CLEAR_ERROR,
})
