import { ConfirmDialog } from '@/modules/report-status/components/mockCustomerNote/confirmDialog'
import { StyledMockCustomerNoteButtonArea } from '@/modules/report-status/styled'
import { Button, Checkbox, Dialog, Flexbox, Text, TextAreaField, TextField } from '@skf-internal/ui-components-react'
import { FC, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

interface DebugMockCustomerNoteProps {
  faultId: string
}

const DebugMockCustomerNote: FC<DebugMockCustomerNoteProps> = ({ faultId }) => {
  const { assetId: assetIdParam } = useParams()
  const initialAssetId = assetIdParam

  const [formDialog, setFormDialog] = useState<boolean>(false)
  const [confirmDialog, setConfirmDialog] = useState<boolean>(false)

  let currentTimestamp = new Date().toISOString()

  const [assetId, setAssetId] = useState<string>(initialAssetId!)
  const [faultVersionId, setFaultVersionId] = useState<string>(faultId)

  const [id, setId] = useState<string>('')
  const [sap, setSap] = useState<string>('')
  const [engineHour, setEngineHour] = useState<string>('0')
  const [timestampNoteCreated, setTimestampNoteCreated] = useState<string>(currentTimestamp)
  const [isInvestigatingSKF, setIsInvestigatingSKF] = useState<boolean>(true)
  const [droplistLevel1to4SKF, setDroplistLevel1to4SKF] = useState<string>('null')
  const [droplistLevel5SKF, setDroplistLevel5SKF] = useState<string>('Faulty Mounting Pad')
  const [isResolvedIdNoteSKF, setIsResolvedIdNoteSKF] = useState<boolean>(true)
  const [isInvestigatingAlert, setIsInvestigatingAlert] = useState<boolean>(false)
  const [typeOfAlert, setTypeOfAlert] = useState<string>('')
  const [notes, setNotes] = useState<string>('')
  const [lastModifiedDate, setLastModifiedDate] = useState<string>(currentTimestamp)
  const [isOrphanNote, setIsOrphanNote] = useState<boolean>(false)
  const [idNoteSKF, setIdNoteSKF] = useState<string>(`${assetId}|${timestampNoteCreated}#${faultVersionId}`)

  const handleAddMockNoteClick = () => setFormDialog(true)
  const handlePublishClick = () => setConfirmDialog(true)
  const handleConfirmDialogCancel = () => setConfirmDialog(false)
  const handleOnCustomerNoteFaultPublish = () => {
    setConfirmDialog(false)
    resetForm()
    setFormDialog(false)
  }

  const resetForm = () => {
    currentTimestamp = new Date().toISOString()

    setAssetId(initialAssetId!)
    setFaultVersionId(faultId)
    setId('')
    setSap('')
    setEngineHour('0')
    setTimestampNoteCreated(currentTimestamp)
    setIsInvestigatingSKF(true)
    setDroplistLevel1to4SKF('null')
    setDroplistLevel5SKF('Faulty Mounting Pad')
    setIsResolvedIdNoteSKF(true)
    setIsInvestigatingAlert(false)
    setTypeOfAlert('')
    setNotes('')
    setLastModifiedDate(currentTimestamp)
    setIsOrphanNote(false)
  }

  useEffect(() => {
    setIdNoteSKF(`${assetId}|${timestampNoteCreated}#${isOrphanNote ? 'F-9999' : faultVersionId}`)
  }, [assetId, faultVersionId, timestampNoteCreated, isOrphanNote])

  return (
    <>
      {confirmDialog && (
        <ConfirmDialog
          onCancel={handleConfirmDialogCancel}
          onCustomerNoteFaultPublish={handleOnCustomerNoteFaultPublish}
          customerNoteData={{
            id,
            sap,
            timestampNoteCreated: timestampNoteCreated,
            engineHour,
            isInvestigatingSKF: isInvestigatingSKF ? 'true' : 'false',
            droplistLevel1to4SKF,
            droplistLevel5SKF,
            idNoteSKF,
            isResolvedIdNoteSKF: isResolvedIdNoteSKF ? 'true' : 'false',
            isInvestigatingAlert: isInvestigatingAlert ? 'true' : 'false',
            typeOfAlert,
            notes,
            lastModifiedDate: lastModifiedDate,
          }}
        />
      )}

      {formDialog && (
        <Dialog
          feTitle="Add mock note"
          open={true}
          feOnClose={() => setFormDialog(false)}
          cssWidth="500px"
          cssHeight="830px"
          feInterruptive={true}
        >
          <Flexbox feGap="md" feFlexDirection="column">
            <h3>Note details</h3>

            <Flexbox feFlexDirection="column" feGap="md" feJustifyContent="space-between">
              <TextField feLabel="Asset ID" value={assetId} readOnly={true} disabled />
              <TextField feLabel="Fault Version ID" value={faultVersionId} readOnly={true} disabled />
              <TextField
                feLabel="Timestamp Note Created"
                value={timestampNoteCreated}
                feHint="The Date format should be: YYYY-MM-DDTHH:MM:SS.mmmZ"
                readOnly={true}
                disabled
              />
              <Checkbox
                feLabel="Is orphan note?"
                checked={isOrphanNote}
                onChange={(_, value) => setIsOrphanNote(value)}
              />

              <Flexbox feFlexDirection="column" feGap="xs">
                <Text feFontSize="md">ID Skf note</Text>
                <Text>{idNoteSKF}</Text>
              </Flexbox>

              <TextField feLabel="ID" value={id} onChange={(_, value) => setId(value)} maxLength={500} />
              <TextField feLabel="SAP" value={sap} onChange={(_, value) => setSap(value)} maxLength={500} />
              <TextField
                feLabel="Engine hour"
                value={engineHour}
                onChange={(_, value) => setEngineHour(value)}
                min={0}
                maxLength={500}
              />
              <Checkbox
                feLabel="Is investigating SKF?"
                checked={isInvestigatingSKF}
                onChange={(_, value) => setIsInvestigatingSKF(value)}
              />
              <TextField
                feLabel="Droplist level 1 to 4 SKF"
                value={droplistLevel1to4SKF}
                onChange={(_, value) => setDroplistLevel1to4SKF(value)}
                maxLength={500}
              />
              <TextField
                feLabel="Droplist level 5 SKF"
                value={droplistLevel5SKF}
                onChange={(_, value) => setDroplistLevel5SKF(value)}
                maxLength={500}
              />
              <Checkbox
                feLabel="Is resolved id note SKF?"
                checked={isResolvedIdNoteSKF}
                onChange={(_, value) => setIsResolvedIdNoteSKF(value)}
              />
              <Checkbox
                feLabel="Is investigating alert?"
                checked={isInvestigatingAlert}
                onChange={(_, value) => setIsInvestigatingAlert(value)}
              />
              <TextField
                feLabel="Type of alert"
                value={typeOfAlert}
                onChange={(_, value) => setTypeOfAlert(value)}
                maxLength={500}
              />
              <TextAreaField feLabel="Notes" value={notes} onChange={(_, value) => setNotes(value)} maxLength={1000} />
              <TextField
                feLabel="Last Modified Date"
                onChange={(_, value) => setLastModifiedDate(value)}
                value={lastModifiedDate}
                feHint="The Date format should be: YYYY-MM-DDTHH:MM:SS.mmmZ"
                maxLength={500}
              />
            </Flexbox>

            <Flexbox feJustifyContent="end" feGap="md">
              <Button
                feType="secondary"
                onClick={() => {
                  resetForm()
                  setFormDialog(false)
                }}
              >
                Cancel
              </Button>
              <Button onClick={handlePublishClick}>Submit</Button>
            </Flexbox>
          </Flexbox>
        </Dialog>
      )}

      <StyledMockCustomerNoteButtonArea>
        <Button feType="secondary" onClick={handleAddMockNoteClick}>
          Add mock note
        </Button>
      </StyledMockCustomerNoteButtonArea>
    </>
  )
}

export default DebugMockCustomerNote
