import { StyledFlexibleCollapseToggleButton } from '@/shared/components/FlexibleCollapse/styled'
import { dataTestId } from '@/tests/testid'
import { Flexbox } from '@skf-internal/ui-components-react'
import { FC, PropsWithChildren, ReactNode, useState } from 'react'

interface FlexibleCollapseProps extends PropsWithChildren {
  header: ReactNode
  toggleButtonBackgroundColor?: string
}

/**
 * A flexible collapse component that allows for custom component title and toggle button styles.
 * If you do not need to use component in collapse title, use regular collapse from component library!!!
 * @param {ReactNode} header - The header content, which can be any valid ReactNode.
 * @param {ReactNode} children - The content to be collapsed or expanded.
 * @param {string} toggleButtonBackgroundColor - The background color of the toggle button.
 * @returns {JSX.Element} The rendered component.
 */
const FlexibleCollapse: FC<FlexibleCollapseProps> = ({ header, children, toggleButtonBackgroundColor = '#FFF' }) => {
  const [open, setOpen] = useState(false)

  return (
    <Flexbox feFlexDirection="column" data-testid={dataTestId.flexibleCollapse}>
      <Flexbox feAlignItems="center">
        {header}
        <StyledFlexibleCollapseToggleButton
          as="button"
          aria-label={open ? 'Hide' : 'Show'}
          feIcon={open ? 'chevronUp' : 'chevronDown'}
          onClick={() => setOpen((open) => !open)}
          feSize="md"
          toggleButtonBackgroundColor={toggleButtonBackgroundColor}
        />
      </Flexbox>
      {open ? children : null}
    </Flexbox>
  )
}

export default FlexibleCollapse
