import { StatusSelectType, StatusType } from '@/models/dashboard/globalFiltering/types'
import {
  AssetCriticalityType,
  AssetReportFilters,
  CriticalityOptionValue,
  ReportDate,
  ReportTypeString,
  StatusOptionValue,
} from '@/models/reportGenerator/types'
import ReportSelect from '@/modules/report-generator/components/ReportSelect'
import ReportDateSelect from '@/modules/report-generator/generateReport/reportFilters/ReportDateSelect'
import ReportTypeSection from '@/modules/report-generator/generateReport/reportFilters/ReportTypeSection'
import {
  StyledFlexItem,
  StyledIconContainer,
  StyledRegenerateButton,
  StyledReportDetailsSection,
  StyledReportFiltersContainer,
  StyledReportTypeHeading,
  StyledReportTypeSection,
  StyledSelectFiltersSection,
  StyledTopSection,
  StyledTotalAssets,
} from '@/modules/report-generator/styled'
import { getCriticality, mapCriticalityOption, mapToSelectItems } from '@/modules/report-generator/utils'
import { criticalityOptions, statusOptions } from '@/modules/report-generator/utils/constants'
import useDeepCompareCallback from '@/shared/hooks/useDeepCompareCallback'
import { getAssetStatuses, mapAssetStatusesOption } from '@/shared/utils'
import { dataTestId } from '@/tests/testid'
import { Flexbox, Icon } from '@skf-internal/ui-components-react'
import { FC, useCallback, useState } from 'react'

const ReportFilters: FC = () => {
  const [assetReportFilters, setAssetReportFilters] = useState<AssetReportFilters>({
    assets: [],
    assetStatus: [],
    assetCriticality: [],
    assetType: [],
    reportDate: { startDate: new Date(), endDate: new Date() },
    reportType: [],
  })

  const handleAssetStatusSelect = useCallback((assetStatus: StatusSelectType[]) => {
    setAssetReportFilters((prevState) => ({
      ...prevState,
      assetStatus,
    }))
  }, [])

  const handleAssetCriticalitySelect = useCallback((assetCriticality: AssetCriticalityType[]) => {
    setAssetReportFilters((prevState) => ({
      ...prevState,
      assetCriticality,
    }))
  }, [])

  const handleAssetTypeSelect = useCallback((assetType: string[]) => {
    setAssetReportFilters((prevState) => ({
      ...prevState,
      assetType,
    }))
  }, [])

  const handleReportDateSelect = useCallback((reportDate: ReportDate) => {
    setAssetReportFilters((prevState) => ({
      ...prevState,
      reportDate,
    }))
  }, [])

  const handleReportType = useDeepCompareCallback(
    (status: string, checked: boolean) => {
      setAssetReportFilters((prevState) => {
        const updatedReportType = checked
          ? [...prevState.reportType, status as ReportTypeString]
          : prevState.reportType.filter((type) => type !== status)

        return { ...prevState, reportType: updatedReportType }
      })
    },
    [assetReportFilters]
  )

  return (
    <StyledReportFiltersContainer data-testid={dataTestId.generateReportFilters}>
      <StyledTopSection>
        <StyledSelectFiltersSection>
          <StyledFlexItem data-testid={dataTestId.generateReportFiltersAssetStatusSelect}>
            <ReportSelect<StatusOptionValue, StatusSelectType>
              multiple={false}
              value={assetReportFilters.assetStatus as StatusType[]}
              transformPassedValueFn={(value) => mapAssetStatusesOption(value as StatusType[])}
              onChange={handleAssetStatusSelect}
              transformSelectedValueFn={(selectedValue) => getAssetStatuses(selectedValue)}
              items={mapToSelectItems(statusOptions)}
              label="Asset status to include"
            />
          </StyledFlexItem>
          <StyledFlexItem data-testid={dataTestId.generateReportFiltersAssetCriticalitySelect}>
            <ReportSelect<CriticalityOptionValue, AssetCriticalityType>
              multiple={false}
              value={assetReportFilters.assetCriticality}
              transformPassedValueFn={(value) => mapCriticalityOption(value)}
              onChange={handleAssetCriticalitySelect}
              transformSelectedValueFn={(selectedValue) => getCriticality(selectedValue)}
              items={mapToSelectItems(criticalityOptions)}
              label="Asset criticality"
            />
          </StyledFlexItem>
          <StyledFlexItem data-testid={dataTestId.generateReportFiltersAssetTypeSelect}>
            <ReportSelect<string, string>
              multiple={true}
              value={assetReportFilters.assetType}
              onChange={handleAssetTypeSelect}
              items={[{ label: 'Vibration', value: 'vibration' }]}
              label="Asset criticality"
            />
          </StyledFlexItem>
          <StyledFlexItem>
            <ReportDateSelect value={assetReportFilters.reportDate} onChange={handleReportDateSelect} />
          </StyledFlexItem>
        </StyledSelectFiltersSection>
        <StyledReportDetailsSection>
          <StyledTotalAssets data-testid={dataTestId.generateReportFiltersTotalAssetIncluded}>
            Total asset included: 0
          </StyledTotalAssets>
          <StyledIconContainer data-testid={dataTestId.generateReportFiltersAssetStatusIcons}>
            <Icon feIcon="checkCircle" feColor="green" feSize="sm" />
            <Icon feIcon="warning" feColor="blue" />
            <Icon feIcon="danger" feColor="red" />
            <Icon feIcon="error" feColor="yellow" />
          </StyledIconContainer>
        </StyledReportDetailsSection>
      </StyledTopSection>
      <StyledReportTypeSection>
        <StyledReportTypeHeading feFlexDirection="row">Charts to include</StyledReportTypeHeading>
        <Flexbox feGap="lg">
          <ReportTypeSection reportTypeStatus={assetReportFilters.reportType} onChange={handleReportType} />
          <StyledRegenerateButton data-testid={dataTestId.generateReportRegenerateButton}>
            Regenerate
          </StyledRegenerateButton>
        </Flexbox>
      </StyledReportTypeSection>
    </StyledReportFiltersContainer>
  )
}

export default ReportFilters
