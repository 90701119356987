import { FaultErrorState, RecommendedActionErrorState } from '@/models/reportStatus/faultsFormStateTypes'
import { Status } from '@/models/reportStatus/faultsFormStateTypes'
import { COLORS } from '@skf-internal/ui-components-react'

const closedRecommendedActionOutcomeLabel: Record<'completed' | 'rejected', string> = {
  completed: 'Completed',
  rejected: 'Rejected',
}

const commonFaultErrorStruct: FaultErrorState = {
  id: '',
  fault: {
    error: '',
    isValid: true,
  },
  collectionDate: {
    error: '',
    isValid: true,
  },
  status: {
    error: '',
    isValid: true,
  },
  observation: {
    error: '',
    isValid: true,
  },
  explanation: {
    error: '',
    isValid: true,
  },
  recommendedActions: [],
}
const commonRecommendedActionErrorStruct: RecommendedActionErrorState = {
  id: '',
  recommendedAction: {
    error: '',
    isValid: true,
  },
  dueDate: {
    error: '',
    isValid: true,
  },
  additionalNotes: {
    error: '',
    isValid: true,
  },
  workOrder: {
    error: '',
    isValid: true,
  },
  outcome: {
    error: '',
    isValid: true,
  },
  closeDate: {
    error: '',
    isValid: true,
  },
}

export enum ErrorTexts {
  REQUIRED = 'Required Field',
}

export const EVIDENCE_S3_FOLDER_NAME = 'evidence'

const statusBackgroundColor: Record<Status, string> = {
  normal: COLORS.gray500,
  acceptable: '#88C008',
  unsatisfactory: '#FFD615',
  unacceptable: COLORS.orangeDark,
  severe: '#EB202A',
}

const statusTextColor: Record<Status, string> = {
  normal: COLORS.white,
  acceptable: COLORS.black,
  unsatisfactory: COLORS.black,
  unacceptable: COLORS.white,
  severe: COLORS.white,
}

export {
  closedRecommendedActionOutcomeLabel,
  commonFaultErrorStruct,
  commonRecommendedActionErrorStruct,
  statusBackgroundColor,
  statusTextColor,
}
