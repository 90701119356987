import { useEffect, useRef } from 'react'

/**
 * A custom hook that debounces the execution of a provided effect function.
 * The effect will only be executed after the specified delay has passed since the last change in dependencies.
 *
 * @param {() => void} effect - The effect function to be debounced.
 * @param {React.DependencyList} deps - The list of dependencies that trigger the effect when changed.
 * @param {number} delay - The debounce delay in milliseconds before executing the effect.
 *
 * @returns {void}
 */

export const useDebounce = (effect: () => void, deps: React.DependencyList, delay: number) => {
  const timeoutId = useRef<number>()

  useEffect(() => {
    timeoutId.current = window.setTimeout(effect, delay)

    return () => clearTimeout(timeoutId.current)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)
}
