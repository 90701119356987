import {
  Channel,
  Device,
  DevicesSensorsTableChannelRowData,
  DevicesSensorsTableDeviceRowData,
} from '@/models/devicesAndSensors/types'
import { Color, IconColor, IconName } from '@skf-internal/ui-components-react'

const DeviceStatusString: Record<DevicesSensorsTableDeviceRowData['status'], string> = {
  normal: 'Normal',
  notNormal: 'Faulted',
}

const DeviceFaultText: Record<Device['faults'][number], string> = {
  buffer: 'Buffer fault',
  cable_fault: 'Broken Connection',
  broken_connection: 'Broken Connection',
  no_measurement_24h: 'No measurement 24h',
  internal_temperature: 'Internal temperature',
  invalid: 'Invalid',
  channel_fault: 'Channel Fault',
}

const ChannelStatusString: Record<DevicesSensorsTableChannelRowData['status'], string> = {
  normal: 'Normal',
  notNormal: 'Faulted',
  noSensorData: 'No measurement ever',
  channelNotConnected: 'Channel not connected to any asset',
}

const ChannelTypeText: Record<DevicesSensorsTableChannelRowData['type'], string> = {
  invalid: 'Invalid',
  analog: 'Analog',
  digital: 'Digital',
}

const DeviceStatusIcon: Record<DevicesSensorsTableDeviceRowData['status'], { icon: IconName; color: IconColor }> = {
  normal: {
    color: 'green',
    icon: 'checkCircle',
  },
  notNormal: {
    color: 'purple',
    icon: 'sensor',
  },
}

const ChannelFaultText: Record<Channel['faults'][number], string> = {
  cable_fault: 'Broken Connection',
  broken_connection: 'Broken Connection',
  no_measurement_24h: 'No measurement 24h',
  no_measurement_ever: 'No measurement ever',
  invalid: 'Invalid',
}

const ChannelTextColor: Record<DevicesSensorsTableChannelRowData['status'], Color> = {
  normal: 'greenBase',
  notNormal: 'purpleBase',
  noSensorData: 'gray700',
  channelNotConnected: 'gray700',
}

const ChannelStatusIcon: Record<DevicesSensorsTableChannelRowData['status'], { icon: IconName; color: IconColor }> = {
  normal: {
    color: 'green',
    icon: 'checkCircle',
  },
  notNormal: {
    color: 'purple',
    icon: 'sensor',
  },
  noSensorData: {
    color: 'gray',
    icon: 'error',
  },
  channelNotConnected: {
    icon: 'forbidden',
    color: 'gray',
  },
}

const deviceNumberFormatter = new Intl.NumberFormat('en-US', {
  minimumIntegerDigits: 3,
  useGrouping: false,
})

export {
  ChannelStatusIcon,
  DeviceStatusString,
  DeviceFaultText,
  ChannelStatusString,
  ChannelTypeText,
  ChannelFaultText,
  DeviceStatusIcon,
  ChannelTextColor,
  deviceNumberFormatter,
}
