// =================================================================================================
// IMPORTS
// =================================================================================================
import { DevicesAndSensorsContextProvider } from '@/contexts/devicesAndSensors'
import { ModuleWrapper, ViewFlexbox } from '@/modules/devices-sensors/components/styled'
import DeviceSensorsTableCard from '@/modules/devices-sensors/components/root/DeviceSensorsTableCard'
import DevicesSensorsSideMenuCard from '@/modules/devices-sensors/components/root/DevicesSensorsSideMenuCard'
import { FetchDataContextProvider } from '@/contexts/common/fetchDataContext'

// =================================================================================================
// MODULE INDEX
// =================================================================================================

const DevicesSensors = () => {
  return (
    <DevicesAndSensorsContextProvider>
      <FetchDataContextProvider>
        <ModuleWrapper>
          <ViewFlexbox feGap="sm">
            <DeviceSensorsTableCard />
            <DevicesSensorsSideMenuCard />
          </ViewFlexbox>
        </ModuleWrapper>
      </FetchDataContextProvider>
    </DevicesAndSensorsContextProvider>
  )
}

export default DevicesSensors
