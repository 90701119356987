import { FC } from 'react'
import { tableCellFormatter, truncateChartAxisValue } from '@/modules/dashboard/utils'
import {
  StyledRAContentRowContainer,
  StyledRAContentStatusRow,
  StyledRAContentStatusRowName,
  StyledRACurrentMonthStatusIcons,
} from '../../styled'
import { statusText } from '@/modules/dashboard/utils/recommended-actions'
import { dataTestId } from '@/tests/testid'

interface RAContentRowProps {
  name: string
  value: number
  showPercentages: boolean
}

const RAContentRow: FC<RAContentRowProps> = ({ name, value, showPercentages }) => {
  return (
    <StyledRAContentRowContainer key={name} data-testid={dataTestId.recommendedActionsCurrentMonthStatusContentRow}>
      <StyledRACurrentMonthStatusIcons name={name}></StyledRACurrentMonthStatusIcons>
      <StyledRAContentStatusRow>
        {value >= 0 ? truncateChartAxisValue(tableCellFormatter(value, 1, showPercentages)) : 'NA'}
        <StyledRAContentStatusRowName>{statusText(name)}</StyledRAContentStatusRowName>
      </StyledRAContentStatusRow>
    </StyledRAContentRowContainer>
  )
}

export default RAContentRow
