import {
  COLORS,
  Divider,
  Flexbox,
  IconButton,
  SPACINGS,
  Tooltip,
  css,
  useTooltip,
} from '@skf-internal/ui-components-react'
import { FC } from 'react'

export const PriorityColumn: FC<{ isInaccuratePriority: boolean; priority: number }> = ({
  isInaccuratePriority,
  priority,
}) => {
  const [hoverRef, isHovered] = useTooltip()

  return (
    <Flexbox feJustifyContent="center" feAlignItems="center">
      {isInaccuratePriority ? (
        <>
          <IconButton
            css={css`
              color: ${COLORS.white};
              background-color: ${COLORS.orangeDarker};
              border-radius: 50%;
              margin-right: ${SPACINGS.xxs};
              cursor: default;
            `}
            feIcon="exclamation"
            feSize="md"
            as="button"
            aria-label="Invalid Input"
            ref={hoverRef}
          />
          <Tooltip isHovered={isHovered} triggerElement={hoverRef.current}>
            Invalid Input
          </Tooltip>
        </>
      ) : (
        <Divider as="div" feColor="secondary" feSpacing="xs" feVertical={true} />
      )}
      {priority}
    </Flexbox>
  )
}
