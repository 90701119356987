import { styled } from '@skf-internal/ui-components-react'

export const StyledTd = styled.td`
  display: flex;
  flex-direction: column;
`
export const StyledSpan = styled.span`
  max-width: 10ch;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
