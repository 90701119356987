import { useFaultsFormStateContext } from '@/contexts/moduleContexts/faultsForm'
import { ClosedFault, FaultState } from '@/models/reportStatus/faultsFormStateTypes'
import FormRadioGroup from '@/modules/report-status/components/formRadioGroup'
import FormTextArea from '@/modules/report-status/components/formTextArea'
import { StyledReportStatusCloseFaultContext } from '@/modules/report-status/styled'
import { getEntityId } from '@/modules/report-status/utils/formUtils'
import useDeepCompareMemo from '@/shared/hooks/useDeepCompareMemo'
import { dataTestId } from '@/tests/testid'
import { FC, useRef } from 'react'

interface CorrectDiagnosticAreaProps {
  fault: ClosedFault
  isInEditMode: boolean
}

const CorrectDiagnosticArea: FC<CorrectDiagnosticAreaProps> = ({ fault, isInEditMode }) => {
  const faultId = useRef<string>(getEntityId(fault))

  const {
    faultsFormState: { errors },
  } = useFaultsFormStateContext()

  const errorData = useDeepCompareMemo(() => {
    return errors?.find((faultError) => faultError.id === faultId.current)
  }, [errors])

  return (
    fault.state.toString() === FaultState.PENDING_CLOSE && (
      <StyledReportStatusCloseFaultContext feAlignItems="flex-start" data-testid={dataTestId.correctDiagnosticArea}>
        <FormRadioGroup
          value={fault.correctDiagnostic ? 'yes' : 'no'}
          items={[
            { value: 'yes', feLabel: 'Yes', feSize: 'sm' },
            { value: 'no', feLabel: 'No', feSize: 'sm' },
          ]}
          legend="Was the diagnosis correct?"
          fieldPath={{
            fieldName: 'correctDiagnostic',
            fieldId: faultId.current,
          }}
        />
        {!fault.correctDiagnostic && (
          <FormTextArea
            label="Explanation"
            isInEditMode={isInEditMode}
            value={fault.explanation}
            hint="Please provide a detailed explanation about the reason the diagnosis was not correct."
            maxLength={1000}
            isRequired={true}
            fieldPath={{
              fieldName: 'explanation',
              fieldId: faultId.current,
            }}
            errorData={errorData?.explanation}
            state={fault.state}
            data-testid={dataTestId.correctDiagnosticAreaTextArea}
          />
        )}
      </StyledReportStatusCloseFaultContext>
    )
  )
}

export default CorrectDiagnosticArea
