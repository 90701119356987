/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useState } from 'react'

/**
 * Custom hook that provides a workaround for handling asynchronous errors within an error boundary.
 *
 * This hook allows you to throw errors inside async functions, ensuring they are caught by React's error boundaries,
 * which normally do not handle asynchronous errors.
 *
 * @returns {(e: any) => void} - A function that throws an error to be caught by the nearest error boundary.
 */
export const useAsyncThrow = () => {
  const [, setErr] = useState()
  return useCallback(
    (e: any) =>
      setErr(() => {
        throw e
      }),
    [setErr]
  )
}
