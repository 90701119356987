import { StyledNodeInfo } from '@/modules/report-status/styled'
import { dataTestId } from '@/tests/testid'
import { Text } from '@skf-internal/ui-components-react'
import { FC } from 'react'

const NoteInfo: FC<{
  label: string
  notes: string
}> = ({ label, notes }) => {
  return (
    <StyledNodeInfo feGap="xs" feFlexDirection="column" data-testid={dataTestId.customerNoteInfo}>
      <Text feFontWeight="bold" feFontSize="sm">
        {label}
      </Text>
      <Text>{notes}</Text>
    </StyledNodeInfo>
  )
}
export default NoteInfo
