import { markOrphanNotesAsAcknowledged } from '@/api/paths/orphanNotes'
import { DarwinError } from '@/shared/components/ErrorBoundary/DarwinError'
import { useApi } from '@/shared/hooks/useApi'
import { useAsyncThrow } from '@/shared/hooks/useAsyncThrow'
import { isString } from '@/shared/utils'
import { AxiosError } from 'axios'

const useMarkAsAcknowledge = () => {
  const throwError = useAsyncThrow()
  return useApi((params) => {
    let dataToAcknowledge: Record<string, string[]> = { noteIDs: [] }
    if (params && params.postData) {
      if (isString(params.postData)) {
        dataToAcknowledge = { noteIDs: [params.postData as unknown as string] }
      } else if (Array.isArray(params.postData)) {
        dataToAcknowledge = { noteIDs: [...(params.postData as string[])] }
      }
    }
    return markOrphanNotesAsAcknowledged(
      { data: dataToAcknowledge },
      {
        onError: async (error: AxiosError) => {
          throwError(new DarwinError(error))
        },
      }
    )
  })
}

export default useMarkAsAcknowledge
