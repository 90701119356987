import useDeepCompareEffect from '@/shared/hooks/useDeepCompareEffect'
import { DependencyList } from 'react'

const ESCAPE_KEY = 'Escape'

type CallbackFunction = (event: KeyboardEvent) => void

/**
 * A custom hook that listens for the `Escape` key press and triggers a provided callback when the key is pressed.
 *
 * @param {CallbackFunction} callback - The function to be called when the `Escape` key is pressed.
 * @param {DependencyList} [dependencies=[]] - Optional array of dependencies that determine when the effect should be re-applied. The effect re-runs only when these dependencies change.
 *
 * @returns {void}
 */

const useEscapeKey = (callback: CallbackFunction, dependencies: DependencyList = []): void => {
  useDeepCompareEffect(() => {
    const onKeyPress = (event: KeyboardEvent) => {
      if (event.key === ESCAPE_KEY) {
        callback(event)
      }
    }

    document.addEventListener('keydown', onKeyPress)

    return () => {
      document.removeEventListener('keydown', onKeyPress)
    }
  }, [callback, dependencies])
}
export default useEscapeKey
