import { useFetchDataContext } from '@/contexts/common/fetchDataContext'
import { AssetRow } from '@/models/analysisBacklog/types'
import { calculateInfoPanelData, exportAnalysisBacklogDataToCsv } from '@/modules/analysis-backlog/utils/index'
import ModuleHeader from '@/shared/components/ModuleHeader'
import { HeaderInfoPanelIconData } from '@/shared/components/ModuleHeader/infoPanel/HeaderInfoPanelIcon'
import useDeepCompareEffect from '@/shared/hooks/useDeepCompareEffect'
import useDeepCompareMemo from '@/shared/hooks/useDeepCompareMemo'
import {
  setModuleHeaderActionPanelData,
  setModuleHeaderCSVData,
  setModuleHeaderConfigData,
} from '@/store/moduleHeader/actions'
import { useAppDispatch, useTypedSelector } from '@/store/store'
import { ColumnFiltersState, Table } from '@tanstack/react-table'
import { Dispatch, FC, SetStateAction } from 'react'

interface AnalysisBacklogHeaderProps {
  table: Table<AssetRow>
  isTableFiltered: boolean
  handleClearFilters: () => void
  showHandled: boolean
  setShowHandled: Dispatch<SetStateAction<boolean>>
  columnFilters: ColumnFiltersState
}

const AnalysisBacklogHeader: FC<AnalysisBacklogHeaderProps> = ({
  table,
  isTableFiltered,
  handleClearFilters,
  showHandled,
  setShowHandled,
  columnFilters,
}) => {
  const analysisBacklogDispatch = useAppDispatch()

  const { fetchDataStatus } = useFetchDataContext()

  const { selectedCustomer } = useTypedSelector((state) => ({ ...state }))

  const infoPanelData = useDeepCompareMemo<HeaderInfoPanelIconData[]>(() => {
    return calculateInfoPanelData(table)
  }, [table.getFilteredRowModel().flatRows])

  useDeepCompareEffect(() => {
    analysisBacklogDispatch(
      setModuleHeaderConfigData({
        infoPanelElement: undefined,
        infoPanelData,
      })
    )
  }, [infoPanelData])

  useDeepCompareEffect(() => {
    analysisBacklogDispatch(
      setModuleHeaderConfigData({
        showHeader: true,
        showInfoPanel: true,
        showActionPanel: true,
        title: 'Analysis Backlog',
      })
    )
    analysisBacklogDispatch(
      setModuleHeaderActionPanelData({
        withLoader: true,
        withClearFiltersButton: true,
        isClearFiltersButtonDisabled: isTableFiltered,
        clearFiltersButtonFn: handleClearFilters,
        withTableStateChangeSwitch: true,
        tableStateChangeSwitchCurrentState: showHandled,
        tableStateChangeSwitchFn: setShowHandled,
        tableStateChangeSwitchLabel: 'Show handled',
        withRefreshButton: true,
        withCSVDownloadButton: true,
        withAsyncIndicator: false,
      })
    )
  }, [table, fetchDataStatus, isTableFiltered, showHandled])

  useDeepCompareEffect(() => {
    const filteredRows = table.getFilteredRowModel().rows
    if (filteredRows.length > 0) {
      analysisBacklogDispatch(
        setModuleHeaderCSVData({
          csvData: exportAnalysisBacklogDataToCsv(
            filteredRows.map((row) => row.original),
            selectedCustomer.name,
            columnFilters
          ),
          moduleName: 'Analysis Backlog',
        })
      )
    }
  }, [table.getFilteredRowModel().rows, selectedCustomer, columnFilters])

  return <ModuleHeader />
}

export default AnalysisBacklogHeader
