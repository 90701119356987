import { StatusCode } from '@/api/constants'
import { DarwinNotFoundError } from '@/shared/components/ErrorBoundary/DarwinError'
import { useEffectOnce } from '@/shared/hooks/useEffectOnce'

/**
 * ErrorNotFound component is used to trigger a not found error (404) when a page is not found.
 *
 * This component utilizes the `useEffectOnce` hook to throw a `DarwinNotFoundError` on mount,
 * indicating that the requested page could not be found.
 *
 * @throws {DarwinNotFoundError} Throws a not found error when the component is mounted.
 *
 * @returns {JSX.Element} An empty div as a placeholder for the component.
 */

export const ErrorNotFound = () => {
  useEffectOnce(() => {
    throw new DarwinNotFoundError({
      status: StatusCode.NotFound,
      message: 'Page not found',
      name: '',
      isAxiosError: false,
      /* v8 ignore next 1 */
      toJSON: () => ({}),
    })
  })

  return <div></div>
}
