export const REPORT_SECTIONS = {
  SUMMARY_CHARTS: 'summaryCharts',
  SUMMARIZED_ASSET_HEALTH: 'summarizedAssetHealth',
  DETAILED_ASSET_HEALTH: 'detailedAssetHealth',
  LAST_MEASUREMENTS: 'lastMeasurements',
  OPEN_RECOMMENDED_ACTIONS: 'openRecommendedActions',
} as const

export const statusOptions = [
  { label: 'All', value: 'all' },
  { label: 'Hide normal', value: 'hideNormal' },
  { label: 'Unsatisfactory, unacceptable, severe', value: 'unsatisfactoryUnacceptableSevere' },
  { label: 'Unsatisfactory, severe', value: 'unsatisfactorySevere' },
  { label: 'Severe', value: 'severe' },
] as const

export const criticalityOptions = [
  { label: 'All', value: 'all' },
  { label: 'A', value: 'a' },
  { label: 'A-B', value: 'ab' },
  { label: 'B-C', value: 'bc' },
] as const
