import { useFetchDataContext } from '@/contexts/common/fetchDataContext'
import { LoadingType, UseApiProps } from '@/shared/hooks/useApi'
import useInterval from '@/shared/hooks/useInterval'
import useTimeout from '@/shared/hooks/useTimeout'
import { stopAsyncPoller } from '@/store/asyncPoller/actions'
import { openToast } from '@/store/genericToast/actions'
import { setModuleHeaderActionPanelData } from '@/store/moduleHeader/actions'
import { useAppDispatch, useTypedSelector } from '@/store/store'
import { useEffect } from 'react'

interface AsyncPoller<T> {
  pollerFn: (props: UseApiProps) => Promise<void | T>
}
/**
 * The AsyncPoller is a generic component that facilitates data polling from the backend at a specified interval.
 * If the provided timeout is exceeded, it raises an error.
 *
 * In addition, over the polling duration, it will toggle the loading indicator in the header action panel.
 *
 * Additionally, if configured, it displays a couple of toast messages.
 *
 * @template T - The type of data returned by the poller function.
 * @param {() => Promise<void | T>} props.pollerFn - The function that performs the polling.
 * @returns {JSX.Element} - The rendered component.
 */

const AsyncPoller = <T,>({ pollerFn }: AsyncPoller<T>): JSX.Element => {
  const asyncPollerDispatch = useAppDispatch()
  const {
    asyncPoller: { isAsyncPollerActive, pollerInterval, pollerTimeout, toastMessages },
  } = useTypedSelector((state) => ({ ...state }))

  const { fetchDataStatus } = useFetchDataContext()

  const { startInterval, clearInterval } = useInterval(() => {
    if (fetchDataStatus !== 'loading') {
      pollerFn({ loaderType: LoadingType.HEADER })
    }
    console.info('A/P was not executed: Old A/P still in action ')
  }, pollerInterval)

  const quitAsyncPoller = () => {
    clearInterval()
    clearTimeout()
    asyncPollerDispatch(setModuleHeaderActionPanelData({ isAsyncIndicatorShown: false }))
    asyncPollerDispatch(stopAsyncPoller())
  }

  const { startTimeout, clearTimeout } = useTimeout(() => {
    quitAsyncPoller()
    asyncPollerDispatch(
      openToast({
        children: toastMessages.timeoutPollingMessage,
        feSeverity: 'error',
        timeout: 3,
      })
    )
  }, pollerTimeout)

  useEffect(() => {
    if (isAsyncPollerActive) {
      startInterval()
      asyncPollerDispatch(setModuleHeaderActionPanelData({ isAsyncIndicatorShown: true }))
      startTimeout()
      asyncPollerDispatch(
        openToast({
          children: toastMessages.startPollingMessage,
          feSeverity: 'info',
          timeout: 3,
        })
      )
    } else {
      quitAsyncPoller()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAsyncPollerActive])

  return <></>
}

export default AsyncPoller
