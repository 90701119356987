import { AxiosError } from 'axios'
import {
  GetOrphanNotesRequest,
  GetOrphanNotesResponse,
  GetOrphanNotesResult,
  MarkAsAcknowledgeOrphanNoteRequest,
  MarkAsAcknowledgeOrphanNoteResult,
} from './types'

import networkClient from '@/api/NetworkClient'

import { PATHS } from '@/api/types'
import { OrphanNotes } from '@/models/orphanNotes/types'

const getOrphanNotes = async (
  handlers: GetOrphanNotesResult,
  params: Record<string, string | undefined>
): Promise<OrphanNotes | void> => {
  try {
    const response = await networkClient.get<GetOrphanNotesRequest, GetOrphanNotesResponse>(PATHS.ORPHAN_NOTES, {
      params,
    })
    handlers.onSuccess && (await handlers.onSuccess(response.data))
    return response.data
  } catch (error) {
    handlers.onError && (await handlers.onError(error as AxiosError))
  }
}

const markOrphanNotesAsAcknowledged = async (
  request: MarkAsAcknowledgeOrphanNoteRequest,
  handlers: MarkAsAcknowledgeOrphanNoteResult
): Promise<void> => {
  try {
    const response = await networkClient.patch<GetOrphanNotesRequest, GetOrphanNotesResponse>(
      PATHS.MARK_AS_ACKNOWLEDGED_ORPHAN_NOTES,
      request.data
    )
    handlers.onSuccess && (await handlers.onSuccess(response.data))
  } catch (error) {
    handlers.onError && (await handlers.onError(error as AxiosError))
  }
}

export { getOrphanNotes, markOrphanNotesAsAcknowledged }
