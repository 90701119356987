import networkClient from '@/api/NetworkClient'
import { CommonPostWidgetDataRequest, CommonPostWidgetDataResult } from '@/api/paths/analytics/common/types'
import { GetFaultsResponse } from '@/api/paths/analytics/faults/types'
import { PATHS } from '@/api/types'

import { AxiosError } from 'axios'

const postFaults = async (
  request: CommonPostWidgetDataRequest,
  handlers: CommonPostWidgetDataResult,
  params: Record<string, string | undefined>
): Promise<GetFaultsResponse | void> => {
  try {
    const response = await networkClient.post<CommonPostWidgetDataRequest, GetFaultsResponse>(
      PATHS.POST_FAULTS,
      request,
      { params }
    )
    handlers.onSuccess && (await handlers.onSuccess(response.data))
  } catch (error) {
    handlers.onError && (await handlers.onError(error as AxiosError))
  }
}

export { postFaults }
