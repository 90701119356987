import { dataTestId } from '@/tests/testid'
import { StyledNoteDataColumnWrapper } from './styled'

const NoteCreationDateColumnHeader = () => {
  return (
    <StyledNoteDataColumnWrapper data-testId={dataTestId.noteCreationDateColumnHeader}>
      Note Creation Date
    </StyledNoteDataColumnWrapper>
  )
}

export default NoteCreationDateColumnHeader
