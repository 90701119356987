import { RecommendedAction } from '@/models/unresolvedRecommendations/types'
import { exportUnresolvedRecommendationsDataToCSV } from '@/modules/unresolved-recommendation/utils'
import ModuleHeader from '@/shared/components/ModuleHeader'
import useDeepCompareEffect from '@/shared/hooks/useDeepCompareEffect'
import {
  setModuleHeaderActionPanelData,
  setModuleHeaderCSVData,
  setModuleHeaderConfigData,
} from '@/store/moduleHeader/actions'
import { useAppDispatch, useTypedSelector } from '@/store/store'
import { ColumnFiltersState, Table } from '@tanstack/react-table'
import { Dispatch, FC, SetStateAction } from 'react'

interface UnresolvedRecommendationsProps {
  table: Table<RecommendedAction>
  isTableFiltered: boolean
  handleClearFilters: () => void
  showClosed: boolean
  setShowClosed: Dispatch<SetStateAction<boolean>>
  columnFilters: ColumnFiltersState
}

const UnresolvedRecommendationsHeader: FC<UnresolvedRecommendationsProps> = ({
  table,
  isTableFiltered,
  handleClearFilters,
  showClosed,
  setShowClosed,
  columnFilters,
}) => {
  const unresolvedRecommendationsDispatch = useAppDispatch()
  const { selectedCustomer } = useTypedSelector((state) => ({ ...state }))

  useDeepCompareEffect(() => {
    unresolvedRecommendationsDispatch(
      setModuleHeaderConfigData({
        showHeader: true,
        showInfoPanel: false,
        showActionPanel: true,
        title: 'Open Recommendations',
      })
    )
    unresolvedRecommendationsDispatch(
      setModuleHeaderActionPanelData({
        withLoader: true,
        withClearFiltersButton: true,
        isClearFiltersButtonDisabled: isTableFiltered,
        clearFiltersButtonFn: handleClearFilters,
        withTableStateChangeSwitch: true,
        tableStateChangeSwitchCurrentState: showClosed,
        tableStateChangeSwitchFn: setShowClosed,
        tableStateChangeSwitchLabel: 'Show closed',
        withRefreshButton: true,
        withCSVDownloadButton: true,
      })
    )
  }, [table, isTableFiltered, showClosed])

  useDeepCompareEffect(() => {
    const filteredRows = table.getFilteredRowModel().rows
    unresolvedRecommendationsDispatch(
      setModuleHeaderCSVData({
        csvData: exportUnresolvedRecommendationsDataToCSV(
          filteredRows.map((row) => row.original),
          selectedCustomer.name,
          columnFilters
        ),
        moduleName: 'Open Recommendations',
      })
    )
  }, [table.getFilteredRowModel().rows, selectedCustomer, columnFilters])

  return <ModuleHeader />
}

export default UnresolvedRecommendationsHeader
