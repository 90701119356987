import { DevicesSensorsTableDeviceRowData, UniqueChannelsAssets } from '@/models/devicesAndSensors/types'
import { filterRegistry } from '@/shared/components/TableComponents/GenericTableFilter/constants'
import TableHeaderColumn from '@/shared/components/TableComponents/TableHeaderColumn'
import { ColumnHelper, Header } from '@tanstack/react-table'
import { FC, useMemo } from 'react'
import { ColumnCell } from './commonCells/column-cell'
import { ColumnDef } from '@/common/table/base-components/column-def'
import useWindowDimensions from '@/shared/hooks/useWindowDimensions'

const COLUMN_WIDTH_IN_PERCENT = 25.3

export const generateConnectedAssetColumnDefinition = (columnHelper: ColumnHelper<DevicesSensorsTableDeviceRowData>) =>
  columnHelper.accessor('uniqueChannelsAssets', {
    id: 'uniqueChannelsAssets',
    header: 'Connected Assets',
    enableColumnFilter: true,
    filterFn: filterRegistry.text.filterFn,
    cell: ({ getValue }) => getValue().map((value) => value.name),
    meta: {
      filterType: 'text',
    },
  })

export const DevicesSensorsTableConnectedAssetColumnDef: FC = () => <ColumnDef columnWidth={COLUMN_WIDTH_IN_PERCENT} />

export const DevicesSensorsTableConnectedAssetHeaderColumn: FC<{
  header: Header<DevicesSensorsTableDeviceRowData, unknown>
}> = ({ header }) => {
  const { width } = useWindowDimensions()

  const characterLength = useMemo(() => {
    let result = 6
    if (width <= 1200) {
      result = 12
    } else if (width <= 1440) {
      result = 20
    } else if (width >= 1440) {
      result = 22
    } else {
      result = 26
    }
    return result
  }, [width])

  return (
    <TableHeaderColumn<DevicesSensorsTableDeviceRowData>
      columnWidth={COLUMN_WIDTH_IN_PERCENT}
      header={header}
      centerValue={false}
      title="Connected Assets"
      characterLength={characterLength}
    />
  )
}

export const DevicesSensorsTableConnectedAssetColumnCell: FC<{ value: Array<UniqueChannelsAssets> }> = ({ value }) => {
  const { width } = useWindowDimensions()

  const characterLength = useMemo(() => {
    let result = 6
    if (width <= 1200) {
      result = 10
    } else if (width < 1440) {
      result = 15
    } else if (width < 1900) {
      result = 18
    } else {
      result = 30
    }
    return result
  }, [width])

  return (
    <>
      {value && value.length > 1 ? (
        <ColumnCell
          columnWidth={COLUMN_WIDTH_IN_PERCENT}
          value={`${value.length} assets connected`}
          centerValue={false}
          characterLength={characterLength}
        />
      ) : value && value.length > 0 ? (
        value.map((val) => (
          <ColumnCell
            columnWidth={COLUMN_WIDTH_IN_PERCENT}
            value={val.name}
            centerValue={false}
            characterLength={characterLength}
          />
        ))
      ) : (
        <ColumnCell
          columnWidth={COLUMN_WIDTH_IN_PERCENT}
          value="-"
          centerValue={false}
          characterLength={characterLength}
        />
      )}
    </>
  )
}
