import React from 'react'
import {
  StyledAssetHierarchyContainer,
  StyledAssetSelectionContainer,
  StyledCompanyLogo,
  StyledCompanyLogoContainer,
} from '@/modules/report-generator/styled'
import { dataTestId } from '@/tests/testid'

const AssetSelection: React.FC = () => {
  return (
    <StyledAssetSelectionContainer data-testid={dataTestId.reportGeneratorAssetSelection}>
      <StyledCompanyLogoContainer data-testid={dataTestId.reportGeneratorCompanyLogo}>
        <StyledCompanyLogo>
          <img src="https://picsum.photos/200" alt="" width={200} height={103} />
        </StyledCompanyLogo>
      </StyledCompanyLogoContainer>
      <StyledAssetHierarchyContainer>
        <h2>Select assets to report</h2>
      </StyledAssetHierarchyContainer>
    </StyledAssetSelectionContainer>
  )
}
export default AssetSelection
