import { START_ASYNC_POLLER, STOP_ASYNC_POLLER } from '@/store/asyncPoller/actions.types'
import { AsyncPollerAction, AsyncPollerState } from '@/store/asyncPoller/types'

export const initialState: AsyncPollerState = {
  isAsyncPollerActive: false,
  pollerInterval: 2000,
  pollerTimeout: 10000,
  toastMessages: {
    startPollingMessage: '',
    timeoutPollingMessage: '',
    successPollingMessage: '',
    failedPollingMessage: '',
  },
}

export type AsyncPollerReducer = (state: AsyncPollerState | undefined, action: AsyncPollerAction) => AsyncPollerState

const asyncPollerReducer: AsyncPollerReducer = (state = initialState, action) => {
  if (action.type === START_ASYNC_POLLER) {
    return { ...action.payload, isAsyncPollerActive: true }
  }

  if (action.type === STOP_ASYNC_POLLER) {
    return { ...action.payload, isAsyncPollerActive: false }
  }

  return state
}

export default asyncPollerReducer
