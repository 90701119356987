import { DevicesSensorsSideMenuDeviceData } from '@/models/devicesAndSensors/types'
import { DeviceFaultText, DeviceStatusIcon, DeviceStatusString } from '@/modules/devices-sensors/utils/constants'
import { Flexbox, Icon, Text } from '@skf-internal/ui-components-react'
import { FC } from 'react'

const DeviceDataFault: FC<{
  status: DevicesSensorsSideMenuDeviceData['status']
  fault?: DevicesSensorsSideMenuDeviceData['faults'][number]
}> = ({ status, fault }) => {
  const iconData = DeviceStatusIcon[status]
  const isFaulted = fault && status

  return (
    <Flexbox data-testid="device-data-fault" feFlexDirection="row" feGap="xs" feAlignItems="center">
      <Icon feIcon={iconData.icon} feColor={iconData.color} feSize="sm" />
      <Text feColor={isFaulted ? 'purpleBase' : 'greenBase'} feFontWeight="bold">
        {isFaulted ? DeviceFaultText[fault] : DeviceStatusString['normal']}
      </Text>
    </Flexbox>
  )
}

export default DeviceDataFault
