import {
  CLEAR_MODULE_HEADER_ACTION_PANEL_DATA,
  CLEAR_MODULE_HEADER_CONFIG_DATA,
  CLEAR_MODULE_HEADER_CSV_DATA,
  SET_MODULE_HEADER_ACTION_PANEL_DATA,
  SET_MODULE_HEADER_CONFIG_DATA,
  SET_MODULE_HEADER_CSV_DATA,
} from './actions.types'
import { ModuleHeaderActionPanelProps, ModuleHeaderCSVDataProps, ModuleHeaderConfigProps } from './types'

export const setModuleHeaderConfigData = (payload: ModuleHeaderConfigProps) => ({
  type: SET_MODULE_HEADER_CONFIG_DATA,
  payload,
})

export const clearModuleHeaderConfigData = () => ({ type: CLEAR_MODULE_HEADER_CONFIG_DATA })

export const setModuleHeaderActionPanelData = (payload: ModuleHeaderActionPanelProps) => ({
  type: SET_MODULE_HEADER_ACTION_PANEL_DATA,
  payload,
})

export const clearModuleHeaderActionPanelData = () => ({
  type: CLEAR_MODULE_HEADER_ACTION_PANEL_DATA,
})

export const setModuleHeaderCSVData = (payload: ModuleHeaderCSVDataProps) => ({
  type: SET_MODULE_HEADER_CSV_DATA,
  payload,
})

export const clearModuleHeaderCSVData = () => ({
  type: CLEAR_MODULE_HEADER_CSV_DATA,
})
