import { FilterContentProps } from '@/shared/components/TableComponents/GenericTableFilter/types'
import { dataTestId } from '@/tests/testid'
import { TextField } from '@skf-internal/ui-components-react'
import { FC } from 'react'

const NumberFilterContent: FC<FilterContentProps<number>> = ({ initialValue, onFilterValueChange }) => {
  return (
    <TextField
      feSize="sm"
      type="number"
      feLabel="Search"
      feHideLabel
      placeholder="Search"
      defaultValue={initialValue}
      onChange={(_, searchString) => onFilterValueChange(+searchString)}
      autoFocus
      data-testid={dataTestId.numberFilterContent}
    />
  )
}

export default NumberFilterContent
