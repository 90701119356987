import styled from '@emotion/styled'
import { COLORS, Icon, Popup, SPACINGS, useTooltip } from '@skf-internal/ui-components-react'
import { FC } from 'react'

const NestedList: FC<{ items: string[]; level?: number }> = ({ items, level = 0 }) => {
  if (items.length === 0) return null

  const [first, ...rest] = items

  return (
    <ul>
      <HierarchyListItem level={level}>
        <HierarchyLine>
          {level > 0 && <Icon feIcon="arrowRight" feSize="xs" />}
          <HierarchyText bold={rest.length === 0}>{first}</HierarchyText>
        </HierarchyLine>

        {rest.length > 0 && <NestedList items={rest} level={level + 1} />}
      </HierarchyListItem>
    </ul>
  )
}

const removeTrailingSlash = (str: string) => {
  return str.endsWith('/') ? str.slice(0, -1) : str
}

export const BelongsToColumn: FC<{ assetName: string; belongsTo: string; hierarchy: string }> = ({
  assetName,
  belongsTo,
  hierarchy,
}) => {
  const [hoverRef, isHovered] = useTooltip()

  const hierarchyPaths = removeTrailingSlash(hierarchy).split('/')
  hierarchyPaths.push(assetName)

  return (
    <>
      <HierarchyPopup isHovered={isHovered} triggerElement={hoverRef.current}>
        <HierarchyWrapper>
          <NestedList items={hierarchyPaths} />
        </HierarchyWrapper>
      </HierarchyPopup>
      <StyledBelongsToText ref={hoverRef}>{belongsTo}</StyledBelongsToText>
    </>
  )
}

const StyledBelongsToText = styled.span`
  cursor: pointer;

  :hover {
    color: ${COLORS.gray700};
  }
`

const HierarchyListItem = styled.li<{ level: number }>`
  padding-left: ${(props) => props.level > 1 && props.level + 10}px;
`

const HierarchyLine = styled.span`
  display: flex;
  align-items: center;
  gap: ${SPACINGS.xxs};
  padding-block: 0.1rem;
`

const HierarchyPopup = styled(Popup)`
  width: fit-content;
`

const HierarchyWrapper = styled.div`
  padding: ${SPACINGS.xs};
`

const HierarchyText = styled.span<{ bold?: boolean }>`
  font-weight: ${(props) => (props.bold ? 'bold' : 'normal')};
`
