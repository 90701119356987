// =================================================================================================
// IMPORTS
// =================================================================================================

import { DevicesSensorsTableDeviceRowData } from '@/models/devicesAndSensors/types'
import StatusValueTooltip from '@/modules/devices-sensors/components/tooltip'
import { filterRegistry } from '@/shared/components/TableComponents/GenericTableFilter/constants'
import TableHeaderColumn from '@/shared/components/TableComponents/TableHeaderColumn'
import { ColumnHelper, Header, Row } from '@tanstack/react-table'
import { FC, ReactNode, useMemo } from 'react'
import { ComponentColumnCell } from './commonCells/column-cell'
import { ColumnDef } from '@/common/table/base-components/column-def'
import useWindowDimensions from '@/shared/hooks/useWindowDimensions'
import { createTableSortingOrderMap, sortingRegistry } from '@/shared/components/TableComponents/sortingFunction'
import { devicesAndSensorsStatusSortingOrder } from '@/shared/components/TableComponents/sortingFunction/constants'

// =================================================================================================
// CONSTANTS
// =================================================================================================

const COLUMN_WIDTH_IN_PERCENT = 12

export const generateStatusColumnDefinition = (columnHelper: ColumnHelper<DevicesSensorsTableDeviceRowData>) =>
  columnHelper.accessor('status', {
    id: 'status',
    header: 'Status',
    sortingFn: (
      rowA: Row<DevicesSensorsTableDeviceRowData>,
      rowB: Row<DevicesSensorsTableDeviceRowData>,
      columnId: string
    ) =>
      sortingRegistry.status<DevicesSensorsTableDeviceRowData>(
        rowA,
        rowB,
        columnId,
        createTableSortingOrderMap(devicesAndSensorsStatusSortingOrder)
      ),
    enableColumnFilter: true,
    filterFn: filterRegistry.status.filterFn,
    cell: ({ getValue }) => {
      return <StatusValueTooltip value={getValue()} />
    },
    meta: {
      filterType: 'device_status',
    },
  })

// =================================================================================================
// COLUMN DEF COMPONENT
// =================================================================================================

export const DevicesSensorsTableStatusColumnDef: FC = () => <ColumnDef columnWidth={COLUMN_WIDTH_IN_PERCENT} />

// =================================================================================================
// HEADER COMPONENT
// =================================================================================================

export const DevicesSensorsTableStatusHeaderColumn: FC<{
  header: Header<DevicesSensorsTableDeviceRowData, unknown>
}> = ({ header }) => {
  const { width } = useWindowDimensions()

  const characterLength = useMemo(() => {
    let result = 6
    if (width <= 1200) {
      result = 1
    } else if (width <= 1440) {
      result = 7
    } else {
      result = 15
    }
    return result
  }, [width])

  return (
    <TableHeaderColumn<DevicesSensorsTableDeviceRowData>
      columnWidth={COLUMN_WIDTH_IN_PERCENT}
      header={header}
      centerValue={true}
      characterLength={characterLength}
      title="Status"
    />
  )
}

// =================================================================================================
// CELL COMPONENT
// =================================================================================================

export const DevicesSensorsTableStatusColumnCell: FC<{ children: ReactNode }> = ({ children }) => (
  <ComponentColumnCell columnWidth={COLUMN_WIDTH_IN_PERCENT} centerValue={true}>
    {children}
  </ComponentColumnCell>
)
