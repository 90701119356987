import { FC, useEffect } from 'react'
import { Button, styled } from '@skf-internal/ui-components-react'
import { useAppDispatch } from '@/store/store'
import { closeGenericConfirmDialog, showGenericConfirmDialog } from '@/store/genericConfirmationDialog/actions'
import GenericConfirmationDialogButton from '@/shared/components/GenericConfirmationDialog/GenericConfirmationDialogButton'
import useMarkAsAcknowledge from '@/modules/orphan-notes/hooks/useMarkAsAcknowledge'
import { useFetchDataContext } from '@/contexts/common/fetchDataContext'

interface OrphanNotesTableActionProps {
  noteId: string
}

const StyledButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

const StyledButton = styled(Button)`
  @media (width <= 1010px) {
    font-size: 0.8rem;
  }
`

const OrphanNotesTableAction: FC<OrphanNotesTableActionProps> = ({ noteId }) => {
  const orphanNotesTableActionsDispatch = useAppDispatch()
  const closeDialog = () => {
    orphanNotesTableActionsDispatch(closeGenericConfirmDialog())
  }

  const { execute: markAsAcknowledged, status: markAsAcknowledgedStatus } = useMarkAsAcknowledge()
  const { fetchDataFn } = useFetchDataContext()
  useEffect(() => {
    if (markAsAcknowledgedStatus === 'success') {
      fetchDataFn({ postData: noteId })
    }
  }, [fetchDataFn, markAsAcknowledgedStatus, noteId])

  return (
    <StyledButtonWrapper>
      <StyledButton
        feType="secondary"
        onClick={() =>
          orphanNotesTableActionsDispatch(
            showGenericConfirmDialog({
              isOpen: true,
              title: 'Acknowledge Note',
              message: 'Mark note as Acknowledged?',
              buttons: [
                <GenericConfirmationDialogButton type="secondary" label="Cancel" onClick={() => closeDialog()} />,
                <GenericConfirmationDialogButton
                  type="primary"
                  label="Confirm"
                  onClick={async () => {
                    noteId && markAsAcknowledged({ postData: noteId })
                    closeDialog()
                  }}
                />,
              ],
            })
          )
        }
      >
        Acknowledge
      </StyledButton>
    </StyledButtonWrapper>
  )
}

export default OrphanNotesTableAction
