import { StyledHistoryRowIconContainer } from '../../styled'
import { AssetStatusIcon } from '@/modules/analysis-backlog/status-icon'
import { getWorstStatus } from '../../utils'
import { dataTestId } from '@/tests/testid'
import { AssetHistorySnapshot } from '@/models/reportStatus/types'

export interface AssetStatusProps {
  snapshot: AssetHistorySnapshot | AssetHistorySnapshot[]
}

export const AssetStatus = ({ snapshot }: AssetStatusProps) => {
  const statusValue = !Array.isArray(snapshot) ? getWorstStatus(snapshot) : 'normal'

  return (
    <StyledHistoryRowIconContainer feAlignItems="center">
      <AssetStatusIcon value={statusValue} data-testid={dataTestId.assetStatusIcon} />
    </StyledHistoryRowIconContainer>
  )
}
