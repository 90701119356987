import { Customer } from '@/models/customer/types'
import { CLEAN_SELECTED_CUSTOMER, SET_SELECTED_CUSTOMER } from '@/store/selectedCustomer/actions.types'

export const setSelectedCustomer = (customer: Customer) => ({
  type: SET_SELECTED_CUSTOMER,
  payload: customer,
})

export const cleanSelectedCustomer = () => ({
  type: CLEAN_SELECTED_CUSTOMER,
})
