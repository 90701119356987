import { HalMockCustomerNote } from '@/models/reportStatus/types'
import useCreateHalMockCustomerNote from '@/modules/report-status/hooks/useCreateHalMockCustomerNote'
import { Button, Dialog, Flexbox } from '@skf-internal/ui-components-react'
import { FC } from 'react'

export const ConfirmDialog: FC<{
  onCancel: () => void
  onCustomerNoteFaultPublish: () => void
  customerNoteData: HalMockCustomerNote
}> = ({ onCancel, onCustomerNoteFaultPublish, customerNoteData }) => {
  const { status, execute } = useCreateHalMockCustomerNote(customerNoteData, onCustomerNoteFaultPublish)

  return (
    <Dialog feTitle="Are you sure?" open={true} feOnClose={onCancel} feInterruptive={true}>
      <Flexbox feGap="lg" feFlexDirection="column">
        <span>Do you want to publish the note?</span>
        <Flexbox feJustifyContent="end" feGap="md">
          <Button disabled={status === 'loading'} feType="secondary" onClick={onCancel}>
            Cancel
          </Button>
          <Button feType="primary" feLoading={status === 'loading'} onClick={() => execute()}>
            Confirm
          </Button>
        </Flexbox>
      </Flexbox>
    </Dialog>
  )
}
