import { DateFilter, RecommendationState, RecommendedAction } from '@/models/unresolvedRecommendations/types'
import { DateFormats, formatDate, moveDatePartToStart, parseDate } from '@/shared/dateUtils'
import { ColumnFiltersState } from '@tanstack/react-table'
import { startCase } from 'lodash-es'

const calculateDueDays = (dueDateStr: string): number => {
  const dueDate = parseDate(dueDateStr)
  const currentDate = new Date()

  const utcDueDate = moveDatePartToStart(dueDate, 'day')
  const utcCurrentDate = moveDatePartToStart(currentDate, 'day')

  const daysDiff = Math.round(
    (parseDate(utcDueDate).getTime() - parseDate(utcCurrentDate).getTime()) / (1000 * 60 * 60 * 24)
  )

  return daysDiff
}

const exportUnresolvedRecommendationsDataToCSV = (
  data: RecommendedAction[],
  customerName: string,
  filters: ColumnFiltersState
): string => {
  const appliedFilters = formatFilters(filters)

  // Format asset status for filters row
  const formattedAssetStatus = formatAssetStatus(appliedFilters['assetStatus'])

  return [
    `Site Name:,${customerName}`,
    'Dashboard:, Open Recommendations',
    `Export date:, ${formatDate(new Date(), DateFormats.AmericanDateTimeFormat)}`,
    [],
    [],
    [],
    // Filters applied
    [
      'Filters Applied:',
      appliedFilters['assetName'] || 'none',
      appliedFilters['assetParent'] || 'none',
      formattedAssetStatus,
      appliedFilters['faultType'] || 'none',
      'none',
      appliedFilters['recommendedAction'] || 'none',
      'none',
      appliedFilters['raOpenDate'] || 'none',
      appliedFilters['raDueDate'] || 'none',
      appliedFilters['dueInDays'] || 'none',
    ].join(','),
    // table header row
    [
      '#',
      'Asset Name',
      'Belongs to',
      'Asset Status',
      'Fault',
      'Fault ID',
      'Recommended Action',
      'Additional Comments',
      'RA Created Date',
      'RA Due Date',
      'Days to Due',
    ].join(','),
    // rows data
    ...data.map((recommendation) =>
      [
        recommendation.id,
        formatCsvValues(recommendation.assetName),
        formatCsvValues(recommendation.assetParent),
        formatCsvValues(recommendation.assetStatus),
        formatCsvValues(recommendation.faultType),
        formatCsvValues(recommendation.faultID),
        `"${recommendation.recommendedAction}"`,
        `"${recommendation.raComment}"`,
        recommendation.raOpenDate,
        recommendation.raDueDate,
        recommendation.id === RecommendationState.Closed ? '-' : calculateDueDays(recommendation.raDueDate).toString(),
      ].join(',')
    ),
  ].join('\n')
}

const formatAssetStatus = (assetStatus: string | undefined): string => {
  return assetStatus
    ? assetStatus
        .split(',')
        .map((status) => status.trim())
        .join(', ')
    : 'none'
}

const formatFilters = (filters: ColumnFiltersState): Record<string, string> => {
  const result: Record<string, string> = {}

  filters.forEach((filter) => {
    switch (filter.id) {
      case 'assetStatus':
        result['assetStatus'] = Array.isArray(filter.value)
          ? filter.value.reduce((result, value) => (result += startCase(value) + '.'), '')
          : String(filter.value)
        break
      case 'assetName':
        result['assetName'] = formatCsvValues(String(filter.value))
        break
      case 'assetParent':
        result['assetParent'] = formatCsvValues(String(filter.value))
        break
      case 'faultType':
        result['faultType'] = formatCsvValues(String(filter.value))
        break
      case 'recommendedAction':
        result['recommendedAction'] = formatCsvValues(String(filter.value))
        break
      case 'raOpenDate':
      case 'raDueDate':
        if (typeof filter.value === 'object') {
          result[filter.id] = formatDateFilter(filter.value as DateFilter)
        }
        break
      case 'dueInDays':
        result['dueInDays'] = formatCsvValues(String(filter.value))
        break
      default:
        break
    }
  })

  return result
}

const formatDateFilter = (value: DateFilter): string => {
  if (!value || typeof value !== 'object') return 'none'

  const { dateFilterType, startDate, endDate } = value

  switch (dateFilterType) {
    case 'after':
      return `After: ${startDate?.toString() ?? 'none'}`
    case 'before':
      return `Before: ${startDate?.toString() ?? 'none'}`
    case 'between':
      return `Start Date: ${startDate?.toString() ?? 'none'}.End Date: ${endDate?.toString() ?? 'none'}`
    default:
      return 'none'
  }
}

const formatCsvValues = (data: string): string => {
  if (data.includes(',') || data.includes('\n')) {
    return `"${data.replace(/"/g, '""').replace(/\n/g, ' ')}"`
  }
  return data
}

const getCSVFilename = (): string => {
  const timestamp = new Date().getTime()
  return `open-recommendations-${timestamp}.csv`
}

export {
  calculateDueDays,
  exportUnresolvedRecommendationsDataToCSV,
  formatAssetStatus,
  formatCsvValues,
  formatDateFilter,
  formatFilters,
  getCSVFilename,
}
