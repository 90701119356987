// =================================================================================================
// IMPORTS
// =================================================================================================

import { COLORS } from '@skf-internal/ui-components-react'
import {
  Channel,
  Device,
  Devices,
  DevicesSensorsTableChannelRowData,
  DevicesSensorsTableDeviceRowData,
} from '@/models/devicesAndSensors/types'

// =================================================================================================
// UTILS
// =================================================================================================

const transformTableData = (devices: Devices): DevicesSensorsTableDeviceRowData[] => {
  return devices.map((d: Device) => {
    const formattedDevice: DevicesSensorsTableDeviceRowData = {
      id: d.id,
      number: d.number,
      name: d.name,
      status: d.status,
      lastCommunicationDate: d.lastCommunicationDate,
      faults: d.faults,
      uniqueChannelsAssets: d.uniqueChannelsAssets,
      firmwareVersion: d.firmwareVersion,
      internalTemp: d.internalTemp,
      usedBuffer: d.usedBuffer,
      model: d.model,
      channels: d.channels.map((c: Channel) => {
        const formattedChannel: DevicesSensorsTableChannelRowData = {
          number: c.number,
          name: c.name,
          status: hasNoMeasurementEverData(c.faults) ? 'noSensorData' : c.status,
          lastCommunicationDate: c.lastCommunicationDate,
          type: c.type,
          faults: c.faults,
          voltage: c.voltage,
          asset: c.asset,
        }

        return formattedChannel
      }),
    }

    return formattedDevice
  })
}

const getFirstDevice = (devices: DevicesSensorsTableDeviceRowData[]) => devices[0]

/* Select first channel following the priority:
 * - Analog
 * - Digital
 * - Invalid
 */
const getFirstChannel = (device: DevicesSensorsTableDeviceRowData) => {
  const channels = device.channels
  const analogSensors = channels.filter((v) => v.type === 'analog')
  const digitalSensors = channels.filter((v) => v.type === 'digital')
  const invalidSensors = channels.filter((v) => v.type === 'invalid')

  if (analogSensors.length > 0) return analogSensors[0]
  else if (digitalSensors.length > 0) return digitalSensors[0]
  else return invalidSensors[0]
}

const getRowBackgroundColor = (odd: boolean, selected: boolean): string => {
  let rowBackgroundColor = odd ? COLORS.gray050 : 'transparent'

  if (selected) {
    rowBackgroundColor = COLORS.primary300
  }

  return rowBackgroundColor
}

const hasNoMeasurementEverData = (faults: Channel['faults']): boolean => {
  return faults.some((f) => f === 'no_measurement_ever')
}

const roundVoltageValue = (value: number): string => value.toFixed(1)
const roundTempValue = (value: number): string => Math.trunc(value).toString()

export { roundVoltageValue, roundTempValue, getRowBackgroundColor, getFirstChannel, getFirstDevice, transformTableData }
