import { DarwinErrorDialog } from '@/shared/components/ErrorBoundary/DarwinError'
import { EvidenceImageData } from '@/models/reportStatus/evidenceTypes'
import { DateFormats, formatDate } from '@/shared/dateUtils'
import { STORAGE_REPORT_STATUS_ORIGIN } from '@/shared/localStorageUtils'
import { convertFileNameExtensionToLowercase, generateUUID, getSidFromPathname } from '@/shared/utils'
import { FileRejection } from 'react-dropzone'

/**
 * Converts data from a file drop zone into an array of `EvidenceImageData` objects.
 *
 * @param {File[] | FileRejection[]} filesArray - An array containing either `File` objects or `FileRejection` objects.
 *   - `File`: Represents a file selected by the user.
 *   - `FileRejection`: Represents a rejected file selection with an error message.
 * @param {string} faultId - The fault ID associated with the uploaded files.
 * @returns {EvidenceImageData[]} An array of `EvidenceImageData` objects, each representing a processed file.
 *
 * @throws {TypeError} - If `filesArray` is not an array of `File` or `FileRejection` objects.
 */
const convertDropZoneData = (filesArray: File[] | FileRejection[], faultId: string): EvidenceImageData[] => {
  return filesArray.map((file) => {
    let errorMessage = ''
    let fileData: File | undefined = undefined
    if ((file as FileRejection).errors) {
      errorMessage =
        (file as FileRejection).errors.length > 1
          ? (file as FileRejection).errors[1].message
          : (file as FileRejection).errors[0].message
    }
    if (file instanceof File) {
      fileData = file
    } else {
      fileData = file.file
    }
    return {
      imageInfo: {
        fileID: generateUUID(), // we cannot use fileID as unique sine in case of new image igt will be replaced with one from bucket upload
        contentType: fileData.type,
        fileURL: URL.createObjectURL(fileData),
        fileName: convertFileNameExtensionToLowercase(fileData.name),
        fileCreatedAt: formatDate(new Date(), DateFormats.AmericanDateFormat),
        comment: '',
        size: fileData.size,
        content: '', // Assuming base64ArrayBuffer is synchronous
        originFile: fileData,
        uniqueId: generateUUID(),
      },
      bucketInfo: {
        category: 'evidence',
      },
      appInfo: {
        nodeID: faultId,
        siteID: getSidFromPathname(sessionStorage.getItem(STORAGE_REPORT_STATUS_ORIGIN)),
        isUploaded: false,
        error: errorMessage,
      },
    } as EvidenceImageData
  })
}
/**
 * Converts a file object to a base64 encoded string asynchronously.
 *
 * @param {File} file - The File object to be converted.
 * @returns {Promise<string>} A promise that resolves to the base64 encoded string of the file content, or rejects with an error.
 *
 * @throws {TypeError} - If the provided argument is not a `File` object.
 */
const convertFileToBase64 = async (file: File): Promise<string> => {
  const reader = new FileReader()
  return new Promise((resolve, reject) => {
    reader.onload = (event) => resolve(event.target?.result as string)
    reader.onerror = reject
    reader.readAsDataURL(file)
  })
}

/**
 * Handles the paste event on an HTML input element, capturing files from the clipboard.
 * If an image file is pasted, it creates a `File` object with a formatted name and assigns it to the input element.
 *
 * @param {HTMLInputElement} htmlInput - The input element where files are to be set.
 * @param {ClipboardEvent | React.MouseEvent} event - The paste or mouse event triggering the file capture.
 * @returns {Promise<void>} - A promise that resolves when the clipboard files are processed.
 */

const handleInputPasteEvent = async (htmlInput: HTMLInputElement, event: ClipboardEvent | React.MouseEvent) => {
  let files: FileList | null = null
  if (event instanceof ClipboardEvent && event.clipboardData?.files) {
    files = event.clipboardData.files
  } else {
    try {
      const clipboardItems = await navigator.clipboard.read()

      for (const clipboardItem of clipboardItems) {
        if (clipboardItem.types.includes('image/png') || clipboardItem.types.includes('image/jpeg')) {
          const imageType = clipboardItem.types.find((type) => type.startsWith('image/'))
          const blob = await clipboardItem.getType(imageType!)

          const file = new File([blob], `${generateUUID()}.${imageType?.split('/')[1]}`, {
            type: blob.type,
          })

          const dataTransfer = new DataTransfer()
          dataTransfer.items.add(file)
          files = dataTransfer.files
        }
      }
    } catch (err) {
      console.error('Failed to read from clipboard: ', err)
    }
  }
  htmlInput.files = files
  htmlInput.dispatchEvent(new Event('change', { bubbles: true }))
}

/**
 * Validates an evidence file for type and size.
 *
 * @param {File} file - The file to validate. This should be a valid `File` object obtained from a user selection or similar mechanism.
 * @param {string[]} acceptedFileTypes - An array of accepted file types in lowercase (e.g., ["jpg", "pdf", "png"]).
 * @param {number} maxFileSize - The maximum allowed file size in bytes.
 * @returns {null|object} If the file is valid, returns `null`. Otherwise, returns an object with the following properties:
 *   - `code` {string}: A unique code identifying the error type (e.g., "file-type-error", "file-size-error").
 *   - `message` {string}: A human-readable error message describing the validation failure.
 */
const evidenceFileValidator = (file: File, acceptedFileTypes: string[], maxFileSize: number) => {
  if (file.name) {
    const fileExtension = file.name?.split('.').pop()?.toLowerCase()

    if (fileExtension && !acceptedFileTypes.includes(fileExtension)) {
      return {
        code: 'file-type-error',
        message: 'Invalid file types are not allowed.',
      }
    }

    if (file.size > maxFileSize) {
      return {
        code: 'file-size-error',
        message: `File size exceeds the ${maxFileSize / (1024 * 1024)} MB limit.`,
      }
    }
  }

  return null
}

/**
 * Maps evidence data from a response to the expected `EvidenceImageData` format,
 * adding missing information if necessary and throwing an error for corrupted data.
 *
 * @param {EvidenceImageData[]} evidences - An array of evidence data objects received from a response.
 * @param {string} nodeID - The ID of the node associated with the evidence.
 * @returns {EvidenceImageData[]} A new array of evidence data objects in the expected format.
 *
 * @throws {DarwinErrorDialog} Throws a custom error with specific details if any evidence data is corrupted.
 */
const mapResponseFaultEvidences = (evidences: EvidenceImageData[] | undefined, nodeID: string) => {
  return evidences?.map((evidence) => {
    const evidenceToCheck = { ...evidence }

    if (!evidenceToCheck.imageInfo) {
      throw new DarwinErrorDialog({
        message: 'Corrupted image data',
        code: '400',
        isAxiosError: false,
        toJSON: () => ({}),
        name: '',
      })
    }

    if (!evidenceToCheck.imageInfo.uniqueId) {
      evidenceToCheck.imageInfo.uniqueId = evidenceToCheck.imageInfo.content
        ? createUniqueIdFromBase64(evidenceToCheck.imageInfo.content)
        : generateUUID()
    }

    if (!evidenceToCheck.appInfo) {
      evidenceToCheck.appInfo = {
        nodeID,
        siteID: getSidFromPathname(sessionStorage.getItem(STORAGE_REPORT_STATUS_ORIGIN)),
        isUploaded: true,
      }
    }
    if (!evidenceToCheck.bucketInfo) {
      evidenceToCheck.bucketInfo = {
        category: 'evidence',
      }
    }

    return evidenceToCheck
  })
}
/**
 * Creates a unique ID from a base64-encoded string.
 *
 * @param {string} base64String - The base64-encoded string to generate the unique ID from.
 * @returns {string} A unique ID consisting of the first 16 alphanumeric characters from the base64 string.
 */
const createUniqueIdFromBase64 = (base64String: string) => {
  // Encode the base64 string and trim it
  const uniqueId = base64String.replace(/[^a-zA-Z0-9]/g, '') // Remove non-alphanumeric characters
  return uniqueId.substring(0, 16) // Return a substring of the first 16 characters
}

export {
  convertDropZoneData,
  convertFileToBase64,
  handleInputPasteEvent,
  mapResponseFaultEvidences,
  evidenceFileValidator,
}
