import { AllKeysOf } from '@/shared/models/types'
import { Slice, YearMonth } from '../asset-data-collection/types'

export type WorkOrderStatusCounts = {
  pending: number
  created: number
  closed: number
}
export type RAstatusOptionalProps = {
  [key: string]: number
}

export type ModifiedDataType = {
  [key: string]: number[]
}
export type WorkOrderStatus = keyof WorkOrderStatusCounts
export type WorkOrderStatuses = AllKeysOf<WorkOrderStatusCounts>

export const raStatusString: Record<WorkOrderStatus, string> = {
  pending: 'Pending',
  created: 'Created',
  closed: 'Closed',
}
export type keysOfRAstatus = keyof RAstatusOptionalProps

export type DueDatesStatusCounts = {
  normal: number | null
  acceptable: number | null
  unsatisfactory: number | null
  unacceptable: number | null
  severe: number | null
}

export type RecommendedActionsDueDatesStatusType = {
  period: string
  statusCounts: DueDatesStatusCounts
}

export enum DueDatesStatusPeriod {
  overdueOverMonth = 'Over a month',
  overdueUnderMonth = 'Under a month',
  upcomingUnderMonth = 'Within a month',
  upcomingOverMonth = '1+ month',
}

export type EChartsFormatterParams = {
  dataIndex: number
  value: number
  seriesName: string
  name: string
  data: number | null
  seriesType: string
}

export type RAwidgetDataType = Slice<RAstatusOptionalProps, YearMonth>[]
