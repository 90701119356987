import { Severities } from '@/models/widgets/asset-health-condition/types'
import { WorkOrderStatuses } from '@/models/widgets/recommended-actions/types'
import { AssetStatus } from '@/shared/models/types'
import { getEnumKeys } from '@/shared/utils'
import { COLORS } from '@skf-internal/ui-components-react'

export const barGraphTotalWidth = '450px'

export const barHeadingColors = {
  Green: 'rgb(60, 135, 74)',
  Gray: 'rgb(105, 111, 123)',
  Red: 'rgb(187, 39, 26)',
}

export const barColors = {
  Gray: 'rgb(199, 202, 208)',
  DarkGray: 'rgb(105, 111, 123)',
  Red: 'rgb(187, 39, 26)',
}

export const barWidth = '40px'
/********************************* */
export const alarmBarWidth = '75px'

export const EmergencyColor = COLORS.redBase
export const AlarmColor = COLORS.orangeDark
export const AlertColor = COLORS.yellowDarker
export const NormalColor = COLORS.greenBase
/********************************* */
export const machineHealthBarWidth = '35%'
export const stackedBarLeftMargin = '50px'

export const severities: Severities = getEnumKeys(AssetStatus, ['never-reported']) as Severities
/********************************* */
export const workOrderStatuses: WorkOrderStatuses = ['created', 'pending', 'closed']
export const ColorPending = COLORS.redBase
export const ColorPendingLight = COLORS.redDark
export const ColorOpen = COLORS.primary600
export const ColorOpenLight = COLORS.blueBase
export const ColorConcluded = COLORS.greenBase
export const ColorConcludedLight = COLORS.greenBase
