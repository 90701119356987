import { criticalityOptions, statusOptions } from '@/modules/report-generator/utils/constants'
import { AllKeysOf, AssetCriticality, ExtractArrayValues } from '@/shared/models/types'
import { StatusSelectType } from '../dashboard/globalFiltering/types'

export type AssetCriticalityString = AssetCriticality
export type AssetCriticalityType = 'all' | AssetCriticalityString

export enum ReportType {
  summaryCharts = 'Summary Charts',
  summarizedAssetHealth = 'Summarized Asset Health',
  detailedAssetHealth = 'Detailed Asset Health',
  lastMeasurements = 'Last Measurements',
  openRecommendedActions = 'Open Recommended Actions',
}

export type ReportTypeString = AllKeysOf<ReportType>

export type ReportDate = { startDate: Date; endDate: Date | undefined }

export type StatusOptionValue = ExtractArrayValues<typeof statusOptions, 'value'>
export type CriticalityOptionValue = ExtractArrayValues<typeof criticalityOptions, 'value'>

export type AssetReportFilters = {
  assets: string[]
  assetStatus: StatusSelectType[]
  assetCriticality: AssetCriticalityType[]
  assetType: string[]
  reportDate: ReportDate
  reportType: ReportTypeString[]
}

export interface IReportSelect<R> {
  value: R[]
  onChange: (value: R[]) => void
}
