import {
  SHOW_GENERIC_CONFIRM_DIALOG,
  CLOSE_GENERIC_CONFIRM_DIALOG,
} from '@/store/genericConfirmationDialog/actions.types'
import { GenericConfirmDialogData } from '@/store/genericConfirmationDialog/types'

export const showGenericConfirmDialog = (payload: GenericConfirmDialogData) => ({
  type: SHOW_GENERIC_CONFIRM_DIALOG,
  payload,
})

export const closeGenericConfirmDialog = () => ({
  type: CLOSE_GENERIC_CONFIRM_DIALOG,
})
