import { LoadingType } from './hooks/useApi'
import { SortingState } from '@tanstack/react-table'
import {
  STORAGE_ERROR_COUNT,
  STORAGE_ERROR_PATH_NAME,
  STORAGE_ERROR_SID,
  STORAGE_MSAL_KEYS_PREFIX,
} from './localStorageUtils'
import { AssetStatus, AssetStatusString } from '@/shared/models/types'
import { isDatesEquals } from '@/shared/dateUtils'
import { ImageFileNameData } from '@/models/reportStatus/evidenceTypes'
import { StatusOptionValue } from '@/models/reportGenerator/types'
import { StatusType } from '@/models/dashboard/globalFiltering/types'
import { AllAssetStatuses } from './constants'

/**
 * Performs a deep equality comparison between two values.
 *
 * This function can handle comparisons for primitive types, arrays, and objects.
 * It accounts for special cases like null values and NaN comparisons.
 *
 * @param {*} value - The first value to compare.
 * @param {*} other - The second value to compare.
 * @returns {boolean} - Returns `true` if the values are deeply equal, `false` otherwise.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */
const isEqual = (value: any, other: any) => {
  // Although null is a primitive type in JavaScript, due to some historical bugs,
  // the type of null is object, so we need to additionally handling for null.
  if (value === null && other === null) {
    return true
  }

  if (value == null || other == null || value == undefined || other == undefined) {
    return value === other
  }

  // First handle the case where both values are primitive types.
  if (typeof value !== 'object' && typeof other !== 'object') {
    // we can avoid the NaN comparison issue if we check the equality of two primitive types using Object.is
    return Object.is(value, other)
  }

  // After handling the case where both are primitive types,
  // we can handle one value is primitive type but the other is not.
  // If one is a primitive type and the other is an object type, then return false
  if (typeof value !== typeof other) {
    return false
  }

  // If the above conditions are passed, it means that both values are object types,
  // So we compare the two objects first, and return true if they are from the same reference
  if (value === other) {
    return true
  }

  // Next, check the case where both objects are arrays
  if (Array.isArray(value) && Array.isArray(other)) {
    // If the two arrays have different lengths, return false
    if (value.length !== other.length) {
      return false
    }
    // iterate over each value in the array, then recursively compare two values with isEqual
    for (let i = 0; i < value.length; i++) {
      if (!isEqual(value[i], other[i])) {
        return false
      }
    }

    return true
  }

  // if one is an array, but the other is not, return false
  // Since we pass the && condition above, it means both not both values are arrays
  // here you can use || to check if one of them is an array
  if (Array.isArray(value) || Array.isArray(other)) {
    return false
  }

  //If both are dates, use date utils fn to check if date is equals
  if (
    Object.prototype.toString.call(value) === '[object Date]' &&
    Object.prototype.toString.call(other) === '[object Date]'
  ) {
    return isDatesEquals(value, other)
  }

  // If the above conditions are not met, the remaining possibility is that both values are objects.
  // First check that the two objects have the same number of keys,
  // if not the same number means that the two objects must be different
  if (Object.keys(value).length !== Object.keys(other).length) {
    return false
  }

  // If two objects have the same number of keys, iterate over the first object through Object.entires
  for (const [k, v] of Object.entries(value)) {
    // If a key in the first object does not exist in the second object, it means the two are different
    if (!(k in other)) {
      return false
    }

    // If the key-value pair in the first object is different from the second, it also means that the two objects are different
    // Remember, because the value may also be an object, so use isEqual to recursively check whether the two values   are the same
    if (!isEqual(v, other[k])) {
      return false
    }
  }

  return true
}

/**
 * Waits for the specified timeout.
 *
 * @param {number} ms - The number of milliseconds to wait.
 * @returns {Promise<void>} - A promise that resolves after the specified timeout.
 */
const waitForTimeout = (ms: number): Promise<void> => {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

/**
 * Rounds a number to the specified decimal places.
 *
 * @param {number} num - The number to round.
 * @param {number} places - The number of decimal places to round to (default is 2).
 * @returns {number} - The rounded value.
 */
const roundTo = (num: number, places: number = 2) => {
  const p = Math.pow(10, places)
  const n = num * p * (1 + Number.EPSILON)
  return Math.round(n) / p
}

/**
 * Returns the index of the last element in the array where predicate is true, and -1
 * otherwise.
 * @param array The source array to search in
 * @param predicate find calls predicate once for each element of the array, in descending
 * order, until it finds one where predicate returns true. If such an element is found,
 * findLastIndex immediately returns that element index. Otherwise, findLastIndex returns -1.
 */
const findLastIndex = <T>(array: Array<T>, predicate: (value: T, index: number, obj: T[]) => boolean): number => {
  let l = array.length
  while (l--) {
    if (predicate(array[l], l, array)) return l
  }
  return -1
}

/**
 * Returns the index of the first element in the array where predicate is true, and -1
 * otherwise.
 * @param array The source array to search in
 * @param predicate find calls predicate once for each element of the array, in descending
 * order, until it finds one where predicate returns true. If such an element is found,
 * findFirstIndex immediately returns that element index. Otherwise, findLastIndex returns -1.
 */
const findFirstIndex = <T>(array: Array<T>, predicate: (value: T, index: number, obj: T[]) => boolean): number => {
  let l = -1
  while (l++ < array.length) {
    if (predicate(array[l], l, array)) return l
  }
  return -1
}

/**
 * Checks if all elements in the array are objects with an optional loaderType property.
 * @param {unknown[]} params - The array to check.
 * @returns {boolean} - True if all elements are objects with loaderType, false otherwise.
 */
const isLoaderTypeArray = (params: unknown[]): params is { loaderType?: number }[] => {
  if (!Array.isArray(params) || params.length === 0) {
    return false
  }
  return params.every(
    (param) =>
      typeof param === 'object' &&
      param !== null &&
      'loaderType' in param &&
      Object.values(LoadingType).includes(param.loaderType as LoadingType)
  )
}
/**
 * Throws an error asynchronously.
 * @param {any} error - The error to throw.
 */
const asyncThrow = (error: any) => {
  setTimeout(() => {
    throw error
  }, 0)
}

/**
 * Determines if a value is a string.
 * @param {unknown} x - The value to check.
 * @returns {boolean} - True if the value is a string, false otherwise.
 */
const isString = (x: unknown) => {
  return Object.prototype.toString.call(x) === '[object String]'
}

/**
 * Converts a JavaScript object to a JSON string, handling circular references.
 *
 * @param {Object} obj - The object to be converted to a JSON string.
 * @returns {string} A JSON string representation of the object.
 *
 * @example
 * const obj = { a: 1, b: 2 };
 * obj.c = obj; // Circular reference
 * const jsonString = stringify(obj);
 * console.log(jsonString); // {"a":1,"b":2}
 *
 * @description
 * This function uses `JSON.stringify` to convert an object to a JSON string.
 * It handles circular references by keeping track of objects that have already
 * been stringified. If a circular reference is detected, the key is discarded.
 * The cache is reset after the stringification process to free up memory.
 */
const circularStringify = (obj: unknown) => {
  let cache: unknown[] = []
  const str = JSON.stringify(obj, function (key, value) {
    if (typeof value === 'object' && value !== null) {
      if (cache.indexOf(value) !== -1) {
        // Circular reference found, discard key
        return
      }
      // Store value in our collection
      cache.push(value)
    }
    return value
  })
  cache = [] // reset the cache
  return str
}

/**
 * Extracts the date part from an ISO 8601 formatted timestamp string.
 *
 * @param {string} timestamp - The ISO 8601 formatted timestamp string (e.g., "2024-05-21T00:00:00Z").
 * @returns {string} The extracted date in the format "YYYY-MM-DD".
 * @throws {Error} If the input timestamp is not in a valid format.
 *
 * @example
 * const timestamp = "2024-05-21T00:00:00Z";
 * const date = extractDateFromTimestamp(timestamp);
 * console.log(date); // Output: "2024-05-21"
 */
const extractDateFromTimestamp = (timestamp: string): string => {
  const dateRegex = /^(\d{4}-\d{2}-\d{2})/
  const match = timestamp.match(dateRegex)

  if (match && match[1]) {
    return match[1]
  }

  throw new Error('Invalid timestamp format')
}

/**
 * Returns the element in the array with the maximum value as determined by the provided iteratee function.
 *
 * @template T - The type of elements in the array.
 * @param {T[]} array - The array to iterate over.
 * @param {(item: T) => number} iteratee - The function invoked per iteration to generate the criterion by which to rank the array elements.
 * @returns {T | undefined} - Returns the element with the maximum value, or `undefined` if the array is empty.
 *
 * @example
 * const objects = [{ n: 1 }, { n: 2 }, { n: 3 }];
 * maxBy(objects, o => o.n); // => { n: 3 }
 */
const maxBy = <T>(array: T[], iteratee: (item: T) => number): T | undefined => {
  if (array?.length === 0) return undefined

  return array?.reduce((max, item) => (iteratee(item) > iteratee(max) ? item : max), array[0])
}

/**
 * Clears all keys from `sessionStorage` that include the specified prefix.
 *
 * This function iterates over all the keys in `sessionStorage` and removes any
 * key that contains the given prefix. It's useful for clearing related session data
 * that share a common naming convention.
 *
 * @param {string} prefix - The prefix to match against keys in `sessionStorage`.
 */
const clearSessionStorageKeys = (prefix: string) => {
  Object.keys(sessionStorage).forEach((key) => {
    if (key.includes(prefix)) {
      sessionStorage.removeItem(key)
    }
  })
}
/**
 * Capitalizes the first letter of a given string.
 *
 * @param str - The input string.
 * @returns The string with the first letter capitalized.
 *
 */
const capitalizeFirstLetter = (str: string = ''): string => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

/**
 * Finds and deletes an item from an array based on a given condition.
 *
 * @template T The type of elements in the array.
 * @param array The array to search and modify.
 * @param condition A function that takes an item from the array and returns a boolean indicating whether it matches the desired condition.
 * @returns The found and deleted item and its index, or undefined if no item matches the condition.
 */
const findAndDelete = <T>(
  array: T[],
  condition: (item: T) => boolean
): { foundItem: T; foundItemIndex: number } | undefined => {
  const index = array.findIndex(condition)
  if (index !== -1) {
    const item = array.splice(index, 1)[0]
    return { foundItem: item, foundItemIndex: index }
  }
  return undefined
}

/**
 * Extracts the route from a pathname.

 * The route is defined as the first two segments of the pathname, separated by slashes.

 * @param {string} pathname - The pathname to extract the route from.
 * @returns {string} The extracted route.
 */
const getRouteFromPathname = (pathname: string) => pathname.split('/').slice(0, 2).join('/')

/**
 * Extracts the site ID (SID) from a given pathname.

 * The SID is assumed to be the last segment of the pathname.

 * @param {string} pathname - The pathname to extract the SID from.
 * @returns {string | null} The extracted SID, or `null` if no SID is found.
 */
const getSidFromPathname = (pathname: string | null) =>
  (pathname && pathname.substring(pathname.lastIndexOf('/') + 1)) || ''

/**
 * Generates a unique ID. If prefix is provided, the ID is appended to it.
 *
 * @param prefix The value to prefix the ID with.
 * @returns   
 Returns the unique ID.
 */
const uniqueIdPrefixable = (prefix?: string): string => {
  const id = generateUUID()
  if (prefix) {
    return `${prefix}-${id}`
  }
  return id
}

/**
 * Compares two values and returns a number indicating their order.
 *
 * - Returns `0` if the values are equal.
 * - Returns a positive or negative value depending on the comparison and the specified order.
 * - Handles `null` and `undefined` values by treating them as larger or smaller based on the order.
 * - Performs locale-aware string comparison for string values.
 *
 * @template T - The type of the values being compared.
 * @param {T} a - The first value to compare.
 * @param {T} b - The second value to compare.
 * @param {'asc' | 'desc'} order - The order to sort by. Use `'asc'` for ascending or `'desc'` for descending.
 * @returns {number}
 *   - `0` if `a` is equal to `b`.
 *   - A positive number if `a` should come after `b` in the specified order.
 *   - A negative number if `a` should come before `b` in the specified order.
 * */

const compareValues = <T>(a: T, b: T, order: 'asc' | 'desc'): number => {
  if (a === b) return 0
  if (a === null || a === undefined) return order === 'asc' ? 1 : -1
  if (b === null || b === undefined) return order === 'asc' ? -1 : 1

  if (typeof a === 'string' && typeof b === 'string') {
    return order === 'asc' ? a.localeCompare(b) : b.localeCompare(a)
  }

  return order === 'asc' ? (a > b ? 1 : -1) : a < b ? 1 : -1
}

/**
 * Sorts an array of objects by specified keys and orders.

 * @template T The type of the elements in the array.
 * @param array The array to be sorted.
 * @param keys An array of keys to sort by.
 * @param orders An array of strings specifying the sort order for each key. Possible values are 'asc' (ascending) or 'desc' (descending).
 * @returns A new array of the sorted objects.
 */
const orderBy = <T>(array: T[], keys: (keyof T)[], orders: Array<'asc' | 'desc'> = ['asc']): T[] => {
  return [...array].sort((a, b) => {
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i]
      const order = orders[i] || 'asc' // Default to ascending if order is not specified
      const comparison = compareValues<T>(a[key] as T, b[key] as T, order)
      if (comparison !== 0) return comparison
    }
    return 0
  })
}

/**
 * Creates a custom sorting change handler that alters the default multi-sort behavior.
 * It ensures that the primary sort always appears first when multiple sorts are applied.
 *
 * @param maxMultiSortColCount - Maximum number of columns to allow for multi-sort.
 * @returns A function that updates the sorting state based on provided updater logic.
 */
const createCustomSortHandler = (maxMultiSortColCount: number = 3) => {
  return (updater: ((old: SortingState) => SortingState) | SortingState) => {
    return (old: SortingState): SortingState => {
      let newSorting: SortingState = []

      if (typeof updater === 'function') {
        newSorting = updater(old.length === maxMultiSortColCount ? old.reverse() : old)
      } else {
        newSorting = updater
      }

      if (newSorting.length === 0) {
        return []
      }

      if (old.length === maxMultiSortColCount) {
        newSorting.reverse()
      }

      const changedItemIndex = newSorting.findIndex(
        (item, index) => !old[index] || item.id !== old[index].id || item.desc !== old[index].desc
      )

      if (changedItemIndex !== -1) {
        const changedItem = newSorting[changedItemIndex]
        newSorting.splice(changedItemIndex, 1)
        newSorting.unshift(changedItem)
        newSorting = newSorting.slice(0, maxMultiSortColCount)
      }

      return newSorting
    }
  }
}

/**
Converts a value to a boolean.
Handles various input types, including strings, numbers, booleans, undefined, and null.
Returns true for values that are considered truthy, otherwise returns false.
@param {string|number|boolean|undefined|null} value - The value to convert to a boolean.
@returns {boolean} - The converted boolean value.
**/

const convertToBoolean = (value: string | number | boolean | undefined | null) => {
  switch (value) {
    case true:
    case 'true':
    case 1:
    case '1':
    case 'on':
    case 'yes':
      return true
    default:
      return false
  }
}

/**
 * Clears specific session storage items related to error tracking.
 * This includes:
 * - STORAGE_ERROR_COUNT
 * - STORAGE_ERROR_PATH_NAME
 * - STORAGE_ERROR_SID
 *
 * This function does not return any value.
 */

const clearSessionStorageItems = () => {
  sessionStorage.removeItem(STORAGE_ERROR_COUNT)
  sessionStorage.removeItem(STORAGE_ERROR_PATH_NAME)
  sessionStorage.removeItem(STORAGE_ERROR_SID)
}

/**
 * Clears all session storage items that match the specified MSAL key prefix.
 * This function iterates through all keys in session storage and removes
 * any key that includes the STORAGE_MSAL_KEYS_PREFIX.
 *
 * @function clearMsalSessionStorageKeys
 * @returns {void} This function does not return any value.
 */

const clearMsalSessionStorageKeys = () => {
  Object.keys(sessionStorage).forEach((key) => {
    if (key.includes(STORAGE_MSAL_KEYS_PREFIX)) {
      sessionStorage.removeItem(key)
    }
  })
}

/**
 * @function generateUUID
 * @description Generates a universally unique identifier (UUID) version 4.
 *
 * @returns {string} A UUID v4 string in the format "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".
 */
const generateUUID = (): string =>
  'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })

/**
 * Converts a file name to lowercase for its extension while preserving the rest of the name.
 * @param fileName - The original file name with extension.
 * @returns The file name with the extension converted to lowercase.
 */
const convertFileNameExtensionToLowercase = (fileName: string): string => {
  const fileNameParts = fileName.split('.')
  const fileExtension = fileNameParts.pop()?.toLowerCase()
  const fileNameWithoutExtension = fileNameParts.join('.')

  return fileExtension ? `${fileNameWithoutExtension}.${fileExtension}` : fileName
}

/**
 * Converts a string-based asset status to the corresponding `AssetStatus` enum value.
 *
 * @param {string} assetStatus - The asset status as a string, one of:
 *   - 'never-reported'
 *   - 'normal'
 *   - 'acceptable'
 *   - 'unsatisfactory'
 *   - 'unacceptable'
 *   - 'severe'
 * @returns {AssetStatus} The corresponding `AssetStatus` enum value.
 */
const getAssetStatusType = (assetStatus: AssetStatusString): AssetStatus => {
  switch (assetStatus) {
    case 'never-reported':
      return AssetStatus['never-reported']
    case 'normal':
      return AssetStatus.normal
    case 'acceptable':
      return AssetStatus.acceptable
    case 'unsatisfactory':
      return AssetStatus.unsatisfactory
    case 'unacceptable':
      return AssetStatus.unacceptable
    case 'severe':
      return AssetStatus.severe
    default:
      return AssetStatus.normal
  }
}

/**
 * Get enum keys as an array with optional exclusion.
 * @param enumObj - The enum object to extract keys from.
 * @param keysToExclude - Optional array of enum keys to exclude.
 * @returns Array of enum keys excluding the specified keys.
 * @example
 * ```typescript
 * // Get all keys
 * const allKeys = getEnumKeys(AssetStatus);
 *
 * // Exclude specific keys
 * const filteredKeys = getEnumKeys(AssetStatus, ['never-reported']);
 * ```
 */
const getEnumKeys = <T extends { [key: string]: string | number }, K extends keyof T = keyof T>(
  enumObj: T,
  keysToExclude?: K[]
): Exclude<keyof T, K>[] => {
  return Object.keys(enumObj).filter((key) => !keysToExclude || !keysToExclude.includes(key as K)) as Exclude<
    keyof T,
    K
  >[]
}

/**
 * Checks if a given value is defined (not `undefined`).

 * @template A - The type of the value to check.
 * @param {A | undefined} x - The value to check.
 * @returns {x is A} - A type guard that returns `true` if `x` is defined, `false` otherwise.
 */
const isDefined = <A>(x: A | undefined): x is A => x !== undefined

/**
 * Converts a boolean value to a string representation, optionally capitalizing the first letter.

 * @param {boolean} booleanValue - The boolean value to convert.
 * @param {boolean} [withCapitalization=false] - Whether to capitalize the first letter of the result.
 * @returns {string} The string representation of the boolean value.
 */
const getBooleanString = (booleanValue: boolean, withCapitalization: boolean = false): string => {
  let result = booleanValue.toString()

  if (withCapitalization) {
    result = result.charAt(0).toUpperCase() + result.slice(1)
  }

  return result
}

/**
 * Extracts the name and extension from a file name string.
 *
 * @param {string} fileName - The full file name including extension.
 * @returns {{ name: string; extension: string }}
 * An object containing:
 * - `name`: The file name without the extension.
 * - `extension`: The file extension (if any), or an empty string if no extension exists.
 *
 * @example
 * extractFileNameAndExtension("example.file.txt");
 * // Returns: { name: "example.file", extension: "txt" }
 *
 * @example
 * extractFileNameAndExtension("example");
 * // Returns: { name: "example", extension: "" }
 *
 * @example
 * extractFileNameAndExtension(".hiddenfile");
 * // Returns: { name: ".hiddenfile", extension: "" }
 */
const extractFileNameAndExtension = (fileName: string): ImageFileNameData => {
  const lastDotIndex = fileName.lastIndexOf('.')
  return lastDotIndex > 0
    ? { fileName: fileName.slice(0, lastDotIndex), fileExtension: fileName.slice(lastDotIndex + 1) }
    : { fileName, fileExtension: '' }
}
/*
 * Returns an array of status types based on the given status option value.
 * @param {StatusOptionValue} value - The selected status option.
 * @returns {StatusType[]} - An array of status types matching the given value.
 */
const getAssetStatuses = (value: StatusOptionValue): StatusType[] => {
  switch (value) {
    case 'all':
      return AllAssetStatuses
    case 'hideNormal':
      return getEnumKeys(AssetStatus, ['never-reported', 'normal'])
    case 'unsatisfactoryUnacceptableSevere':
      return getEnumKeys(AssetStatus, ['never-reported', 'normal', 'acceptable'])
    case 'unsatisfactorySevere':
      return getEnumKeys(AssetStatus, ['never-reported', 'normal', 'acceptable', 'unacceptable'])
    case 'severe':
      return getEnumKeys(AssetStatus, ['never-reported', 'normal', 'acceptable', 'unacceptable', 'unsatisfactory'])
    default:
      return []
  }
}

/**
 * Maps a list of asset statuses to a corresponding status option value.
 *
 * This function compares the provided statuses against predefined groups of statuses
 * and returns a specific status option value. If no specific group matches, it defaults to "all".
 *
 * @param {AssetStatusString[]} statuses - The array of asset statuses to be mapped.
 * @returns {StatusOptionValue} - The corresponding status option value,
 */

const mapAssetStatusesOption = (statuses: AssetStatusString[]): StatusOptionValue => {
  const statusMappings: { [key: string]: AssetStatusString[] } = {
    all: AllAssetStatuses,
    hideNormal: getEnumKeys(AssetStatus, ['never-reported', 'normal']),
    unsatisfactoryUnacceptableSevere: getEnumKeys(AssetStatus, ['never-reported', 'normal', 'acceptable']),
    unsatisfactorySevere: getEnumKeys(AssetStatus, ['never-reported', 'normal', 'acceptable', 'unacceptable']),
    severe: getEnumKeys(AssetStatus, ['never-reported', 'normal', 'acceptable', 'unacceptable', 'unsatisfactory']),
  }

  for (const [key, expectedStatuses] of Object.entries(statusMappings)) {
    if (statuses.length === expectedStatuses.length && statuses.every((status) => expectedStatuses.includes(status))) {
      return key as StatusOptionValue
    }
  }

  return 'all'
}

/**
 * Checks if a given value is an object.

 * @param {unknown} value - The value to check.
 * @returns {boolean} `true` if the value is an object, `false` otherwise.

 * @description
 * This function determines whether a given value is an object. It excludes arrays and null values from the definition of an object.
 * 
 * **Note:** This function only checks for plain JavaScript objects. It will return `false` for objects created from custom constructors or other exotic objects.
 */
const isObject = (value: unknown): boolean => {
  return (
    value !== null &&
    typeof value === 'object' &&
    Object.prototype.toString.call(value) === '[object Object]' &&
    !Array.isArray(value)
  )
}
export {
  circularStringify,
  isEqual,
  waitForTimeout,
  roundTo,
  findLastIndex,
  findFirstIndex,
  isLoaderTypeArray,
  asyncThrow,
  isString,
  extractDateFromTimestamp,
  maxBy,
  clearSessionStorageKeys,
  capitalizeFirstLetter,
  findAndDelete,
  getRouteFromPathname,
  getSidFromPathname,
  uniqueIdPrefixable,
  orderBy,
  createCustomSortHandler,
  convertToBoolean,
  generateUUID,
  clearSessionStorageItems,
  clearMsalSessionStorageKeys,
  convertFileNameExtensionToLowercase,
  getAssetStatusType,
  getEnumKeys,
  isDefined,
  getBooleanString,
  extractFileNameAndExtension,
  getAssetStatuses,
  mapAssetStatusesOption,
  compareValues,
  isObject,
}
