import React from 'react'
import GenerateReport from '@/modules/report-generator/generateReport'
import AssetSelection from '@/modules/report-generator/assetsSelection'
import { StyledReportGeneratorContainer } from '@/modules/report-generator/styled'
import { dataTestId } from '@/tests/testid'

const ReportGenerator: React.FC = () => {
  return (
    <StyledReportGeneratorContainer data-testid={dataTestId.reportGenerator}>
      <AssetSelection />
      <GenerateReport />
    </StyledReportGeneratorContainer>
  )
}
export default ReportGenerator
