import React from 'react'
import { Flexbox } from '@skf-internal/ui-components-react'
import ReportFilters from '@/modules/report-generator/generateReport/reportFilters'
import {
  StyledClearFiltersButton,
  StyledGenerateReportContainer,
  StyledReportFilters,
  StyledReportGeneratorHeading,
} from '@/modules/report-generator/styled'
import { dataTestId } from '@/tests/testid'

const GenerateReport: React.FC = () => {
  return (
    <StyledGenerateReportContainer data-testid={dataTestId.generateReport}>
      <Flexbox feJustifyContent="space-between">
        <StyledReportGeneratorHeading as="h2">Report Generator</StyledReportGeneratorHeading>
        <StyledClearFiltersButton
          data-testid={dataTestId.generateReportClearFilterButton}
          feType="secondary"
          feSize="sm"
          disabled={true}
        >
          Clear filter
        </StyledClearFiltersButton>
      </Flexbox>
      <StyledReportFilters>
        <ReportFilters />
      </StyledReportFilters>
    </StyledGenerateReportContainer>
  )
}
export default GenerateReport
