import { FC } from 'react'
import { Flexbox, Text } from '@skf-internal/ui-components-react'
import { dataTestId } from '@/tests/testid'

const CustomerNoteHistoryInfo: FC<{ value: string; label: string }> = ({ value, label }) => {
  return (
    <Flexbox feGap="xs" feFlexDirection="column" data-testid={dataTestId.customerNoteHistoryInfo}>
      <Text feFontWeight="bold" feFontSize="sm">
        {label}
      </Text>
      <Text>{value}</Text>
    </Flexbox>
  )
}
export default CustomerNoteHistoryInfo
