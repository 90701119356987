import { FetchDataContextProvider, useFetchDataContext } from '@/contexts/common/fetchDataContext'
import { OverviewContextProvider } from '@/contexts/overview'
import { OverviewSummary } from '@/models/overview/types'
import OverviewHeader from '@/modules/overview/components/header'
import OverviewTable from '@/modules/overview/components/overview-table/table'
import useGetOverviewData from '@/modules/overview/hooks/useGetOverviewData'
import { StyledOverviewContainer, StyledTableCard, StyledViewFlexbox } from '@/modules/overview/styled'
import DataRefresher from '@/shared/components/DataRefresher'
import { LoadingType } from '@/shared/hooks/useApi'
import useCleanupStorage from '@/shared/hooks/useCleanupStorage'
import { dataTestId } from '@/tests/testid'
import { FC, useEffect } from 'react'

const Overview: FC = () => {
  return (
    <OverviewContextProvider>
      <FetchDataContextProvider>
        <StyledOverviewContainer>
          <OverviewPanel />
        </StyledOverviewContainer>
      </FetchDataContextProvider>
    </OverviewContextProvider>
  )
}

const OverviewPanel = () => {
  const { execute: getOverviewData, status: getOverviewDataStatus } = useGetOverviewData()

  useEffect(() => {
    getOverviewData({ loaderType: LoadingType.GLOBAL })
  }, [getOverviewData])

  const { setFetchDataFn, setFetchDataStatus } = useFetchDataContext()

  useEffect(() => {
    setFetchDataFn(() => getOverviewData)
  }, [getOverviewData, setFetchDataFn])

  useEffect(() => {
    setFetchDataStatus(getOverviewDataStatus)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getOverviewDataStatus])

  useCleanupStorage()

  return (
    <StyledViewFlexbox feGap="lg">
      <OverviewHeader />
      {getOverviewDataStatus !== 'loading' && (
        <StyledTableCard data-testid={dataTestId.overviewModuleComponent}>
          <OverviewTable />
          <DataRefresher<OverviewSummary> functionToExecute={getOverviewData} />
        </StyledTableCard>
      )}
    </StyledViewFlexbox>
  )
}

export default Overview
