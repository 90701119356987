import { Heading } from '@skf-internal/ui-components-react'
import { isEqual } from 'lodash-es'
import { useMemo, useState } from 'react'
import { Slice, YearMonth } from '@/models/widgets/asset-data-collection/types'
import { ConditionStatusType, ConditionStatusWidgetDataType } from '@/models/widgets/asset-health-condition/types'
import AssetHealthConditionCurrentMonthStatus from './ahc-current-month-status'
import { roundToPercentageForConditionWidget } from '@/modules/dashboard/utils/asset-health-condition'
import { dataTestId } from '@/tests/testid'
import { useDashboardContext } from '@/contexts/dashboard'
import useDeepCompareEffect from '@/shared/hooks/useDeepCompareEffect'
import AssetHealthConditionTrendView from './ahc-trend-view'
import { StyledAHCWidgetContainer, StyledAHCWidgetContent, StyledAHCWidgetHeading } from './styled'
import { useFetchDataContext } from '@/contexts/common/fetchDataContext'
import { useAppDispatch, useTypedSelector } from '@/store/store'
import { addWidgetType, removeWidgetLoader } from '@/store/loading/action'
import GenericLoader, { GenericLoaderType } from '@/shared/components/GenericLoader'
import useWidgets from '@/modules/dashboard/hooks/widgets/useWidgetsHook'
import { StyledRadioButton } from '@/modules/report-status/styled'
import { WidgetType } from '@/models/dashboard/types'

const AssetHealthCondition = () => {
  const { widgetAssetHealthConditionData } = useDashboardContext()
  const { fetchDataStatus } = useFetchDataContext()
  const {
    widgetConditionalStatuses: { status },
  } = useWidgets()
  const assetHealthConditionDispatch = useAppDispatch()
  const { widgetTypes } = useTypedSelector((state) => state.widgetLoading)
  const [showPercentages, setShowPercentages] = useState(false)
  const [radioValue, setRadioValue] = useState('Amount')
  const [selectedPeriod, setSelectedPeriod] = useState<YearMonth>()
  const [currentMonthDataInPercentages, setCurrentMonthDataInPercentages] =
    useState<Slice<ConditionStatusType, YearMonth>>()

  const slicesInPercentages = useMemo<ConditionStatusWidgetDataType>(
    () =>
      widgetAssetHealthConditionData?.map(({ data, period }) => ({
        period,
        data: roundToPercentageForConditionWidget(data),
      })),
    [widgetAssetHealthConditionData]
  )
  const getPercentageValue = (value: string) => {
    setRadioValue(value)
    if (value === 'Percentage') {
      setShowPercentages(true)
    } else {
      setShowPercentages(false)
    }
  }

  useDeepCompareEffect(() => {
    if (widgetAssetHealthConditionData.length > 0) {
      const currentMonth = new Date().getMonth() + 1
      const currentYear = new Date().getFullYear()
      const currentMonthData = widgetAssetHealthConditionData.find(
        (item) => item.period.month === currentMonth && item.period.year === currentYear
      )
      const defaultSelectedMonth = widgetAssetHealthConditionData[widgetAssetHealthConditionData.length - 1]
      if (currentMonthData || defaultSelectedMonth) {
        setSelectedPeriod(currentMonthData ? currentMonthData.period : defaultSelectedMonth?.period)
      }
      if (slicesInPercentages.length > 0 && defaultSelectedMonth) {
        setCurrentMonthDataInPercentages(
          (showPercentages ? slicesInPercentages : widgetAssetHealthConditionData).find(({ period }) =>
            isEqual(period, selectedPeriod)
          ) ?? defaultSelectedMonth
        )
      }
    }
  }, [widgetAssetHealthConditionData, selectedPeriod, showPercentages, slicesInPercentages])

  useDeepCompareEffect(() => {
    if ((status === 'success' || status === 'not-executed') && widgetTypes.includes(WidgetType.ASSET_HEALTH_CONDITION))
      assetHealthConditionDispatch(removeWidgetLoader(WidgetType.ASSET_HEALTH_CONDITION))
    if (fetchDataStatus === 'loading') {
      assetHealthConditionDispatch(addWidgetType(WidgetType.ASSET_HEALTH_CONDITION))
    }
  }, [status, widgetTypes, fetchDataStatus])

  const slices = showPercentages ? slicesInPercentages : widgetAssetHealthConditionData

  const shouldRenderAssetConditionWidget = useMemo(() => {
    return (
      widgetAssetHealthConditionData.length > 0 &&
      selectedPeriod &&
      currentMonthDataInPercentages &&
      !widgetTypes.includes(WidgetType.ASSET_HEALTH_CONDITION) &&
      fetchDataStatus !== 'loading'
    )
  }, [
    widgetAssetHealthConditionData.length,
    selectedPeriod,
    currentMonthDataInPercentages,
    widgetTypes,
    fetchDataStatus,
  ])

  return shouldRenderAssetConditionWidget ? (
    <StyledAHCWidgetContainer data-testid={dataTestId.assetHealthCondition}>
      <StyledAHCWidgetHeading>
        <Heading as="h2">Asset Health Condition</Heading>
        {/* <StyledHeaderWrapper> */}
        {/* <WidgetRefreshButton<GetConditionStatusesResponse>
            executeFn={execute}
            widgetType={WidgetType.ASSET_HEALTH_CONDITION}
          /> */}
        <StyledRadioButton
          value={radioValue}
          onChange={(_, value: string | number) => getPercentageValue(value as string)}
          feHorizontal
          feItems={[
            {
              feLabel: 'Amount',
              value: 'Amount',
              feSize: 'sm',
            },
            {
              feLabel: 'Percentage',
              value: 'Percentage',
              feSize: 'sm',
            },
          ]}
          feLegend=""
        />
        {/* </StyledHeaderWrapper> */}
      </StyledAHCWidgetHeading>
      <StyledAHCWidgetContent>
        <AssetHealthConditionTrendView
          slices={slices}
          showPercentages={showPercentages}
          selectedPeriod={selectedPeriod!}
        />
        <AssetHealthConditionCurrentMonthStatus
          slice={currentMonthDataInPercentages!}
          showPercentages={showPercentages}
        />
      </StyledAHCWidgetContent>
    </StyledAHCWidgetContainer>
  ) : (
    <GenericLoader loaderType={GenericLoaderType.WIDGET} />
  )
}
export default AssetHealthCondition
