import { AssetRow, ReasonIndicatorData } from '@/models/analysisBacklog/types'
import { BelongsToColumn } from '@/modules/analysis-backlog/asset-table/belongs-to-column'
import { filterRegistry } from '@/shared/components/TableComponents/GenericTableFilter/constants'
import { AssetStatusString, MetaTableState } from '@/shared/models/types'
import { COLORS, Icon, SPACINGS, css } from '@skf-internal/ui-components-react'
import { Row, createColumnHelper } from '@tanstack/react-table'
import { AssetStatusIcon } from '../status-icon'
import { ActionMenu } from './actions-menu'
import { CopyHierarchyButton } from './copy-name-button'
import { PriorityColumn } from '@/modules/analysis-backlog/asset-table/priority-column'
import AnalysisReason from '@/modules/analysis-backlog/analysis-reason'
import { DateFormats, formatDate } from '@/shared/dateUtils'
import { createTableSortingOrderMap, sortingRegistry } from '@/shared/components/TableComponents/sortingFunction'
import { analysisBacklogAssetStatusSortingOrder } from '@/shared/components/TableComponents/sortingFunction/constants'

const columnHelper = createColumnHelper<AssetRow>()

export const analysisBacklogTableDefinition = (tableState: boolean) => [
  columnHelper.accessor('priority', {
    header: undefined,
    cell: ({ row }) => {
      if (row.original.handled) {
        return <Icon feIcon="asset" feColor="gray" feSize="sm" />
      } else {
        return (
          <PriorityColumn isInaccuratePriority={row.original.isInaccuratePriority} priority={row.original.priority} />
        )
      }
    },
    size: 75,
    enableColumnFilter: false,
  }),
  columnHelper.accessor((row) => row.asset.name, {
    id: 'asset',
    header: 'Asset Name',
    cell: ({ getValue, row }) => {
      const assetName = getValue<string>()
      const hierarchy = row.original.belongsTo.hierarchyPath

      return (
        <div
          css={css`
            display: flex;
            align-items: center;
            column-gap: ${SPACINGS.xs};

            color: ${COLORS.brand};
          `}
        >
          <span
            css={css`
              word-wrap: break-word; /* IE */
              overflow: hidden;
            `}
          >
            {assetName}
          </span>
          <CopyHierarchyButton assetName={assetName} hierarchy={hierarchy} />
        </div>
      )
    },
    size: 200,
    enableColumnFilter: true,
    filterFn: filterRegistry.text.filterFn,
    meta: {
      filterType: 'text',
    },
  }),
  columnHelper.accessor((row) => row.belongsTo.name, {
    id: 'belongsTo',
    header: 'Belongs to',
    cell: ({ getValue, row }) => {
      const hierarchy = row.original.belongsTo.hierarchyPath
      const assetName = row.original.asset.name

      return <BelongsToColumn belongsTo={getValue<string>()} assetName={assetName} hierarchy={hierarchy} />
    },
    size: 200,
    enableColumnFilter: true,
    filterFn: filterRegistry.text.filterFn,
    meta: {
      filterType: 'text',
    },
  }),
  columnHelper.accessor('lastCollectedDate', {
    header: 'Collected',
    cell: ({ getValue }) => (
      <div css={css({ textAlign: 'center' })}>
        {getValue() !== 'no date available' ? formatDate(getValue<string>(), DateFormats.AmericanDateFormat) : '-'}
      </div>
    ),
    size: 145,
    sortingFn: sortingRegistry.date<AssetRow>,
    enableColumnFilter: true,
    meta: {
      filterType: 'date',
    },
    filterFn: filterRegistry.date.filterFn,
  }),
  columnHelper.accessor('lastReportedDate', {
    header: 'Reported',
    cell: ({ getValue }) => (
      <div css={css({ textAlign: 'center' })}>
        {getValue() !== 'no date available' ? formatDate(getValue<string>(), DateFormats.AmericanDateFormat) : '-'}
      </div>
    ),
    size: 145,
    sortingFn: sortingRegistry.date<AssetRow>,
    enableColumnFilter: true,
    meta: {
      filterType: 'date',
    },
    filterFn: filterRegistry.date.filterFn,
  }),
  columnHelper.accessor('status', {
    header: 'Status',
    cell: ({ getValue }) => (
      <AssetStatusIcon
        css={css`
          margin: auto;
        `}
        value={getValue<AssetStatusString>()}
      />
    ),
    size: 120,
    sortingFn: (rowA: Row<AssetRow>, rowB: Row<AssetRow>, columnId: string) =>
      sortingRegistry.status<AssetRow>(
        rowA,
        rowB,
        columnId,
        createTableSortingOrderMap(analysisBacklogAssetStatusSortingOrder)
      ),
    enableColumnFilter: true,
    filterFn: filterRegistry.status.filterFn,
    meta: {
      filterType: 'status',
    },
  }),
  columnHelper.accessor('analysisReasons', {
    id: 'analysisReasons',
    header: 'Analysis Reasons',
    cell: ({ row }) => {
      if (row.original.handled === false) {
        const analysisReasonsOrder: ReasonIndicatorData['type'][] = [
          'band',
          'diagnostics',
          'protean',
          'ai',
          'overall',
          'feedback',
          'device_fault',
          'no_sensor_data',
          'no_data_24h',
        ]
        const orderedAnalysisReasons = row.original.analysisReasons
          .slice()
          .sort((a, b) => analysisReasonsOrder.indexOf(a.type) - analysisReasonsOrder.indexOf(b.type))

        return (
          <div
            css={css`
              display: flex;
              flex-wrap: wrap;
              gap: ${SPACINGS.xs};
            `}
          >
            {orderedAnalysisReasons.map((reason, i) => (
              <AnalysisReason key={i} reason={reason} />
            ))}
          </div>
        )
      } else {
        return <></>
      }
    },
    enableSorting: false,
    enableColumnFilter: true,
    filterFn: filterRegistry.analysis_reason.filterFn,
    meta: {
      filterType: 'analysis_reason',
    },
    size: undefined,
    minSize: 300,
  }),
  columnHelper.display({
    id: 'actions',
    cell: ({ row: { original }, table, column }) => {
      const { columnFilters, sorting } = table.getState()
      const {
        columnDef: { meta },
      } = column
      const tableState = (meta as MetaTableState).tableState

      return (
        <ActionMenu
          assetRow={original}
          elements={
            original.status === 'never-reported' || original.status === 'normal'
              ? ['mark-as-normal', 'report-status']
              : ['report-status']
          }
          columnFilters={columnFilters}
          sorting={sorting}
          tableState={tableState}
        />
      )
    },
    size: 50,
    meta: {
      tableState,
    },
  }),
]
