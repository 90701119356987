import { CenteredLoaderContainer } from '@/shared/components/CenteredLoader/styled'
import { dataTestId } from '@/tests/testid'

import { Loader } from '@skf-internal/ui-components-react'

/**
 * A centered loader component that displays a loading indicator in the center of the screen.
 * @returns {JSX.Element} The JSX element representing the centered loader.
 */
const CenteredLoader = (): JSX.Element => {
  return (
    <CenteredLoaderContainer data-testid={dataTestId.centeredLoader}>
      <Loader />
    </CenteredLoaderContainer>
  )
}
export default CenteredLoader
