import { AssetStatusString } from '@/shared/models/types'
import { COLORS } from '@skf-internal/ui-components-react'

export const AssetColors: { [key in AssetStatusString]: string } = {
  'never-reported': COLORS.white,
  normal: COLORS.greenDark,
  acceptable: COLORS.greenBase,
  unsatisfactory: COLORS.yellowDarker,
  unacceptable: COLORS.orangeDark,
  severe: COLORS.redBase,
} as const
