import { Customer } from '@/models/customer/types'
import { CLEAN_CUSTOMERS_LIST, SET_CUSTOMERS_LIST } from '@/store/customersList/actions.types'

export const setCustomersList = (customers: Customer[]) => ({
  type: SET_CUSTOMERS_LIST,
  payload: customers,
})

export const cleanCustomersList = () => ({
  type: CLEAN_CUSTOMERS_LIST,
  payload: [],
})
