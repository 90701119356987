import { FC } from 'react'
import { StyledColoredSpan } from './styled'

interface ColoredValue {
  executeFn: (val: string, unit: string) => string
  alarmHighLevel: string
  warningHighLevel: string
  value: string
  unit: string
}

export const ColoredValue: FC<ColoredValue> = ({ executeFn, value, warningHighLevel, alarmHighLevel, unit }) => {
  return (
    <StyledColoredSpan value={value} alarm={alarmHighLevel} warning={warningHighLevel}>
      {executeFn(value, unit)}
    </StyledColoredSpan>
  )
}
