import {
  Channel,
  Device,
  DevicesAndSensorsData,
  DevicesSensorsTableDeviceRowData,
} from '@/models/devicesAndSensors/types'
import {
  ChannelFaultText,
  ChannelStatusString,
  DeviceFaultText,
  DeviceStatusString,
  deviceNumberFormatter,
} from '@/modules/devices-sensors/utils/constants'
import { roundTempValue } from '@/modules/devices-sensors/utils/tableUtils'
import { DateFormats, formatDate } from '@/shared/dateUtils'
import { roundTo } from '@/shared/utils'
import { ColumnFiltersState } from '@tanstack/react-table'
import { startCase } from 'lodash-es'

const transformDeviceModelStatus = (
  deviceModel: DevicesAndSensorsData['devices'][number]['model']
): Device['model'] => {
  let value: Device['model'] = 'invalid'

  if (deviceModel === 'IMxSixteenPlus' || deviceModel === 'IMxSixteenW') {
    value = 'IMX-16'
  } else if (deviceModel === 'IMxEight') {
    value = 'IMX-8'
  }

  return value
}

const transformChannel = (channel: DevicesAndSensorsData['devices'][number]['channels'][number]): Channel => {
  return {
    number: channel.number,
    name: channel.name,
    status: channel.status,
    type: channel.type,
    lastCommunicationDate: channel.lastCommunicationTime || undefined,
    voltage: channel.voltage,
    asset: channel.uniquePlaces,
    faults: channel.faults.sort(({ fault: a }, { fault: b }) => a.localeCompare(b)).map((f) => f['fault']),
  }
}

const transformDevice = (device: DevicesAndSensorsData['devices'][number]): Device => {
  return {
    id: device.deviceID,
    number: device.deviceNumber,
    name: device.deviceName,
    model: transformDeviceModelStatus(device.model),
    status: device.status,
    firmwareVersion: device.firmwareVersion,
    lastCommunicationDate: device.lastCommunicationDate || undefined,
    internalTemp: device.internalTemp,
    usedBuffer: device.usedBufferObject,
    faults: device.faults.sort(({ fault: a }, { fault: b }) => a.localeCompare(b)).map((f) => f['fault']),
    channels: device.channels.map(transformChannel),
    uniqueChannelsAssets: device.uniqueChannelsAssets,
  }
}

const formatInternalTemp = (internalTemp: string | null, unit: string): string => {
  if (internalTemp !== null)
    return `${formatProp(internalTemp, (prop) => roundTempValue(parseFloat(prop)))} ${formatProp(unit, (prop) => prop)}`
  else return '-'
}

const formatUsedBuffer = (usedBuffer: string | null, unit: string): string => {
  if (usedBuffer !== null)
    return `${formatProp(usedBuffer, (prop) => roundTo(parseFloat(prop), 1).toString())} ${formatProp(unit, (prop) => prop)}`
  else return '-'
}

type DateFilter = {
  dateFilterType: string
  endDate: Date | undefined
  startDate: Date | undefined
}

const filtersTypes = ['name', 'status', 'lastCommunicationDate']

const formatDeviceStatusAndFaults = (status: Device['status'], faults: Device['faults']) => {
  let text = `${DeviceStatusString[status]}`

  if (status !== 'normal') {
    text = `${text} ( ${faults.map((v) => DeviceFaultText[v]).join('|')} )`
  }

  return text
}

const formatChannelStatusAndFaults = (status: Channel['status'], faults: Channel['faults']) => {
  let text = `${ChannelStatusString[status]}`

  if (status !== 'normal') {
    text = `${text} ( ${faults.map((v) => ChannelFaultText[v]).join('|')} )`
  }

  return text
}

export const formatFilters = (filters: ColumnFiltersState): Record<string, string> => {
  const result: Record<string, string> = {
    name: 'none',
    status: 'none',
    lastCommunicationDate: 'none',
  }

  filters.forEach((filter) => {
    if (filter.value && filtersTypes.includes(filter.id)) {
      if (Array.isArray(filter.value)) {
        result[filter.id] = filter.value.reduce((result, value) => (result += startCase(value) + '.'), '')
      } else if (filter.id === 'lastCommunicationDate') {
        const dateFilterObject = filter.value as DateFilter
        const startDate = `Start Date: ${dateFilterObject.startDate?.toString() ?? 'none'}`
        const endDate = `End Date: ${dateFilterObject.endDate?.toString() ?? 'none'}`
        result[filter.id] = startDate.concat('.', endDate)
      } else {
        result[filter.id] = filter.value.toString()
      }
    }
  })

  return result
}

const exportDevicesAndSensorsDataToCSV = (
  data: DevicesSensorsTableDeviceRowData[],
  customerName: string,
  filters: ColumnFiltersState
): string => {
  const formattedFilters = formatFilters(filters)

  return [
    ['Site Name:', customerName].join(','),
    ['Dashboard:', 'Device & Sensor Management'].join(','),
    ['Export date:', formatDate(new Date(), DateFormats.AmericanDateTimeFormat)].join(','),
    [],
    [],
    [],
    [
      'Filters Applied:',
      '-',
      formattedFilters.name,
      '-',
      formattedFilters.status,
      formattedFilters.lastCommunicationDate,
      '-',
      '-',
      '-',
      '-',
      '-',
      '-',
    ].join(','),
    [
      'Device / Channel number',
      'Type',
      'Name',
      'Device',
      'Status',
      'Last Communication',
      'Device Firmware Version',
      'Device Internal Temperature',
      'Device used Buffer (MB)',
      'Sensor Voltage',
      'Sensor functional Location',
      'Sensor asset',
    ].join(','),
    data
      .map((device) => {
        const digitalChannelsData = device.channels
          .filter((c) => c.type === 'digital')
          .toSorted((a, b) => a.number - b.number) //sort alphabetically by type
          .map((c) => [
            `\t${deviceNumberFormatter.format(c.number)}`,
            'Sensor',
            c.name,
            device.name,
            formatChannelStatusAndFaults(c.status, c.faults),
            (c.lastCommunicationDate && formatDate(device.lastCommunicationDate, DateFormats.LongDateFormat)) || '-',
            '-',
            '-',
            '-',
            c.voltage || '-',
            (c.asset &&
              c.asset.map((item) => (item.functionalLocationName ? item.functionalLocationName : '-')).join('; ')) ||
              '-',
            (c.asset && c.asset.map((item) => (item.asset.name ? item.asset.name : '-')).join('; ')) || '-',
          ])
          .join('\n')

        const analogChannelsData = device.channels
          .filter((c) => c.type === 'analog')
          .toSorted((a, b) => a.number - b.number) //sort alphabetically by type
          .map((c) => [
            `\t${deviceNumberFormatter.format(c.number)}`,
            'Sensor',
            c.name,
            device.name,
            formatChannelStatusAndFaults(c.status, c.faults),
            (c.lastCommunicationDate && formatDate(device.lastCommunicationDate, DateFormats.LongDateFormat)) || '-',
            '-',
            '-',
            '-',
            c.voltage || '-',
            (c.asset &&
              c.asset.map((item) => (item.functionalLocationName ? item.functionalLocationName : '-')).join('; ')) ||
              '-',
            (c.asset && c.asset.map((item) => (item.asset.name ? item.asset.name : '-')).join('; ')) || '-',
          ])
          .join('\n')

        const deviceData = [
          `\t${deviceNumberFormatter.format(device.number)}`,
          'Device',
          device.name,
          '-',
          formatDeviceStatusAndFaults(device.status, device.faults),
          (device.lastCommunicationDate && formatDate(device.lastCommunicationDate, DateFormats.LongDateFormat)) || '-',
          device.firmwareVersion || '-',
          formatInternalTemp(device.internalTemp.value, device.internalTemp.unit),
          formatUsedBuffer(device.usedBuffer.value, device.usedBuffer.unit),
          '-',
          '-',
          '-',
        ].join()

        const returnData = [deviceData]

        analogChannelsData.length > 0 && returnData.push(analogChannelsData)
        digitalChannelsData.length > 0 && returnData.push(digitalChannelsData)

        return returnData.join('\n')
      })
      .join('\n'),
  ].join('\n')
}

/**
 * Formats a given value using a provided formatting function.
 *
 * @template T The type of the value to be formatted.
 * @param {T | undefined} prop - The value to be formatted.
 * @param {function(value: T): string} formatFunction - A function that formats the value.
 * @param {string} [defaultValue='-'] - The default value to return if the `prop` is undefined.
 * @returns {string} The formatted value or the default value if the `prop` is undefined.
 *
 * @example
 * ```javascript
 * const formattedDate = formatProp(myDate, (date) => date.toLocaleDateString());
 * const formattedNumber = formatProp(myNumber, (number) => number.toFixed(2));
 * ```
 */
const formatProp = <T>(prop: T | undefined, formatFunction: (value: T) => string, defaultValue = '-'): string => {
  return prop != undefined ? formatFunction(prop) : defaultValue
}

export { transformDevice, exportDevicesAndSensorsDataToCSV, formatInternalTemp, formatUsedBuffer, formatProp }
