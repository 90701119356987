import networkClient from '@/api/NetworkClient'
import { GetConditionStatusesResponse } from '@/api/paths/analytics/conditionStatuses/types'
import { CommonPostWidgetDataRequest, CommonPostWidgetDataResult } from '@/api/paths/analytics/common/types'
import { PATHS } from '@/api/types'
import { AxiosError } from 'axios'

const postConditionalStatuses = async (
  request: CommonPostWidgetDataRequest,
  handlers: CommonPostWidgetDataResult,
  params: Record<string, string | undefined>
): Promise<GetConditionStatusesResponse | void> => {
  try {
    const response = await networkClient.post<CommonPostWidgetDataRequest, GetConditionStatusesResponse>(
      PATHS.POST_CONDITION_STATUSES,
      request,
      {
        params,
      }
    )
    handlers.onSuccess && (await handlers.onSuccess(response.data))
  } catch (error) {
    handlers.onError && (await handlers.onError(error as AxiosError))
  }
}

export { postConditionalStatuses }
