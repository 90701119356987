import {
  StyledRecommendedActionDateDisplayLabel,
  StyledRecommendedActionDateDisplayValue,
} from '@/modules/report-status/styled'
import { dataTestId } from '@/tests/testid'
import { Flexbox } from '@skf-internal/ui-components-react'
import { FC } from 'react'

interface RecommendedActionDateDisplayProps {
  label: string
  value: string
}

export const RecommendedActionDateDisplay: FC<RecommendedActionDateDisplayProps> = ({ label, value }) => {
  return (
    <Flexbox feFlexDirection="column" data-testid={dataTestId.recommendedActionDateDisplay}>
      <StyledRecommendedActionDateDisplayLabel>{label}:</StyledRecommendedActionDateDisplayLabel>
      <StyledRecommendedActionDateDisplayValue>{value}</StyledRecommendedActionDateDisplayValue>
    </Flexbox>
  )
}
