import { START_HEADER_LOADING, STOP_LOADING } from './action.types'
import { LoadingLocalState, LoadingStateAction } from './types'

export const initialState: LoadingLocalState = {
  isHeaderLoading: false,
}

type HeaderLoadingReducer = (state: LoadingLocalState | undefined, action: LoadingStateAction) => LoadingLocalState

const reducerHeaderLoading: HeaderLoadingReducer = (state = initialState, action) => {
  if (action.type === START_HEADER_LOADING) {
    return {
      ...state,
      isHeaderLoading: true,
    }
  }
  if (action.type === STOP_LOADING) {
    return {
      ...state,
      isHeaderLoading: false,
    }
  }
  return state
}

export default reducerHeaderLoading
