import { FC, PropsWithChildren } from 'react'
import {
  StyledEvidenceRowChildrenContainer,
  StyledEvidenceRowContainer,
  StyledEvidenceRowMenuBar,
} from '@/modules/report-status/styled'
import { NonNormalStatus, Status } from '@/models/reportStatus/faultsFormStateTypes'
import { FaultEvidenceContextProvider } from '@/contexts/moduleContexts/evidence'

interface EvidenceRowProps extends PropsWithChildren {
  status: Status | NonNormalStatus
  faultId: string
}

const EvidenceRow: FC<EvidenceRowProps> = ({ children, status, faultId }) => {
  return (
    <FaultEvidenceContextProvider faultId={faultId}>
      <StyledEvidenceRowContainer status={status}>
        <div>Evidence</div>
        <StyledEvidenceRowChildrenContainer>{children}</StyledEvidenceRowChildrenContainer>
        <StyledEvidenceRowMenuBar />
      </StyledEvidenceRowContainer>
    </FaultEvidenceContextProvider>
  )
}
export default EvidenceRow
