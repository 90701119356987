import { START_GLOBAL_LOADING, STOP_LOADING } from './action.types'
import { LoadingGlobalState, LoadingStateAction } from './types'

export const initialState: LoadingGlobalState = {
  isGlobalLoading: false,
}

type LoadingGlobalReducer = (state: LoadingGlobalState | undefined, action: LoadingStateAction) => LoadingGlobalState

const reducerGlobalLoading: LoadingGlobalReducer = (state = initialState, action) => {
  if (action.type === START_GLOBAL_LOADING) {
    return {
      ...state,
      isGlobalLoading: true,
    }
  }
  if (action.type === STOP_LOADING) {
    return {
      ...state,
      isGlobalLoading: false,
    }
  }
  return state
}

export default reducerGlobalLoading
