import { OrphanNotesContextProvider } from '@/contexts/orphanNotes'
import useDeepCompareEffect from '@/shared/hooks/useDeepCompareEffect'
import { FC, useEffect } from 'react'
import OrphanNotesTable from './components/table'
import DataRefresher from '@/shared/components/DataRefresher'
import { FetchDataContextProvider, useFetchDataContext } from '@/contexts/common/fetchDataContext'
import { LoadingType } from '@/shared/hooks/useApi'
import { StyledOrphanNotesRoot } from '@/modules/orphan-notes/styled'
import { dataTestId } from '@/tests/testid'
import useGetOrphansNotes from '@/modules/orphan-notes/hooks/useGetOrphansNotes'
import { useParams } from 'react-router-dom'
import { OrphanNotes as OrphanNotesType } from '@/models/orphanNotes/types'
import useCleanupStorage from '@/shared/hooks/useCleanupStorage'

export const OrphanNotes: FC = () => (
  <OrphanNotesContextProvider>
    <FetchDataContextProvider>
      <InnerOrphanNotes />
    </FetchDataContextProvider>
  </OrphanNotesContextProvider>
)

const InnerOrphanNotes: FC = () => {
  // const { selectedCustomer } = useTypedSelector((state) => ({ ...state }))
  const { sid } = useParams()

  const { status, execute } = useGetOrphansNotes()

  const { setFetchDataFn, setFetchDataStatus } = useFetchDataContext()

  useEffect(() => {
    if (sid) {
      execute({ loaderType: LoadingType.GLOBAL })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sid])

  useEffect(() => {
    setFetchDataFn(() => execute)
  }, [execute, setFetchDataFn])

  useDeepCompareEffect(() => {
    setFetchDataStatus(status)
  }, [status])

  useCleanupStorage()

  return (
    <StyledOrphanNotesRoot data-testid={dataTestId.orphanNotesRoot}>
      <OrphanNotesTable />
      <DataRefresher<OrphanNotesType> functionToExecute={execute} />
    </StyledOrphanNotesRoot>
  )
}
