import { useReportStatusContext } from '@/contexts/reportStatus'
import { dataTestId } from '@/tests/testid'
import { Button, css, Dialog, Flexbox, SPACINGS, Text } from '@skf-internal/ui-components-react'
import { useNavigate } from 'react-router-dom'

const ConflictDialog = () => {
  const { isConflictDialogOpen, setIsConflictDialogOpen } = useReportStatusContext()
  const navigate = useNavigate()
  const closeConflictDialog = (withRefresh: boolean = false) => {
    setIsConflictDialogOpen(false)
    withRefresh && navigate(0)
  }
  return (
    <Dialog
      data-testid={dataTestId.conflictDialog}
      feTitle="Conflict"
      open={isConflictDialogOpen}
      feInterruptive={false}
    >
      <Flexbox feGap="sm" feFlexDirection="column">
        <Text>Another user has updated this fault, please refresh the page to review the new updates. </Text>
        <Text feFontWeight="bold">Your current updates will not be saved.</Text>
      </Flexbox>

      <Flexbox
        feJustifyContent="end"
        feGap="md"
        css={css`
          width: 100%;
          padding-top: ${SPACINGS.md};
        `}
      >
        <Button
          data-testid={dataTestId.conflictDialogCloseButton}
          feType="secondary"
          onClick={() => closeConflictDialog()}
        >
          Cancel
        </Button>

        <Button
          data-testid={dataTestId.conflictDialogRefreshButton}
          feType="primary"
          onClick={() => closeConflictDialog(true)}
        >
          Ok, Refresh
        </Button>
      </Flexbox>
    </Dialog>
  )
}

export default ConflictDialog
