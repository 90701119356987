import { FilterContentProps, DateFilterValue } from '@/shared/components/TableComponents/GenericTableFilter/types'
import { StyledDateFilterContentContainer } from '@/shared/components/TableComponents/styled'
import { addToDate, DateFormats, epochDate, parseDate } from '@/shared/dateUtils'
import useDeepCompareCallback from '@/shared/hooks/useDeepCompareCallback'
import { dataTestId } from '@/tests/testid'
import { DatePicker, Flexbox, RadioGroup, Spacer } from '@skf-internal/ui-components-react'
import { FC, useEffect, useState } from 'react'

/**
 * A component for filtering data based on date ranges.

 * @param {FilterContentProps<DateFilterValue>} props - The component props.
 * @property {DateFilterValue} props.initialValue - The initial filter value.
 * @property {(newFilterValue: DateFilterValue | undefined) => void} props.onFilterValueChange - Callback function to handle filter value changes.
 * @returns {JSX.Element} The rendered DateFilterContent component.
 */
const DateFilterContent: FC<FilterContentProps<DateFilterValue>> = ({ initialValue, onFilterValueChange }) => {
  const [dateFilterType, setDateFilterType] = useState<DateFilterValue['dateFilterType']>(
    initialValue?.dateFilterType || 'before'
  )

  const [startDate, setStartDate] = useState<Date | undefined>(initialValue?.startDate || new Date())
  const [endDate, setEndDate] = useState(initialValue?.endDate)

  const handleDateFilterTypeChange = useDeepCompareCallback(
    (value: DateFilterValue['dateFilterType']) => {
      setDateFilterType(value)
      setStartDate(value === 'before' ? new Date() : undefined)
      setEndDate(undefined)
      if (value === 'before') {
        onFilterValueChange({
          dateFilterType: value,
          startDate: new Date(),
          endDate: undefined,
        })
      } else {
        onFilterValueChange(undefined)
      }
    },
    [initialValue]
  )

  const handleSingleFilterChange = useDeepCompareCallback(
    (date: Date | undefined) => {
      setStartDate(date)
      setEndDate(undefined)

      if (date) {
        onFilterValueChange({
          dateFilterType,
          startDate: date,
          endDate: undefined,
        })
      } else {
        onFilterValueChange(undefined)
      }
    },
    [dateFilterType, endDate]
  )

  const handleRangeFilterChange = useDeepCompareCallback(
    (dates: [Date | undefined, Date | undefined]) => {
      const [start, end] = dates
      setStartDate(start)
      setEndDate(end)

      if (start && end) {
        onFilterValueChange({ dateFilterType, startDate: start, endDate: end })
      } else {
        onFilterValueChange(undefined)
      }
    },
    [dateFilterType]
  )

  useEffect(() => {
    if (!initialValue && dateFilterType === 'before') {
      onFilterValueChange({ dateFilterType, startDate: new Date(), endDate: undefined })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateFilterType])

  return (
    <StyledDateFilterContentContainer feJustifyContent="space-between" data-testid={dataTestId.dateFilterContent}>
      <RadioGroup<DateFilterValue['dateFilterType']>
        feItems={[
          {
            feLabel: 'Before date',
            value: 'before',
          },
          {
            feLabel: 'Between two dates',
            value: 'between',
          },
          {
            feLabel: 'Newer than date',
            value: 'after',
          },
        ]}
        feLegend="Date filter type"
        name="dateFilterType"
        onChange={(_, value) => handleDateFilterTypeChange(value)}
        value={dateFilterType}
      />

      <Spacer feHorizontal={true} feSpacing="xxs" />

      <Flexbox feAlignItems="end" feFlexDirection="column" feGap="xs">
        {(dateFilterType === 'after' || dateFilterType === 'before') && (
          <DatePicker
            placeholder={DateFormats.ISO8601Date}
            feLabel="Date Picker"
            onChange={(date) => handleSingleFilterChange(date as Date)}
            // @ts-expect-error lib issue
            value={[startDate]}
            feHideLabel
            allowClear
            feMinDate={epochDate().toDate()}
            feMaxDate={parseDate(addToDate(new Date(), 100, 'year'))}
          />
        )}

        {dateFilterType === 'between' && (
          <DatePicker
            feLabel="Date Picker"
            onChange={(range) => handleRangeFilterChange(range as [Date, Date])}
            value={[startDate, endDate]}
            allowClear
            feRange
            feHideLabel
            feMinDate={epochDate().toDate()}
            feMaxDate={parseDate(addToDate(new Date(), 100, 'years'))}
          />
        )}
      </Flexbox>
    </StyledDateFilterContentContainer>
  )
}
export default DateFilterContent
