import { useState, useEffect } from 'react'

/**
 * A custom hook that debounces a given input value.
 *
 * This hook takes a value and a delay, and returns a debounced version of the value.
 * The debounced value updates only after the specified delay period has passed since
 * the last change to the input value. This is useful for optimizing performance
 * by reducing the frequency of state updates in response to rapidly changing input.
 *
 * @template T
 * @param {T} value - The input value to debounce.
 * @param {number} [delay=500] - The debounce delay in milliseconds. Defaults to 500ms.
 * @returns {T} The debounced value.
 */

const useInputDebounce = <T>(value: T, delay = 500) => {
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)

    return () => {
      clearTimeout(timer)
    }
  }, [value, delay])

  return debouncedValue
}

export default useInputDebounce
