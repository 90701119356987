/**
 * Checks if a given element has content that overflows its visible area in either the horizontal or vertical direction.

 * @param {React.MutableRefObject<HTMLDivElement | null>} hoverRef - A reference to the HTML div element.
 * @returns {boolean} - `true` if the element has content overflow, `false` otherwise.
 */
const isMultilineOverflow = (hoverRef: React.MutableRefObject<HTMLDivElement | null>): boolean => {
  const { scrollWidth, scrollHeight, clientWidth, clientHeight } = hoverRef.current ?? {}

  if (
    scrollWidth === undefined ||
    scrollHeight === undefined ||
    clientWidth === undefined ||
    clientHeight === undefined
  ) {
    return false
  }
  return scrollWidth > clientWidth || scrollHeight > clientHeight
}
/**
 * Checks if a given string exceeds a specified character length.

 * @param {string | null | undefined} value - The string to check.
 * @param {number} characterLength - The maximum allowed character length.
 * @returns {boolean} - `true` if the string is longer than the character length, `false` otherwise.
 */
const isSingleLineOverflow = (value: string | null | undefined, characterLength: number = 10): boolean =>
  !!(value && value.length > characterLength)

/**
 * Constructs a string with line breaks replaced by `<br>` tags.

 * @param {string | null | undefined} value - The input string.
 * @returns {string} - The string with line breaks replaced.
 */
const constructMultilineEllipsisValue = (value: string | null | undefined): string =>
  value ? value.replace(/\n/g, '<br>') : ''

/**
 * Constructs a string with ellipsis if it exceeds a specified character length.

 * @param {string | null | undefined} value - The input string.
 * @param {number} characterLength - The maximum allowed character length.
 * @param {boolean} isOverflowing - Indicates whether the string is overflowing.
 * @returns {string} - The truncated string with ellipsis or the original string.
 */
const constructSingleLineEllipsisValue = (
  value: string | null | undefined,
  characterLength: number = 10,
  isOverflowing: boolean
): string => (value && isOverflowing ? value.slice(0, characterLength) + '...' : value || '')

export { isMultilineOverflow, constructMultilineEllipsisValue, isSingleLineOverflow, constructSingleLineEllipsisValue }
