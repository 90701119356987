import { Fault } from '@/models/reportStatus/faultsFormStateTypes'
import { useAsync } from '@/shared/hooks/useAsync'

export enum LoadingType {
  GLOBAL,
  HEADER,
  NONE,
  WIDGET,
}

export interface UseApiProps {
  loaderType?: LoadingType
  postData?: Fault[] | string[] | string
  assetId?: string
  widgetType?: string
}

/**
 * Custom hook to manage API requests with loading states.
 *
 * This hook uses the `useAsync` hook to manage asynchronous requests and returns
 * the current state of the request, along with methods to trigger and control the request.
 *
 * @template T - Type of the expected API response.
 *
 * @param {function} request - A function that returns a Promise for an API request.
 * @returns {object} - The current state of the request (loading , success , error , not-executed) and
 *                     methods to trigger and manage the request.
 */

export function useApi<T>(request: (props?: UseApiProps) => Promise<T>) {
  const [state, methods] = useAsync(request)

  return {
    ...state,
    ...methods,
  }
}
