import { getAnalyticsBacklogAssets } from '@/api/paths/analysis'
import { GetAnalysisBacklogAssetsResponse } from '@/api/paths/analysis/types'
import {
  getCustomerNotes,
  // getRecommendationTypes,
  getReportFaultTypes,
  getReportsHistory,
} from '@/api/paths/reportStatus'
import {
  // GetRecommendationTypesResponse,
  GetReportFaultTypesResponse,
  GetReportHistoryResponse,
} from '@/api/paths/reportStatus/types'
import { ReportStatusLoaderData } from '@/app/routes/types'
import { DarwinErrorDialog } from '@/shared/components/ErrorBoundary/DarwinError'
import { Assets } from '@/models/analysisBacklog/types'
import { Customer, siteIdsWithNotes } from '@/models/customer/types'
import { Site, SitesData } from '@/models/overview/types'
import { FaultsAndHistory, HalCustomerNoteData, ResponseCustomerNotes } from '@/models/reportStatus/types'
import { getAssetRow, mapAssetsData, mapFaultTypes } from '@/modules/analysis-backlog/utils/index'
import { mapAssetFaultsAndHistoryResponse, mapCustomerNotes } from '@/modules/report-status/utils'
import { ERR_CANCELED, ROUTES } from '@/shared/constants'
import { STORAGE_ERROR_COUNT, STORAGE_REPORT_STATUS_ORIGIN } from '@/shared/localStorageUtils'
import { getRouteFromPathname, getSidFromPathname } from '@/shared/utils'
import { setSelectedCustomer } from '@/store/selectedCustomer/actions'
import { store } from '@/store/store'
import { AxiosError } from 'axios'

const getReportStatusLoaderData = async (assetId: string | undefined): Promise<Partial<ReportStatusLoaderData>> => {
  const result: Partial<ReportStatusLoaderData> = {}
  const selectedCustomer = getSelectedCustomer()
  const storedCustomerId = getSidFromPathname(sessionStorage.getItem(STORAGE_REPORT_STATUS_ORIGIN))

  const dataToExecute = [
    getAnalyticsBacklogData(
      selectedCustomer && Object.keys(selectedCustomer).length > 0 ? selectedCustomer.id : storedCustomerId
    ),
    getAssetFaultsAndHistory(assetId),
    getReportCustomerNotes(assetId),
    getReportFaultTypesData(),
  ]

  const promises = await Promise.allSettled(dataToExecute)

  const [assets, assetFaultsAndHistory, customerNotes, reportFaultTypes] = promises

  const errors = promises.filter(
    (promise): promise is PromiseRejectedResult => promise.status === 'rejected' && promise.reason.code !== ERR_CANCELED
  )

  if (errors.length > 0) {
    throw new DarwinErrorDialog(errors[0].reason)
  } else {
    result.assets = (assets as PromiseFulfilledResult<Assets>).value
    result.assetFaultsAndHistory = (assetFaultsAndHistory as PromiseFulfilledResult<FaultsAndHistory>).value
    result.customerNotes = (customerNotes as PromiseFulfilledResult<HalCustomerNoteData[]>).value
    result.reportFaultTypes = (reportFaultTypes as PromiseFulfilledResult<ReturnType<typeof mapFaultTypes>>).value
    result.assetRow = getAssetRow(result.assets, assetId)
    const errorCount = sessionStorage.getItem(STORAGE_ERROR_COUNT)
    if (errorCount && +errorCount === 1) {
      sessionStorage.removeItem(STORAGE_ERROR_COUNT)
    }
  }

  return result
}

const getAnalyticsBacklogData = async (customerId: string) => {
  const analyticsBacklogDataResponse = (await getAnalyticsBacklogAssets(
    {
      onError: async (error) => {
        return Promise.reject(new DarwinErrorDialog(error))
      },
    },
    { customerId }
  )) as GetAnalysisBacklogAssetsResponse
  return mapAssetsData(analyticsBacklogDataResponse)
}

const getAssetFaultsAndHistory = async (assetId: string | undefined) => {
  const assetFaultsAndHistoryResponse = (await getReportsHistory(
    {
      onError: async (error) => {
        return Promise.reject(new DarwinErrorDialog(error))
      },
    },
    { assetId }
  )) as GetReportHistoryResponse
  return mapAssetFaultsAndHistoryResponse(assetFaultsAndHistoryResponse.data)
}

const getReportCustomerNotes = async (assetId: string | undefined) => {
  const customerNotesResponse = (await getCustomerNotes(
    {
      onError: async (error: AxiosError) => {
        return Promise.reject(new DarwinErrorDialog(error))
      },
    },
    { assetId, isOrphan: false }
  )) as ResponseCustomerNotes
  return mapCustomerNotes(customerNotesResponse)
}

const getReportFaultTypesData = async () => {
  const reportFaultTypesResponse = (await getReportFaultTypes({
    onError: async (error) => {
      return Promise.reject(new DarwinErrorDialog(error))
    },
  })) as GetReportFaultTypesResponse
  return mapFaultTypes(reportFaultTypesResponse)
}

/**
 * Retrieves the selected customer information based on the following criteria:
 *
 * 1. If the `selectedCustomer` is defined and not empty, it is returned.
 * 2. If `selectedCustomer` is empty and a stored customer exists in localStorage, it is returned.
 * 3. If neither `selectedCustomer` nor a stored customer is found, the first customer from `customersList` is returned.
 * 4. If `customersList` is empty, a default customer (presumably `customerListProd[0]`) is returned.
 *
 * @returns {Customer} The selected customer information.
 */
const getSelectedCustomer = (): Customer | undefined => {
  let result: Customer | undefined = undefined
  const { selectedCustomer, customersList } = store.getState()
  const pathCustomerId = getSidFromPathname(location.href)
  const storedCustomerId = getSidFromPathname(sessionStorage.getItem(STORAGE_REPORT_STATUS_ORIGIN))
  result = customersList.find((customer) => customer.id === pathCustomerId)

  if (
    (!result || Object.keys(result).length === 0) &&
    getRouteFromPathname(location.pathname) === ROUTES.REPORT_STATUS
  ) {
    result = customersList.find((customer) => customer.id === storedCustomerId)
    store.dispatch(setSelectedCustomer(result || selectedCustomer))
  }

  return result || selectedCustomer
}

// const getRecommendationTypesData = async () => {
//   const recommendationTypesResponse = (await getRecommendationTypes({
//     onError: async (error) => {
//       asyncThrow(new DarwinError(error))
//     },
//   })) as GetRecommendationTypesResponse
//   return mapRecommendationTypes(recommendationTypesResponse)
// }

/**
 * Transforms the API response data into a sorted list of Customer objects.
 * Each customer object combines company and site information, and indicates
 * whether customer notes are enabled for the site.
 *
 * @param {SitesData} data - Array of Site objects containing company and site information
 * @returns {Customer[]} An array of Customer objects sorted alphabetically by name with the following properties:
 *   - id {string|number} - The unique site identifier
 *   - name {string} - Combined company and site name (format: "CompanyName - SiteName")
 *   - customerNotesEnabled {boolean} - Indicates if customer notes are enabled for this site
 */

const createCustomerList = (data: SitesData): Customer[] => {
  return data
    .map((item: Site) => ({
      id: item.siteId,
      name: `${item.companyName} - ${item.siteName}`,
      customerNotesEnabled: siteIdsWithNotes.includes(item.siteId),
    }))
    .sort((a, b) => a.name.localeCompare(b.name))
}

export { getReportStatusLoaderData, createCustomerList }
