import {
  AssetCriticalityType,
  CriticalityOptionValue,
  ReportType,
  ReportTypeString,
} from '@/models/reportGenerator/types'
import { SelectItem } from '@skf-internal/ui-components-react'

/**
 * Returns an array of asset criticality types based on the given criticality option value.
 * @param {CriticalityOptionValue} value - The selected criticality option.
 * @returns {AssetCriticalityType[]} - An array of criticality types matching the given value.
 */
const getCriticality = (value: CriticalityOptionValue): AssetCriticalityType[] => {
  switch (value) {
    case 'all':
      return ['A', 'B', 'C']
    case 'a':
      return ['A']
    case 'ab':
      return ['A', 'B']
    case 'bc':
      return ['B', 'C']
    default:
      return []
  }
}

/**
 * Maps an array of asset criticality types to a specific criticality option value.
 * @param {AssetCriticalityType[]} criticality - An array of asset criticality types to map.
 * @returns {CriticalityOptionValue} - The criticality option value that represents the provided criticality types.
 */
const mapCriticalityOption = (criticality: AssetCriticalityType[]): CriticalityOptionValue => {
  switch (true) {
    case criticality.length === 1 && criticality.every((status) => ['A'].includes(status)):
      return 'a'
    case criticality.length === 2 && criticality.every((status) => ['A', 'B'].includes(status)):
      return 'ab'
    case criticality.length === 2 && criticality.every((status) => ['B', 'C'].includes(status)):
      return 'bc'
    default:
      return 'all'
  }
}

/**
 * Returns the report type enum value based on the provided report type string.
 * @param {ReportTypeString} reportType - The string representation of a report type.
 * @returns {ReportType} - The corresponding report type enum value.
 */
const getReportType = (reportType: ReportTypeString): ReportType => {
  switch (reportType) {
    case 'summaryCharts':
      return ReportType.summaryCharts
    case 'summarizedAssetHealth':
      return ReportType.summarizedAssetHealth
    case 'detailedAssetHealth':
      return ReportType.detailedAssetHealth
    case 'lastMeasurements':
      return ReportType.lastMeasurements
    default:
      return ReportType.openRecommendedActions
  }
}

/**
 * Utility function to transform options into SelectItem format.
 * @param options - The array of options with `label` and `value`.
 * @returns Transformed array of SelectItem objects.
 */
const mapToSelectItems = <T extends string>(options: ReadonlyArray<{ label: string; value: T }>): SelectItem<T>[] => {
  return options.map((option) => ({
    label: option.label,
    value: option.value,
  }))
}
export { getCriticality, mapCriticalityOption, getReportType, mapToSelectItems }
