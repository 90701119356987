import { RefObject, useRef } from 'react'

/**
 * Scrolls the specified element into view once when the component mounts or the `ref` changes.
 *
 * @param {RefObject<HTMLElement>} ref - A reference to the element to be scrolled into view.
 * @returns {Function} A function that can be called to manually trigger the scrolling behavior.
 */
const useScrollIntoViewOnce = (ref: RefObject<HTMLElement>) => {
  const hasScrolled = useRef(false)

  const scrollIntoView = () => {
    if (ref.current && !hasScrolled.current) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
      hasScrolled.current = true
    }
  }

  return scrollIntoView
}

export default useScrollIntoViewOnce
