import { useRef, useEffect, useCallback } from 'react'

type TimeoutCallback = () => void
/**
 * The useTimeout hook provides a way to manage timeouts in a React component.
 * Unlike typical timeouts that start immediately, this hook returns a function that can be used to start the timeout when needed.
 *
 * The useTimeout hook accepts two arguments and returns an object with two functions.
 *
 * `callback` (TimeoutCallback): A function to be executed when the timeout is triggered.
 *
 * `delay` (number): The delay in milliseconds before the callback is executed.
 *
 * Returns:
 *
 * `startTimeout` (() => void): A function to start the timeout.
 *
 * `clearTimeout` (() => void): A function to clear the timeout.
 */
const useTimeout = (callback: TimeoutCallback, delay: number) => {
  const timeoutRef = useRef<number | null>(null)
  const savedCallback = useRef<TimeoutCallback>(callback)

  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  const startTimeout = useCallback(() => {
    if (typeof delay === 'number') {
      timeoutRef.current = window.setTimeout(() => {
        savedCallback.current()
      }, delay)
    }
  }, [delay])

  const clearTimeout = useCallback(() => {
    if (timeoutRef.current !== null) {
      window.clearTimeout(timeoutRef.current)
      timeoutRef.current = null
    }
  }, [])

  useEffect(() => {
    return () => clearTimeout()
  }, [clearTimeout])

  return { startTimeout, clearTimeout }
}

export default useTimeout
