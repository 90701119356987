import { HalCustomerNoteData } from '@/models/reportStatus/types'
import { StyledInfoCard } from '@/modules/report-status/styled'
import { FC } from 'react'
import { DateFormats, formatDate } from '@/shared/dateUtils'
import { dataTestId } from '@/tests/testid'
import NoteInfo from '@/modules/report-status/components/customerNote/NoteInfo'

interface CustomerInfoCardProps {
  noteData: HalCustomerNoteData
}

const CustomerInfoCard: FC<CustomerInfoCardProps> = ({ noteData }) => {
  return (
    <StyledInfoCard data-testid={dataTestId.customerInfoCard}>
      <NoteInfo label="Engine Hour" notes={noteData.engineHour} />
      <NoteInfo label="Is Investigating Alert?" notes={String(noteData.isInvestigatingAlert)} />
      <NoteInfo label="Is Resolved Id Note SKF?" notes={String(noteData.isResolvedIdNoteSKF)} />
      <NoteInfo label="Is Investigating SKF" notes={String(noteData.isInvestigatingSKF)} />
      <NoteInfo label="SAP" notes={noteData.SAP} />
      <NoteInfo label="Type of Alert" notes={noteData.typeOfAlert} />
      <NoteInfo label="Drop list Level 1 to 4 SKF" notes={noteData.droplistLevel1to4SKF} />
      <NoteInfo label="Drop list Level 5 SKF" notes={noteData.droplistLevel5SKF} />
      <NoteInfo label="Creation Date" notes={formatDate(noteData.noteDate, DateFormats.AmericanDateFormat)} />
    </StyledInfoCard>
  )
}

export default CustomerInfoCard
