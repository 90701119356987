import { Customer } from '@/models/customer/types'
import { CLEAN_SELECTED_CUSTOMER, SET_SELECTED_CUSTOMER } from '@/store/selectedCustomer/actions.types'
import { SelectedCustomerAction, SelectedCustomerState } from '@/store/selectedCustomer/types'

export const initialState: SelectedCustomerState = {} as Customer

export type CustomersListReducer = (
  state: SelectedCustomerState | undefined,
  action: SelectedCustomerAction
) => SelectedCustomerState

const selectedCustomerReducer: CustomersListReducer = (state = initialState, action) => {
  if (action.type === SET_SELECTED_CUSTOMER) {
    return action.payload
  }

  if (action.type === CLEAN_SELECTED_CUSTOMER) {
    return null
  }

  return state
}

export default selectedCustomerReducer
