import networkClient from '@/api/NetworkClient'
import {
  GetDevicesAndSensorsDataRequest,
  GetDevicesAndSensorsDataResponse,
  GetDevicesAndSensorsDataResult,
} from '@/api/paths/devicesAndSensors/types'
import { PATHS } from '@/api/types'

import { DevicesAndSensorsData } from '@/models/devicesAndSensors/types'
import { AxiosError } from 'axios'

const getDevicesAndSensorsData = async (
  handlers: GetDevicesAndSensorsDataResult,
  params: Record<string, string | undefined>
): Promise<DevicesAndSensorsData | void> => {
  try {
    const response = await networkClient.get<GetDevicesAndSensorsDataRequest, GetDevicesAndSensorsDataResponse>(
      PATHS.GET_DEVICES_AND_SENSORS_DATA,
      {
        params,
      }
    )
    handlers.onSuccess && (await handlers.onSuccess(response.data))
  } catch (error) {
    handlers.onError && (await handlers.onError(error as AxiosError))
  }
}

export { getDevicesAndSensorsData }
