import { DATE_REFRESHER_TIMEOUT } from '@/shared/constants'
import { useEffect } from 'react'
import useInterval from '../../hooks/useInterval'
import { LoadingType, UseApiProps } from '@/shared/hooks/useApi'

type DateRefresherProps<T> = {
  /**
   * Function to be executed at each interval.
   * @param {Object} options - Options to customize the function behavior.
   * @param {LoadingType} options.loaderType - The type of loading state to set.
   */
  functionToExecute: (props: UseApiProps) => Promise<void | T>
}

/**
 * DataRefresher component that periodically executes a given function.
 *
 * @template T - The type parameter for the data that may be passed to functionToExecute.
 *
 * @param {DataRefresherProps<T>} props - The props for the DataRefresher component.
 * @param {(options: { loaderType: LoadingType }) => void} props.functionToExecute - The function to execute at each interval.
 *
 * @returns {JSX.Element} An empty fragment since the component does not render anything visually.
 */

const DataRefresher = <T,>({ functionToExecute }: DateRefresherProps<T>): JSX.Element => {
  const { startInterval, clearInterval } = useInterval(() => {
    functionToExecute({ loaderType: LoadingType.NONE })
  }, DATE_REFRESHER_TIMEOUT)

  useEffect(() => {
    startInterval()
    return () => {
      clearInterval()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <></>
}

export default DataRefresher
