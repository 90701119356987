import {
  StyledFaultedAlarmsPopup,
  StyledReasonIndicator,
} from '@/modules/analysis-backlog/asset-table/reasonIndicator/styled'
import { ReasonIndicatorProps } from '@/modules/analysis-backlog/asset-table/reasonIndicator/types'
import useRedirectToModuleWithSelectedCustomer from '@/shared/hooks/useRedirectToModuleWithSelectedCustomer'
import Badge from '@/shared/components/Badge'
import useDeepCompareMemo from '@/shared/hooks/useDeepCompareMemo'
import { dataTestId } from '@/tests/testid'
import { Divider, Flexbox, Icon, Text, useTooltip } from '@skf-internal/ui-components-react'
import { FC, useCallback, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { deviceNumberFormatter } from '@/modules/devices-sensors/utils/constants'

const ReasonIndicator: FC<ReasonIndicatorProps> = ({
  reasonIcon,
  reasonSeverity,
  reasonText,
  reasonCount,
  withRedirect = false,
  redirectUrl,
  withBadge = false,
  customStyle,
  onClick,
  innerRedirectModule,
  faultedData,
}) => {
  const iconData = useDeepCompareMemo(() => {
    let result: typeof reasonIcon = reasonIcon

    if (!result) {
      switch (reasonSeverity) {
        case 'alarm':
          result = { iconName: 'danger', iconColor: 'red' }
          break
        case 'alert':
          result = { iconName: 'warning', iconColor: 'orange' }
      }
    }
    return result
  }, [reasonIcon])

  const enableRedirect = useMemo(() => !!(withRedirect && redirectUrl), [withRedirect, redirectUrl])

  const iconElement = useDeepCompareMemo(() => {
    let result = <Icon feIcon={iconData.iconName} feColor={iconData.iconColor} feSize="sm" />
    if (withBadge) {
      result = (
        <Badge badgeContent="" bgColor="red">
          {result}
        </Badge>
      )
    }
    return result
  }, [iconData, withBadge])

  const redirectToInnerModule = useRedirectToModuleWithSelectedCustomer()
  const { sid } = useParams()

  const onReasonIndicatorClick = useCallback(() => {
    onClick && onClick()
    enableRedirect &&
      Object.assign(document.createElement('a'), {
        target: '_blank',
        href: redirectUrl,
      }).click()
    faultedData &&
      faultedData.length > 0 &&
      faultedData[0].deviceNumber &&
      sid &&
      innerRedirectModule &&
      redirectToInnerModule(innerRedirectModule, sid, {
        columnFilters: [{ id: 'number', value: faultedData[0].deviceNumber }],
      })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enableRedirect, redirectUrl, sid, faultedData, innerRedirectModule])

  const [hoveredRef, isHovered] = useTooltip()

  return (
    <>
      <StyledReasonIndicator
        style={{ ...customStyle }}
        withRedirect={enableRedirect || !!(innerRedirectModule && sid && faultedData && faultedData.length > 0)}
        onClick={onReasonIndicatorClick}
        feNoPadding
        data-testid={dataTestId.reasonIndicator}
        ref={hoveredRef}
      >
        {iconElement}
        <span>{`${reasonText}`}</span>
        {reasonCount > 0 && (
          <>
            <Divider data-testid={dataTestId.reasonIndicatorCount} feVertical={true} as="div" feSpacing="xxs" />{' '}
            <span>{reasonCount}</span>
          </>
        )}

        {enableRedirect && <Icon feIcon="openInNew" feSize="sm" />}
      </StyledReasonIndicator>
      {faultedData && faultedData.length > 0 && (
        <StyledFaultedAlarmsPopup isHovered={isHovered} triggerElement={hoveredRef.current}>
          <Flexbox feFlexDirection="column" feGap="sm" data-testid={dataTestId.reasonIndicatorFilterPopover}>
            {faultedData.flatMap(({ deviceNumber, deviceName, channelNumber, channelName, faults }) =>
              faults.map((f) => (
                <Text>{`${deviceNumberFormatter.format(deviceNumber)} ${deviceName}, ${deviceNumberFormatter.format(channelNumber)} ${channelName}, ${f}`}</Text>
              ))
            )}
          </Flexbox>
        </StyledFaultedAlarmsPopup>
      )}
    </>
  )
}

export default ReasonIndicator
