import {
  CLOSE_GENERIC_CONFIRM_DIALOG,
  SHOW_GENERIC_CONFIRM_DIALOG,
} from '@/store/genericConfirmationDialog/actions.types'
import { GenericConfirmDialogAction, GenericConfirmDialogState } from '@/store/genericConfirmationDialog/types'

export const initialState: GenericConfirmDialogState = {
  isOpen: false,
  title: '',
  message: '',
  buttons: [],
}

type GenericConfirmDialogDataReducer = (
  state: GenericConfirmDialogState | undefined,
  action: GenericConfirmDialogAction
) => GenericConfirmDialogState

const toggleGenericConfirmDialogReducer: GenericConfirmDialogDataReducer = (state = initialState, action) => {
  if (action.type === SHOW_GENERIC_CONFIRM_DIALOG) {
    return action.payload
  }
  if (action.type === CLOSE_GENERIC_CONFIRM_DIALOG) {
    return initialState
  }
  return state
}

export default toggleGenericConfirmDialogReducer
