import { Button, COLORS, Flexbox, Heading, SPACINGS, styled } from '@skf-internal/ui-components-react'

export const StyledReportGeneratorContainer = styled(Flexbox)`
  width: 100%;
  overflow: scroll;
  margin-top: -2rem;
  padding-bottom: 2rem;
`

export const StyledClearFiltersButton = styled(Button)`
  margin-top: auto;
  margin-bottom: 0.5rem;
`
export const StyledReportGeneratorHeading = styled(Heading)`
  font-weight: 300;
  font-size: 2.5rem;
  padding-bottom: 1rem;

  @media (width >= 1400px) {
    font-size: 3.5rem;
  }
`

//Asset selection
export const StyledAssetSelectionContainer = styled(Flexbox)`
  min-width: 21rem;
  height: 100%;
  padding: 0 1rem;
  background: ${COLORS.gray025};
  box-shadow: 0 0.0625rem 0.1875rem 0 rgb(0 0 0 / 25%);
  align-items: center;
  flex-direction: column;

  @media (width >= 1400px) {
    min-width: 25rem;
  }
`

export const StyledCompanyLogoContainer = styled(Flexbox)`
  height: 10rem;
  justify-content: center;
  align-items: center;
`

export const StyledCompanyLogo = styled.div`
  min-width: 10rem;
  min-height: 7rem;
`

export const StyledAssetHierarchyContainer = styled(Flexbox)`
  width: 100%;
  height: 42rem;
  flex-shrink: 0;
  border-radius: 0.25rem;
  background: ${COLORS.white};
  box-shadow: 0 0.0625rem 0.1875rem 0 rgb(0 0 0 / 25%);
  padding: 1.5rem;
  flex-direction: column;
`

//Generate Report
export const StyledGenerateReportContainer = styled.div`
  padding: 1rem;
  width: 100%;
  background-color: ${COLORS.gray300};
`
export const StyledReportFilters = styled(Flexbox)`
  width: 100%;
  height: 100vh;
  flex-direction: column;
  background-color: ${COLORS.white};
`

export const StyledReportFiltersContainer = styled(Flexbox)`
  gap: ${SPACINGS.md};
  min-height: 4.69rem;
  background-color: ${COLORS.gray100};
  padding: 2rem;
  flex-direction: column;
`

export const StyledTopSection = styled(Flexbox)`
  width: 100%;
  display: flex;
  gap: ${SPACINGS.md};
`

export const StyledSelectFiltersSection = styled(Flexbox)`
  width: 70%;
  gap: ${SPACINGS.md};
  display: flex;
  flex-direction: row;

  @media (width >= 1400px) {
    width: 85%;
  }
`

export const StyledReportDetailsSection = styled(Flexbox)`
  width: 30%;
  gap: ${SPACINGS.md};
  display: flex;
  justify-content: center;
  align-items: start;
`

export const StyledFlexItem = styled(Flexbox)`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: start;
`

export const StyledTotalAssets = styled(Flexbox)`
  width: 25rem;
  font-weight: 700;
  font-size: 0.9rem;
  color: ${COLORS.gray600};
  padding-top: 1.1rem;

  @media (width >= 1400px) {
    font-size: 1.5rem;
  }
`

export const StyledIconContainer = styled(Flexbox)`
  padding-top: 1.1rem;

  @media (width >= 1400px) {
    gap: ${SPACINGS.sm};
  }
`

export const StyledReportTypeSection = styled.div`
  width: 100%;
  border-top: 0.12rem solid ${COLORS.gray300};
`

export const StyledReportTypeContainer = styled(Flexbox)`
  font-size: 0.5rem;
  font-weight: 500;
  gap: 2rem;
`

export const StyledReportDateSelectWrap = styled.div`
  label {
    font-weight: 700;
  }

  span {
    font-weight: 400;
  }
`

export const StyledRegenerateButton = styled.button`
  margin-left: auto;
  font-weight: 600;
  color: ${COLORS.gray600};
`
export const StyledReportTypeHeading = styled(Flexbox)`
  padding: 1rem 0;
`

export const StyledReportSelectWrapper = styled.div`
  * {
    flex-wrap: wrap;
  }

  label {
    font-weight: 700;
  }

  span {
    font-weight: 400;
  }

  width: 100%;
`
