import { FC } from 'react'
import { HalCustomerNoteData } from '@/models/reportStatus/types'
import { dataTestId } from '@/tests/testid'
import { StyledHALCustomerNoteHistoryContainer } from '@/modules/report-status/styled'
import CustomerNoteHistoryInfo from '@/modules/report-status/components/history/customerNotes/CustomerNoteHistoryInfo'

interface HalCustomerNoteHistoryProps {
  customerNote: HalCustomerNoteData
}

const HalCustomerNoteHistory: FC<HalCustomerNoteHistoryProps> = ({ customerNote }) => {
  return (
    <StyledHALCustomerNoteHistoryContainer data-testid={dataTestId.halCustomerNoteHistory}>
      <CustomerNoteHistoryInfo value={String(customerNote.isInvestigatingAlert)} label="Is Investigating Alert?" />
      <CustomerNoteHistoryInfo value={String(customerNote.isResolvedIdNoteSKF)} label="Is Resolved ID Note SKF?" />
      <CustomerNoteHistoryInfo value={String(customerNote.engineHour)} label="Engine Hour" />
      <CustomerNoteHistoryInfo value={String(customerNote.SAP)} label="SAP" />
      <CustomerNoteHistoryInfo value={String(customerNote.typeOfAlert)} label="Alert Type" />
      <CustomerNoteHistoryInfo value={String(customerNote.isInvestigatingSKF)} label="Is SKF Investigating?" />
      <CustomerNoteHistoryInfo value={String(customerNote.droplistLevel1to4SKF)} label="Droplist Level 1-4 SKF" />
      <CustomerNoteHistoryInfo value={String(customerNote.droplistLevel5SKF)} label="Droplist Level 5 SKF" />
    </StyledHALCustomerNoteHistoryContainer>
  )
}

export default HalCustomerNoteHistory
