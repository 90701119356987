import { AssetHistorySnapshot } from '@/models/reportStatus/types'
import { StyledReconfirmRowContainer, StyledReconfirmRowInnerContainer } from '../../styled'
import HistoryReconfirmSection from './HistoryReconfirmSection'
import { dataTestId } from '@/tests/testid'

interface FaultHistoryContainer {
  snapshot: AssetHistorySnapshot | AssetHistorySnapshot[]
}

export const ReconfirmHistoryContainer = ({ snapshot }: FaultHistoryContainer) => {
  return (
    <>
      {!Array.isArray(snapshot) && snapshot.reconfirms.length > 0 && (
        <StyledReconfirmRowContainer feFlexDirection="column" data-testid={dataTestId.reconfirmHistoryContainer}>
          <StyledReconfirmRowInnerContainer feFlexDirection="row" feJustifyContent="flex-start">
            <HistoryReconfirmSection reconfirms={snapshot.reconfirms} />
          </StyledReconfirmRowInnerContainer>
        </StyledReconfirmRowContainer>
      )}
    </>
  )
}
