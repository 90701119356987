import { saveHalMockCustomerNote } from '@/api/paths/reportStatus'
import { useApi } from '@/shared/hooks/useApi'
import { openToast } from '@/store/genericToast/actions'
import { useAppDispatch } from '@/store/store'
import { useAsyncThrow } from '@/shared/hooks/useAsyncThrow'
import { AxiosError } from 'axios'
import { DarwinError } from '@/shared/components/ErrorBoundary/DarwinError'
import { HalMockCustomerNote } from '@/models/reportStatus/types'

/**
 * Creates a new Hal Mock Customer Note.

 * @param {HalMockCustomerNote} publishedCustomerNote - The customer note data to be created.
 * @param {() => void} successCallback - A callback function to be executed upon successful creation.
 * @returns {() => Promise<void>} A function that triggers the creation process and returns a Promise.

 * @description
 * This hook initiates the process of creating a new Hal Mock Customer Note. It dispatches an action to create the note, handles success and error scenarios, and displays appropriate toast messages.
 */
const useCreateHalMockCustomerNote = (publishedCustomerNote: HalMockCustomerNote, successCallback: () => void) => {
  const createHalMockCustomerNoteDispatch = useAppDispatch()
  const throwError = useAsyncThrow()
  return useApi(async () => {
    return saveHalMockCustomerNote(
      {
        onSuccess: async () => {
          createHalMockCustomerNoteDispatch(
            openToast({
              feSeverity: 'success',
              children: 'Customer note was successfully created',
            })
          )
          successCallback()
        },
        onError: async (error: AxiosError) => {
          throwError(new DarwinError(error))
        },
      },
      publishedCustomerNote
    )
  })
}

export default useCreateHalMockCustomerNote
