import { FC, useMemo, useCallback } from 'react'
import { Flexbox } from '@skf-internal/ui-components-react'
import { GlobalFilters, RecommendedActionStatusType, SelectFilterType } from '@/models/dashboard/globalFiltering/types'
import { useDashboardContext } from '@/contexts/dashboard'
import RecommendedActionSelect from './RecommendedActionSelect'
import StatusSelect from './StatusSelect'
import FaultTypeSelect from './FaultTypeSelect'
import {
  severities as allAssetStatusValues,
  workOrderStatuses as allRaStatusValues,
} from '@/modules/dashboard/utils/constants'
import useDeepCompareEffect from '@/shared/hooks/useDeepCompareEffect'
import { useLocation, useNavigationType } from 'react-router-dom'
import { ROUTES } from '@/shared/constants'
import { StyledGlobalFilterContainer } from '@/modules/dashboard/styled'

const GlobalFiltering: FC = () => {
  const {
    faultTypeSelectOptions,
    dashboardFilters,
    setDashboardFilters,
    setWidgetErrors,
    setWidgetAssetHealthFaultData,
  } = useDashboardContext()
  const location = useLocation()
  const navType = useNavigationType()
  const allFaultTypeValues = useMemo(
    () => faultTypeSelectOptions.map((option) => option.faultType),
    [faultTypeSelectOptions]
  )
  const updateFilters = useCallback(
    (newFilters: SelectFilterType) => {
      setDashboardFilters(newFilters)
      setWidgetErrors([])
    },
    [setDashboardFilters, setWidgetErrors]
  )

  useDeepCompareEffect(() => {
    const { dashboardFilters, from, widgetAssetHealthFaultData } = location?.state || {}

    if (
      navType === 'PUSH' &&
      from === ROUTES.REPORT_STATUS &&
      (dashboardFilters?.raStatus?.length ||
        dashboardFilters?.assetStatus?.length ||
        dashboardFilters?.faultType?.length)
    ) {
      updateFilters(dashboardFilters)
      setWidgetAssetHealthFaultData(widgetAssetHealthFaultData)
    } else {
      updateFilters({
        raStatus: allRaStatusValues as RecommendedActionStatusType[],
        faultType: allFaultTypeValues,
        assetStatus: allAssetStatusValues,
      })
    }
  }, [faultTypeSelectOptions, location, navType])

  const handleAssetStatusChange = useCallback(
    (assetStatus: SelectFilterType['assetStatus']) => {
      updateFilters({ ...dashboardFilters, assetStatus })
    },
    [dashboardFilters, updateFilters]
  )

  const handleRaStatusChange = useCallback(
    (raStatus: SelectFilterType['raStatus']) => {
      if (raStatus.includes('all') || raStatus.length === 0) {
        raStatus = allRaStatusValues as RecommendedActionStatusType[]
      }
      updateFilters({ ...dashboardFilters, raStatus })
    },
    [dashboardFilters, updateFilters]
  )

  const handleFaultTypeChange = useCallback(
    (faultType: GlobalFilters['faultType']) => {
      if (faultType.includes('all') || faultType.length === 0) {
        faultType = allFaultTypeValues
      }
      updateFilters({ ...dashboardFilters, faultType })
    },
    [dashboardFilters, updateFilters, allFaultTypeValues]
  )

  return (
    <StyledGlobalFilterContainer feAlignItems="center" data-tets>
      <Flexbox feJustifyContent="center" feAlignItems="start">
        <StatusSelect value={dashboardFilters.assetStatus} onChange={handleAssetStatusChange} />
      </Flexbox>
      <Flexbox feJustifyContent="center" feAlignItems="start">
        <RecommendedActionSelect value={dashboardFilters.raStatus} onChange={handleRaStatusChange} />
      </Flexbox>
      <Flexbox feJustifyContent="center" feAlignItems="start">
        <FaultTypeSelect value={dashboardFilters.faultType} onChange={handleFaultTypeChange} />
      </Flexbox>
    </StyledGlobalFilterContainer>
  )
}

export default GlobalFiltering
