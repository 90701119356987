import { RAwidgetDataType } from '@/models/widgets/recommended-actions/types'
import { tableCellFormatter, truncateValue } from '@/modules/dashboard/utils'

import { statusColor, statusText } from '@/modules/dashboard/utils/recommended-actions'
import { dataTestId } from '@/tests/testid'
import { css } from '@emotion/react'
import { FC } from 'react'

interface RATableRowProps {
  status: string
  slices: RAwidgetDataType
  showPercentages: boolean
}

const RATableRow: FC<RATableRowProps> = ({ status, slices, showPercentages }) => {
  return (
    <tr key={status} data-testid={dataTestId.recommendedActionsTrendViewTableRow}>
      <th
        css={css`
          color: ${statusColor(status)};
        `}
      >
        <h4>{statusText(status)}</h4>
      </th>
      {slices.map(({ period, data }) => (
        <td
          role="gridcell"
          key={`${period.year}-${period.month}-${status}`}
          css={css`
            background-color: ${Object.keys(data).length === 0 ? '#f6f6f6' : ''};
            color: ${data[status] >= 0 ? statusColor(status) : '#aaafb8'};
          `}
        >
          {data[status] >= 0 ? truncateValue(tableCellFormatter(data[status], 1, showPercentages)) : 'NA'}
        </td>
      ))}
    </tr>
  )
}

export default RATableRow
