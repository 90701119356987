import { OrphanNoteAcknowledged, OrphanNoteUnacknowledged, OrphanNotes } from '@/models/orphanNotes/types'
import { IconProps } from '@skf-internal/ui-components-react'

const transformUnacknowledgedNoteToAcknowledged = (
  unacknowledgedOrphanNote: OrphanNoteUnacknowledged
): OrphanNoteAcknowledged => {
  return {
    engineHour: unacknowledgedOrphanNote.engineHour,
    id: unacknowledgedOrphanNote.id,
    isInvestigatingAlert: unacknowledgedOrphanNote.isInvestigatingAlert,
    isResolvedIDNoteSKF: unacknowledgedOrphanNote.isResolvedIDNoteSKF,
    message: unacknowledgedOrphanNote.message,
    sap: unacknowledgedOrphanNote.sap,
    alertType: unacknowledgedOrphanNote.alertType,
    IsSKFInvestigating: unacknowledgedOrphanNote.IsSKFInvestigating,
    droplistLevel1to4SKF: unacknowledgedOrphanNote.droplistLevel1to4SKF,
    droplistLevel5SKF: unacknowledgedOrphanNote.droplistLevel5SKF,
    composedAt: unacknowledgedOrphanNote.composedAt,
    acknowledged: true,
    databaseName: unacknowledgedOrphanNote.databaseName,
    isSkf: false,
    orphan: unacknowledgedOrphanNote.orphan,
    lastModifiedTimestamp: unacknowledgedOrphanNote.lastModifiedTimestamp,
  }
}

const moveFromUnacknowledgedToAcknowledged = (
  unacknowledgedNote: OrphanNoteUnacknowledged,
  notes: Pick<OrphanNotes, 'unacknowledged' | 'acknowledged'>
): void => {
  const unacknowledgedNoteIndex = notes.unacknowledged.findIndex((row) => row.id === unacknowledgedNote.id)

  notes.unacknowledged.splice(unacknowledgedNoteIndex, 1)
  notes.acknowledged.push(transformUnacknowledgedNoteToAcknowledged(unacknowledgedNote))
}

// const mapAndFormatOrphanNotes = (orphanNotes: OrphanNotesResponseType): OrphanNotes => {
//   const formatOrphanNoteObject = (orphanNote: OrphanNoteResponseType, isAcknowledged: boolean): OrphanNote => {
//     return {
//       engineHour: orphanNote.engineHour,
//       isInvestigatingAlert: orphanNote.isInvestigatingAlert,
//       isInvestigatingSKF: orphanNote.IsSKFInvestigating,
//       isResolvedIdNoteSKF: orphanNote.isResolvedIDNoteSKF,
//       isSkf: false,
//       typeOfAlert: orphanNote.alertType,
//       noteDate: orphanNote.composedAt,
//       notes: orphanNote.message,
//       orphan: orphanNote.orphan,
//       SAP: orphanNote.sap,
//       databaseName: orphanNote.databaseName,
//       droplistLevel1to4SKF: orphanNote.droplistLevel1to4SKF,
//       droplistLevel5SKF: orphanNote.droplistLevel5SKF,
//       idNote: orphanNote.id,
//       acknowledgment: isAcknowledged,
//       lastModifiedTimestamp: orphanNote.lastModifiedTimestamp,
//     }
//   }

//   return {
//     acknowledged: orphanNotes.acknowledged
//       ? orphanNotes.acknowledged.map((on: OrphanNoteResponseType) => formatOrphanNoteObject(on, true))
//       : [],
//     unacknowledged: orphanNotes.unacknowledged
//       ? orphanNotes.unacknowledged.map((on: OrphanNoteResponseType) => formatOrphanNoteObject(on, false))
//       : [],
//   } as OrphanNotes
// }

const calculateOrphanNotesSubheaderInfoPanelData = (orphanNotes: OrphanNotes) => {
  return [
    {
      count: orphanNotes.unacknowledged.length,
      icon: 'unknownDiamond' as IconProps['feIcon'],
      iconColor: 'grey' as IconProps['feColor'],
      hoverText: 'Unacknowledged notes',
    },
    {
      count: orphanNotes.acknowledged.length,
      icon: 'checkCircle' as IconProps['feIcon'],
      iconColor: 'green' as IconProps['feColor'],
      hoverText: 'Acknowledged notes',
    },
  ]
}

export { /*mapAndFormatOrphanNotes,*/ moveFromUnacknowledgedToAcknowledged, calculateOrphanNotesSubheaderInfoPanelData }
