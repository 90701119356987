import { useEffect, RefObject } from 'react'

type OutsideClickHandler = (event: MouseEvent | TouchEvent) => void

type UseOutsideClickOptions = {
  classNames?: string[]
  ids?: string[]
  refs?: RefObject<HTMLElement>[]
}

/**
 * @function useOutsideClick
 * @description A React hook that detects clicks or touches outside of a specified area.
 *
 * @param {OutsideClickHandler} handler - A function to be called when a click or touch occurs outside of the specified area.
 *   - This function receives the event object (MouseEvent or TouchEvent) as an argument.
 *
 * @param {UseOutsideClickOptions} options - Configuration options for the hook.
 *   - `classNames` (optional): An array of CSS class names to check against the clicked element.
   - `ids` (optional): An array of element IDs to check against the clicked element.
   - `refs` (optional): An array of React ref objects pointing to DOM elements to exclude from the outside click area.
 *
 * @returns {void} This hook doesn't return a value, but it adds and removes event listeners during the component's lifecycle.
 *
 */
const useOutsideClick = (handler: OutsideClickHandler, options: UseOutsideClickOptions) => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent | TouchEvent) => {
      // Extract configuration options from the options object
      const { classNames = [], ids = [], refs = [] } = options

      // Get the clicked element
      const clickedElement = event.target as HTMLElement

      // Check if the clicked element is inside any of the specified elements
      const isClickedOutside = [
        // Flatten the classNames array and get the corresponding elements
        ...classNames.flatMap((className) => Array.from(document.getElementsByClassName(className))),

        // Flatten the ids array and get the corresponding elements
        ...ids.flatMap((id) => {
          const element = document.getElementById(id)
          return element ? [element] : []
        }),

        // Flatten the refs array and get the current elements
        ...refs.flatMap((ref) => (ref.current ? [ref.current] : [])),
      ].every((element) => !element.contains(clickedElement))

      // If the click was outside, call the handler function
      if (isClickedOutside) {
        handler(event)
      }
    }

    // Add event listeners
    document.addEventListener('mouseup', handleClickOutside)
    document.addEventListener('touchend', handleClickOutside)

    // Cleanup: Remove event listeners on unmount
    return () => {
      document.removeEventListener('mouseup', handleClickOutside)
      document.removeEventListener('touchend', handleClickOutside)
    }
  }, [handler, options])
}

export default useOutsideClick
