import { AxiosError } from 'axios'
import networkClient from '@/api/NetworkClient'
import { PATHS } from '@/api/types'
import {
  GetAnalysisBacklogAssetsRequest,
  GetAnalysisBacklogAssetsResponse,
  GetAnalysisBacklogAssetsResult,
  MarkReportAsNormalRequest,
  MarkReportAsNormalResponse,
  MarkReportAsNormalResult,
} from '@/api/paths/analysis/types'

const getAnalyticsBacklogAssets = async (
  handlers: GetAnalysisBacklogAssetsResult,
  params: Record<string, string | undefined>
): Promise<GetAnalysisBacklogAssetsResponse | void> => {
  try {
    const response = await networkClient.get<GetAnalysisBacklogAssetsRequest, GetAnalysisBacklogAssetsResponse>(
      PATHS.GET_ANALYSIS_BACKLOG_DATA,
      {
        params,
      }
    )
    handlers.onSuccess && (await handlers.onSuccess(response.data))
    return response.data
  } catch (error) {
    handlers.onError && (await handlers.onError(error as AxiosError))
  }
}

const markReportAsNormal = async (
  handlers: MarkReportAsNormalResult,
  params: Record<string, string>
): Promise<void> => {
  try {
    await networkClient.put<MarkReportAsNormalRequest, MarkReportAsNormalResponse>(
      PATHS.PUT_MARK_AS_NORMAL,
      undefined,
      {
        params,
      }
    )
    handlers.onSuccess && (await handlers.onSuccess())
  } catch (error) {
    handlers.onError && (await handlers.onError(error as AxiosError))
  }
}

export { getAnalyticsBacklogAssets, markReportAsNormal }
