import { AxiosError } from 'axios'
import { JwtPayload } from 'jwt-decode'

export enum PathIdentifiers {
  CustomerNotes = 'customer-notes',
  Dashboards = 'dashboard',
  Customers = 'customers',
  Analytics = 'analytics',
  Widget = 'widget',
  UnresolvedRecommendations = 'unresolved-ra',
  Reporting = 'reporting',
  OverallDashboard = 'overall-dashboard',
  DevicesAndSensors = 'devices-and-sensors',
  AnalysisBacklog = 'analysis',
  AssetHealthCondition = 'asset-health-condition',
  RecommendedAction = 'recommended-action',
  DueDatesStatus = 'open-ra-due-date',
  OpenFaults = 'open-faults',
  MockCustomerNote = 'customer_note',
  Documents = 'documents',
  Sites = 'sites',
}

export enum PATHS {
  ORPHAN_NOTES = `${PathIdentifiers.CustomerNotes}/companies/\${companyId}/orphans`,
  MARK_AS_ACKNOWLEDGED_ORPHAN_NOTES = `${PathIdentifiers.CustomerNotes}/notes/acknowledged`,
  DASHBOARDS = `${PathIdentifiers.Dashboards}`,
  DASHBOARDS_LAYOUT = `${PathIdentifiers.Dashboards}/\${dashboardId}/layouts`,
  DASHBOARDS_WIDGETS = `${PathIdentifiers.Dashboards}/\${dashboardId}/widgets`,
  UPDATE_DASHBOARD = `${PathIdentifiers.Dashboards}/\${dashboardId}`,
  CREATE_DASHBOARD = `${PathIdentifiers.Dashboards}`,
  UPDATE_DASHBOARD_LAYOUTS = `${PathIdentifiers.Dashboards}/\${dashboardId}/layouts`,
  CREATE_NEW_DASHBOARD_WIDGET = `${PathIdentifiers.Dashboards}/\${dashboardId}/widgets`,
  DELETE_DASHBOARD_WIDGET = `${PathIdentifiers.Dashboards}/\${dashboardId}/widgets/\${widgetId}`,
  POST_DATA_COLLECTION = `${PathIdentifiers.Analytics}/assets/datacollection`,
  GET_DATA_COLLECTION = `${PathIdentifiers.Analytics}/assets/datacollection/\${query_id}`,
  POST_FAULTS = `${PathIdentifiers.Widget}/open-faults/\${company_id}`,
  GET_FAULTS = `${PathIdentifiers.Analytics}/faults/\${query_id}`,
  GET_SITES_DATA = `${PathIdentifiers.Sites}`,
  GET_SITE_DETAILS = `${PathIdentifiers.Sites}/\${sid}`,
  GET_SITE_DETAILS_LOGO = `${PathIdentifiers.Documents}/files?fileIDs=\${fileId}&small=true`,
  POST_ALARMS = `${PathIdentifiers.Analytics}/assets/alarms`,
  GET_ALARMS = `${PathIdentifiers.Analytics}/assets/alarms/\${query_id}`,
  POST_CONDITION_STATUSES = `${PathIdentifiers.Widget}/asset-health-condition/\${company_id}`,
  GET_CONDITION_STATUSES = `${PathIdentifiers.Analytics}/assets/condition-statuses/\${query_id}`,
  POST_RECOMMENDATIONS = `${PathIdentifiers.Widget}/recommended-action/\${company_id}`,
  GET_RECOMMENDATIONS = `${PathIdentifiers.Analytics}/recommendations/\${query_id}`,
  POST_RA_DUE_DATES_STATUS = `${PathIdentifiers.Widget}/open-ra-due-date/\${company_id}`,
  CUSTOMERS = `${PathIdentifiers.Customers}`,
  GET_UNRESOLVED_RECOMMENDATIONS = `${PathIdentifiers.UnresolvedRecommendations}/\${companyId}`,
  GET_REPORT_FAULT_TYPES = `${PathIdentifiers.Reporting}/fault-types`,
  GET_OVERVIEW_DATA = `${PathIdentifiers.OverallDashboard}`,
  GET_DEVICES_AND_SENSORS_DATA = `${PathIdentifiers.DevicesAndSensors}/companies/\${companyId}/imx`,
  GET_ANALYSIS_BACKLOG_DATA = `${PathIdentifiers.AnalysisBacklog}/\${companyId}/assets`,
  PUT_MARK_AS_NORMAL = `${PathIdentifiers.Reporting}/assets/\${assetId}/reports/normal`,
  GET_AUTOMATIC_DIAGNOSTICS_DATA = `${PathIdentifiers.Reporting}/companies/\${companyId}/automatic-diagnostics`,
  GET_REPORT_HISTORY = `${PathIdentifiers.Reporting}/assets/\${assetId}/reports`,
  GET_CUSTOMER_NOTES = `${PathIdentifiers.CustomerNotes}/assets/\${assetId}/notes?orphan=\${isOrphan}`,
  GET_RECOMMENDATION_TYPES = `${PathIdentifiers.Reporting}/recommendation-types`,
  POST_PUBLISH_REPORT = `${PathIdentifiers.Reporting}/assets/\${assetId}/reports`,
  POST_RECONFIRM_REPORT = `${PathIdentifiers.Reporting}/assets/\${assetId}/reconfirm`,
  POST_MOCK_CUSTOMER_NOTE = `${PathIdentifiers.MockCustomerNote}`,
  POST_UPLOAD_EVIDENCE_IMAGES = `${PathIdentifiers.Documents}/files`,
  DELETE_EVIDENCE_IMAGE = `${PathIdentifiers.Documents}/files/\${fileId}`,
  PATCH_UPDATE_EVIDENCE_IMAGE_DATA = `${PathIdentifiers.Documents}/files/\${fileId}/metadata`,
}

export interface NetworkResult<T> {
  onSuccess?: (result: T) => Promise<void>
  onError?: (error: AxiosError) => Promise<void>
}

export type DarwinJWT = JwtPayload & {
  email: string
}
