import { useRef, useMemo, DependencyList } from 'react'
import { isEqual } from '@/shared/utils'

/**
 * A custom hook that works like `useMemo`, but performs a deep comparison of dependencies
 * to avoid unnecessary recalculations. The memoized value will only be recomputed if the
 * deep comparison determines that the dependencies have changed.
 *
 * @template T
 * @param {() => T} callback - The function whose result will be memoized.
 * @param {DependencyList} dependencies - The list of dependencies for the memoization,
 * which will be deeply compared to decide whether the memoized value should be recomputed.
 * @returns {T} - The memoized value.
 */

const useDeepCompareMemo = <T>(callback: () => T, dependencies: DependencyList): T => {
  const ref = useRef<DependencyList>(dependencies)

  if (!isEqual(ref.current, dependencies)) {
    ref.current = dependencies
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(callback, [ref.current])
}

export default useDeepCompareMemo
