import { GetAnalysisBacklogAssetsResponse } from '@/api/paths/analysis/types'
import { DarwinError } from '@/shared/components/ErrorBoundary/DarwinError'
import { AnalysisBacklogContextProvider, useAnalysisBacklogContext } from '@/contexts/analysisBacklog'
import { FetchDataContextProvider, useFetchDataContext } from '@/contexts/common/fetchDataContext'
import AnalysisBacklogHeader from '@/modules/analysis-backlog/header'
import useGetAnalyticsBacklogData from '@/modules/analysis-backlog/hooks/useGetAnalyticsBacklogData'
import { mapAssetsData } from '@/modules/analysis-backlog/utils/index'
import DataRefresher from '@/shared/components/DataRefresher'
import useApplyTableFiltersAndSorting from '@/shared/hooks/useApplyTableFiltersAndSorting'
import { useAsyncThrow } from '@/shared/hooks/useAsyncThrow'
import useDeepCompareEffect from '@/shared/hooks/useDeepCompareEffect'
import { useAppDispatch, useTypedSelector } from '@/store/store'
import {
  ColumnFiltersState,
  SortingState,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { AssetTable } from './asset-table'
import { analysisBacklogTableDefinition } from '@/modules/analysis-backlog/asset-table/table-column-definition'
import { createCustomSortHandler, isEqual } from '@/shared/utils'
import { stopAsyncPoller } from '@/store/asyncPoller/actions'
import AsyncPoller from '@/shared/components/AsyncPoller'
import { openToast } from '@/store/genericToast/actions'
import { setModuleHeaderActionPanelData } from '@/store/moduleHeader/actions'
import { LoadingType } from '@/shared/hooks/useApi'
import { useLocation, useParams } from 'react-router-dom'
import { useEffectOnce } from '@/shared/hooks/useEffectOnce'
import { StyledAnalysisBacklogRoot } from '@/modules/analysis-backlog/styled'
import useDeepCompareMemo from '@/shared/hooks/useDeepCompareMemo'
import useCleanupStorage from '@/shared/hooks/useCleanupStorage'

export const AnalysisBacklog: FC = () => {
  return (
    <StyledAnalysisBacklogRoot>
      <AnalysisBacklogContextProvider>
        <FetchDataContextProvider>
          <InnerAnalysisBacklog />
        </FetchDataContextProvider>
      </AnalysisBacklogContextProvider>
    </StyledAnalysisBacklogRoot>
  )
}

const InnerAnalysisBacklog = () => {
  const {
    asyncPoller: { isAsyncPollerActive, toastMessages },
  } = useTypedSelector((state) => ({ ...state }))
  const { analysisBacklogData: assets, setAnalysisBacklogData } = useAnalysisBacklogContext()
  const [showHandled, setShowHandled] = useState<boolean>(false)
  const [sorting, setSorting] = useState<SortingState>([])
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
  const [isTableFiltered, setIsTableFiltered] = useState<boolean>(false)
  const sortingChangeHandler = useMemo(() => createCustomSortHandler(3), [])

  const analysisBacklogDispatch = useAppDispatch()

  const { state } = useLocation()

  const { sid } = useParams()

  const throwError = useAsyncThrow()
  const { setFetchDataFn, setFetchDataStatus } = useFetchDataContext()

  const { status, execute } = useGetAnalyticsBacklogData(
    async (result: GetAnalysisBacklogAssetsResponse) => {
      const mappedAssetsData = mapAssetsData(result)

      if (isAsyncPollerActive) {
        if (!isEqual(assets, mappedAssetsData)) {
          analysisBacklogDispatch(stopAsyncPoller())
          setAnalysisBacklogData(mappedAssetsData)
        }
      } else {
        setAnalysisBacklogData(mappedAssetsData)
      }
    },
    async (error) => {
      if (isAsyncPollerActive) {
        analysisBacklogDispatch(setModuleHeaderActionPanelData({ isAsyncIndicatorShown: false }))
        analysisBacklogDispatch(stopAsyncPoller())
        analysisBacklogDispatch(
          openToast({
            children: toastMessages.failedPollingMessage,
            feSeverity: 'error',
            timeout: 3,
          })
        )
      } else {
        throwError(new DarwinError(error))
      }
    }
  )

  useCleanupStorage()

  useEffect(() => {
    setFetchDataFn(() => execute)
  }, [execute, setFetchDataFn])

  useDeepCompareEffect(() => {
    setFetchDataStatus(status)
  }, [status])

  useEffectOnce(() => {
    if (state?.assetsData) {
      setAnalysisBacklogData(state.assetsData)
    }
  })

  useEffectOnce(() => {
    if (state?.assetsData) {
      setTimeout(() => {
        state.assetsData = undefined
      }, 0)
    }
  })

  useEffect(() => {
    if (sid && !isAsyncPollerActive && !state?.assetsData) {
      execute({ loaderType: LoadingType.GLOBAL })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sid])

  const assetRows = useDeepCompareMemo(() => {
    if (assets === undefined) {
      return undefined
    }

    if (showHandled) {
      return [...assets.unhandled, ...assets.handled]
    }

    return assets.unhandled
  }, [assets, showHandled])

  const table = useReactTable({
    data: assetRows || [],
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    columns: analysisBacklogTableDefinition(showHandled),
    state: { sorting, columnFilters },
    onSortingChange: (updater) => {
      setSorting(sortingChangeHandler(updater))
    },
    onColumnFiltersChange: setColumnFilters,
    enableMultiSort: true,
    maxMultiSortColCount: 3,
    isMultiSortEvent: () => true,
  })

  const resetTableFilters = useCallback(() => {
    table.resetColumnFilters()
    table.resetSorting()
    setShowHandled(false)
  }, [table])

  const handleClearFilters = () => resetTableFilters()

  useEffect(() => {
    setIsTableFiltered(columnFilters.length === 0 && sorting.length === 0)
  }, [columnFilters, sorting])

  useEffect(() => resetTableFilters(), [resetTableFilters, sid])

  const { tableScrollOffset } = useApplyTableFiltersAndSorting({
    setColumnFilters,
    setIsTableFiltered,
    setSorting,
    setTableState: setShowHandled,
  })

  return (
    <>
      <AnalysisBacklogHeader
        table={table}
        isTableFiltered={isTableFiltered}
        handleClearFilters={handleClearFilters}
        showHandled={showHandled}
        setShowHandled={setShowHandled}
        columnFilters={columnFilters}
      />
      <AssetTable table={table} tableScrollOffset={tableScrollOffset} />
      <DataRefresher<GetAnalysisBacklogAssetsResponse> functionToExecute={execute} />
      <AsyncPoller<GetAnalysisBacklogAssetsResponse> pollerFn={execute} />
    </>
  )
}
