import { ROUTES } from '@/shared/constants'
import { openToast } from '@/store/genericToast/actions'
import { useAppDispatch } from '@/store/store'
import { ColumnFiltersState, SortingState } from '@tanstack/react-table'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useLocation, useNavigationType } from 'react-router-dom'

type UseApplyTableFiltersAndSortingProps = {
  setColumnFilters: (value: SetStateAction<ColumnFiltersState>) => void
  setIsTableFiltered: (value: SetStateAction<boolean>) => void
  setSorting: (value: SetStateAction<SortingState>) => void
  setTableState: Dispatch<SetStateAction<boolean>>
}

/**
 * Custom hook to apply table filters and sorting state based on the navigation state.
 *
 * This hook listens to changes in the location and navigation type, and applies column filters,
 * sorting, and table state based on the state passed back from the `report status` route.
 * It also dispatches a toast notification if any `toastProps` are provided in the navigation state.
 *
 * @param {(value: SetStateAction<ColumnFiltersState>) => void} props.setColumnFilters - Function to update the column filters state in the table.
 * @param {(value: SetStateAction<boolean>) => void} props.setIsTableFiltered - Function to update the table filtered state (true if the table is filtered).
 * @param {(value: SetStateAction<SortingState>) => void} props.setSorting - Function to update the sorting state of the table.
 * @param {Dispatch<SetStateAction<boolean>>} props.setTableState - Function to update the general table state.
 *
 * @returns {object} - Returns the scroll offset for the table, if any, to be used for restoring the scroll position.
 */
const useApplyTableFiltersAndSorting = ({
  setColumnFilters,
  setIsTableFiltered,
  setSorting,
  setTableState,
}: UseApplyTableFiltersAndSortingProps) => {
  const location = useLocation()
  const navType = useNavigationType()
  const applyTableFiltersAndSortingDispatch = useAppDispatch()

  const [tableScrollOffset, setTableScrollOffset] = useState<number | undefined>(undefined)

  useEffect(() => {
    const { columnFilters, sorting, from, toastProps, tableState, tableScrollPosition } = location?.state || {}

    if (
      navType === 'PUSH' &&
      from === ROUTES.REPORT_STATUS &&
      (columnFilters?.length ||
        sorting?.length ||
        toastProps !== undefined ||
        tableState !== undefined ||
        tableScrollPosition !== undefined)
    ) {
      if (columnFilters?.length) {
        setColumnFilters(columnFilters)
        setIsTableFiltered(columnFilters.length === 0)
      }
      if (sorting?.length) {
        setSorting(sorting)
      }
      if (tableState !== undefined) {
        setTableState(tableState)
      }

      if (tableScrollPosition !== undefined) {
        setTableScrollOffset(tableScrollPosition)
      }

      if (toastProps !== undefined) {
        applyTableFiltersAndSortingDispatch(openToast(toastProps))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, navType])
  return { tableScrollOffset }
}

export default useApplyTableFiltersAndSorting
