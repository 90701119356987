import { Button } from '@skf-internal/ui-components-react'
import { FC } from 'react'

type GenericConfirmationDialogButtonProps<T> = {
  label: string
  type: 'primary' | 'secondary'
  onClick: (event: React.MouseEvent<T, MouseEvent>) => void
}

/**
 * A generic confirmation dialog button component.
 *
 * This component renders a button with a specified label and type.
 * The button triggers a click event handler when clicked.
 *
 * @param {GenericConfirmationDialogButtonProps<HTMLButtonElement>} props - The props for the component.
 * @param {string} props.label - The text to display on the button.
 * @param {'primary' | 'secondary'} props.type - The type of button, determining its styling.
 * @param {(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void} props.onClick - The function to call on button click.
 *
 * @returns {JSX.Element} The rendered button component.
 */

const GenericConfirmationDialogButton: FC<GenericConfirmationDialogButtonProps<HTMLButtonElement>> = ({
  label,
  type,
  onClick,
}: GenericConfirmationDialogButtonProps<HTMLButtonElement>) => {
  return (
    <Button feType={type} onClick={onClick}>
      {label}
    </Button>
  )
}

export default GenericConfirmationDialogButton
