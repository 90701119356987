import networkClient from '@/api/NetworkClient'
import {
  GetDashboardLayoutsResult,
  GetDashboardLayoutsRequest,
  GetDashboardLayoutsResponse,
  GetDashboardsRequest,
  GetDashboardsResponse,
  GetDashboardsResult,
  GetDashboardWidgetsResult,
  GetDashboardWidgetsResponse,
  GetDashboardWidgetsRequest,
  UpdateDashboardRequest,
  UpdateDashboardResult,
  UpdateDashboardResponse,
  DeleteDashboardResult,
  DeleteDashboardRequest,
  DeleteDashboardResponse,
  CreateDashboardResult,
  CreateDashboardRequest,
  CreateDashboardResponse,
  UpdateDashboardLayoutRequest,
  UpdateDashboardLayoutResponse,
  CreateNewDashboardWidgetRequest,
  CreateNewDashboardWidgetResult,
  CreateNewDashboardWidgetResponse,
  DeleteDashboardWidgetRequest,
  DeleteDashboardWidgetResponse,
} from '@/api/paths/dashboard/types'
import { PATHS } from '@/api/types'

import { Dashboard, DashboardLayout, DashboardWidget } from '@/models/dashboard/types'
import { AxiosError } from 'axios'

const getDashboards = async (handlers: GetDashboardsResult): Promise<Dashboard[] | void> => {
  try {
    const response = await networkClient.get<GetDashboardsRequest, GetDashboardsResponse>(PATHS.DASHBOARDS)
    handlers.onSuccess && (await handlers.onSuccess(response.data))
  } catch (error) {
    handlers.onError && (await handlers.onError(error as AxiosError))
  }
}

const getDashboardLayouts = async (
  handlers: GetDashboardLayoutsResult,
  params: Record<string, string | undefined>
): Promise<DashboardLayout[] | void> => {
  try {
    const response = await networkClient.get<GetDashboardLayoutsRequest, GetDashboardLayoutsResponse>(
      PATHS.DASHBOARDS_LAYOUT,
      {
        params,
      }
    )
    handlers.onSuccess && (await handlers.onSuccess(response.data))
  } catch (error) {
    handlers.onError && (await handlers.onError(error as AxiosError))
  }
}

const getDashboardWidgets = async (
  handlers: GetDashboardWidgetsResult,
  params: Record<string, string | undefined>
): Promise<DashboardWidget[] | void> => {
  try {
    const response = await networkClient.get<GetDashboardWidgetsRequest, GetDashboardWidgetsResponse>(
      PATHS.DASHBOARDS_WIDGETS,
      {
        params,
      }
    )
    handlers.onSuccess && (await handlers.onSuccess(response.data))
  } catch (error) {
    handlers.onError && (await handlers.onError(error as AxiosError))
  }
}

const updateDashboardData = async (
  request: UpdateDashboardRequest,
  handlers: UpdateDashboardResult,
  params: Record<string, string>
): Promise<void> => {
  try {
    const response = await networkClient.put<UpdateDashboardRequest, UpdateDashboardResponse>(
      PATHS.UPDATE_DASHBOARD,
      request,
      {
        params,
      }
    )
    handlers.onSuccess && (await handlers.onSuccess(response.data))
  } catch (error) {
    handlers.onError && (await handlers.onError(error as AxiosError))
  }
}

const createDashboard = async (
  request: CreateDashboardRequest,
  handlers: CreateDashboardResult
): Promise<string | void> => {
  try {
    const response = await networkClient.post<CreateDashboardRequest, CreateDashboardResponse>(
      PATHS.CREATE_DASHBOARD,
      request
    )
    return response.data.dashboardId
  } catch (error) {
    handlers.onError && (await handlers.onError(error as AxiosError))
  }
}

const deleteDashboard = async (
  handlers: DeleteDashboardResult,
  params: Record<string, string | undefined>
): Promise<void> => {
  try {
    const response = await networkClient.delete<DeleteDashboardRequest, DeleteDashboardResponse>(PATHS.DASHBOARDS, {
      params,
    })
    handlers.onSuccess && (await handlers.onSuccess(response.data))
  } catch (error) {
    handlers.onError && (await handlers.onError(error as AxiosError))
  }
}

const updateDashboardLayouts = async (
  request: UpdateDashboardLayoutRequest,
  handlers: GetDashboardLayoutsResult,
  params: Record<string, string | undefined>
): Promise<UpdateDashboardLayoutResponse | void> => {
  try {
    const response = await networkClient.put<UpdateDashboardLayoutRequest, UpdateDashboardLayoutResponse>(
      PATHS.UPDATE_DASHBOARD_LAYOUTS,
      request,
      {
        params,
      }
    )
    handlers.onSuccess && (await handlers.onSuccess(response.data))
  } catch (error) {
    handlers.onError && (await handlers.onError(error as AxiosError))
  }
}

const createNewDashboardWidget = async (
  request: CreateNewDashboardWidgetRequest,
  handlers: CreateNewDashboardWidgetResult,
  params: Record<string, string | undefined>
): Promise<CreateNewDashboardWidgetResponse | void> => {
  try {
    const response = await networkClient.put<CreateNewDashboardWidgetRequest, CreateNewDashboardWidgetResponse>(
      PATHS.CREATE_NEW_DASHBOARD_WIDGET,
      request,
      {
        params,
      }
    )
    handlers.onSuccess && (await handlers.onSuccess(response.data))
  } catch (error) {
    handlers.onError && (await handlers.onError(error as AxiosError))
  }
}

const deleteDashboardWidget = async (
  handlers: DeleteDashboardResult,
  params: Record<string, string | undefined>
): Promise<void> => {
  try {
    const response = await networkClient.delete<DeleteDashboardWidgetRequest, DeleteDashboardWidgetResponse>(
      PATHS.DELETE_DASHBOARD_WIDGET,
      {
        params,
      }
    )
    handlers.onSuccess && (await handlers.onSuccess(response.data))
  } catch (error) {
    handlers.onError && (await handlers.onError(error as AxiosError))
  }
}

export {
  getDashboards,
  getDashboardLayouts,
  getDashboardWidgets,
  updateDashboardData,
  createDashboard,
  deleteDashboard,
  updateDashboardLayouts,
  createNewDashboardWidget,
  deleteDashboardWidget,
}
