import { css } from '@emotion/react'
import { Icon, SIZES, useTooltip, IconProps, Tooltip } from '@skf-internal/ui-components-react'
import { FC } from 'react'
import { AssetStatus } from '@/shared/models/types'

const AssetStatusIcon: FC<{ value: AssetStatus; className?: string }> = ({ value, className }) => {
  const [hoverRef, isHovered] = useTooltip()

  return (
    <div
      css={css`
        width: ${SIZES.s20};
        height: ${SIZES.s20};
      `}
      className={className}
      ref={hoverRef}
    >
      <Icon
        css={css`
          width: auto;
          height: auto;
        `}
        {...iconProps(value)}
        feSize="sm"
      />
      <Tooltip isHovered={isHovered} triggerElement={hoverRef.current}>
        {value ?? 'Invalid data'}
      </Tooltip>
    </div>
  )
}

export default AssetStatusIcon

const iconProps = (value: AssetStatus): Pick<IconProps, 'feIcon' | 'feColor' | 'feSize'> => {
  switch (value) {
    case AssetStatus['never-reported']:
      return { feIcon: 'circleStatus', feColor: 'gray' }
    case AssetStatus.normal:
      return { feIcon: 'checkCircle', feColor: 'green' }
    case AssetStatus.acceptable:
      return { feIcon: 'refresh', feColor: 'green' }
    case AssetStatus.unsatisfactory:
      return { feIcon: 'error', feColor: 'yellow' }
    case AssetStatus.unacceptable:
      return { feIcon: 'warning', feColor: 'orange' }
    case AssetStatus.severe:
      return { feIcon: 'danger', feColor: 'red' }
    default:
      return { feIcon: 'forbidden', feColor: 'red' }
  }
}
