import {
  StyledReconfirmRowStartAnchorContainer,
  StyledReconfirmRowStartAnchorLine,
} from '@/modules/report-status/styled'
import { dataTestId } from '@/tests/testid'

import { FC } from 'react'

interface HistoryLinePrefixProps {
  marginTop: string
}

const HistoryLinePrefix: FC<HistoryLinePrefixProps> = ({ marginTop }) => (
  <StyledReconfirmRowStartAnchorContainer marginTop={marginTop} data-testid={dataTestId.faultHistoryLinePrefix}>
    <StyledReconfirmRowStartAnchorLine />
  </StyledReconfirmRowStartAnchorContainer>
)

export default HistoryLinePrefix
