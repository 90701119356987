// =================================================================================================
// IMPORTS
// =================================================================================================

import { DevicesSensorsTableDeviceRowData } from '@/models/devicesAndSensors/types'
import { filterRegistry } from '@/shared/components/TableComponents/GenericTableFilter/constants'
import TableHeaderColumn from '@/shared/components/TableComponents/TableHeaderColumn'
import { ColumnHelper, Header } from '@tanstack/react-table'
import { FC, useMemo } from 'react'
import { ColumnCell } from './commonCells/column-cell'
import { ColumnDef } from '@/common/table/base-components/column-def'
import useWindowDimensions from '@/shared/hooks/useWindowDimensions'

// =================================================================================================
// CONSTANTS
// =================================================================================================

const COLUMN_WIDTH_IN_PERCENT = 23

// =================================================================================================
// COLUMN DEF
// =================================================================================================

export const generateNameColumnDefinition = (columnHelper: ColumnHelper<DevicesSensorsTableDeviceRowData>) =>
  columnHelper.accessor('name', {
    id: 'name',
    header: 'Device',
    enableColumnFilter: true,
    filterFn: filterRegistry.text.filterFn,
    meta: {
      filterType: 'text',
    },
    cell: ({ getValue }) => getValue(),
  })

// =================================================================================================
// COLUMN DEF COMPONENT
// =================================================================================================

export const DevicesSensorsTableNameColumnDef: FC = () => <ColumnDef columnWidth={COLUMN_WIDTH_IN_PERCENT} />

// =================================================================================================
// HEADER COMPONENT
// =================================================================================================

export const DevicesSensorsTableNameHeaderColumn: FC<{ header: Header<DevicesSensorsTableDeviceRowData, unknown> }> = ({
  header,
}) => {
  const { width } = useWindowDimensions()

  const characterLength = useMemo(() => {
    let result = 6
    if (width <= 1200) {
      result = 7
    } else if (width <= 1440) {
      result = 10
    } else {
      result = 15
    }
    return result
  }, [width])

  return (
    <TableHeaderColumn<DevicesSensorsTableDeviceRowData>
      columnWidth={COLUMN_WIDTH_IN_PERCENT}
      header={header}
      centerValue={false}
      title="Device"
      characterLength={characterLength}
    />
  )
}

// =================================================================================================
// CELL COMPONENT
// =================================================================================================

export const DevicesSensorsTableNameColumnCell: FC<{ value: string }> = ({ value }) => {
  const { width } = useWindowDimensions()

  const characterLength = useMemo(() => {
    let result = 6
    if (width <= 1200) {
      result = 7
    } else if (width <= 1440) {
      result = 14
    } else if (width < 1900) {
      result = 16
    } else {
      result = 20
    }
    return result
  }, [width])

  return (
    <ColumnCell
      characterLength={characterLength}
      columnWidth={COLUMN_WIDTH_IN_PERCENT}
      value={value}
      centerValue={false}
    />
  )
}
