import { useState, useEffect } from 'react'

/**
 * Get the current dimensions of the browser window.
 * @returns {{ width: number, height: number }} The width and height of the window.
 */
const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window
  return {
    width,
    height,
  }
}

/**
 * Custom hook to get the current window dimensions and automatically update them on resize.
 * @returns {{ width: number, height: number }} The current window dimensions (width and height).
 */
const useWindowDimensions = () => {
  // Initialize state with the current window dimensions
  const [windowDimensions, setWindowDimensions] = useState(() => getWindowDimensions())

  useEffect(() => {
    /**
     * Handle the window resize event and update the state with the new dimensions.
     */
    function handleResize() {
      setWindowDimensions(getWindowDimensions())
    }

    // Attach the resize event listener
    window.addEventListener('resize', handleResize)

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowDimensions
}

export default useWindowDimensions
