import { uploadFaultImages } from '@/api/paths/documents/files'
import { PostUploadFaultImagesRequest } from '@/api/paths/documents/files/types'
import { useSiteDetailsContext } from '@/contexts/siteDetails'
import { EvidenceImageData } from '@/models/reportStatus/evidenceTypes'
import { SiteDetailsData } from '@/models/siteDetails/types'
import { useApi } from '@/shared/hooks/useApi'
import { AxiosError } from 'axios'

const useUploadSiteDetailsLogo = (imageRequestData: EvidenceImageData) => {
  const { setLogoURL, siteDetailsError, setSiteDetailsError, setSiteDetailsEditedFormData, siteDetailsEditedFormData } =
    useSiteDetailsContext()
  return useApi(() => {
    const uploadRequest = {
      category: imageRequestData.bucketInfo.category,
      content: imageRequestData.imageInfo.content,
      contentType: imageRequestData.imageInfo.contentType,
      fileName: imageRequestData.imageInfo.fileName,
      nodeID: imageRequestData.appInfo.nodeID,
      siteID: imageRequestData.appInfo.siteID,
      tags: imageRequestData.bucketInfo.tags,
    } as PostUploadFaultImagesRequest

    return uploadFaultImages(uploadRequest, {
      onSuccess: async (result) => {
        setLogoURL(result.fileURL)
        setSiteDetailsEditedFormData({
          ...siteDetailsEditedFormData,
          logo: {
            ...siteDetailsEditedFormData?.logo,
            fileURL: result.fileURL,
            fileId: result.fileID,
            fileName: imageRequestData.imageInfo.fileName,
          },
        } as SiteDetailsData)
      },
      onError: async (error: AxiosError) => {
        setSiteDetailsError({ ...siteDetailsError, siteDetailsLogoUploadError: error.message })
      },
    })
  })
}

export default useUploadSiteDetailsLogo
