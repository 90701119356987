import { useFetchDataContext } from '@/contexts/common/fetchDataContext'
import { AssetRow } from '@/models/analysisBacklog/types'
import { calculateInfoPanelData } from '@/modules/analysis-backlog/utils/index'
import useDeepCompareEffect from '@/shared/hooks/useDeepCompareEffect'
import { setModuleHeaderConfigData } from '@/store/moduleHeader/actions'
import { useAppDispatch, useTypedSelector } from '@/store/store'
import { css } from '@emotion/react'
import { EmptyState } from '@skf-internal/ui-components-react'
import { Table } from '@tanstack/react-table'
import { useLocation } from 'react-router-dom'

interface EmptyAssetTableState {
  assetTable: Table<AssetRow>
}

const EmptyAssetTableState: React.FC<EmptyAssetTableState> = ({ assetTable }: EmptyAssetTableState) => {
  const {
    globalLoading: { isGlobalLoading },
  } = useTypedSelector((state) => ({ ...state }))

  const emptyAssetTableStateDispatch = useAppDispatch()
  const countRows = assetTable.getRowModel().rows
  const { fetchDataStatus } = useFetchDataContext()

  const { state } = useLocation()
  useDeepCompareEffect(() => {
    if (countRows.length === 0 && !isGlobalLoading && !state?.assetsData && fetchDataStatus === 'success') {
      emptyAssetTableStateDispatch(
        setModuleHeaderConfigData({
          infoPanelElement: undefined,
          infoPanelData: calculateInfoPanelData(assetTable, true),
        })
      )
    }
  }, [isGlobalLoading, state, emptyAssetTableStateDispatch, assetTable, countRows, fetchDataStatus])

  return (
    <>
      {(countRows.length === 0 && !isGlobalLoading && !state?.assetsData && (
        <div
          css={css({
            width: '100%',
            height: 'calc(100% - 36px)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          })}
        >
          <EmptyState
            feHeading={{
              children: 'No assets could be found',
            }}
          />
        </div>
      )) ||
        null}
    </>
  )
}
export default EmptyAssetTableState
