import { CustomersListState, CustomersListAction } from '@/store/customersList/types'
import { CLEAN_CUSTOMERS_LIST, SET_CUSTOMERS_LIST } from './actions.types'

export const initialState: CustomersListState = []

export type CustomersListReducer = (
  state: CustomersListState | undefined,
  action: CustomersListAction
) => CustomersListState

const customersListReducer: CustomersListReducer = (state = initialState, action) => {
  if (action.type === SET_CUSTOMERS_LIST) {
    return action.payload
  }

  if (action.type === CLEAN_CUSTOMERS_LIST) {
    return initialState
  }

  return state
}

export default customersListReducer
