import { DeviceStatus } from '@/models/devicesAndSensors/types'
import { DeviceStatusString } from '@/modules/devices-sensors/utils/constants'
import { FilterSummaryProps } from '@/shared/components/TableComponents/GenericTableFilter/types'
import { StyledDeviceStatusFilterSummaryContainer } from '@/shared/components/TableComponents/styled'
import { dataTestId } from '@/tests/testid'
import { FC } from 'react'

const DeviceStatusFilterSummary: FC<FilterSummaryProps<DeviceStatus[]>> = ({ filterValue }) => {
  return (
    <StyledDeviceStatusFilterSummaryContainer data-testid={dataTestId.deviceStatusFilterSummary}>
      {filterValue.map((v, index) => (
        <li key={index}>{DeviceStatusString[v]}</li>
      ))}
    </StyledDeviceStatusFilterSummaryContainer>
  )
}

export default DeviceStatusFilterSummary
