import { StatusCode } from '@/api/constants'
import { DarwinJWT, PathIdentifiers } from '@/api/types'
import { initializeMsal } from '@/modules/azure-auth/initializeMsal'
import { DarwinError } from '@/shared/components/ErrorBoundary/DarwinError'
import { ENV } from '@/shared/constants'
import { SilentRequest } from '@azure/msal-browser'
import axios, { AxiosError, AxiosResponse } from 'axios'
import { jwtDecode } from 'jwt-decode'

//DO NOT CHANGE THE ORDER OF CONDITIONS LOGIC BELOW!!!!!
const getBaseClientUrl = (url: string | undefined): string | undefined => {
  let result = undefined

  if (url) {
    if (import.meta.env.MODE === ENV.DEV) {
      result = 'http://localhost:6767/api/v1/'
    } else if (url.includes(PathIdentifiers.OverallDashboard)) {
      result = import.meta.env.VITE_DARWIN_OVERVIEW_BASE_URL
    } else if (url.includes(PathIdentifiers.MockCustomerNote)) {
      result = import.meta.env.VITE_DARWIN_MOCK_CUSTOMER_NOTE_SERVICE_BASE_URL
    } else if (url.includes(PathIdentifiers.CustomerNotes) || url.includes(PathIdentifiers.Reporting)) {
      result = import.meta.env.VITE_DARWIN_STATUS_REPORTING_BASE_URL
    } else if (url.includes(PathIdentifiers.Dashboards)) {
      result = `${import.meta.env.VITE_DASHBOARD_RESOURCE_BASE_URL}`
    } else if (
      url.includes(PathIdentifiers.AssetHealthCondition) ||
      url.includes(PathIdentifiers.RecommendedAction) ||
      url.includes(PathIdentifiers.DueDatesStatus) ||
      url.includes(PathIdentifiers.OpenFaults)
    ) {
      result = `${import.meta.env.VITE_DARWIN_ANALYTICS_SERVICE_BASE_URL}`
    } else if (url.includes(PathIdentifiers.UnresolvedRecommendations)) {
      result = import.meta.env.VITE_DARWIN_UNRESOLVED_RECOMMENDATION_SERVICE_BASE_URL
    } else if (url.includes(PathIdentifiers.DevicesAndSensors)) {
      result = import.meta.env.VITE_DARWIN_DEVICES_AND_SENSORS_SERVICE_BASE_URL
    } else if (url.includes(PathIdentifiers.AnalysisBacklog)) {
      result = import.meta.env.VITE_DARWIN_ANALYSIS_BACKLOG_SERVICE_BASE_URL
    } else if (url.includes(PathIdentifiers.Documents)) {
      result = import.meta.env.VITE_DARWIN_THINGS_SERVICE_BASE_URL
    } else if (url.includes(PathIdentifiers.Sites)) {
      result = import.meta.env.VITE_DARWIN_SITE_LIST_BASE_URL
    }
  } else {
    throw new axios.Cancel('Unknown base client found')
  }
  return result
}

const apiSuccessResponseMapping = async (response: AxiosResponse<Record<string, unknown>>) => {
  return response
}

const apiErrorResponseMapping = (error: AxiosError) => {
  if ((!error.status && !error.response) || (!error.status && error.response && !error.response.status)) {
    if (error.code === 'ERR_NETWORK') {
      error.status = StatusCode.TimeoutGateway
    }
  }
  throw error
}

const getUsername = async () => {
  const idToken = await getIdToken()
  let userName = null

  if (idToken) {
    userName = jwtDecode<DarwinJWT>(idToken).email
  } else {
    throw new DarwinError({
      status: StatusCode.BadToken,
      message: 'No Id token was provided',
      name: ' Bad Id Token',
      isAxiosError: false,
      toJSON: () => ({}),
    })
  }
  return userName
}

const getIdToken = async () => {
  const msalInstance = await initializeMsal()
  const account = msalInstance.getActiveAccount() || undefined

  if (account) {
    const request: SilentRequest = {
      scopes: import.meta.env.VITE_AZURE_SILENCE_REQUEST_SCOPES.split(','),
      account,
    }

    const idToken = await msalInstance
      .acquireTokenSilent(request)
      .then((response) => {
        return response.idToken
      })
      .catch((error) => {
        throw new DarwinError(error)
      })

    return idToken
  }

  return null
}

const getAuthToken = async () => {
  const msalInstance = await initializeMsal()
  const account = msalInstance.getActiveAccount() || undefined

  if (account) {
    const request: SilentRequest = {
      scopes: import.meta.env.VITE_AZURE_SILENCE_REQUEST_SCOPES.split(','),
      account,
    }

    const accessToken = await msalInstance
      .acquireTokenSilent(request)
      .then((response) => {
        return response.accessToken
      })
      .catch((error) => {
        throw new DarwinError(error)
      })

    return accessToken
  }

  return null
}

export { getUsername, getIdToken, getBaseClientUrl, apiSuccessResponseMapping, apiErrorResponseMapping, getAuthToken }
